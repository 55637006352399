import { Avatar, Box, Button, CircularProgress, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import React from 'react'
import {entities} from '../../HtmlEntity'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setOpenSnack, setSnackMessage } from '../../../store/authSlice'
import ReactGA from 'react-ga4'

const InviteFromUserListItem = ({user,token,socket,group_id}) => {
    const [inviting, setInviting] = React.useState(false)
    const [recentlyInvited,setRecentlyInvited] = React.useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleInviteFriends = () => {
      ReactGA.event({
        category:'invite button clicked',
        action:'handle invite',
        label: 'sending invitation from inviteFromUserlistItem'
      })
        setInviting(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/group/invite_to_group`, { group_id, to_user_id:user.u_id,message:'invite you to a group' }, { headers: { authorization: "Bearer " + token } })
          .then((res) => {
            setInviting(false)
            if (res.data.status === 1 || res.data.status === 2 || res.data.status === 3) {
              // invitation send
              // navigate(-1)
              setRecentlyInvited(true)
              setInviting(false)
            }
            // if (res.data.status === 2) {
            //   // invitation send and notification saved
            //   // navigate(-1)
            // }
            // if (res.data.status === 3) {
            //   // invitation send and failed to save notification
            //   // navigate(-1)
            // }
            if(res.data.status !== 0  && res.data.status !== 3) {
              // invitation sent
              socket.emit("sendInvitation",{receiverid:user.u_id}) 
            }
            dispatch(setSnackMessage(res.data.message))
            dispatch(setOpenSnack(true))
          }).catch(error => {
            setInviting(false)
            dispatch(setSnackMessage("Something went wrong.Please try again later."))
            dispatch(setOpenSnack(true))
          })
    
      }
  return (
    <ListItem
    // key={user.group_id}
    secondaryAction={
      (user.is_invited || user.is_member || recentlyInvited) ? null :
        <Box sx={{ position: 'relative'}}>
          <Button size='small' disabled={(inviting) ? true : false} disableElevation variant="contained" fullWidth onClick={handleInviteFriends} >Invite</Button>
          {inviting && (
            <CircularProgress
              size={24}
              sx={{
                color: 'orange',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
    }
    disablePadding
  >
    <ListItemButton>
      <ListItemAvatar>
        <Avatar
        sx={{width:30,height:30}}
          src={user.profile_pic}
        />
          {/* {group.name.replace(/&#?\w+;/g, match => entities[match]).charAt(0).toUpperCase()}</Avatar> */}
      </ListItemAvatar>
      <ListItemText
        primary={user.name.replace(/&#?\w+;/g, match => entities[match])}
        secondary = {<Typography variant='caption'>{(user.is_invited) && <span style={{ color: 'red' }}>Already invited</span>} {(user.is_member) && <span style={{ color: '#6c63FF' }}>Already a member</span>}</Typography>}
      />
    </ListItemButton>
  </ListItem>
  )
}

export default InviteFromUserListItem