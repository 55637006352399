import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Alert, Avatar, Box, Button, CircularProgress, Divider, TextField } from '@mui/material';
import Emojibottompost from './Emojibottompost';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import Comment from './Comment';
import CardTopHeader from './CardTopHeader';
import moment from 'moment/moment';
import { useRef } from 'react';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import { entities } from "../HtmlEntity"
import { setOpenUaDialog } from '../../store/groupSlice';
import AllPostCardTopHeader from '../AllPost/AllPostCardTopHeader';
import { editPostDescription, increasePostCommentCount, setFullPostDetails } from '../../store/postSlice';
import { FilledButton } from '../../CustomComponents';
import SharePostMenu from '../Menu/SharePostMenu';

export default function Basiccard({ val, value }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false)
  const [description, setDescription] = React.useState()
  const [isEditable, setisEditable] = React.useState(false)
  const [comment, setComment] = React.useState("")
  const [newComment, setNewComment] = React.useState({})
  const [totalComment, setTotalComment] = React.useState(0)
  const [isPinned, setIsPinned] = React.useState(null)
  const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
  const [is_saved, set_is_saved] = React.useState(false)
  const [is_commented, set_is_commented] = React.useState(false)
  const [isShowMore, setIsShowMore] = React.useState(false);
  const [updating, setUpdating] = React.useState(false)
  // const [lineCount, setLineCount] = React.useState(0);
  const socket = useOutletContext()
  const dispatch = useDispatch()
  const { uid = undefined, name, profile_pic } = JSON.parse(localStorage.getItem("user")) || {}
  const { role } = useSelector(state => state.group.groupShortInfo)
  const token = JSON.parse(localStorage.getItem('token'))
  const navigate = useNavigate()
  const location = useLocation()
  const ref = useRef(null)
  // const descref = useRef(null);

  React.useEffect(() => {
    setTotalComment(val.total_comment)
    set_is_saved(val.is_saved)
    set_is_commented(val.is_commented)
    if (val.description && val.description.length > 0) {
      setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
    }
    if (val.is_pinned !== null) {
      setIsPinned(val.is_pinned)
    }
  }, [])

  React.useEffect(() => {
    set_is_saved(val.is_saved)
},[val.is_saved])
  const toggleReadMore = () => setIsShowMore((show) => !show);

  // React.useEffect(() => {
  //   if (expanded === true && comment_list.length <= 0) {
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/comment/${val.xz12uied7}/${user_id}`, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {
  //         // dispatch(setFetchedComment(response.data.data))
  //         setComment_list(response.data.data)
  //       }).catch(error => {
  //       })
  //   }
  // }, [expanded])

  // const decreaseCommentReplyCount = (effectedCommentId) => {
  //   setComment_list(comment_list.map(el => {
  //     if (el.comment_id === effectedCommentId) {
  //       // setReplyToComment("")
  //       return { ...el, reply_count: parseInt(el.reply_count) - 1 }
  //     } else {
  //       return el
  //     }
  //   }))
  // }
  // const increaseCommentReplyCount = (effectedCommentId) => {
  //   setComment_list(comment_list.map(el => {
  //     if (el.comment_id === effectedCommentId) {
  //       // setReplyToComment("")
  //       return { ...el, reply_count: parseInt(el.reply_count) + 1 }
  //     } else {
  //       return el
  //     }
  //   }))
  // }

  // const deleteComment = (comment_id) => {
  //   axios.delete(`${process.env.REACT_APP_API_URL}/api/post/deleteComment/${comment_id}`, { headers: { authorization: "Bearer " + token } })
  //     .then(response => {
  //       setComment_list(comment_list.filter(el => el.comment_id !== comment_id))
  //     }).catch(error => {
  //     })
  // }

  // const handleCommentReaction = (commentIsReacted, comment_id) => {
  //   if (commentIsReacted === null) {
  //     axios.post(`${process.env.REACT_APP_API_URL}/api/post/commentAction`, { comment_id, user_id }, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {
  //         setComment_list(comment_list.map(el => {
  //           if (el.comment_id === comment_id) {
  //             return { ...el, is_reacted: 1, react_count: parseInt(el.react_count) + 1 }
  //           } else {
  //             return el
  //           }
  //         }))
  //       }).catch(error => {

  //       })
  //   } else {
  //     axios.delete(`${process.env.REACT_APP_API_URL}/api/post/commentAction/${comment_id}/${user_id}`, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {
  //         setComment_list(comment_list.map(el => {
  //           if (el.comment_id === comment_id) {
  //             return { ...el, is_reacted: null, react_count: parseInt(el.react_count) - 1 }
  //           } else {
  //             return el
  //           }
  //         }
  //         ))
  //       }).catch(error => {

  //       })

  //   }
  // }

  const handleRemovePost = () => {
    set_is_saved(false)
  }

  const callBackFunction = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  const options = {
    root: null,
    rootMargin: '-150px',
    threshold: 0.1
    // threshold: 1
  }

  React.useEffect(() => {
    // ref.current.ownerDocument.body.scrollTop = 0;

    const observer = new IntersectionObserver(callBackFunction, options)
    if (ref.current) {
      // observer.observe(refs)
      observer.observe(ref.current)
      if (isVisible) {
        if (val.file_type === "video") {
          //     ref.current.autoplay = true;
          ref.current.play()
          // var playPromise = ref.current.play()
          // if (playPromise !== undefined) {
          //   playPromise.then(function () {
          //     // Automatic playback started!
          //   }).catch(function (error) {
          //     // Automatic playback failed.
          //     // Show a UI element to let the user manually start playback.
          //   });
          // }
        }
      }
    }
    return () => {
      if (ref.current) {
        if (val.file_type === "video") {
          ref.current.pause()
          // var pausePromise = ref.current.pause()
          // if (pausePromise !== undefined) {
          //   pausePromise.then(function () {
          //     // Automatic playback started!
          //   }).catch(function (error) {
          //     // Automatic playback failed.
          //     // Show a UI element to let the user manually start playback.
          //   });
          // }
        }
        observer.unobserve(ref.current)
      }
    }
  }, [ref, options])


  const handleCommentSubmit = (e) => {
    e.preventDefault()
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    setCreateCommentStatus('loading')
    const data = { post_id: val.xz12uied7, group_id: val.group_id, comment, message: `comments on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`, user_to_notify: val.u_id,from_where:val.type,topic:'postComment' }
    axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status !== 0) {
          setTotalComment(totalComment + 1)
          const new_comment = {
            comment_id: response.data.comment_id,
            comment,
            added_on: moment().format(),
            user_id: uid,
            profile_pic,
            total_reply: 0,
            total_reacted: 0,
            is_reacted: null,
            name
          }
          setNewComment(new_comment)
          set_is_commented(true)
          // if (value === 'pin') {
          //   dispatch(increasePinPostCommentCount(val.xz12uied7))
          // } else if (value === 'saved') {
          //   dispatch(increaseSavePostCommentCount(val.xz12uied7))
          // } else if (value === 'myPost') {
          //   dispatch(increaseMyPostCommentCount(val.xz12uied7))
          // } else {
          // }
          dispatch(increasePostCommentCount({ feed_value: value, data: val.xz12uied7 }))
        }
        if (response.data.status === 1) {
          if (val.creator_id !== uid) {
            socket?.emit('commentOnPost', { receiverid: val.u_id })
          }
        }
        setCreateCommentStatus('ok')
      }).catch(error => {
        setCreateCommentStatus('error')
      })
    // dispatch(submitComment(data))
    setComment("")
  };

  const fullImageHandler = () => {
    dispatch(setFullPostDetails(val.org_file))
    navigate(`/full-image`)
  }

  const handleVisitSharedPeopleProfile = (shared_by_user_id, shared_from_group_id) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    navigate(`/profile/${shared_by_user_id}/${shared_from_group_id}`)
  }
  const handleVisitSharedGroupProfile = (shared_from_group_id) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    navigate(`/group-profile/${shared_from_group_id}`)
  }

  const handleUpdateDescription = (e) => {
    e.preventDefault()
    setUpdating(true)
    axios.put(`${process.env.REACT_APP_API_URL}/api/post/updatePost`, { post_id: val.xz12uied7, description }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        setisEditable(false)
        setUpdating(false)
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
        // if (value === 'pin') {
        //   dispatch(editPinPostDescription({ post_id: val.xz12uied7, description }))
        // } else if (value === 'saved') {
        //   dispatch(editSavePostDescription({ post_id: val.xz12uied7, description }))
        // } else if (value === 'myPost') {
        //   dispatch(editMyPostDescription({ post_id: val.xz12uied7, description }))
        // } else {
        // }
        dispatch(editPostDescription({ feed_value: value, post_id: val.xz12uied7, description }))
      }).catch(error => {
        setUpdating(false)
        dispatch(setSnackMessage("Something went wrong. Try later."))
        dispatch(setOpenSnack(true))
      })
  }
  // backgroundColor:val.role==="Super Admin" ? '#e7e6ff':val.role === 'Admin'?'#ebf7fa':'white'
  return (
    <>
      <Card elevation={0} sx={{ backgroundColor: val.is_colored === 'no' ? 'white' : val.role === 'Super Admin' ? '#d2f9de' : val.role === 'Admin' ? '#e6eeff' : 'white', width: '100%', marginBottom: 2, borderRadius: { xs: '0px', sm: '10px' } }}>
        {
          val.shared_by &&
          <Alert icon={false} severity="info" sx={{ borderRadius: '0px' }}>
            shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
          </Alert>
        }
        {
          (location.pathname === '/') ?
            <AllPostCardTopHeader isPinned={isPinned} post_type='basic' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} group_profile_pic={val.group_profile_pic} group_name={val.group_name} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} /> :
            <CardTopHeader isPinned={isPinned} post_type='basic' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} />
        }
        <CardContent sx={{ pt: 0, pb: 1 }}>
          {(description && description.length > 250 && !isShowMore) ?
            <>
              <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                {description.substring(0, 250)}...
              </Typography>
              <Typography component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</Typography>
            </>
            :
            <>
              <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                {description}
              </Typography>
              {
                description && description.length > 250 ?
                  <Typography sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</Typography> :
                  null
              }
            </>
          }
          {
            isEditable &&
            <Box component='form' onSubmit={handleUpdateDescription}>
              <TextField
                fullWidth
                value={description}
                id="edit_basic_post_dialog_textfield_id"
                label="edit post description"
                multiline
                maxRows={4}
                name="edit_description_id"
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mb: 1, mt: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button disableElevation variant="outlined" onClick={() => setisEditable(false)}>Cancel</Button>
                <Box sx={{ position: 'relative', ml: 1 }}>
                  <Button disableElevation type='submit' disabled={(!description || updating) ? true : false} variant="contained">Update</Button>
                  {updating && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: '#44bd32',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          }
          {
            (val.link) &&
            <Box>
              {(val.link && val.link.length > 100) ?
                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                  {val.link.substring(0, 100)} ...
                </a> :
                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                  {val.link}
                </a>
              }
            </Box>
          }
        </CardContent>
        {
          val.file_count > 0 && (val.file_type == 'image' || val.file_type == 'gif') ?
            <img ref={ref} alt='No pic'
              // src="https://images.pexels.com/photos/2166711/pexels-photo-2166711.jpeg?auto=compress&cs=tinysrgb&w=600"
              src={val.file_type === 'image' ? val.files : val.org_file}
              onClick={fullImageHandler}
              style={{ objectFit: 'cover', width: '100%', backgroundColor: '#2C3A47', minHeight: "200px",maxHeight:'500px', minWidth: '100%' }} loading='lazy' />
            : val.file_count > 0 && val.file_type === 'video' ?
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <video ref={ref} src={val.org_file} style={{ maxHeight: '500px', height: '100%', width: '100%' }} controls controlsList='nodownload' />
              </Box>
              : null
        }
        <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
          <Emojibottompost value={value} handleRemovePost={handleRemovePost} total_comment={totalComment} total_react={val.total_react} setExpanded={setExpanded} expanded={expanded} val={val} react={val.react} is_saved={is_saved} is_commented={is_commented} post_id={val.xz12uied7} />
          <Divider sx={{ mt: 1, display: { xs: 'block', sm: 'none' } }} />
        </CardActions>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '100%' },
            display: 'flex',
            alignItems: 'start',
            margin: '6px 10px 10px'
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleCommentSubmit}
        >
          {/* <textarea type='text' value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Comment something...' id="fname" name="fname" style={{
          width: '100%',
          padding: '12px 20px',
          // margin: '8px 0px',
          fontSize:'16px',
          boxSizing: 'border-box',
          border: 'none',
          backgroundColor: (val.is_colored === 'yes' && val.role !== 'General') ? 'white' : '#f1f2f3',
          color: 'white',
          outlineColor: '#dcdde1',
          color: '#525960',
          // maxLines:2,
          // wordBreak:'break-word'
        }} ></textarea> */}
          <Avatar variant='rounded' src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{ width: 32, height: 32 }} />
          <input
            id="basic_comment_textarea_id"
            value={comment}
            placeholder='comment something'
            style={{
              backgroundColor: '#EAEAF2',
              padding: '8px',
              border: 'none',
              outline: 'none',
              width: '100%',
              borderRadius: '6px',
              margin: "0px 10px"
            }}
            name="description"
            onChange={(e) => setComment(e.target.value)}
          />
          <Box sx={{ position: 'relative' }}>
            <FilledButton type='submit' size='small' variant="contained" disabled={(!token || createCommentStatus === 'loading') ? true : false} disableElevation>Send</FilledButton>

            {(createCommentStatus === 'loading') && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#44bd32',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ padding: '0px 8px 24px' }}>
            {
              (!token) ?
                <Typography mt={1} sx={{ textAlign: 'center', fontWeight: 600, color: '#2d3436', fontSize: '14px' }}>Sign In for read comments</Typography> :
                <Comment post_id={val.xz12uied7} is_colored={val.is_colored} role={val.role} value={value} newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} type={val.type} />
            }
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}
