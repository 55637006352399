import { Avatar, Badge, BottomNavigation, BottomNavigationAction, Drawer, Fab, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper } from '@mui/material'
import { styled } from '@mui/material/styles';
import React from 'react'
import HomeRoundedIcon from '@mui/icons-material/HomeOutlined';
import InvitationRoundedIcon from '@mui/icons-material/EmailRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GridViewIcon from '@mui/icons-material/GridView';
import AddIcon from '@mui/icons-material/Add';
import RecommendRoundedIcon from '@mui/icons-material/RecommendRounded';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Diversity1, Diversity3, Forum, PersonAdd } from '@mui/icons-material';
import theme from '../Theme';

const StyledFab = styled(Fab)({
    boxShadow: 'none',
    border: '2px solid black',
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

const MyBottomNavigation = () => {
    const [value, setValue] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(false)
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    const hasInvitation = useSelector(state => state.auth.hasInvitation)

    const ref = React.useRef(null);
    const location = useLocation()
    const navigate = useNavigate()
    React.useEffect(() => {
        if (location.pathname === "/") {
            setValue(0)
        } else if (location.pathname === "/gm-vjx") {
            setValue(1)
        } else if (location.pathname === "/fav-post") {
            setValue(3)
            // } else if (location.pathname === "/all_folders" || location.pathname.split('/')[1] === 'saved-post') {
        } else if (location.pathname === "/all_groups") {
            setValue(4)
        }
        else {
            setValue(null)
        }
    }, [location])
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [value]);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenDrawer(open)
    };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // }
    return (
        <Paper sx={{ position: 'fixed', zIndex: 999, bottom: 0, left: 0, right: 0, display: { xs: 'block', sm: 'none' } }} elevation={3}>
            <Drawer
                anchor='bottom'
                open={openDrawer}
                onClose={toggleDrawer(false)}
            >
                <List>
                    {/* {['Create Group Post', 'Upload Your Photo','Create Event','Create Offer','Boost Post','Sell Product'].map((text, index) => ( //,'Create Story','Ask a question' */}
                    {['Create Group Post', 'Post To Friends', 'Post To Follower'].map((text, index) => ( //,'Create Story','Ask a question'
                        <ListItem key={text} disablePadding>
                            <ListItemButton onClick={() => {
                                setOpenDrawer(false)
                                if (index === 0) {
                                    navigate('/new_create_post')
                                } else if (index === 1) {
                                    navigate("/ff_post", {
                                        state: {
                                            post_to: 'friend',
                                        },
                                    })
                                } else {
                                    navigate("/ff_post", {
                                        state: {
                                            post_to: 'follower',
                                        },
                                    })
                                }
                            }}>
                                <ListItemIcon>
                                    {index === 0 ? <Groups2OutlinedIcon /> : (index === 1) ? <Diversity3 /> : <Diversity1 />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <BottomNavigation
                // showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction icon={<HomeRoundedIcon fontSize={value === 0 ? 'medium' : 'small'} />} onClick={() => {
                    navigate("/", { replace: true })
                }} />
                <BottomNavigationAction icon={<Diversity3 fontSize={value === 1 ? 'medium' : 'small'} />} onClick={() => {
                    navigate("/gm-vjx", { replace: true })
                }} />
                <BottomNavigationAction icon={<AddBoxIcon fontSize='large' sx={{ color: 'black' }} />} onClick={() => {
                    // navigate("/favourite-post", { replace: true })
                    setOpenDrawer(true)
                }} />
                <BottomNavigationAction icon={
                    <Diversity1 fontSize={value === 3 ? 'medium' : 'small'} />
                } onClick={() => navigate("/fav-post")} />
                <BottomNavigationAction onClick={() => navigate("/all_groups")}
                    icon={<Groups2OutlinedIcon fontSize={value === 4 ? 'medium' : 'small'} />}
                />
                {/* <BottomNavigationAction onClick={() => navigate("/m_conversation")} 
                icon={<Badge badgeContent={2} color="error">
                <Forum sx={{ color: theme.palette.dark.light }} />
              </Badge>} 
                /> */}
            </BottomNavigation>
        </Paper>
    )

    // return (
    //     <>
    //         <Avatar sx={{ position: 'fixed', bottom: "20px", right: '20px' }} onClick={(event) => setAnchorEl(event.currentTarget)}>
    //             <AddIcon />
    //         </Avatar>
    //         <Menu
    //             id="basic-menu"
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleClose}
    //             MenuListProps={{
    //                 'aria-labelledby': 'basic-button',
    //             }}
    //         >
    //             <MenuItem onClick={handleClose}>
    //                 <ListItemIcon>
    //                     <Groups2OutlinedIcon fontSize="small" />
    //                 </ListItemIcon>
    //                 Create Group Post
    //             </MenuItem>
    //             <MenuItem onClick={handleClose}>
    //                 <ListItemIcon>
    //                     <Diversity3 fontSize="small" />
    //                 </ListItemIcon>
    //                 Post To Friends
    //             </MenuItem>
    //             <MenuItem onClick={handleClose}>
    //                 <ListItemIcon>
    //                     <Diversity1 fontSize="small" />
    //                 </ListItemIcon>
    //                 Post To Follower
    //             </MenuItem>
    //         </Menu>
    //     </>
    // )
}

export default MyBottomNavigation