import { Avatar, Badge, Box, Button, CardActions, CardContent, Chip, CircularProgress, IconButton, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import Photo from '@mui/icons-material/Landscape';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { appendNewPostIntList } from '../../../../store/postSlice';
import moment from 'moment/moment';
import { PostAdd, Add, Clear } from '@mui/icons-material';
import { setOpenSnack, setSnackMessage } from '../../../../store/authSlice';
import { useLocation } from 'react-router-dom';

const PostButton = styled(Button)((theme) => ({
    backgroundColor: 'rgba(64,53,151,0.8)',
    borderRadius: '0px',
    border: '1px solid rgba(64,53,151,0.8)',
    color: 'white',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: 'rgba(64,53,151,0.9)',
    }
}))

const CancelButton = styled(Button)((theme) => ({
    border: '1px solid rgba(64,53,151,0.8)',
    // backgroundColor: 'transparent',
    borderRadius: '0px',
    color: 'rgba(64,53,151,0.8)',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: '#f5f6fa',
    }
}))
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const ComparePost = ({ post_type }) => {
    const [mediaPollOrComparePostData, setMediaPollOrComparePostData] = React.useState({ post_type, description: '', files: [],options:[] });
    const [description, setDescription] = React.useState('')
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [options, setOptions] = React.useState([]) // key , profile_pic, text
    const [optionText, setOptionText] = React.useState('')
    const [curFile, setCurFile] = React.useState('')
    // const user_id = useSelector((state) => state.auth.userInfo.id)
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem("token"))
    const { id: group_id } = useSelector(state => state.group.groupShortInfo)
    // const group_id = useSelector((state) => state.group.groupProfileId)
    const dispatch = useDispatch()
    const location = useLocation()

    // React.useEffect(() => {
    //     if (mediaPollOrComparePostData.files.length === 1) {
    //         setError(`Multiple files neede for ${post_type}`)
    //         // return
    //     } else if (mediaPollOrComparePostData.files.length > 3) {
    //         setError(`Maximum 3 files can contain`)
    //         // return 
    //     } else {
    //         setError('')
    //     }
    // }, [mediaPollOrComparePostData])

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    // const handleSubmit = (event) => {
    //     event.preventDefault()
    //     var react_counting = []
    //     setLoading(true)
    //     var formData = new FormData()
    //     formData.append("group_id", group_id);
    //     formData.append("user_id", user_id);
    //     formData.append("post_type", post_type);
    //     formData.append("description", mediaPollOrComparePostData.description);
    //     formData.append("file_type", mediaPollOrComparePostData.file_type);
    //     formData.append("total_file", mediaPollOrComparePostData.files.length);
    //     formData.append("react_counting", react_counting.toString());
    //     for (let index = 0; index < mediaPollOrComparePostData.files.length; index++) {
    //         if (getExtension(mediaPollOrComparePostData.files[index].name).toLowerCase() !== ("png" || "jpg" || "jpeg" || "mp4" || "gif")) {
    //             dispatch(setSnackMessage(`[.${getExtension(mediaPollOrComparePostData.files[index].name)}] file not allowed. Use another file.`))
    //             dispatch(setOpenSnack(true))
    //             return false;
    //         }
    //         if (mediaPollOrComparePostData.files[index].file && !mediaPollOrComparePostData.files[index].name) {
    //             dispatch(setSnackMessage(`File not found.`))
    //             dispatch(setOpenSnack(true))
    //             return false;
    //         }
    //         if (mediaPollOrComparePostData.files[index].size > 70e6) {
    //             dispatch(setSnackMessage("Please upload a file smaller than 70 mb."))
    //             dispatch(setOpenSnack(true))
    //             return false;
    //         }
    //         formData.append("post_file", mediaPollOrComparePostData.files[index]);
    //         react_counting.push(0)
    //     }
    //     // for (let index = 0; index < mediaPollOrComparePostData.files.length; index++) {
    //     //     react_counting.push(0)
    //     // }
    //     const config = {
    //         headers: {
    //             'content-type': 'multipart/form-data',
    //             'authorization': "Bearer " + token
    //         }
    //     }
    //     axios.post(`${process.env.REACT_APP_API_URL}/api/post/createCompareOrPollPost`, formData, config)
    //         .then(response => {
    //             event.target.reset()
    //             setLoading(false)
    //             const current_post = {
    //                 "xz12uied7": response.data.post_id,
    //                 "group_id": group_id,
    //                 "user_id": user_id,
    //                 "post_type": post_type,
    //                 "file_type": mediaPollOrComparePostData.file_type,
    //                 "file_count": response.data.post_files.split(",").length,
    //                 "files": response.data.post_files,
    //                 "post_to": "all",
    //                 "options": "",
    //                 "description": mediaPollOrComparePostData.description,
    //                 "status": "active",
    //                 "added_on": moment().format(),
    //                 "name": name,
    //                 "react_counting": react_counting.toString(),
    //                 "total_react": 0,
    //                 "profile_pic": profile_pic
    //             }
    //             setMediaPollOrComparePostData({ post_type, description: '', file_type: '', files: '' })
    //             dispatch(appendNewPostIntList(current_post))
    //             dispatch(setSnackMessage("Posted Successfully."))
    //             dispatch(setOpenSnack(true))
    //         }).catch(error => {
    //             dispatch(setSnackMessage("Something went wrong.Try later."))
    //             dispatch(setOpenSnack(true))
    //             setLoading(false)
    //         })
    // }

    const submitPostOption = (post_id) => {
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': "Bearer " + token
            },
            onUploadProgress: (data) => {
                // setUploadPercentage(Math.round((data.loaded / data.total) * 100));
            }
        };
        for (let index = 0; index < options.length; index++) {
            var formData = new FormData();
            formData.append("post_id", post_id);
            formData.append("option_text", options[index].text);
            formData.append("post_file", options[index].pic);
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_media_option`, formData, options)
                .then(response => {
                    if (response.data.status === 1) {
                        if (index === (options.length - 1)) {
                            setLoading(false)
                        }
                    }
                }).catch(error => {
                    setLoading(false)
                })
        }
    }

    const handleSubmit = (event) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_simple_post`, { group_id: location.state.id, post_type, link: '', description }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.status === 1) {
                    submitPostOption(response.data.post_id)
                }
            }).catch(error => {
                setLoading(false)
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
            })
    }
    const handleFileSelect = (event) => {
        var file_extension = getExtension(event.target.files[0].name)
        if (!event.target.files[0].name) {
            return false;
        }
        if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
            // setCurFile(event.target.files[0])
            setMediaPollOrComparePostData({ ...mediaPollOrComparePostData, files: [...event.target.files] })
            // let poll_options = []
            // for (let index = 0; index < event.target.files.length; index++) {
            //     poll_options.push(null)
            // }
            // setMediaPollOrComparePostData({...mediaPollOrComparePostData,options:[...poll_options]})
            setError("")
        } else {
            setError("Sorry, this file format is not allowed.Use only png, jpg, jpeg, mp4, gif.")
        }
    }

    const handleDelete = (chipToDelete) => () => {
        setOptions((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };
    return (
        <Box component='form' onSubmit={handleSubmit}>
            {/* <Button onClick={() => {
                console.log(mediaPollOrComparePostData.files)
            }}>Show files</Button>
            <Button onClick={() => {
                console.log(mediaPollOrComparePostData)
            }}>Show all</Button> */}
            <CardContent sx={{ py: 0 }}>
                <TextField
                    fullWidth
                    id="basic_post_dialog_textfield_id"
                    label="Write description"
                    multiline
                    maxRows={2}
                    name={description}
                    onChange={(event) => setDescription(event.target.value)}
                />

                <Typography variant='caption' color='red'>{error}</Typography>
                <Typography variant='subtitle2' color='black' mt={1}>Add Options</Typography>
                {
                    options.map((option) => {
                        return <Chip
                            key={option.key}
                            sx={{ marginTop: '4px' }}
                            avatar={<Avatar src={option.pic && URL.createObjectURL(option.pic)} />}
                            label={option.text}
                            onDelete={handleDelete(option)}
                        />
                    })
                }

                    <Box sx={{ paddingTop: '6px' }}>
                        {
                            mediaPollOrComparePostData.files.map((item, index) => {
                                setMediaPollOrComparePostData({...mediaPollOrComparePostData,options:[...mediaPollOrComparePostData.options,null]})
                                return <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Avatar variant="rounded" src={item && URL.createObjectURL(item)}>
                                        {/* src={item && URL.createObjectURL(item)} */}
                                        <IconButton aria-label="upload picture" component="label" sx={{ opacity: 8 }} onChange={(e) => handleFileSelect(e)}>
                                            <input hidden accept="image/*" type="file" />
                                            <Photo />
                                        </IconButton>
                                    </Avatar>
                                    <TextField
                                        label="wirte something"
                                        id="outlined-size-small"
                                        value='nothing'
                                        size="small"
                                        sx={{ flexGrow: 1, marginX: '10px' }}
                                        onChange={(event) => setMediaPollOrComparePostData({...mediaPollOrComparePostData,options:[...mediaPollOrComparePostData.options,{index,option:event.target.value}]})}
                                    />
                                    <Clear sx={{ color: 'red' }} onClick={() => {
                                        let key = Date.now()
                                        if (curFile !== '') {
                                            setOptions(option => {
                                                return [...option, { key: key, pic: curFile, text: optionText }]
                                            })
                                        }
                                        setOptionText('')
                                        setCurFile('')
                                    }} />
                                </Box>
                            })
                        }
                            <Typography variant='body2' textAlign='right' marginRight='40px' marginTop='6px' fontSize='12px' flexGrow={1}>{optionText.length}/18</Typography>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                            <img src={mediaPollOrComparePostData.files[0] && URL.createObjectURL(mediaPollOrComparePostData.files[0])} style={{ width: '40px', objectFit: 'cover', marginTop: '6px' }} />
                        </Box> */}
                    </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'end' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Button onChange={(e) => handleFileSelect(e)} size='small' variant="contained" component="label" startIcon={<Photo />} sx={{ borderRadius: '20px', backgroundColor: '#cfccff', color: '#6C63FF', fontSize: '14px', fontWeight: 400 }}>
                        Take Multiple image
                        <input hidden accept='image/png,image/jpeg,image/jpg' multiple type="file" />
                    </Button>
                </Box>
                <Box sx={{ mr: 1, position: 'relative' }}>
                    <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={(loading || curFile === '') ? true : false} disableElevation>Post</Button>

                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#44bd32',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
                {/* <Box>
                    <Button type='submit' variant="contained" startIcon={<PostAdd/>} disabled={(error || mediaPollOrComparePostData.files === '') ? true : false} disableElevation>Post</Button>
                </Box> */}
            </CardActions>
        </Box>
    )
}

export default ComparePost