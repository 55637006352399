import { Avatar, Backdrop, Box, Button, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setTotalRequestsInGroupShortInfo } from '../../../store/groupSlice';
import Handshake from '@mui/icons-material/HandshakeOutlined';
import Reject from '@mui/icons-material/ClearOutlined';
import NotFound from '../../status/NotFound';
import Loading from '../../status/Loading';
import moment from 'moment';
import { setOpenSnack, setSnackMessage } from '../../../store/authSlice';
// import Loading from '../Loading';
import { entities } from "../../HtmlEntity"
import { FilledAvatar, LightAvatar, OutlinedAvatar, SmallLightAvatar, SubTitle2, SubTitle3, Title2, Title3 } from '../../../CustomComponents';
import InfiniteScroll from 'react-infinite-scroll-component';
import theme from '../../../Theme';
import { CheckRounded, ClearRounded, Light } from '@mui/icons-material';
import { setInvitationRequestUpdateInfo } from '../../../store/postSlice';

const Requests = ({ requests, callNo, hasMore, setRequests, setCallNo, setHasMore, total_group_request,socket }) => {
    const [loadingStatus, setLoadingStatus] = React.useState('loading') // ok, loading, error
    const [open, setOpen] = React.useState(false)
    // const {id:group_id} = useSelector(state => state.group.groupShortInfo)
    const token = JSON.parse(localStorage.getItem("token"))
    // const {total_request} = useSelector(state => state.group.groupShortInfo)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (requests && requests.length > 0) {
            return
        }
        setLoadingStatus('loading')
        axios.get(`${process.env.REACT_APP_API_URL}/api/group/get_user_requests/${callNo}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length === 0) {
                    setLoadingStatus('empty')
                } else {
                    setLoadingStatus('ok')
                }
                if (response.data.data.length >= 10) {
                    setCallNo(callNo + 1)
                    setHasMore(true)
                    setRequests(response.data.data)
                } else {
                    setRequests(response.data.data)
                    setHasMore(false)
                }
            }).catch(error => {
                setLoadingStatus(`error`)
            })
    }, [])

    const fetchMoreData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/group/get_user_requests/${callNo}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length >= 10) {
                    setCallNo(callNo + 1)
                    setRequests(requests.concat(response.data.data))
                } else {
                    setRequests(requests.concat(response.data.data))
                    setHasMore(false)
                }
            }).catch(error => {
                setHasMore(false)
                // dispatch(setHasMoreRequest({ data: false }))
            })
    }

    const handleClickForProfile = (uid) => {
        navigate("/profile", {
            state: {
                group_id: 0,
                user_id: uid
            },
        })
    }

    const handleAcceptRequests = (m_id, user_id,group_id,group_name) => {
        setOpen(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/group/accept_to_join_group`, { to_user_id: user_id, group_id,message: `accepts your request to join ${group_name.replace(/&#?\w+;/g, match => entities[match])} group.` }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setOpen(false)
                if (response.data.status !== 0) {
                    dispatch(setInvitationRequestUpdateInfo({ status: 'groups', value: total_group_request - 1 }))
                    setRequests(requests.filter(el => el.m_id !== m_id))
                }
                if (response.data.status === 1) {
                    socket?.emit("acceptRequest", { receiverid: user_id })
                }
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setOpen(false)
                dispatch(setSnackMessage("Sorry, something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }

    const handleCancelRequests = (m_id,user_id,group_id) => {
        setOpen(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/group/cancel_request_to_join_group/${group_id}/${user_id}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setOpen(false)
                if (response.data.status === 1) {
                    setRequests(requests.filter(el => el.m_id !== m_id))
                    dispatch(setInvitationRequestUpdateInfo({ status: 'groups', value: total_group_request - 1 }))
                }
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setOpen(false)
                dispatch(setSnackMessage("Sorry, something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }

    return (
        loadingStatus === 'loading' ? <Loading /> : (loadingStatus === 'empty' || loadingStatus === 'error' || (loadingStatus !== 'loading' && requests.length <= 0)) ?
            <NotFound data={"No request found "} subData={"Sorry, we could not found any request or you accepted all the request."} /> :
            <Box sx={{ bgcolor: 'white', padding: '12px', mt: 1 }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={() => setOpen(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    (loadingStatus === 'ok' && requests && requests.length > 0) &&
                    <InfiniteScroll
                        dataLength={requests.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        /></Box>}
                        endMessage={
                            requests.length !== 0 ? <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p> : null
                        }
                    >
                        {
                            requests.map((request, index) => {
                                return <Box key={index} sx={{ display: 'flex', alignItems: "start", gap: 2, mb: 3 }}>
                                    <Avatar variant='rounded' src={request.profile_pic} sx={{ width: 32, height: 32 }} onClick={() => handleClickForProfile(request.uid)} />
                                    <Box sx={{ flexGrow: 1 }}>
                                        {/* <Title3 color='dark.light' sx={{ flexGrow: 1 }}>{request.name.replace(/&#?\w+;/g, match => entities[match])}</Title3> */}
                                        <SubTitle2 color='dark.main'><span style={{ color: theme.palette.primary.main }}>{request.name.replace(/&#?\w+;/g, match => entities[match])}</span> requests to join {request.group_name} group</SubTitle2>
                                        <SubTitle3 color='dark.light' mb={1}>{moment(request.date).fromNow()}</SubTitle3>
                                        <Button variant='contained' size='small' color='primary' onClick={() => handleAcceptRequests(request.m_id,request.uid,request.group_id,request.group_name)}>Accept</Button>
                                        <Button variant='contained' size='small' color='light' sx={{ color: theme.palette.light.dark, marginLeft: '10px' }} onClick={() => handleCancelRequests(request.m_id,request.uid,request.group_id)}>Cancel</Button>
                                    </Box>
                                    {/* <FilledAvatar variant='rounded' onClick={() => handleAcceptRequests(request.uid)}><CheckRounded/></FilledAvatar>
                                    <SmallLightAvatar variant='rounded'  onClick={() => handleCancelRequests(request.uid)}><ClearRounded/></SmallLightAvatar> */}
                                </Box>
                            })
                        }
                    </InfiniteScroll>
                }
            </Box>
    )
}

export default Requests