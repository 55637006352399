import * as React from 'react';
// import { useSelector } from 'react-redux';
import { Box,  Typography } from '@mui/material';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4'
import { ArrowBackIos, Save, Title } from '@mui/icons-material';
import ByMeRequestList from './ByMeRequestList';
import ToMeRequestList from './ToMeRequestList';
import theme from '../../Theme';
import NotFound from '../status/NotFound';
import { Title1 } from '../../CustomComponents';
import Requests from '../Grid/PrivateFac/Requests';

export default function FavRequests() {
  const [selectedItem, setSelectedItem] = React.useState(0)
  const [toMeHasMore, setToMeHasMore] = React.useState(true)
  const [byMeHasMore, setByMeHasMore] = React.useState(true)

  const [toMeCallNo, setToMeCallNo] = React.useState(1)
  const [byMeCallNo, setbyMeCallNo] = React.useState(1)

  const [toMeRequestLsit, setToMeRequestLsit] = React.useState([])
  const [byMeRequestLsit, setByMeRequestLsit] = React.useState([])
  const token = JSON.parse(localStorage.getItem("token"))
  const socket = useOutletContext()
  const {total_fav_request,total_group_request } = useSelector(state => state.post.invitationRequestInfo)
  const navigate = useNavigate()
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])
  // const location = useLocation()

  return (
    <>
      <Box sx={{ position: 'sticky', backgroundColor: 'white', top: { xs: '53px', sm: '63px' } }}>
        <Box sx={{ padding: '10px 10px', display: 'flex', gap: 2,alignItems:'center' }}>
          <ArrowBackIos sx={{ fontSize: '18px', color: theme.palette.dark.main }} onClick={() => navigate(-1)} />
          <Title1 color='dark'>Requests</Title1>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, padding: '6px', borderBottom: (selectedItem === 0) ? '2px solid #10ac84' : '2px solid #d3d8de', textAlign: 'center' }} onClick={() => setSelectedItem(0)}>
            <Typography color={(selectedItem === 0) ? theme.palette.primary.main : theme.palette.disabled.main} >Favourite {total_fav_request ? `(${total_fav_request})` : null }</Typography>
          </Box>
          <Box sx={{ flex: 1, padding: '6px', textAlign: 'center', borderBottom: (selectedItem === 1) ? '2px solid #10ac84' : '2px solid #d3d8de' }} onClick={() => setSelectedItem(1)}>
            <Typography color={(selectedItem === 1) ? theme.palette.primary.main : theme.palette.disabled.main} >Groups {total_group_request ? `(${total_group_request})` : null }</Typography>
          </Box>
          {/* <Box sx={{ flex: 1, padding: '6px', textAlign: 'center', borderBottom: (selectedItem === 2) ? '2px solid #10ac84' : '2px solid #d3d8de' }} onClick={() => setSelectedItem(2)}>
            <Typography color={(selectedItem === 2) ? theme.palette.primary.main : theme.palette.disabled.main} >Group</Typography>
          </Box> */}
        </Box>
      </Box>
      {
        (selectedItem === 0) ? <ToMeRequestList total_fav_request={total_fav_request} requests={toMeRequestLsit} callNo={toMeCallNo} hasMore={toMeHasMore} setRequests={setToMeRequestLsit} setCallNo={setToMeCallNo} setHasMore={setToMeHasMore} socket={socket} /> : <Requests total_group_request={total_group_request} requests={byMeRequestLsit} callNo={byMeCallNo} hasMore={byMeHasMore} setRequests={setByMeRequestLsit} setCallNo={setbyMeCallNo} setHasMore={setByMeHasMore} socket={socket} />  
      }
    </>
  );
}
