import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import AdsCard from './AdsCard'
import theme from '../Theme'
import { Add } from '@mui/icons-material'
import { Title2 } from '../CustomComponents'
import { useNavigate } from 'react-router-dom'

const AdsHome = () => {
    const navigate = useNavigate()
    const datas = [
        { pic: 'https://images.pexels.com/photos/371306/pexels-photo-371306.jpeg?auto=compress&cs=tinysrgb&w=600', logo: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', company_name: 'Facebook', title: 'Sed ut perspiciatis unde omnis iste natus', subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,', total_clicked: 186, total_viewed: '2.5k' },
        { pic: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', logo: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', company_name: 'Facebook', title: 'Sed ut perspiciatis unde omnis iste natus', subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,', total_clicked: 186, total_viewed: '2.5k' },
        { pic: 'https://images.pexels.com/photos/551579/pexels-photo-551579.jpeg?auto=compress&cs=tinysrgb&w=600', logo: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', company_name: 'Facebook', title: 'Sed ut perspiciatis unde omnis iste natus', subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,', total_clicked: 186, total_viewed: '2.5k' },
        { pic: 'https://images.pexels.com/photos/2422290/pexels-photo-2422290.jpeg?auto=compress&cs=tinysrgb&w=600', logo: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', company_name: 'Facebook', title: 'Sed ut perspiciatis unde omnis iste natus', subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,', total_clicked: 186, total_viewed: '2.5k' },
        { pic: 'https://images.pexels.com/photos/8158175/pexels-photo-8158175.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', logo: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', company_name: 'Facebook', title: 'Sed ut perspiciatis unde omnis iste natus', subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,', total_clicked: 186, total_viewed: '2.5k' },
        { pic: 'https://images.pexels.com/photos/37859/sailing-ship-vessel-boat-sea-37859.jpeg?auto=compress&cs=tinysrgb&w=600', logo: 'https://images.pexels.com/photos/954386/pexels-photo-954386.jpeg?auto=compress&cs=tinysrgb&w=600', company_name: 'Facebook', title: 'Sed ut perspiciatis unde omnis iste natus', subtitle: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,', total_clicked: 186, total_viewed: '2.5k' }
    ]
    return (
        <>
            <Typography fontSize='22px' color='dark' fontWeight={600} mt={2}>Current Ads Activities</Typography>
            <Box sx={{ height: '200px', border: '2px solid lightgray', padding: '16px', marginY:"24px" }}>
            </Box>
            <Divider/>
            <Typography fontSize='22px' color='dark' fontWeight={600} mt={2}>Your Ads History</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '16px', pb: 3,pt:1 }}>
                {
                    datas.map((data, index) => {
                        return <AdsCard key={index} data={data} />
                    })
                }
                <Box sx={{ width: '24%', border: '1.5px solid #98a4b3', borderRadius: '4px', backgroundColor: theme.palette.disabled.light, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', alignSelf: 'stretch' }} onClick={() => {
                    navigate("/ads/create")
                }}>
                    <Add color='dark' />
                    <Title2 color='disabled'>Create New Add</Title2>
                </Box>
            </Box>
        </>
    )
}

export default AdsHome