import { Alert, Avatar, Box, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import axios from "axios"
import moment from "moment/moment"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSearchParams, useNavigate } from "react-router-dom"
import { MoreButton } from "../../CustomComponents"
import { setHasInvitation, setUserProfileId } from "../../store/authSlice"
import { setActivityList, setMoreActivityInList } from "../../store/groupSlice"
import ReactGA from 'react-ga4'
import {entities} from "../HtmlEntity"

function Activities() {
  // const [activityList, setActivityList] = React.useState([])
  const [status, setStatus] = React.useState('ok') // ok, error, loading, empty
  const [callNo, setCallNo] = React.useState(1)
  const [hasMore, setHasMore] = React.useState(true)
  const activityList = useSelector(state => state.group.activityList)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { uid: user_id } = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))

  React.useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
  },[])

  React.useEffect(() => {
    const getInvitations = async () => {
      if (activityList.length <= 0) {
        setStatus('loading')
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/group/whom_i_invites/${callNo}`, { headers: { authorization: "Bearer " + token } });
          // setActivityList(res.data.data)
          dispatch(setActivityList(res.data.data))
          // if(res.data.data.length > 0){
          //   dispatch(setHasInvitation(1))
          // }else{
          //   dispatch(setHasInvitation(0))
          // }
          setStatus('ok')
          if (res.data.data.length < 5) {
            setHasMore(false)
          } else {
            setHasMore(true)
            setCallNo(callNo + 1)
          }
        } catch (error) {
          setStatus('error')
        }
      }
    }
    getInvitations()
  }, [])
  const showUserProfile = (user_id) => {
    // dispatch(setUserProfileId(user_id))
    // navigate("/profile")
    navigate(`/profile/${user_id}/0`)
  }


  const showGroupProfile = (group_id) => {
    // dispatch(setGroupProfileId(group_id))
    // navigate("/group-profile")
    navigate({ pathname: `/group-profile`, search: createSearchParams({ groupProfileId: group_id }).toString() })
  }

  const handleLoadMoreInvitations = async () => {
    setStatus('loading')
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/group/whom_i_invites/${callNo}`, { headers: { authorization: "Bearer " + token } });
      // dispatch(setGroupactivityList(res.data.data))
      // setActivityList(activityList.concat(res.data.data))
      dispatch(setMoreActivityInList(res.data.data))
      if (res.data.data.length < 5) {
        setHasMore(false)
      } else {
        setHasMore(true)
        setCallNo(callNo + 1)
      }
      setStatus('ok')
    } catch (error) {
      setStatus('error')
    }
  }

  return (
    (activityList && activityList.length > 0) &&
    <Box sx={{ backgroundColor: 'white', marginBottom: '16px', padding: '16px' }}>
      <Typography variant='h6' color='primary' >Activities</Typography>
      <List dense={true}>
        {
          (activityList && activityList.length > 0) && activityList.map((invitation, index) => {
            return (<Box key={index}>
              <ListItem sx={{ alignItems: 'start' }} >
                <ListItemAvatar>
                  <Avatar src={invitation.u_pic} sx={{ width: 30, height: 30 }} onClick={() => showUserProfile(invitation.user_id)} />
                  {/* <Groupinvitationitem group_name={invitation.group_name} group_pic={invitation.group_pic} user_pic={invitation.u_pic} user_name={invitation.user_name} the_group_id={invitation.id} the_user_id={invitation.user_id} /> */}
                </ListItemAvatar>
                <ListItemText
                  sx={{ margin: '0px' }}
                  primary={<Typography variant='h6' fontSize='14px' display='inline-block' component='div' color='primary' onClick={() => showGroupProfile(invitation.id)} sx={{ cursor: 'pointer' }}>You invited {invitation.user_name.replace(/&#?\w+;/g, match => entities[match])} to <span style={{ color: '#554dff' }}>{invitation.group_name.replace(/&#?\w+;/g, match => entities[match])}</span> group.</Typography>}
                  secondary={
                    <Typography variant='caption' component='p' color='gray'>{moment(invitation.added_on).fromNow()}</Typography>
                  }
                />
              </ListItem>
              {
                activityList.length !== (index + 1) ?
                  <Divider variant="inset" component="li" /> :
                  null
              }

            </Box>)

          })
        }
        {
          status === 'error' &&
          <Alert severity="error">Something went wrong</Alert>
        }
        {
          hasMore ?
            <Box sx={{ position: 'relative', marginBottom: '15px', marginTop: '15px' }}>
              <MoreButton disabled={(status === 'loading') ? true : false} disableElevation variant="contained" fullWidth onClick={handleLoadMoreInvitations}>Load more invitations</MoreButton>
              {(status === 'loading') && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'green',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box> : null
        }
      </List>
    </Box>
  );
}

export default Activities;