import { Avatar, Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import NotFound from '../status/NotFound';
import Loading from '../status/Loading';
import ReactGA from 'react-ga4'
import CreateFavouritePost from '../Create/CreateFavouritePost';
import { FilledButton, OutlinedAvatar, SubTitle3, Title2 } from '../../CustomComponents';
import { appendRequestList, setHasMoreRequest, setOpenSnack, setRequestList, setRequestListCallNo, setSnackMessage } from '../../store/authSlice';
import { CancelOutlined, CancelRounded, ClearRounded, MoreHorizOutlined } from '@mui/icons-material';
import moment from 'moment';
import RequestListItem from './RequestListItem';


const ToMeRequestList = ({requests,callNo,hasMore,setRequests,setCallNo,setHasMore,total_fav_request,socket}) => {
    const [loadingStatus, setLoadingStatus] = React.useState('loading') // ok, loading, error
    // const [requests,setRequests] = React.useState([])
    // const [callNo,setCallNo] = React.useState(1)
    // const [hasMore,setHasMore] = React.useState(true)
    const token = JSON.parse(localStorage.getItem("token"))

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])
    useEffect(() => {
        // if (requests.length > 0 && feedType === 'request_list') {
        //     // when user come back from full image view
        //     return
        // }
        // if ((feedType === 'my_member_post' && requests[0].user_id !== user_id) || feedType === 'member_post') {
        //   dispatch(emptyPostList({ feed_value: 'member_post' }))
        //   window.scrollTo(0, 0)
        // }
        // if (feedType !== 'request_list') {
        // //     dispatch(setPostFeedType({ data: 'request_list' }))
        // // }
        if (requests.length > 0) {
            return
        }
        setLoadingStatus('loading')
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/request/to_make_favourite/${1}/${10}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length === 0) {
                    setLoadingStatus('empty')
                } else {
                    setLoadingStatus('ok')
                }
                if (response.data.data.length >= 5) {
                    setCallNo(callNo + 1)
                    setHasMore(true)
                    setRequests(response.data.data)
                    // dispatch(setRequestListCallNo())
                    // dispatch(setHasMoreRequest({ data: true }))
                    // dispatch(setRequestList({ data: response.data.data }))
                } else {
                    setRequests(response.data.data)
                    setHasMore(false)
                    // dispatch(setRequestList({ data: response.data.data }))
                    // dispatch(setHasMoreRequest({ data: false }))
                }
            }).catch(error => {
                // dispatch(setHasMoreRequest({ data: false }))
                setHasMore(false)
                setLoadingStatus('error')
            })
    }, [])


    const fetchMoreData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/request/to_make_favourite/${callNo}/${10}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length >= 5) {
                    // dispatch(setRequestListCallNo())
                    // dispatch(appendRequestList({ data: response.data.data }))
                    setCallNo(callNo + 1)
                    setRequests(requests.concat(response.data.data))
                } else {
                    // dispatch(appendRequestList({ data: response.data.data }))
                    // dispatch(setHasMoreRequest({ data: false }))
                    setRequests(requests.concat(response.data.data))
                    setHasMore(false)
                }
            }).catch(error => {
                setHasMore(false)
                // dispatch(setHasMoreRequest({ data: false }))
            })
    }

    return (
        <>
            <Box sx={{ padding: '16px', marginTop: '10px', backgroundColor: requests.length > 0 ? 'white' : 'transparent' }}>
                {(loadingStatus === 'loading') && <Loading />}
                {
                    // (hasMore === false && requests.length <= 0 && loadingStatus !== 'ok') &&
                    (loadingStatus === 'empty' || loadingStatus === 'error' || (loadingStatus !== 'loading' && requests.length <= 0)) &&
                    <NotFound data={"No request yet"} subData={"Sorry, we could not found any request to make you favourite to show.Please try again later."} />
                }
                {
                    (loadingStatus === 'ok' && requests.length > 0) &&
                    <InfiniteScroll
                        dataLength={requests.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        /></Box>}
                        endMessage={
                            requests.length !== 0 ? <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p> : null
                        }
                    >
                        {
                            requests.map((request, index) => {
                                return <RequestListItem key={index} request={request} token={token} requests={requests} setRequests={setRequests} total_fav_request={total_fav_request} socket={socket}/>
                            })
                        }
                    </InfiniteScroll>
                }
            </Box>
        </>
    )
}

export default ToMeRequestList

// if(refs.current) observer.observe(refs.current)