import { Box, Button, Checkbox, CircularProgress, Container, CssBaseline, Divider, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'
import React from 'react'
import { EmotInput, StyledInputLabel, FormFieldAlertBox, FilledButton, DisabledButton, OutlinedButton, Title1, SubTitle3 } from '../CustomComponents'
import { AddAPhoto, ArrowBackIosNewOutlined, PostAdd, SaveAsOutlined, Yard } from '@mui/icons-material'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setOpenSnack, setSnackMessage } from '../store/authSlice'
import axios from 'axios'
import ReactGA from 'react-ga4'

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const CreateAdsCard = () => {
    const [status, setStatus] = React.useState(false)
    const [details, setDetails] = React.useState({ title: '', description: '', link: '', tag: '', file_type: 'image', file: null });
    const [uploadPercentage, setUploadPercentage] = React.useState(0);
    const [timeNeeded, setTimeNeeded] = React.useState(0)
    const [speed, setSpeed] = React.useState(0)
    const [titleCount, setTitleCount] = React.useState(0)
    const [tagCount, setTagCount] = React.useState(0)
    const token = JSON.parse(localStorage.getItem('token'))
    const socket = useOutletContext()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])

    const convertToMinAndHour = (seconds) => {
        var toMin = Math.floor(seconds / 60)
        if (toMin > 60) {
            // convert to hour
            return `${Math.floor(toMin / 60) || 0} hour`
        } else if (toMin > 0 && toMin < 60) {
            // convert to minute
            return `${Math.floor(seconds / 60) || 0} min`
        } else {
            return `${seconds} sec`
        }
    }
    const convertToKBAndMB = (byte) => {
        var kbdata = Math.floor(byte / 1000)
        if (kbdata > 1024) {
            return `${Math.floor(kbdata / 1000) || 0} mb`
        } else if (byte < 1000) {
            return `${Math.floor(byte) || 0} b`
        } else {
            return `${kbdata} kb`
        }
    }

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    function handleFileChange(event) {
        var file_extension = getExtension(event.target.files[0].name)
        if (event.target.files[0].size > 6e6) {
            dispatch(setSnackMessage("Please upload a file smaller than 6mb."))
            dispatch(setOpenSnack(true))
            return false;
        }
        if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
            // setFile(event.target.files[0])
            setDetails({ ...details, file: event.target.files[0] })
        } else {
            dispatch(setSnackMessage("Sorry, this file format is not allowed.Use only png, jpg, jpeg file."))
            dispatch(setOpenSnack(true))
        }
        // setOpen(true)
    }
    const handleSubmitArticle = (event) => {
        event.preventDefault()
        var formData = new FormData()
        formData.append("title", details.title);
        formData.append("channel_id", location.state.channel_id);
        formData.append("post_type", 'article');
        formData.append("post_to", 'channel');
        formData.append("description", details.description);
        formData.append("link", details.link);
        formData.append("file_type", 'image');
        formData.append("article_file", details.file);
        formData.append("tag", details.tag);
        setStatus(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/bi-xjV/create_post`, formData, {
            // signal:controller.signal,
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': "Bearer " + token
            },
            onUploadProgress: (data) => {
                setUploadPercentage(Math.round(data.progress * 100));
                setTimeNeeded(Math.floor(data.estimated || 0))
                setSpeed(Math.round(data.rate))
                // setUploadPercentage(Math.round(data.progress * 100));
            },
        })
            .then(response => {
                setUploadPercentage(0);
                setTimeNeeded(0)


                setStatus(false)
                // const post = {
                //     "xz12uied7": response.data.post_id,
                //     "group_name": group_name,
                //     "creator_id": user_id,
                //     "post_type": post_type,
                //     "link": basicOrRatedPostData.link,
                //     "total_react": 0,
                //     "total_share": 0,
                //     "total_comment": 0,
                //     "file_type": basicOrRatedPostData.file_type,
                //     "file_count": 1,
                //     "files": response.data.post_file,
                //     "org_file": response.data.org_post_file,
                //     "post_to": "all",
                //     "description": basicOrRatedPostData.description,
                //     "status": "active",
                //     "added_on": moment().format(),
                //     "name": name,
                //     "profile_pic": profile_pic,
                //     "react": null,
                //     "is_saved": null,
                //     "is_pinned": null,
                //     "is_boosted": null,
                //     "is_commented": null,
                //     "is_colored": is_colored,
                //     "post_to": post_to,
                //     "role": role,
                // }
                // if (response.data.status !== 0) {
                //     // dispatch(appendNewPostIntList(post))
                //     if(post_to === 'friend') {
                //         dispatch(appendNewPostIntList({feed_value:'member_post',data:post}))
                //     }
                //     if(post_to === 'follower') {
                //         // dispatch(appendNewPostIntList({feed_value:'follower_post',data:post}))
                //     }
                // }
                if (response.data.status === 1) {
                    // socket?.emit('new-post', { roomId: group_id, group_name })
                    socket?.emit('new-channel-post', { roomId: location.state.channel_id, group_name: location.state.channel_name })
                }
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                navigate(-1)
            }).catch(error => {
                setUploadPercentage(0);
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
                setStatus(false)
            })
    }
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                p: { xs: '8px', md: '16px' }
            }}
        >
            <CssBaseline />
            <Container maxWidth="sm">
                <Title1>Create ADS</Title1>
                <Box component="form" noValidate onSubmit={handleSubmitArticle}
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '8px',
                        border: '1px solid #EAEAF2',
                        p: { xs: '8px', md: '16px' }
                    }}
                >
                    <>
                        <StyledInputLabel htmlFor="room-name-field-id">
                            Write title
                        </StyledInputLabel>
                        <EmotInput
                            required
                            fullWidth
                            id="article-title-id"
                            value={details.title || ''}
                            onChange={(event) => {
                                if (details.title.length < 180) {
                                    setDetails({ ...details, title: event.target.value })
                                    setTitleCount(details.title.length)
                                }
                            }}
                        />
                        <SubTitle3 textAlign='end' mb={2} marginTop='4px'>{titleCount}/180</SubTitle3>
                    </>
                    {/* <StyledInputLabel htmlFor="short-room-description-id">
            Short Descripiton*
          </StyledInputLabel>
          <EmotInput
            required
            id="short-room-description-id"
            sx={{ mb: 2 }}
          /> */}
                    <StyledInputLabel htmlFor="about-room-field-id">
                        Write Description
                    </StyledInputLabel>
                    <EmotInput
                        required
                        variant='standard'
                        multiline
                        // maxRows={4}
                        // rows={4}
                        id="article-description-id"
                        sx={{ mb: 2 }}
                        value={details.description || ''}
                        onChange={(event) => setDetails({ ...details, description: event.target.value })}
                    />
                    <>
                        <StyledInputLabel htmlFor="room-name-field-id">
                            Enter Website URL
                        </StyledInputLabel>
                        <EmotInput
                            required
                            fullWidth
                            id="article-title-id"
                            value={details.title || ''}
                            onChange={(event) => {
                                if (details.title.length < 180) {
                                    setDetails({ ...details, title: event.target.value })
                                    setTitleCount(details.title.length)
                                }
                            }}
                        />
                        <SubTitle3 textAlign='end' mb={2} marginTop='4px'>{titleCount}/50</SubTitle3>
                    </>
                    <FormControl>
                        <Box sx={{display:"flex",alignItems:'center',justifyContent:'space-between'}}>
                        <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                        <Switch {...label} defaultChecked />
                        </Box>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Book" />
                            <FormControlLabel value="male" control={<Radio />} label="Order Online" />
                            <FormControlLabel value="other" control={<Radio />} label="Buy" />
                            <FormControlLabel value="other" control={<Radio />} label="Learn More" />
                            <FormControlLabel value="other" control={<Radio />} label="Sign up" />
                        </RadioGroup>
                    </FormControl>
                    <StyledInputLabel htmlFor="about-room-field-id">
                        Add Image
                    </StyledInputLabel>
                    <Box sx={{ width: '220px', height: '150px', border: '2px dashed darkgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            (details.file !== null) ?
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={URL.createObjectURL(details.file)} /> :
                                <Box component='label' onChange={(event) => handleFileChange(event)}>
                                    <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                                    <AddAPhoto color='dark' />
                                </Box>
                        }
                        {/* hello */}
                    </Box>
                    {
                        (details.file !== null) &&
                        <>
                            <Button variant='contained' type='submit' component="label" sx={{ whiteSpace: 'nowrap', maxWidth: '50%', my: 1 }} onChange={(event) => handleFileChange(event)}>
                                <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                Choose File
                            </Button>
                            <Divider />
                        </>
                    }
                    {
                        (uploadPercentage > 0) &&
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', marginRight: '4px' }}>
                                    <LinearProgress variant="determinate" value={uploadPercentage} />
                                </Box>
                                <Box sx={{ minWidth: 35, textAlign: 'end' }}>
                                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                                        uploadPercentage,
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography fontSize='13px' color='grey' mx={1}>{convertToMinAndHour(timeNeeded)}</Typography>
                                <Typography fontSize='13px' color='grey'>({convertToKBAndMB(speed)}/sec)</Typography>
                            </Box>
                        </>
                    }
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
                        <Button variant='contained' color='light' sx={{ color: theme.palette.dark.light }} startIcon={<ArrowBackIosNewOutlined />} onClick={() => navigate(-1)}>Back</Button>
                        <Button variant='contained' type='submit' disabled={status ? true : false} component="label" startIcon={<SaveAsOutlined />}>
                            Submit
                        </Button>
                    </Box> */}
                    <Box sx={{ mr: 1, position: 'relative', textAlign: 'end', my: 2 }}>
                        <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={status ? true : (details.file !== null || details.description !== null || details.title !== null) ? false : true} >Post</Button>
                        {status && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default CreateAdsCard