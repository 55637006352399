import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'

const NextUpdate = () => {
    return (
        <Box sx={{p:1}}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >1</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Notification</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >2</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Notice system in a group</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >3</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>File uploading progressbar</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >4</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Groups Restriction</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >5</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Some different type of post</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >6</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Two step verification</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >7</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Chat System</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >8</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Channel System</Typography>
            </Box>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >9</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Two step verification</Typography>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >9</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Earning System</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >10</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>And many more 💕💕</Typography>
            </Box>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'#6C63FF' }} >N</Avatar>
                <Typography variant='body2' fontWeight={600} marginLeft='16px'>Ads Management</Typography>
            </Box> */}
        </Box>
    )
}

export default NextUpdate