import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import theme from "../../Theme"
import { ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../../media/images/emotiontown.png'
import ReactGA from 'react-ga4'
import { Avatar, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { entities } from "../../components/HtmlEntity"
import { Logo } from '../../CustomComponents';
import { useSelector } from 'react-redux';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © Emotiontown.com'}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link> */}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function AvatarSignIn() {
  // const [data, setData] = React.useState({ mobile_no: '', password: '' })
  const [password, setPassword] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)
  const { type, id } = useSelector(state => state.auth.destination)
  const accounts = JSON.parse(localStorage.getItem('accounts'))
  const navigate = useNavigate()
  const location = useLocation()
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])
  React.useEffect(() => {
    setMessage("")
    return () => {
      setMessage("")
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'avatar sign in',
      action: 'avatar sign in',
      label: 'avatar sign in'
    })
    setIsLoading(true)
    // return
    axios.post(`${process.env.REACT_APP_API_URL}/api/auth/loginThroughAvatar`, { user_id: location.state.uid, password })
      .then(response => {
        setIsLoading(false)
        if (response.data.status === "error") {
          setMessage(response.data.message)
          return
        }
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token))
          localStorage.setItem('user', JSON.stringify(response.data.user))
          // localStorage.setItem('accounts',JSON.stringify())
          // localStorage.setItem('user', JSON.stringify(response.data.user.name.replace(/&#?\w+;/g, match => entities[match])))
          // if(JSON.parse(localStorage.getItem("accounts")).length > 1) {
          //   navigate("/", { replace: true })
          // }else{
            // navigate("/no-groups-list", { replace: true })
            if(type !== '' && id !== '') {
              if(type === 'group') {
                // navigate to group
                navigate(`/group-profile/${id}`, { replace: true })
              }
              if(type === 'channel') {
                navigate(`/channel-profile/${id}`, { replace: true })
              }
            }else{
              navigate("/no-groups-list", { replace: true })
            }
          // }
          // navigate("/feed")
        } else {
          setMessage("Something went wrong.Try later.")
        }
      }).catch(error => {
        setIsLoading(false)
        setMessage(error.response.data.message)
      })
  };

  (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: 'white', height: `100vh` }}>
        <Box sx={{ padding: '10px' }}>
          <Logo variant="h4" color='primary' sx={{ flexGrow: 1 }}>
            EmotionTown
          </Logo>
        </Box>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '4px',
              boxShadow: 'none',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <img src={logo} alt="not found" style={{ width: '55px', marginbottom: '22px' }} />
              <Typography fontWeight={600} fontSize='19px'>
                Welcome to Emotion Town
              </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: '10px', marginTop: '15px' }} autoComplete='off'>
              {
                message &&
                <Box sx={{ padding: '20px', borderRadius: '8px', backgroundColor: 'wheat' }}>{message}</Box>
              }
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, backgroundColor: '#dfe4ea', borderRadius: '6px' }}>
                <Avatar src={location.state.profile_pic} sx={{ width: 40, height: 40, marginRight: '12px' }} />
                <Typography variant='body2'>{location.state.name}</Typography>
              </Box>
              <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password"> Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password || ''}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color='greyish'
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Box sx={{ position: 'relative', ml: 1 }}>
                  <Button
                    type="submit"
                    disabled={isLoading ? true : false}
                    variant="contained"
                  >
                    Sign In
                  </Button>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#44bd32",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Copyright sx={{ mt: 2 }} />
          <Box sx={{ padding: '10px', textAlign: 'center' }}>
            {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
            <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  )

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', backgroundColor: theme.palette.primary.main, height: '100vh' }}>
        <Box className="img_container" sx={{ flex: 6, display: { xs: 'none', md: 'block' }, position: 'relative', height: '100%', overflow: 'hidden' }}>
          <img src='https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Box sx={{ flex: 3, backgroundColor: 'white', p: 3 }}>
          <Typography fontSize='25px' color='dark' fontWeight={800} my={4}>Emotiontown</Typography>
          <Box component="form" onSubmit={handleSubmit} autoComplete='off'>
            {
              message &&
              <Box sx={{ padding: '16px', borderRadius: '6px', backgroundColor: 'wheat' }}>{message}</Box>
            }
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1, backgroundColor: '#dfe4ea', borderRadius: '6px',mt:2 }}>
              <Avatar src={location.state.profile_pic} sx={{ width: 40, height: 40, marginRight: '12px' }} />
              <Typography variant='body2'>{location.state.name}</Typography>
            </Box>
            <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password"> Password</InputLabel>
              <OutlinedInput
                required
                fullWidth
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color='greyish'
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Box sx={{ position: 'relative', ml: 1 }}>
                <Button
                  type="submit"
                  disabled={isLoading ? true : false}
                  variant="contained"
                >
                  Sign In
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#44bd32",
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ position: 'relative', mt: 2 }}>
            <FilledButton
              type="submit"
              fullWidth
              disabled={isLoading ? true : false}
              variant="contained"
              sx={{}}
              disableElevation
            >
              Sign In
            </FilledButton>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#44bd32",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box> */}
          <Box sx={{ padding: '10px', textAlign: 'center', mt: 2 }}>
            <strong style={{ color: theme.palette.dark.light }}>New to Emotion Town ? </strong>
            <strong style={{ color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => navigate('/register-second')}>Create an account</strong>
          </Box>
          <Copyright sx={{ mt: 2 }} />
          <Box sx={{ padding: '10px', textAlign: 'center', mt: 2, display:'flex',flexWrap:'wrap' }}>
            {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/affiliate-disclosure')}>Affiliate Disclosure</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
            <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}