import { Avatar, Badge, Box, CircularProgress } from '@mui/material'
import React from 'react'
import { SubTitle1, SubTitle2 } from '../../CustomComponents'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../status/Loading'
import NotFound from '../status/NotFound'
import axios from 'axios'

const PeopleConversation = () => {
    const [loadingStatus, setLoadingStatus] = React.useState('ok')
    const [peopleConversations, setPeopleConversations] = React.useState([])
    const [callNo, setCallNo] = React.useState(1)
    const [hasMore, setHasMore] = React.useState(true)
    const { uid } = JSON.parse(localStorage.getItem('user')) || {}
    const token = JSON.parse(localStorage.getItem("token"))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/chat-xjV/Vy/getPeopleConversation/${1}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length === 0) {
                    setLoadingStatus('empty')
                } else {
                    setLoadingStatus('ok')
                }
                if (response.data.data.length >= 10) {
                    // dispatch(setPostListCallNo({ "feed_value": 'all_group_post' }))
                    // dispatch(setHasMorePost({ data: true, feed_value: 'all_group_post' }))
                    // dispatch(setPostList({ data: response.data.data, feed_value: 'all_group_post' }))
                    setCallNo(callNo + 1)
                    setHasMore(true)
                    setPeopleConversations(response.data.data)
                } else {
                    // dispatch(setPostList({ data: response.data.data, feed_value: 'all_group_post' }))
                    // dispatch(setHasMorePost({ data: false, feed_value: 'all_group_post' }))
                    setPeopleConversations(response.data.data)
                    setHasMore(false)
                }
            }).catch(error => {
                setHasMore(false)
                // dispatch(setHasMorePost({ data: false, feed_value: 'all_group_post' }))
                setLoadingStatus('error')
            })
    }, [])

    const fetchMoreData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/chat-xjV/Vy/getPeopleConversation/${callNo}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length >= 10) {
                    //   dispatch(setPostListCallNo({ "feed_value": 'all_group_post' }))
                    //   dispatch(appendPostList({ data: response.data.data, feed_value: 'all_group_post' }))
                    setCallNo(callNo + 1)
                    setPeopleConversations(peopleConversations.concat(response.data.data))
                } else {
                    //   dispatch(appendPostList({ data: response.data.data, feed_value: 'all_group_post' }))
                    //   dispatch(setHasMorePost({ data: false, feed_value: 'all_group_post' }))
                    setPeopleConversations(peopleConversations.concat(response.data.data))
                    setHasMore(false)
                }
            }).catch(error => {
                // dispatch(setHasMorePost({ data: false, feed_value: 'all_group_post' }))
                setHasMore(false)
            })
    }

    const goToChatPanel = (chat_room_id) => {
        navigate("/chat", {
            state: {
                room_id: chat_room_id
            },
            // replace: true
        })
    }
    return (
        <Box sx={{ marginTop: '16px' }}>
            {(loadingStatus === 'loading') && <Loading />}
            {
                (hasMore === false && peopleConversations.length <= 0 && loadingStatus !== 'ok') &&
                <NotFound data={"No conversation yet"} subData={"Sorry, we could not found any conversation to show.Please try again later."} />
            }
            {
                (loadingStatus === 'ok' && peopleConversations.length > 0) &&
                <InfiniteScroll
                    dataLength={peopleConversations.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
                        size={24}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    /></Box>}
                    // endMessage={
                    //     peopleConversations.length !== 0 ? <p style={{ textAlign: "center" }}>
                    //         <b>Yay! You have seen it all</b>
                    //     </p> : null
                    // }
                >
                    {
                        peopleConversations.map((val, index) => {
                            return <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center', marginTop: '22px' }} onClick={() => goToChatPanel(val.chat_room_id)}>
                                <Badge badgeContent={4} color="success">
                                    <Avatar sx={{ width: 30, height: 30 }} src={val.profile_pic} />
                                </Badge>
                                <Box>
                                    <SubTitle1>{val.name}</SubTitle1>
                                    <SubTitle2 color='darkgray'>{val.chat}</SubTitle2>
                                </Box>
                            </Box>
                        })
                    }
                </InfiniteScroll>
            }
        </Box>
    )
}

export default PeopleConversation