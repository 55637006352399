import React, { Fragment, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { Box } from '@mui/system';
import { Button, CircularProgress, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound from '../status/NotFound';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactGA from 'react-ga4'
// import { setGroupList, setNewGroupInList } from '../../store/groupSlice';
// import { increaseFavouritePostTotalShareCount, increaseMyPostTotalShareCount, increasePinPostTotalShareCount, increasePostTotalShareCount, increaseSavePostTotalShareCount } from '../../store/postSlice';
import {entities} from "../HtmlEntity"
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';

const MoreButton = styled(Button)((theme) => ({
  // backgroundColor: 'rgba(199, 201, 201,0.3)',
  // color: 'blue',
  textTransform: 'none',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: 'rgba(199, 201, 201,0.7)',
  }
}))

const SharedToGroup = () => {
  const [checked, setChecked] = React.useState([]);
  const [groups,setGroups] = React.useState([])
  const [callNo,setCallNo] = React.useState(1)
  const [hasMore,setHasMore] = React.useState(true)
  const [groupIds, setGroupIds] = React.useState([])
  const [sharing, setSharing] = React.useState(false)
  // const groups = useSelector(state => state.group.sharedGroupList)
  const { uid: id, name } = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const current_post = useSelector(state => state.post.postToSave)
  // const current_group = useSelector(state => state.group.groupShortInfo)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  React.useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
  },[])

  useEffect(() => {
    if (groups.length <= 0) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/group/shared_to_groups/${current_post.xz12uied7}/${callNo}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.length >= 12) {
            setCallNo(2)
            setHasMore(true)
            // dispatch(setGroupList(response.data.data))
            setGroups(response.data.data)
          } else {
            setGroups(response.data.data)
            setHasMore(false)
          }
        }).catch(error => {
  
        })
    }
  }, [])

  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/group/shared_to_groups/${current_post.xz12uied7}/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length >= 12) {
          setCallNo(callNo + 1)
          setGroups(groups.concat(response.data.data))
        } else {
          setGroups(groups.concat(response.data.data))
          // dispatch(setNewGroupInList(response.data.data))
          setHasMore(false)
        }
      }).catch(error => {
      })
  }

  // so tricky
  const handleToggle = (value, group_id) => () => {
    const currentIndex = checked.indexOf(value);
    const currentGroupIdIndex = groupIds.indexOf(group_id)
    const newChecked = [...checked];
    const newGroupIds = [...groupIds]

    if (currentIndex === -1) {
      // if (newChecked.length < 4) {
      newChecked.push(value);
      newGroupIds.push(group_id)
      // } else {
      //   setIsAlert(true)
      //   setTimeout(() => {
      //     setIsAlert(false)
      //   }, 4000);
      // }
    } else {
      newChecked.splice(currentIndex, 1);
      newGroupIds.splice(currentGroupIdIndex, 1);
    }

    setChecked(newChecked);
    setGroupIds(newGroupIds)
  };
  //  group_id, user_id, post_type, file_type, file_count, files, post_to, description, optionns, status, original_post_id, shared_by, shared_by_name, shared_from_group_id, shared_from_group_name 
  const handleSharePost = async () => {
    // return
    try {
      setSharing(true)
      // groupIds, user_id, post_type, file_type, file_count, files, post_to, description, options, status, original_post_id, shared_by, shared_by_name, shared_from_group_id, shared_from_group_name
      await axios.post(`${process.env.REACT_APP_API_URL}/api/post/share_post`, { groupIds, user_id: current_post.u_id, original_post_id: current_post.xz12uied7, shared_from_group_id: current_post.group_id, shared_from_group_name: current_post.group_name }, { headers: { authorization: "Bearer " + token } })
      setSharing(false)
      // if(location.state.value === 'myPost') {
      //   dispatch(increaseMyPostTotalShareCount({post_id: current_post.xz12uied7,count:groupIds.length}))
      // }
      // else if(location.state.value === 'saved') {
      //   dispatch(increaseSavePostTotalShareCount({post_id: current_post.xz12uied7,count:groupIds.length}))
      // }
      // else if(location.state.value === 'pin') {
      //   dispatch(increasePinPostTotalShareCount({post_id: current_post.xz12uied7,count:groupIds.length}))
      // }
      // else if(location.state.value === 'favourite') {
      //   dispatch(increaseFavouritePostTotalShareCount({post_id: current_post.xz12uied7,count:groupIds.length}))
      // }
      // else {
        // }
          // dispatch(increasePostTotalShareCount({feed_value:location.state.value,post_id: current_post.xz12uied7,count:groupIds.length}))
      dispatch(setSnackMessage(`Post shared successfully.`))
      dispatch(setOpenSnack(true))
      navigate(-1)
    } catch (error) {
      setSharing(false)
      dispatch(setSnackMessage(`Sorry,failed to share. Try later.`))
      dispatch(setOpenSnack(true))
    }

  }

  return (
    <Box sx={{ bgcolor: 'white', padding: '16px',marginTop:'10px' }}>
      {/* {(groups.length >= 0) ? <NotFound /> : */}
      <>
        <InfiniteScroll
          dataLength={groups.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          /></Box>}
        >
          <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {groups.map((group, index) => {
              const labelId = `checkbox-list-secondary-label-${index}`;
              {
                if (current_post.group_id !== group.id) {
                  return (
                   <ListItem
                     key={index}
                     secondaryAction={
                       <Checkbox
                         edge="end"
                         onChange={handleToggle(index, group.id)}
                         checked={checked.indexOf(index) !== -1}
                         inputProps={{ 'aria-labelledby': labelId }}
                         disabled={(group.is_shared_to_this_group) ? true : false}
                       />
                     }
                     disablePadding
                   >
                     <ListItemButton>
                       <ListItemAvatar>
                         <Avatar
                           alt={`${group.name.replace(/&#?\w+;/g, match => entities[match])} image`}
                           src={group.group_profile_pic}
                           sx={{height:32,width: 32}}
                         >{group.name.replace(/&#?\w+;/g, match => entities[match]).charAt(0).toUpperCase()}</Avatar>
                       </ListItemAvatar>
                       <ListItemText id={labelId} primary={group.name.replace(/&#?\w+;/g, match => entities[match])} secondary={group.is_shared_to_this_group ? <Typography variant='caption' color='#6C63FF'>You already shared in this group</Typography> :<Typography variant='caption' color='gray'>{group.group_type} group . {group.role}</Typography>} />
                     </ListItemButton>
                   </ListItem>
                 );
                }
              }
            })}
          </List>
        </InfiniteScroll>
        <Box sx={{ position: 'relative', marginBottom: '15px', marginTop: '15px' }}>
          <MoreButton disabled={(sharing ||  (checked.length <= 0)) ? true : false} disableElevation variant="contained" fullWidth onClick={handleSharePost} sx={{ bgcolor: 'green', color: 'white' }}>Share Post</MoreButton>
          {sharing && (
            <CircularProgress
              size={24}
              sx={{
                color: 'orange',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </>
      {/* } */}
    </Box>
  )
}

export default SharedToGroup