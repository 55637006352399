import { Alert, Box, Button, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import React from 'react'
import { ColorButton } from '../../CustomComponents'
import help_us from '../../media/images/help_us.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import theme from '../../Theme';
import ReactGA from 'react-ga4'

const HelpUs = () => {
    const [suggestion, setSuggestion] = React.useState("")
    const [open, setOpen] = React.useState(false);
    const token = JSON.parse(localStorage.getItem("token"))
    const navigate = useNavigate()

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])

    const handleSubmitData = (event) => {
        event.preventDefault()
        setOpen(true);
        // return
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/user_suggestion`, { suggestion }, { onUploadProgress: (p) => {
        }, headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status) {
                    setSuggestion("")
                    setOpen(true);
                }
            }).catch(error => {
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <Box sx={{ bgcolor: 'white', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',gap: '30px',height:{xs: "calc(100vh - 54px)" ,md: "calc(100vh - 70px)"} }}>
            <img src={help_us} alt='no data found' style={{ width: '160px' }} />
            <Box
                component="form"
                sx={{
                    width: '80%',
                    
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmitData}
            >
                <Typography sx={{textAlign:'center'}} variant='h6' color='primary' fontWeight='600'>Help us to improve</Typography>
                <Typography sx={{textAlign:'center',color:'gray'}} variant='body2' mb={3} component='p' >If you have any suggestion please let us know.</Typography>
                <TextField
                    id="objectionId"
                    label="Write your suggestion"
                    placeholder="Write your suggestion"
                    multiline
                    fullWidth
                    rows={4}
                    value={suggestion}
                    onChange={(e) => setSuggestion(e.target.value)}
                />
                <Box sx={{display:'flex',justifyContent:'space-between',gap:1,my:1}}>
                <Button variant="contained" color='light' startIcon={<ArrowBackIcon />} fullWidth sx={{ color: theme.palette.dark.light }} onClick={() => navigate(-1)}>
                    Back
                </Button>
                <Button type='submit' variant="contained" endIcon={<SendIcon />} fullWidth>
                    Submit
                </Button>
                </Box>
                {/* <ColorButton type='submit' startIcon={<ArrowBackIcon />} >Submit</ColorButton> */}
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Successfully sent."
                    action={<IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>}
                />
            </Box>
        </Box>
    )
}

export default HelpUs