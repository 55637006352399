import {createTheme} from '@mui/material/styles'
// import teal from '@mui/material/colors/teal'
// import { purple } from '@mui/material/colors';

const theme = createTheme ({
    typography:{
        fontFamily: ['Poppins', 'sans-serif'].join(',')
    },
    components:{
        // MuiButtonBase:{
        //     defaultProps{

        //     }
        // }
        MuiButton:{
            defaultProps:{
                disableElevation:true
            },
            styleOverrides:{
                root:{
                    textTransform:'capitalize',
                    color:'white'
                }
            }
        }
    },
    palette: {
        primary: {
            light:'#14d2a2',
            main:'#10ac84',
            dark:'#0b755a'
        }
        ,secondary: {
            // main: '#ffb663' // yellow ochare
            // main: '#b963ff' // Move
            main: '#ffb663' // pink
        },
        greyish: {
            main: '#dfe4ea'
        },
        redish: {
            light: '#fee7ea',
            main: '#f53b57'
        },
        signin: {
            main: '#ffffff'
        },
        // black: {
        //     main: '#636e72'
        // },
        // light1:{
        //     main: '#F2F2F7'
        // },
        // light2:{
        //     main: '#EAEAF2'
        // },
        dark:{
            light:'#515e61',
            main: '#2e3638',
            dark:'#23282a'
        },
        disabled: {
            light: '#d3d8de',
            main: '#98a4b3',
            dark: '#627184'
        },
        light:{
            light:'#F2F2F7',
            main:'#EAEAF2',
            dark:'#c0c0d8'
        },
        redAlert: {
            light: '#fcd7cf',
            main: '#e17055',
        }
        // ,warning: {
        //     main: '#6C63FF'
        // }
        // danger: {
        //     light
        // }
        // info:{
        //     // main:'#ffe5cc',
        //     main:'#ff9f43',
        //     dark:'red'
        // }
    }
})

// const theme = createTheme({
//     palette: {
//       primary: {
//         // Purple and green play nicely together.
//         main: purple[500],
//       },
//       warning: {
//         // This is green.A700 as hex.
//         main: '#6C63FF',
//       },
//     },
//   });

export default theme