import styled from '@emotion/styled'
import { Avatar, Badge, Box, Button, CardActions, CardContent, CircularProgress, IconButton, LinearProgress, TextField, Typography } from '@mui/material'
import React from 'react'
import Photo from '@mui/icons-material/InsertPhotoOutlined';
import Video from '@mui/icons-material/VideocamOutlined';
import Gif from '@mui/icons-material/GifBoxOutlined';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { appendNewPostIntList } from '../../../../store/postSlice';
import moment from 'moment/moment';
import { PostAdd } from '@mui/icons-material';
import { setOpenSnack, setSnackMessage } from '../../../../store/authSlice';
import { useLocation, useSearchParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const PostButton = styled(Button)((theme) => ({
    backgroundColor: 'rgba(64,53,151,0.8)',
    borderRadius: '0px',
    border: '1px solid rgba(64,53,151,0.8)',
    color: 'white',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: 'rgba(64,53,151,0.9)',
    }
}))

const CancelButton = styled(Button)((theme) => ({
    border: '1px solid rgba(64,53,151,0.8)',
    // backgroundColor: 'transparent',
    borderRadius: '0px',
    color: 'rgba(64,53,151,0.8)',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: '#f5f6fa',
    }
}))

const LinkPost = ({ post_type }) => {
    const [linkPostData, setLinkPostData] = React.useState({ post_type, description: '', poll_options: '', link:'',file_type: '', file: '' });
    const [error, setError] = React.useState("")
    // const [uploaded, setUploaded] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const { uid: user_id } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem("token"))
    // const [searchparams] = useSearchParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        if (!linkPostData.description && !linkPostData.file.name) {
            return false;
        }
        if (linkPostData.file && !linkPostData.file.name) {
            return false;
        }

        if (linkPostData.file === undefined || linkPostData.file === "") {
            // group_id, user_id, post_type, description, poll_options
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_simple_post`, { group_id: location.state.id, post_type: linkPostData.post_type, description: linkPostData.description, poll_options: '' }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    setLoading(false)
                    // const post = {
                    //     "xz12uied7": response.data.post_id,
                    //     "group_id": searchparams.get("id"),
                    //     "user_id": user_id,
                    //     "post_type": post_type,
                    //     "file_type": linkPostData.file_type,
                    //     "file_count": 0,
                    //     "total_react":0,
                    //     "total_comment":0,
                    //     "total_share":0,
                    //     "files": '',
                    //     "post_to": "all",
                    //     "options": "",
                    //     "description": linkPostData.description,
                    //     "status": "active",
                    //     "added_on": moment().format(),
                    //     "name": name,
                    //     "profile_pic": profile_pic
                    // }
                    // dispatch(appendNewPostIntList(post))
                    dispatch(setSnackMessage("Posted Successfully"))
                    dispatch(setOpenSnack(true))
                }).catch(error => {
                    dispatch(setSnackMessage("Something went wrong.Try later."))
                    dispatch(setOpenSnack(true))
                })
            // simple post 
        } else {
            if (linkPostData.file.size > 70e6) {
                setLoading(false)
                dispatch(setSnackMessage("Please upload a file smaller than 70 mb."))
                dispatch(setOpenSnack(true))
                return false;
            }
            var formData = new FormData();
            formData.append("group_id", location.state.id);
            // formData.append("user_id", user_id);
            formData.append("post_type", post_type);
            formData.append("description", linkPostData.description);
            formData.append("poll_options", linkPostData.poll_options);
            formData.append("file_type", linkPostData.file_type);
            formData.append("post_file", linkPostData.file);
            const config = {
                // onUploadProgress: (data) => {
                //     setUploaded(Math.round((data.loaded / data.total) * 100))
                // },
                headers: {
                    'content-type': 'multipart/form-data',
                    'authorization': "Bearer " + token
                }
            }
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_post`, formData, config)
                .then(response => {
                    setLoading(false)
                    // const post = {
                    //     "xz12uied7": response.data.post_id,
                    //     "group_id": searchparams.get("id"),
                    //     "user_id": user_id,
                    //     "post_type": post_type,
                    //     "file_type": linkPostData.file_type,
                    //     "file_count": 1,
                    //     "total_react": 0,
                    //     "total_comment": 0,
                    //     "total_share": 0,
                    //     "files": response.data.post_file,
                    //     "post_to": "all",
                    //     "options": "",
                    //     "description": linkPostData.description,
                    //     "status": "active",
                    //     "added_on": moment().format(),
                    //     "name": name,
                    //     "profile_pic": profile_pic
                    // }
                    // // setLinkPostData({ post_type, description: '', poll_options: '', file_type: '', file: '' })
                    // dispatch(appendNewPostIntList(post))
                    dispatch(setSnackMessage("Posted Successfully"))
                    dispatch(setOpenSnack(true))
                    // setUploaded(null)
                }).catch(error => {
                    dispatch(setSnackMessage("Something went wrong.Try later."))
                    dispatch(setOpenSnack(true))
                    // setUploaded(null)
                    setLoading(false)
                })
        }
        setLinkPostData({ post_type, description: '', poll_options: '', file_type: '', file: '' })
    }
    const handleInputFieldChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setLinkPostData(data => ({ ...data, [name]: value }))
        setError("")
    }
    return (
        <Box component='form' onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
                {/* <Box> */}
                <TextField
                    fullWidth
                    value={linkPostData.link}
                    id="link_post_link_textfield_id"
                    label="your link"
                    multiline
                    maxRows={4}
                    name="link"
                    onChange={handleInputFieldChange}
                    sx={{mb:2}}
                />
                <TextField
                    fullWidth
                    value={linkPostData.description}
                    id="link_post_description_textfield_id"
                    label="Write something"
                    multiline
                    rows={2}
                    name="description"
                    onChange={handleInputFieldChange}
                // defaultValue="Default Value"
                />
                <Typography variant='caption' color='red'>{error}</Typography>
                {/* {
                    uploaded &&
                    <LinearProgress variant="determinate" value={uploaded} sx={{ mt: 1, height: '12px', borderRadius: '2px' }} />
                } */}
            </CardContent>
            <CardActions disableSpacing sx={{ display:'flex',justifyContent:'end' }}>
                {/* <Box>
                    <IconButton aria-label="take image" sx={{ color: '#6c5ce7' }} component='label' onChange={(event) => handleFileSelect(event, "image")}>
                        <input hidden accept="image/*" type="file" />
                        {
                            linkPostData.file_type === "image" ?
                                <Badge color="redish" variant="dot">
                                    <Photo color='primary' />
                                </Badge>
                                :
                                <Photo />
                        }
                    </IconButton>
                    <IconButton aria-label="take video" sx={{ color: '#6c5ce7' }} component='label' onChange={(event) => handleFileSelect(event, "video")}>
                        <input hidden accept="video/*" type="file" />
                        {
                            linkPostData.file_type === "video" ?
                                <Badge color="redish" variant="dot">
                                    <Video color='primary' />
                                </Badge>
                                :
                                <Video />
                        }
                    </IconButton>
                    <IconButton aria-label="take gif" sx={{ color: '#6c5ce7' }} component='label' onChange={(event) => handleFileSelect(event, "gif")}>
                        <input hidden accept=".gif" type="file" />
                        {
                            linkPostData.file_type === "gif" ?
                                <Badge color="redish" variant="dot">
                                    <Gif color='primary' />
                                </Badge>
                                :
                                <Gif />
                        }
                    </IconButton>
                </Box> */}
                <Box sx={{ mr: 1, position: 'relative' }}>
                    <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={loading ? true : (linkPostData.file !== '' || linkPostData.description !== '') ? false : true} disableElevation>Post</Button>

                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#44bd32',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </CardActions>
        </Box>
    )
}

export default LinkPost