import { Box, Grid, TextField, Button, Typography, CircularProgress, Alert } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { setNewGroupInList } from '../../../store/groupSlice'
import ReactGA from 'react-ga4'
import axios from 'axios';

const SubmitButton = styled(Button)((theme) => ({
    fontWeight: '600',
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 0,
    color: 'black',
    // display:'inline-block',
    padding: '12px 22px',
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.3)',
    }
}))

const CreateNewGroup = () => {
    const [details, setDetails] = React.useState({ name: '', about: '', grp_subtype: 'Friendly', grp_topic: '' })
    const [need, setNeed] = React.useState('')
    const [status, setStatus] = React.useState('ok')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = JSON.parse(localStorage.getItem("token"))
    const group_type = useSelector(state => state.group.createGropType)

    React.useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname})
      },[])

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setDetails(values => ({ ...values, [name]: value }))
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (details.name.trim().length <= 2) {
            setNeed('name')
            return
        }
        if (details.about.trim() === '') {
            setNeed('about')
            return
        }
        setStatus('loading')
        axios.post(`${process.env.REACT_APP_API_URL}/api/group/create/group`, { ...details, grp_type: group_type },{ headers: { authorization: "Bearer " + token } })
            .then(response => {
                setStatus('ok')
                dispatch(setNewGroupInList(response.data.data))
                setDetails({ name: '', about: '', grp_subtype: 'Friendly', grp_topic: '' })
                navigate("/group_topic", {
                    state: {
                        id:response.data.data.id
                    },
                    replace: true
                })
                // navigate({ pathname: '/feed', search: createSearchParams({ id:response.data.data.id }).toString() },{replace:true})
            })
            .catch(error => {
                setStatus('error')
            });
    };
    return (
        <Grid item maxWidth='xs'>
            <Box sx={{ bgcolor: 'white', padding: '16px' }}>
                <Box>
                    {/* <p className="title_1">Create new group to share your emotion with specific friends</p> */}
                    {/* <p className="title_1">Create new group to share your emotion</p> */}
                    <Typography variant='caption'>Create new group to share your emotion</Typography>
                    <Typography variant='h6' fontWeight={600}>Create Group Form</Typography>
                    {/* <h2 className="title_2">
                        Create Group Form
                    </h2> */}
                </Box>
                {(status === 'error') ? (<Alert severity="error">Sorry, something went wrong.</Alert>) : null}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        fullWidth
                        required
                        id="outlined-required"
                        label="Group Name"
                        margin='normal'
                        name='name'
                        value={details.name || ""}
                        onChange={handleChange}
                    />
                    {
                        (need === 'name') && <Typography mb={1} variant='caption' component='p' color='red'>Group name required</Typography>
                    }
                    <TextField
                        fullWidth
                        id="outlined-textarea"
                        label="About Group"
                        placeholder="About Group"
                        multiline
                        margin='normal'
                        name='about'
                        rows={2}
                        value={details.about || ""}
                        onChange={handleChange}
                    />
                    {
                        (need === 'about') && <Typography mb={1} variant='caption' component='p' color='red'>Group description required</Typography>
                    }
                    {/* <FormControl fullWidth margin='normal'>
                        <InputLabel id="demo-simple-select-label">Sub Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={details.grp_subtype}
                            label="Sub Type"
                            onChange={handleChange}
                            name='grp_subtype'
                        >
                            <MenuItem value='Friendly'>Friendly</MenuItem>
                            <MenuItem value='Restricted'>Restricted</MenuItem>
                        </Select>
                    </FormControl> */}
                    <Box sx={{ position: 'relative', display: 'inline-block', marginTop: '20px' }}>
                        <SubmitButton disabled={(status === 'loading') ? true : false} disableElevation variant="contained" type='submit'>
                            Submit
                        </SubmitButton>
                        {(status === 'loading') && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'green',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>

                </Box>
            </Box>
        </Grid>
    )
}

export default CreateNewGroup