import { Avatar, Typography } from '@mui/material'
import React, { Suspense } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeRounded, FeedbackRounded, Report, ContactMailRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

// const SidebarGroupList = React.lazy(() => import('./SidebarGroupList'))

const ASidebar = () => {
  const [open, setOpen] = React.useState(false);
  const token = JSON.parse(localStorage.getItem('token'))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSavedFolderOpen = () => {

  }

  const createGrop = () => {
    navigate('/group-type')
    // navigate('/create-group')
  }
  const chatHistory = () => {
    navigate('/chat-history')
    // navigate('/create-group')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'white' }}>
      {/* <List> */}
      <Box sx={{ padding: '10px 15px', }}>
        <ListItemButton onClick={() => navigate('/admin/home')}>
          <ListItemIcon>
            <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
              <HomeRounded color='primary' fontSize='small' />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Home"
          />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/admin/reports')}>
          <ListItemIcon>
            <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
              <Report color='primary' fontSize='small' />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Reports"
          />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/admin/contacts')}>
          <ListItemIcon>
            <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
              <ContactMailRounded color='primary' fontSize='small' />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Contacts"
          />
        </ListItemButton>
        <ListItemButton onClick={() => navigate('/admin/feedback')}>
          <ListItemIcon>
            <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
              <FeedbackRounded color='primary' fontSize='small' />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Feedback"
          />
        </ListItemButton>
        {/* <Suspense fallback={<div>Loading</div>}>
          {
            (token) &&
            <SidebarGroupList handleChatOpen={handleChatOpen} />
          }
        </Suspense> */}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => navigate('/affiliate-disclosure')}>Affiliate Disclosure</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
        <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
      </Box>
      {/* </List> */}
    </Box>
  )
}

export default ASidebar