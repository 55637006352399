import { Avatar, Box, Button, CircularProgress, Collapse, Typography } from '@mui/material'
import React from 'react'
import axios from 'axios';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import theme from '../../Theme';
import { SubTitle1, SubTitle3, Title2 } from '../../CustomComponents';
import { SignpostOutlined, WallpaperRounded } from '@mui/icons-material';
import { entities } from '../HtmlEntity';

const PostNotificationItem = ({ notification }) => {
    const navigate = useNavigate()
    const handlePostDetails = (post_id, user_who_fired_event) => {
        navigate("/notifications_post", {
            state: {
                post_id: post_id,
                user_who_fired_event
            },
        })
    }
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1,gap:1 }}>
                <Avatar variant='rounded' sx={{ width: 30, height: 30 }} src={notification.profile_pic} />
                <Box sx={{ flexGrow: 1 }}>
                    <SubTitle1 sx={{ color: theme.palette.dark.main }}><span style={{ color: theme.palette.primary.dark, fontWeight: 600 }}>{notification.name}</span> {notification?.message.replace(/&#?\w+;/g, match => entities[match])}.</SubTitle1>
                    {/* <SubTitle3 color='gray'>{moment(notification.added_on).format('dddd, MMM DD YYYY h:mm A')}</SubTitle3> */}
                    <Typography variant='caption' color='gray'>{moment(notification.added_on).format('dddd, MMM DD YYYY h:mm A')}</Typography>
                    {/* <Box sx={{}}>
                        <Box sx={{ cursor: 'pointer', padding: '4px 12px', borderRadius: '2px', display: 'inline-block', fontSize: '12px', backgroundColor: '#a29bfe', color: 'white' }} onClick={() => handlePostDetails(notification.post_id, notification.user_who_fired_event)}>
                            View
                        </Box>
                        <Typography variant='caption' color='text.secondary' ml={1}>{moment(notification.added_on).format('dddd, MMM DD YYYY h:mm A')}</Typography>
                    </Box> */}
                </Box>
                <WallpaperRounded color='dark' onClick={() => handlePostDetails(notification.post_id, notification.user_who_fired_event)} />
            </Box>
        </>
    )
}

export default PostNotificationItem