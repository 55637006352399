import { Avatar, Box } from '@mui/material'
import React from 'react'
import { SubTitle2, SubTitle3, Title2, Title3 } from '../CustomComponents'
import { Visibility } from '@mui/icons-material'

const AdsCard = ({ data }) => {
  return (
    <Box sx={{ width: '24%', overflow: 'hidden', borderRadius: '4px', border:'1px solid lightgray', position:"relative" }}>
              <span style={{position:'absolute',display:'inline-block', padding:'3px', borderRadius:'2px', backgroundColor:'lightgray', top:'6px', right:'10px', fontSize:'12px'}}>AD.</span>
      <img src={data.pic} style={{ width: '100%', height: '180px', objectFit: 'cover' }} />
      <Box sx={{ px: '8px' }}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '4px' }}>
          <Avatar src={data.logo} sx={{ width: 22, height: 22 }} />
          <Box sx={{display:'flex',gap:'4px'}}>
          <Title3>{data.company_name}</Title3>
          <span style={{display:'inline-block',backgroundColor:'green',color:'white',padding:'2px',borderRadius:'2px', fontSize:'10px'}}>active</span>
          <span style={{display:'inline-block',backgroundColor:'red',color:'white',padding:'2px',borderRadius:'2px', fontSize:'10px'}}>pending</span>
          </Box>
        </Box> */}
        <Title2 marginY='6px'>{data.title}</Title2>
        <SubTitle2>{data.subtitle}</SubTitle2>
        <Box sx={{display:'flex',alignItems:'center',gap:'20px',my:1}}>
            <SubTitle2>1.5k view</SubTitle2>
            <SubTitle2 flexGrow={1}>226 clicked</SubTitle2>
            <SubTitle2>2 MAY, 2023</SubTitle2>
        </Box>
      </Box>
    </Box>
  )
}

export default AdsCard