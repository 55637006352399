import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import theme from "../../Theme"
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../../media/images/emotiontown.png'
import ReactGA from 'react-ga4'
import { Avatar, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { entities } from "../../components/HtmlEntity"
import { EmotInput, FilledButton, Logo, StyledInputLabel, Title3 } from '../../CustomComponents';
import './signin.css'
import { useSelector } from 'react-redux';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © Emotiontown.com'}
      {/* <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link> */}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const [data, setData] = React.useState({ mobile_no: '', password: '' })
  const [message, setMessage] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)
  const accounts = JSON.parse(localStorage.getItem('accounts'))
  const { type, id } = useSelector(state => state.auth.destination)
  const navigate = useNavigate()
  const [searchparams] = useSearchParams()
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])
  React.useEffect(() => {
    setMessage("")
    return () => {
      setMessage("")
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'sign in',
      action: 'sign in',
      label: 'sign in'
    })
    setIsLoading(true)
    // return
    axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, data)
      .then(response => {
        setIsLoading(false)
        if (response.data.status === "error") {
          setMessage(response.data.message)
          return
        }
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token))
          localStorage.setItem('user', JSON.stringify(response.data.user))
          // localStorage.setItem('accounts',JSON.stringify())
          // localStorage.setItem('user', JSON.stringify(response.data.user.name.replace(/&#?\w+;/g, match => entities[match])))
          if(type !== '' && id !== '') {
            if(type === 'group') {
              // navigate to group
              navigate(`/group-profile/${id}`, { replace: true })
            }
            if(type === 'channel') {
              navigate(`/channel-profile/${id}`, { replace: true })
            }
          }else{
            navigate("/no-groups-list", { replace: true })
          }
        } else {
          setMessage("Something went wrong.Try later.")
        }
      }).catch(error => {
        setIsLoading(false)
        setMessage(error.response.data.message)
      })
  };

  const handleLoginThroughId = (user_id, profile_pic, name) => {
    navigate("/signinA", {
      state: {
        uid: user_id,
        profile_pic,
        name
      },
    })
  }

  (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: `100vh`, position: 'relative' }}>
        <Box sx={{ padding: '10px' }}>
          <Logo
            variant='h4'
            noWrap
            component="span"
            sx={{ fontSize: '20px', fontWeight: 600, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', flexGrow: 1 }}>
            EmotionTown
          </Logo>
        </Box>
        {/* <Avatar src='https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'absolute',top:'20px',left:'20px'}}/>
        <Avatar src='https://images.pexels.com/photos/3765114/pexels-photo-3765114.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'absolute',top:'100px',left:'320px'}}/>
        <Avatar src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'bottom',top:'30px',right:'200px'}}/>
        <Avatar src='https://images.pexels.com/photos/2775835/pexels-photo-2775835.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'absolute',top:'40px',left:'210px'}}/>
        <Avatar src='https://images.pexels.com/photos/2775835/pexels-photo-2775835.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'absolute',bottom:'70px',right:'120px'}}/>
        <Avatar src='https://images.pexels.com/photos/2775835/pexels-photo-2775835.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'absolute',top:'20px',left:'80px'}}/>
        <Avatar src='https://images.pexels.com/photos/2775835/pexels-photo-2775835.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:80,height:80,position:'absolute',bottom:'20px',right:'10px'}}/> */}
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px',
              backgroundColor: 'white',
              boxShadow: 'none',
              // backdropFilter: 'blur(7px)',
              padding: '16px',
              // backgroundColor: 'rgba(255,255,255,0.1)'
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <img src={logo} alt="not found" style={{ width: '55px', marginbottom: '22px' }} />
              <Typography fontWeight={600} fontSize='19px'>
                Welcome to Emotion Town
              </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ padding: '10px' }} autoComplete='off'>
              {
                message &&
                <Box sx={{ padding: '20px', borderRadius: '8px', backgroundColor: 'wheat' }}>{message}</Box>
              }
              <TextField
                margin="normal"
                required
                fullWidth
                id="mobile_no"
                label="Mobile No."
                name="mobile_no"
                type='tel'
                color='primary'
                sx={{ borderColor: 'white', color: 'white', outlineColor: 'white' }}
                value={data.email}
                onChange={(e) => setData({ ...data, mobile_no: e.target.value })}
              />
              <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password"> Password</InputLabel>
                <OutlinedInput
                  required
                  fullWidth
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={data.password || ''}
                  onChange={(e) => setData({ ...data, password: e.target.value })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Box sx={{ position: 'relative' }}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading ? true : false}
                  variant="contained"
                  sx={{}}
                  disableElevation
                >
                  Sign In
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#44bd32",
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Box>
            {
              (accounts && accounts.length > 0) &&
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start', width: '100%', boxSizing: 'border-box', p: 1 }}>
                {
                  (accounts && accounts.length > 0) &&
                  accounts.map((account, index) => {
                    return <Box key={index} sx={{ width: '100px' }} onClick={() => handleLoginThroughId(account.uid, account.profile_pic, account.name)} >
                      <Avatar src={account.profile_pic} sx={{ width: 50, height: 50, mb: 1, mx: "auto" }} />
                      <Typography fontSize='12px' color='text.secondary' sx={{ textOverflow: 'ellipsis', width: '90px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{account.name}</Typography>
                    </Box>
                  })
                }
              </Box>
            }
            <Box sx={{ padding: '10px' }}>
              <strong>New to Emotion Town ? </strong>
              <strong style={{ color: '#6C63FF', cursor: 'pointer' }} onClick={() => navigate('/register-second')}>Create an account</strong>
            </Box>
          </Box>
          <Copyright sx={{ mt: 2 }} />
          <Box sx={{ padding: '10px', textAlign: 'center' }}>
            {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
            <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', backgroundColor: theme.palette.primary.main, height: '100vh' }}>
        <Box className="img_container" sx={{ flex: 6, display: { xs: 'none', md: 'block' }, position: 'relative', height: '100%', overflow: 'hidden' }}>
          <img src='https://images.pexels.com/photos/7219325/pexels-photo-7219325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          {/* <img src='https://images.pexels.com/photos/8954915/pexels-photo-8954915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{width:'100%',height:'100%',objectFit:'cover'}}/> */}
          {/* <img src='https://images.pexels.com/photos/7219325/pexels-photo-7219325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{width:'100%',height:'100%',objectFit:'cover',filter: 'grayscale(1000%)',WebkitFilter:'grayscale(100%)'}}/> */}
          {/* <Avatar className='avatar' src='https://images.pexels.com/photos/1037989/pexels-photo-1037989.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'120px',height:'120px',position:'absolute',top:"202px",left:'50%'}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/1205033/pexels-photo-1205033.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',top:"318px",right:'100px',opacity:0.7}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/5225115/pexels-photo-5225115.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=699.825&fit=crop&h=1133.05' sx={{width:'80px',height:'80px',position:'absolute',bottom:"30%",right:'345px',display:{md:'none',lg:'inline-block'},opacity:0.6}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/4658128/pexels-photo-4658128.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',top:"100px",right:'20px',opacity:0.7}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/1498337/pexels-photo-1498337.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'95px',height:'95px',position:'absolute',top:"20px",left:'40%',opacity:0.7}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/1310522/pexels-photo-1310522.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',bottom:"45%",left:'100px',opacity:0.7}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/1090393/pexels-photo-1090393.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'130px',height:'130px',position:'absolute',bottom:"20px",right:'-56px'}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/783243/pexels-photo-783243.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'130px',height:'130px',position:'absolute',top:"20px",left:'20px'}}/>
          <Avatar className='avatar' src='https://images.pexels.com/photos/2531093/pexels-photo-2531093.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',bottom:"80px",left:'28%',opacity:0.7}}/> */}
        </Box>
        <Box sx={{ flex: 3, backgroundColor: 'white', p: 3 }}>
          <Typography fontSize='25px' color='dark' fontWeight={800} my={4}>Emotiontown</Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ padding: '10px' }} autoComplete='off'>
            {
              message &&
              <Box sx={{ padding: '16px', borderRadius: '6px', backgroundColor: 'wheat', mb: 2, color: theme.palette.dark.light }}>{message}</Box>
            }
            <StyledInputLabel htmlFor="short-room-description-id">
              Enter Mobile no.
            </StyledInputLabel>
            <EmotInput
              required
              fullWidth
              value={data.mobile_no || ''}
              id="mobile_no_id"
              sx={{}}
              onChange={(e) => setData({ ...data, mobile_no: e.target.value })}

            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <StyledInputLabel htmlFor="short-room-description-id">
                Enter Password
              </StyledInputLabel>
              <Title3 color='secondary' onClick={() => setShowPassword((show) => !show)} sx={{ cursor: 'pointer' }}>{showPassword ? 'Hide' : 'Show'}</Title3>
            </Box>
            <EmotInput
              fullWidth
              value={data.password || ''}
              required
              id="si_password-id"
              sx={{}}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
            <Box sx={{ position: 'relative', mt: 2 }}>
              <FilledButton
                type="submit"
                fullWidth
                disabled={isLoading ? true : false}
                variant="contained"
                sx={{}}
                disableElevation
              >
                Sign In
              </FilledButton>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#44bd32",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
          {
            (accounts && accounts.length > 0) &&
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start', width: '100%', boxSizing: 'border-box', p: 1 }}>
              {
                (accounts && accounts.length > 0) &&
                accounts.map((account, index) => {
                  return <Box key={index} sx={{ width: '100px' }} onClick={() => handleLoginThroughId(account.uid, account.profile_pic, account.name)} >
                    <Avatar src={account.profile_pic} sx={{ width: 50, height: 50, mb: 1, mx: "auto" }} />
                    <Typography fontSize='12px' color='text.secondary' sx={{ textOverflow: 'ellipsis', width: '90px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{account.name}</Typography>
                  </Box>
                })
              }
            </Box>
          }
          {/* <FilledButton sx={{ width: '100%', mt: 2, padding: '10px', fontWeight: 600 }} variant='contained'>Sign In</FilledButton> */}
          <Box sx={{ padding: '10px', textAlign: 'center', mt: 2 }}>
            <strong style={{ color: theme.palette.dark.light }}>New to Emotion Town ? </strong>
            <strong style={{ color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => navigate('/register-second')}>Create an account</strong>
          </Box>
          <Copyright sx={{ mt: 2 }} />
          <Box sx={{ padding: '10px', textAlign: 'center', mt: 2, display: 'flex', flexWrap: 'wrap' }}>
            {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/affiliate-disclosure')}>Affiliate Disclosure</Typography>
            <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
            <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}