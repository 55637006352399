import { Avatar, Box, Button, Divider, Fab, Grid, IconButton, Rating, Typography } from '@mui/material'
import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import styled from '@emotion/styled';
import Like from '@mui/icons-material/FavoriteBorderOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ClapOutlined from '@mui/icons-material/SignLanguageOutlined';
import Clap from '@mui/icons-material/SignLanguage';
import { Logo } from '../../CustomComponents';
import NotFound from '../status/NotFound';

const UserFullImage = () => {
    const { org_profile_pic, org_cover_pic } = useSelector(state => state.auth.userProfileInfo)
    const { org_group_profile_pic } = useSelector(state => state.group.currentGroupInfo)
    // const {info} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    return (
        (location.state.info === 'profile' || location.state.info === 'cover' || location.state.info === 'group') ?
            <>
                <Grid sx={{ height: '100vh', boxSizing: 'border-box' }}>
                    <Grid item sm={12} sx={{ backgroundColor: '#232629', textAlign: 'center', overflow: 'hidden', }}>
                        <Box sx={{ position: "absolute", top: '20px', left: '20px', display: 'flex', alignItems: 'center', zIndex: 99 }}>
                            <Avatar sx={{ width: 50, height: 50, bgcolor: '#ff7675' }} onClick={() => navigate(-1)}>
                                <CloseIcon />
                            </Avatar>
                            {/* <Logo variant='h4' mx={2} color='white'>EmotionTown</Logo> */}
                            <Logo
                            variant='h4'
                            noWrap
                            mx={2}
                            color='white'
                            sx={{ margin: '0px 16px', fontWeight: 600, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text',display: {xs:'none', md:'inline-block'} }}
                            >EmotionTown</Logo>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100vh' }}>
                            {
                                (location.state.info === 'cover') ?
                                    <img src={org_cover_pic} alt="full view" style={{ height: '100%', width: "100%", objectFit: 'contain' }} /> :
                                    (location.state.info === 'profile') ?
                                        <img src={org_profile_pic} alt="full view" style={{ height: '100%', width: "100%", objectFit: 'contain' }} /> :
                                        (location.state.info === 'group') ?
                                            <img src={org_group_profile_pic} alt="full view" style={{ height: '100%', width: "100%", objectFit: 'contain' }} /> :
                                            null
                            }
                        </Box>
                    </Grid>
                </Grid>
            </> :
            <NotFound data={"No data found"} subData={"Sorry, we could not found any data to show.Please try again later."} />
    )
}

export default UserFullImage