import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { Avatar } from '@mui/material';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';

export default function Users() {
    const [status, setStatus] = React.useState('loading')
    const [filter, setFilter] = React.useState('newest')
    const users = useSelector(state => state.post.allGroupPostList)
    const callNo = useSelector(state => state.post.allGroupPostListCallNo)
    const hasMore = useSelector(state => state.post.hasMoreAllGroupPost)
    const token = JSON.parse(localStorage.getItem("token"))
    const dispatch = useDispatch()

    React.useEffect(() => {
        // if (!profileInfo.id || profileInfo.id !== location.state.user_id) {
        //     setStatus('loading')
        //     axios.get(`${process.env.REACT_APP_API_URL}/api/users/getUser`, { headers: { authorization: "Bearer " + token } })
        //         .then(response => {
        //             // dispatch(setuserProfileInfo(response.data.data))
        //             setUsers(response.data.data)
        //             if (response.data.data) {
        //                 setStatus('ok')
        //             } else {
        //                 setStatus('not_found')
        //                 return
        //             }
        //             if (response.data.data.is_listed) {
        //                 setListed(true)
        //             } else {
        //                 setListed(false)
        //             }
        //         }).catch(error => {
        //             setStatus('error')
        //         })
        // } else {

        // }
    },[])

    const fetchMoreData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/post/getAllGroupPosts/${filter}/${callNo}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length >= 5) {
                    // dispatch(setPostListCallNo({ "feed_value": 'all_group_post' }))
                    // dispatch(appendPostList({ data: response.data.data, feed_value: 'all_group_post' }))
                } else {
                    // dispatch(appendPostList({ data: response.data.data, feed_value: 'all_group_post' }))
                    // dispatch(setHasMorePost({ data: false, feed_value: 'all_group_post' }))
                }
            }).catch(error => {
                // dispatch(setHasMorePost({ data: false, feed_value: 'all_group_post' }))
            })
    }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>sl.No</TableCell>
            <TableCell align="right">Profile</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Gender</TableCell>
            <TableCell align="right">Country</TableCell>
            <TableCell align="right">Added On</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index}
              </TableCell>
              <TableCell align="right"><Avatar src={user.profile_pic} sx={{width:30, height:30}}></Avatar></TableCell>
              <TableCell align="right">{user.name}</TableCell>
              <TableCell align="right">{user.gender}</TableCell>
              <TableCell align="right">{user.country}</TableCell>
              <TableCell align="right">{moment(user.added_on).format('DD MMM YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}