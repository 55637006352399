import { Avatar, Box, Button, InputBase, InputLabel, styled, TextField, Typography } from "@mui/material";
import { deepOrange } from "@mui/material/colors";

export const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  // color: rgba(152, 48, 255 ,0.8),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  transition: 0.3,
  '&:hover': {
    backgroundColor: 'rgba(252, 66, 123,0.2)',
  },
}));
export const NewLightButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  // color: rgba(152, 48, 255 ,0.8),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  transition: 0.3,
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#cfccff',
  },
}));
export const NewDarkButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  // color: rgba(152, 48, 255 ,0.8),
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.light,
  transition: 0.3,
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
export const Logo = styled(Typography)(({ theme }) => ({
  fontFamily: ['Raleway', 'sans-serif', 'Niconne', 'cursive'].join(','),
  userSelect: 'none',
  // background: 'linear-gradient(to right, #6C63FF,#6c5ce7, #e84393)',
  // background: 'linear-gradient(to right, #6C63FF,#14d2a2, #0b755a)',
  background: 'linear-gradient(to right, #0a3d62,#079992,#60a3bc,#0a3d62)',
  // -webkit-background-clip: 'text',
  // : 'transparent',
  //   -webkit-user-select: none; /* Safari */        
  // -moz-user-select: none; /* Firefox */
  // -ms-user-select: none; /* IE10+/Edge */
  // user-select: none; /* Standard */
}));
export const JoinButton = styled(Button)((theme) => ({
  fontWeight: '600',
  borderRadius: '40px',
  backgroundColor: '#4b7bec',
  color: 'white',
  padding: '6px 28px',
  textTransform: 'capitalize',
  marginRight: '15px',
  '&:hover': {
    backgroundColor: '#2f67e9',
  }
}))
export const CreatePostButton = styled(Button)((theme) => ({
  borderRadius: '6px',
  backgroundColor: '#4b7bec',
  fontSize: '14px',
  color: 'white',
  padding: '4px 20px',
  margin: '0px 8px',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#2f67e9',
  }
}))
export const CancelButton = styled(Button)((theme) => ({
  fontWeight: '600',
  borderRadius: '40px',
  backgroundColor: '#f1f3f3',
  color: '#2d3436',
  padding: '6px 28px',
  textTransform: 'capitalize',
  marginRight: '15px',
  '&:hover': {
    backgroundColor: '#c7cfd1',
  }
}))

export const AboutGroupBox = styled(Box)((theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap:'3px',
  color: 'white'
}))

export const MoreButton = styled(Button)((theme) => ({
  backgroundColor: 'rgba(199, 201, 201,0.3)',
  color: 'blue',
  textTransform: 'none',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: 'rgba(199, 201, 201,0.7)',
  }
}))
// title =======================================================
export const Title1 = styled(Typography)((theme) => ({
  cursor:'pointer',
  fontSize: '18px',
  fontWeight: 600,
  color:'#2e3638'
}))
export const Title2 = styled(Typography)((theme) => ({
  cursor:'pointer',
  fontSize: '14px',
  fontWeight: 600,
  color:'#2e3638'
}))
export const Title3 = styled(Typography)((theme) => ({
  cursor:'pointer',
  fontSize: '13px',
  fontWeight: 600,
  color:'#2e3638'
}))
export const Title4 = styled(Typography)((theme) => ({
  cursor:'pointer',
  fontSize: '11px',
  fontWeight: 600,
  color:'#2e3638'
}))
// sub title =======================================================
export const SubTitle1 = styled(Typography)((theme) => ({
  fontSize: '14px',
}))
export const SubTitle2 = styled(Typography)((theme) => ({
  fontSize: '12px',
}))
export const SubTitle3 = styled(Typography)((theme) => ({
  fontSize: '11px',
  // wordWrap:`break-word`,
  // wordSpacing:`9999999px`
  whiteSpace:'break-spaces'
}))
// =======================================================
// =======================================================
export const Heading1 = styled(Typography)((theme) => ({
  cursor:'pointer',
  fontSize: '20px',
  fontWeight: 600
}))
// =======================================================
// =======================================================
export const Title = styled(Typography)((theme) => ({
  fontSize: '14px',
  fontWeight: 600,
  // color: '#2e3638'
}))
export const MiniTitle = styled(Typography)((theme) => ({
  fontSize: '12px',
  fontWeight: 600,
  color: '#2e3638'
}))
export const SubTitle = styled(Typography)((theme) => ({
  fontFamily: ['Raleway', 'sans-serif', 'Niconne', 'cursive'].join(','),
  fontSize: '12px',
  color: '#627184'
}))
export const DarkSubTitle = styled(Typography)((theme) => ({
  fontFamily: ['Raleway', 'sans-serif',].join(','),
  fontSize: '12px',
  color: '#4c5767'
}))
export const DarkText = styled(Typography)((theme) => ({
  fontFamily: ['Rubik', 'sans-serif',].join(','),
  fontSize: '12px',
  fontWeight:500,
  color: '#4c5767'
}))
export const FilledButton = styled(Button)((theme) => ({
  borderRadius: '4px',
  backgroundColor: 'primary',
  padding: '6px 18px',
  color: 'white',
  fontSize: '12px',
  whiteSpace:'nowrap'
  // display:'inline-block'
}))
export const OutlinedButton = styled(Button)((theme) => ({
  borderRadius: '6px',
  backgroundColor: '#e8fdf8',
  padding: '6px 18px',
  color: '#d0fbf0',
  fontSize: '12px',
  whiteSpace:'nowrap'
}))
// export const OutlinedButton = styled(Button)((theme) => ({
//   borderRadius:'8px',
//   backgroundColor:'#EAEAF2',
//   padding:'8px 18px',
//   color:'#515e61',
//   fontSize:'12px',
// }))
export const DisabledButton = styled(Button)((theme) => ({
  fontSize: '12px',
  backgroundColor: '#EAEAF2',
  color: '#2e3638',
  whiteSpace:'nowrap'
}))
export const AmazonButton = styled(Button)((theme) => ({
  fontSize: '12px',
  backgroundColor: '#FEBD69',
  color: 'black',
  whiteSpace:'nowrap'
}))
export const LightAvatar = styled(Avatar)((theme) => ({
  width: 32,
  height: 32,
  backgroundColor: '#EAEAF2',
}))
export const SmallLightAvatar = styled(Avatar)((theme) => ({
  width: 30,
  height: 30,
  backgroundColor: '#c0c0d8',
}))
export const OutlinedAvatar = styled(Avatar)((theme) => ({
  width: 30,
  height: 30,
  backgroundColor: 'transparent',
  border:'1px solid #10ac84'
}))
export const FilledAvatar = styled(Avatar)((theme) => ({
  width: 30,
  height: 30,
  backgroundColor: '#d0fbf0',
  border:'1px solid #10ac84',
  color:'#10ac84'
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 600,
  color: '#515e61',
  marginBottom: '4px'
}))
export const EmotInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 6,
    backgroundColor: '#F2F2F7',
    fontSize: 14,
    width: '100%',
    padding: '10px 12px',
  },
}));
export const AuthInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 6,
    backgroundColor: '#F2F2F7',
    fontSize: 14,
    width: '100%',
    padding: '15px',
  },
}));
export const WhiteInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 6,
    // backgroundColor: '#F2F2F7',
    fontSize: 14,
    width: '100%',
    padding: '7px 12px',
  },
}));
export const FormFieldAlertBox = styled(Box)((theme) => ({
  backgroundColor: '#fcd7cf',
  color: '#e17055',
  fontSize: '14px',
  fontWeight: 600,
  padding: '16px',
  borderRadius: '8px'
}))