import { Avatar, Box, Button, Typography } from '@mui/material'
import React from 'react'
import { ColorButton, NewDarkButton, NewLightButton } from '../../CustomComponents'
import SecurityIcon from '@mui/icons-material/Security';
import private_group from '../../media/images/private.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Restricted = () => {
    const navigator = useNavigate()
    return (
        <Box sx={{ bgcolor: 'white', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 114px)' }}>
            {/* <img src={private_group} alt='no data found' style={{ width: '260px' }} /> */}
            <Avatar sx={{backgroundColor: '#cfccff',width:100,height:100}}><SecurityIcon fontSize='large' color='primary'/></Avatar>
            <Typography variant='h6' component='span' color='#6C63FF' mt={3} fontWeight='600'>Restricted</Typography>
            <Typography variant='body2' my={1} component='p' sx={{textAlign:'center'}}>Sorry, this user restrict you. Thats why you can't invite or get hot post from this user untill you become unrestricted.</Typography>
            <Button variant='contained' type='submit' startIcon={<ArrowBackIcon />} sx={{ml:1}} onClick={() => navigator(-1)}>Back</Button>
            {/* <Box sx={{display:'flex'}}>
            <ColorButton startIcon={<ArrowBackIcon />} onClick={() => window.location.reload(false)}>Refresh</ColorButton>
                <NewLightButton></NewLightButton>
                <NewDarkButton></NewDarkButton>
            </Box> */}
        </Box>
    )
}

export default Restricted