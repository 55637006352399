import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
// import groupSlice from './groupSlice'


const postSlice = createSlice({
    name: 'post',
    initialState: {
        openReactDialog: false,
        openShareDialog: false,
        openShareProfileDialog: false,
        openBookmarkDialog: false,
        // openRemoveMemberDialog: {staus:false,member_name:'',member_id:'',message:''},
        openNotifyDialog: { status: false, message: '' },
        openDeletePostDialog: { status: false, post_id: null, post_type: 'love', value: '', file_count: 0 },
        // =======================
        invitationRequestInfo: { total_invitation: 0, total_fav_request: 0, total_group_request: 0 },
        // =======================
        groupsPostUpdate: {
            newPostGroups: [],
            total_pin_posts: null,
            isNewPostGroupsChecked:false,
            isTotalPinPostChecked:false,
        },
        // =======================
        allGroupPostList: [],
        hasMoreAllGroupPost: true,
        allGroupPostListCallNo: 1,
        // =================
        memberPostList: [],
        hasMoreMemberPost: true,
        memberPostListCallNo: 1,
        // =================
        dataList: [],
        hasMoreData: true,
        dataListCallNo: 1,
        // =================
        reactList: [],
        hasMoreReact: true,
        reactListCallNo: 1,

        feedType: 'group_feed',
        // =======================
        reactPostInfo: {},

        foldersList: [], // ok ========================================
        fullPostDetails: {}, // to show full image =================
        postToSave: {}, // ok ===== used in share dialog (used to share into groups) == if contains group_profile_pic then share group_profile
        currentPostInfo: {}, // this is for feed_value and post_id --- used in bookmark dialog || getting feed type when sharing post or group_profile and user_profile
        shareProfileInfo: {}
    },
    reducers: {
        setOpenDeletePostDialog(state, action) {
            state.openDeletePostDialog = action.payload // groups who have new post today (Limit 5) // newPostGroups.jsx
        },
        setOpenNotifyDialog(state, action) {
            state.openNotifyDialog = action.payload // groups who have new post today (Limit 5) // newPostGroups.jsx
        },
        // setOpenRemoveMemberDialog(state, action) {
        //     state.openRemoveMemberDialog = action.payload // groups who have new post today (Limit 5) // newPostGroups.jsx
        // },
        setShareProfileInfo(state, action) {
            state.shareProfileInfo = action.payload // groups who have new post today (Limit 5) // newPostGroups.jsx
        },
        setNewPostedGroups(state, action) {
            state.groupsPostUpdate = { ...state.groupsPostUpdate, newPostGroups: action.payload.data, isNewPostGroupsChecked: true } // groups who have new post today (Limit 5) // newPostGroups.jsx
        },
        setTotalNewPinPostsUpdate(state, action) {
            state.groupsPostUpdate = { ...state.groupsPostUpdate, total_pin_posts: action.payload.data, isTotalPinPostChecked: true } // todays total post and total pin post in groups // todaysPostUpdate.jsx
        },
        setReactPostInfo(state, action) {
            state.reactPostInfo = action.payload // post_type && post_id && group_id
        },
        setInvitationRequestInfo(state, action) {
            state.invitationRequestInfo = action.payload // post_type && post_id && group_id
        },
        setInvitationRequestUpdateInfo(state, action) {
            if (action.payload.status === 'invitation') {
                state.invitationRequestInfo = { ...state.invitationRequestInfo, total_invitation: action.payload.value }
            } else if (action.payload.status === 'favourite') {
                state.invitationRequestInfo = { ...state.invitationRequestInfo, total_fav_request: action.payload.value }
            } else if (action.payload.status === 'groups') {
                state.invitationRequestInfo = { ...state.invitationRequestInfo, total_group_request: action.payload.value }
            }
        },
        setCurrentPostInfo(state, action) {
            state.currentPostInfo = action.payload
        },
        setOpenShareDialog(state, action) {
            state.openShareDialog = action.payload
        },
        setOpenShareProfileDialog(state, action) {
            state.openShareProfileDialog = action.payload
        },
        setOpenReactDialog(state, action) {
            state.openReactDialog = action.payload
        },
        setOpenBookmarkDialog(state, action) {
            state.openBookmarkDialog = action.payload
        },
        // react list ======================================================================
        // *********************************************************************************
        // set react list
        setReactList(state, action) {
            state.react = action.payload.data
        },
        // append reactlist
        appendReactList(state, action) {
            state.reactList = state.reactList.concat(action.payload.data)
        },
        // set react call no
        setReactListCallNo(state, action) {
            state.reactListCallNo = state.reactListCallNo + 1
        },
        // has more react
        setHasMoreReact(state, action) {
            state.hasMoreReact = action.payload.data
        },

        // post to save
        // ************************************************************************************************
        setfileList(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, fileList: action.payload.files }
                    } else {
                        return post
                    }
                })
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, fileList: action.payload.files }
                    } else {
                        return post
                    }
                })
            } else {
                state.dataList = state.dataList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, fileList: action.payload.files }
                    } else {
                        return post
                    }
                })
            }
        },
        // add poll options 
        setPollPostOptions(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, options: action.payload.options }
                    } else {
                        return post
                    }
                })
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, options: action.payload.options }
                    } else {
                        return post
                    }
                })
            } else {
                state.dataList = state.dataList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, options: action.payload.options }
                    } else {
                        return post
                    }
                })
            }
        },
        // add vote // is_voted: voted_option_id / null
        setVoteInPost(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, react: action.payload.is_voted, total_react: action.payload.total_react, options: action.payload.options }
                    } else {
                        return post
                    }
                })
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, react: action.payload.is_voted, total_react: action.payload.total_react, options: action.payload.options }
                    } else {
                        return post
                    }
                })
            } else {
                state.dataList = state.dataList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, react: action.payload.is_voted, total_react: action.payload.total_react, options: action.payload.options }
                    } else {
                        return post
                    }
                })
            }
        },
        // group post  ==============================
        // ok ========================================
        setPostToSave(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.postToSave = state.allGroupPostList.find(post => post.xz12uied7 === action.payload.data)
            } else if (action.payload.feed_value === 'member_post') {
                state.postToSave = state.memberPostList.find(post => post.xz12uied7 === action.payload.data)
            } else {
                state.postToSave = state.dataList.find(post => post.xz12uied7 === action.payload.data)
            }
        },
        setReadPostDataToPostToSave(state, action) {
            state.postToSave = action.payload.data
        },
        // ok ========================================
        setPostList(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = action.payload.data
            } else if (action.payload.feed_value === 'member_post') {
                state.feedType = 'member_post'
                state.memberPostList = action.payload.data
            } else {
                state.feedType = action.payload.feed_value
                state.dataList = action.payload.data
            }
        },
        // ok ========================================
        appendPostList(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.concat(action.payload.data)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.concat(action.payload.data)
            } else {
                state.dataList = state.dataList.concat(action.payload.data)
            }
        },
        // ok ========================================
        appendNewPostIntList(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = [action.payload.data, ...state.allGroupPostList]
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = [action.payload.data, ...state.memberPostList]
            } else {
                state.dataList = [action.payload.data, ...state.dataList]
            }
        },
        // ok ========================================
        setPostListCallNo(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostListCallNo = state.allGroupPostListCallNo + 1
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostListCallNo = state.memberPostListCallNo + 1
            } else {
                state.dataListCallNo = state.dataListCallNo + 1
            }
        },
        // ok ========================================
        setHasMorePost(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.hasMoreAllGroupPost = action.payload.data
            } else if (action.payload.feed_value === 'member_post') {
                state.hasMoreMemberPost = action.payload.data
            } else {
                state.hasMoreData = action.payload.data
            }
        },
        // ok ========================================
        setReactOnPost(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, react: action.payload.react, total_react: action.payload.total_react }
                    } else {
                        return post
                    }
                    // post.xz12uied7 === action.payload.id ? action.payload.val_copy : post
                })
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, react: action.payload.react, total_react: action.payload.total_react }
                    } else {
                        return post
                    }
                })
            } else {
                state.dataList = state.dataList.map((post, index) => {
                    if (post.xz12uied7 === action.payload.id) {
                        return { ...post, react: action.payload.react, total_react: action.payload.total_react }
                    } else {
                        return post
                    }
                })
            }
        },
        // // ok ========================================
        // increasePostTotalShareCount(state, action) {
        //     if (action.payload.feed_value === 'all_group_post') {
        //         state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, total_share: post.total_share + action.payload.count } : post)
        //     } else if (action.payload.feed_value === 'member_post') {
        //         state.memberPostList = state.memberPostList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, total_share: post.total_share + action.payload.count } : post)
        //     } else {
        //         state.dataList = state.dataList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, total_share: post.total_share + action.payload.count } : post)
        //     }
        // },
        setSavedPostToFolder(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xzu34ie0d98 === action.payload.data ? { ...post, is_saved: true } : post)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => post.xzu34ie0d98 === action.payload.data ? { ...post, is_saved: true } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xzu34ie0d98 === action.payload.data ? { ...post, is_saved: true } : post)
            }
        },
        setRemovePostFromFolder(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xzu34ie0d98 === action.payload.data ? { ...post, is_saved: null } : post)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => post.xzu34ie0d98 === action.payload.data ? { ...post, is_saved: null } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xzu34ie0d98 === action.payload.data ? { ...post, is_saved: null } : post)
            }
        },
        setRemovePostFromSavedFeed(state, action) {
            state.dataList = state.dataList.filter(post => post.xzu34ie0d98 !== action.payload.data)
        },
        setRemoveBookmarkedProfileFromSavedFeed(state, action) {
            state.dataList = state.dataList.filter(profile => profile.bookmark_item_id !== action.payload.data)
        },
        // ok =======================
        increasePostCommentCount(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xz12uied7 === action.payload.data ? { ...post, total_comment: post.total_comment + 1, is_commented: 'something' } : post)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => post.xz12uied7 === action.payload.data ? { ...post, total_comment: post.total_comment + 1, is_commented: 'something' } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xz12uied7 === action.payload.data ? { ...post, total_comment: post.total_comment + 1, is_commented: 'something' } : post)
            }
        },
        // ok =======================
        decreasePostCommentCount(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xz12uied7 === action.payload.data ? { ...post, total_comment: post.total_comment - 1 } : post)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => post.xz12uied7 === action.payload.data ? { ...post, total_comment: post.total_comment - 1 } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xz12uied7 === action.payload.data ? { ...post, total_comment: post.total_comment - 1 } : post)
            }
        },
        editPostDescription(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, description: action.payload.description } : post)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, description: action.payload.description } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, description: action.payload.description } : post)
            }
        },
        // ok =======================
        deletePost(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.filter(post => post.xz12uied7 !== action.payload.data)
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = state.memberPostList.filter(post => post.xz12uied7 !== action.payload.data)
            } else {
                state.dataList = state.dataList.filter(post => post.xz12uied7 !== action.payload.data)
            }
        },
        // ok =========================
        handlePinPost(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, is_pinned: action.payload.value } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, is_pinned: action.payload.value } : post)
            }
        },
        // ok =========================
        removePinPost(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.filter(post => post.xz12uied7 !== action.payload.data)
            } else {
                state.dataList = state.dataList.filter(post => post.xz12uied7 !== action.payload.data)
            }
        },
        // ok =======================
        updateShowHideStatus(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = state.allGroupPostList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, status: action.payload.value } : post)
            } else {
                state.dataList = state.dataList.map((post, index) => post.xz12uied7 === action.payload.post_id ? { ...post, status: action.payload.value } : post)
            }
        },
        // ok =========================
        emptyPostList(state, action) {
            if (action.payload.feed_value === 'all_group_post') {
                state.allGroupPostList = []
                state.hasMoreAllGroupPost = false
                state.allGroupPostListCallNo = 1
            } else if (action.payload.feed_value === 'member_post') {
                state.memberPostList = []
                state.hasMoreMemberPost = false
                state.memberPostListCallNo = 1
            } else {
                state.dataList = []
                state.hasMoreData = false
                state.dataListCallNo = 1
            }
        },
        // ************************************************************************************************
        // ok =========================
        setPostFeedType(state, action) {
            state.feedType = action.payload.data
        },

        // ok ========================================
        setFullPostDetails(state, action) {
            state.fullPostDetails = action.payload
        },
        // ok ========================================
        setFoldersList(state, action) {
            state.foldersList = action.payload
        },
        // ok ========================================
        addNewFolder(state, action) {
            state.foldersList = [action.payload, ...state.foldersList]
        },
        // ok ========================================
        increaseFolderContentCount(state, action) {
            state.foldersList = state.foldersList.map((folder, index) => folder.folder_id === action.payload ? { ...folder, content_count: folder.content_count + 1 } : folder)
        },
        // ok ========================================
        decreaseFolderContentCount(state, action) {
            state.foldersList = state.foldersList.map((folder, index) => folder.folder_id === action.payload ? { ...folder, content_count: folder.content_count - 1 } : folder)
        },
        // ok ========================================
        deleteFolder(state, action) {
            state.foldersList = state.foldersList.filter(folder => folder.folder_id !== action.payload)
        },
        // ok ========================================
        resetPostSlice(state, action) {
            state.openReactDialog = false
            state.openShareDialog = false
            state.openShareProfileDialog = false
            state.openBookmarkDialog = false
            state.openNotifyDialog = {
                status: false,
                message: ''
            }
            state.openDeletePostDialog = {
                status: false,
                post_id: null,
                post_type: 'love',
                value: '',
                file_count: 0
            }
            // ==========================
            state.invitationRequestInfo = {
                total_invitation: 0,
                total_fav_request: 0,
                total_group_request: 0
            }
            // ==========================
            state.groupsPostUpdate = {
                newPostGroups: [],
                total_new_posts: {},
                isNewPostGroupsChecked:false,
                isTotalPinPostChecked:false
            }
            // ==========================
            state.allGroupPostList = []
            state.hasMoreAllGroupPost = true
            state.allGroupPostListCallNo = 1
            // =================
            state.memberPostList = []
            state.hasMoreMemberPost = true
            state.memberPostListCallNo = 1
            // =================
            state.dataList = []
            state.hasMoreData = true
            state.dataListCallNo = 1
            // ==================================
            state.reactList = []
            state.hasMoreReact = true
            state.reactListCallNo = 1
            // ==================================
            state.feedType = 'group_feed'
            state.reactPostInfo = {}
            // ==================================
            state.foldersList = [] // ok ========================================
            state.fullPostDetails = {} // to show full image =================
            state.postToSave = {} // ok ========================================
            state.currentPostInfo = {}
            state.shareProfileInfo = {}
        }
    }
})

export const { setOpenDeletePostDialog, setOpenNotifyDialog, setShareProfileInfo, setfileList, setPollPostOptions, setVoteInPost, setPostToSave, setReadPostDataToPostToSave, setPostList, appendPostList, appendNewPostIntList, setPostListCallNo, setHasMorePost, setReactOnPost, setSavedPostToFolder, setRemovePostFromFolder, setRemoveBookmarkedProfileFromSavedFeed, setRemovePostFromSavedFeed, increasePostCommentCount, decreasePostCommentCount, editPostDescription, deletePost, handlePinPost, updateShowHideStatus, emptyPostList, setFullPostDetails, setFoldersList, addNewFolder, increaseFolderContentCount, decreaseFolderContentCount, deleteFolder, resetPostSlice, setPostFeedType, removePinPost, setOpenBookmarkDialog, setOpenShareDialog, setOpenShareProfileDialog, setInvitationRequestInfo, setInvitationRequestUpdateInfo, setCurrentPostInfo, setReactPostInfo, setReactList, appendReactList, setHasMoreReact, setReactListCallNo, setOpenReactDialog, setNewPostedGroups, setTotalNewPinPostsUpdate } = postSlice.actions
export default postSlice.reducer