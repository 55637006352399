import './iconsidebar.css'
import groups from '../../media/icons/groups2.png'
import bookmark from '../../media/icons/bookmark.png'
import home from '../../media/icons/home.png'
import invitation from '../../media/icons/invitation.png'
import createGroup from '../../media/icons/createGroup.png'
import library from '../../media/icons/photo.png'
import request from '../../media/icons/request.png'
import { Box } from '@mui/material'

const IconSidebar = () => {
  return (
    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',paddingTop:'63px'}}>
    <img className='icon_sidebar_img' src={home} />
    <img className='icon_sidebar_img' src={createGroup} />
    <img className='icon_sidebar_img' src={invitation} />
    <img className='icon_sidebar_img' src={request} />
    <img className='icon_sidebar_img' src={bookmark} />
    <img className='icon_sidebar_img' src={library} />
    <img className='icon_sidebar_img' src={groups} />
    </Box>
  )
}

export default IconSidebar