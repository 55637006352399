import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import NotFound from '../status/NotFound';
import Loading from '../status/Loading';
import ReactGA from 'react-ga4'
import { appendPostList, emptyPostList, setHasMorePost, setPostFeedType, setPostList, setPostListCallNo } from '../../store/postSlice';
import Lovecard from '../Cards/Lovecard';
import MultiLovecard from '../Cards/MultiLovecard';
import SharedGroupCard from '../Cards/SharedGroupCard';


const MyMemberPostFeed = ({ user_id }) => {
  const dispatch = useDispatch()
  const [loadingStatus, setLoadingStatus] = React.useState('ok') // ok, loading, error
  const feedType = useSelector(state => state.post.feedType)
  const posts = useSelector(state => state.post.dataList)
  const callNo = useSelector(state => state.post.dataListCallNo)
  const hasMore = useSelector(state => state.post.hasMoreData)
  const token = JSON.parse(localStorage.getItem("token"))

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])
  useEffect(() => {
    if(posts && posts.length > 0 && feedType === 'my_member_post' && posts[0].user_id === user_id) {
      // when user come back from full image view
      return
    }
    if ((feedType === 'my_member_post' && posts && posts.length > 0 && posts[0].user_id !== user_id) || feedType !== 'my_member_post') {
      dispatch(emptyPostList({ feed_value: 'my_member_post' }))
      // window.scrollTo(0, 0)
    }
    if(feedType !== 'my_member_post') {
      dispatch(setPostFeedType({data:'my_member_post'}))
    }
    dispatch(setHasMorePost({ data: true, feed_value: 'my_member_post' }))
    setLoadingStatus('loading')
    axios.get(`${process.env.REACT_APP_API_URL}/api/member/getMyMember-post/${user_id}/${1}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length === 0) {
          setLoadingStatus('empty')
        } else {
          setLoadingStatus('ok')
        }
        if (response.data.data.length >= 5) {
          dispatch(setPostListCallNo({ feed_value: "my_member_post" }))
          dispatch(setHasMorePost({ data: true, feed_value: 'my_member_post' }))
          dispatch(setPostList({ data: response.data.data, feed_value: 'my_member_post' }))
        } else {
          dispatch(setPostList({ data: response.data.data, feed_value: 'my_member_post' }))
          dispatch(setHasMorePost({ data: false, feed_value: 'my_member_post' }))
        }
      }).catch(error => {
        dispatch(setHasMorePost({ data: false, feed_value: 'my_member_post' }))
        setLoadingStatus('error')
      })
  }, [])


  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/member/getMyMember-post/${user_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length >= 5) {
          dispatch(setPostListCallNo({ feed_value: 'my_member_post' }))
          dispatch(appendPostList({ data: response.data.data, feed_value: 'my_member_post' }))
        } else {
          dispatch(appendPostList({ data: response.data.data, feed_value: 'my_member_post' }))
          dispatch(setHasMorePost({ datat: false, feed_value: 'my_member_post' }))
        }
      }).catch(error => {
        dispatch(setHasMorePost({ datat: false, feed_value: 'my_member_post' }))
      })
  }

  return (
    <>
      <Box sx={{ paddingBottom: '10px', marginTop: '10px' }}>
        {(loadingStatus === 'loading') && <Loading />}
        {
          (hasMore === false && posts.length <= 0 && loadingStatus !== 'ok') &&
          <NotFound data={"No post yet"} subData={"Sorry, we could not found any post to show.Please try again later."} />
        }
        {
          (loadingStatus === 'ok' && posts.length > 0) &&
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            /></Box>}
            endMessage={
              posts.length !== 0 ? <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p> : null
            }
          >
            {
              posts.map((val, index) => {
                if (val.post_type === 'love' && val.file_count > 1) {
                  return <MultiLovecard key={index} val={val} value='my_member_post'/>
                }
                else if (val.post_type === 'love') {
                  return <Lovecard key={index} val={val} value='my_member_post'/>
                }
                else if (val.post_type === 'groupProfile') {
                  return <SharedGroupCard key={index} val={val} value='my_member_post' />
                }
                else {
                  return
                }
              })
            }
          </InfiniteScroll>
        }
      </Box>
    </>
  )
}

export default MyMemberPostFeed

// if(refs.current) observer.observe(refs.current)