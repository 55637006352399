import { Chip } from '@mui/material'
import React from 'react'
import { setOpenSnack, setSnackMessage } from '../../../store/authSlice'
import { useDispatch } from 'react-redux'

const InterestItem = ({ item, index, setItems }) => {
    const [isClicked, setIsClicked] = React.useState(false)
    const dispatch = useDispatch()
    const handleClick = (item_no) => {
        if (isClicked) {
            // remove and seisclicked => false
            setIsClicked(false)
            setItems(e => e.filter(item => item !== item_no))
        } else {
            setIsClicked(true)
            setItems(e => {
                return [...e, item_no]
            })
        }
    }
    return (
        <Chip size='small' icon={item.icon} label={item.label} variant={isClicked ? "filled" : "outlined"} onClick={() => handleClick(index + 1)} color='primary' />
    )
}

export default InterestItem