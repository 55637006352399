import { Box, Typography } from '@mui/material'
import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../Theme'
import { Logo } from '../../CustomComponents';
import logo from '../../media/images/emotiontown.png'
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Emotiontown.com'}
            {/* <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link> */}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const AboutUs = () => {
    const navigate = useNavigate()
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])
    // return (
    //     <ThemeProvider theme={theme}>
    //         <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'white', position: 'fixed', zIndex: -1 }}></Box>
    //         <Box sx={{backgroundColor:'white'}}>
    //             <Box sx={{ width: { xs: '100%', md: '50%' }, backgroundColor: 'white', padding: '20px', margin: 'auto' }}>
    //                 <Box sx={{ padding: '10px', marginBottom: '30px' }}>
    //                     <Logo variant="h4" color='primary' sx={{ flexGrow: 1 }}>
    //                         EmotionTown
    //                     </Logo>
    //                 </Box>
    //                 <Box sx={{ textAlign: 'center', mb: 2 }}>
    //                     <img src={logo} alt="not found" style={{ width: '55px', marginbottom: '22px' }} />
    //                     <Typography fontWeight={600} fontSize='22px'>
    //                         Welcome to Emotion Town
    //                     </Typography>
    //                 </Box>
    //                 <Typography variant='body2' >
    //                     Emotiontown.com is a social networking Platform. Here you can create different type of group and communicate with different type of people. Here we provide a facility to creating different type post to communicate effectively and easyly with a interesting way that you will enjoy very much. We are committed to providing you the best of social networking, with a focus on reliability, making groups and high security. we strive to turn our passion for social networking into a thriving website. We hope you enjoy our social networking as much as we enjoy giving them to you.<br /><br />
    //                     I will keep on posting such valuable anf knowledgeable information on my Website for all of you. Your love and support matters a lot.
    //                 </Typography>
    //                 <Typography fontWeight={600} fontSize='17px' textAlign='center' mt={3}>
    //                     Thank you For Visiting Our Site
    //                 </Typography>
    //                 <Typography fontWeight={600} fontSize='17px' textAlign='center'>
    //                     Have a great day !
    //                 </Typography>
    //             </Box>
    //             <div>

    //                 <h2>Welcome to our website <b>abc</b></h2>
    //                 <p><b>abc</b>, is a professional platform where we provide informative content like [Your Contents Type]. We hope you like all the contents provided by us.</p><p>If you have additional questions or require more information about our website, do not hesitate to Contact through email at <b>abc@gmail.com</b></p>
    //                 <h2>About <b>abc</b></h2>
    //                 <p> <b>abc</b> This website founded in 9 FEB, 2023 by Prabir Khanra. Also, <b>EmotionTown</b> commits to reply to all people who subscribe to the YouTube Channel [channel link] and Follow the our website. We hope you enjoy Our services as much as we enjoy offering them to you. Sincerely, [Your Name]</p>
    //                 <h2>Aim of <b>abc</b></h2>
    //                 <p>Like any other website, <b>abc</b> We want viewers to enhance their skills with the help of our contents. We will continue to provide helpful content to you like this.Our About-us page was created with the help of the <a href="https://aboutuspagegenerate.blogspot.com/">About Us Generator Tool</a></p><p>We are a leading online learning platform, guiding viewers to identify their strengths and fill in their learning gaps.We are working to turn our passion of <b>abc</b> into a growing online website. If you have any question or query regarding our website, Please don't hesitate to contact us.</p>
    //                 <h2>Thanks For Visiting Our Site</h2>

    //             </div>
    //             <Copyright sx={{ mt: 1 }} />
    //             <Box sx={{ padding: '10px', textAlign: 'center' }}>
    //                 {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
    //                 <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
    //                 <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
    //                 <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
    //                 <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
    //             </Box>
    //         </Box>
    //     </ThemeProvider>
    // )
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'white', zIndex: -1, position: 'fixed' }}></Box>
            <Box sx={{ padding: { xs: '15px', md: '15px 100px' } }}>
                <Box sx={{ padding: '10px', marginBottom: '30px' }}>
                    <Logo
                        variant='h4'
                        noWrap
                        component="span"
                        sx={{ fontSize: '20px', fontWeight: 800, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', flexGrow: 1 }}>
                        EmotionTown
                    </Logo>
                </Box>
                <Box sx={{ marginBottom: '22px' }}>
                    <Typography fontWeight={600} fontSize='17px' marginBottom='6px'>🤝 Welcome to Emotiontown.com</Typography>
                    <Typography variant='body2'><b>EmotionTown</b>, is a social networking platform where you can create different type of group and communicate with different type of people. Here we provide a facility to creating different type of post to communicate effectively and easily with a interesting way that you will enjoy very much. We are committed to providing you the best of social networking, with a focus on reliability and high security. We hope you like all the features provided by us. If you have any questions or require more information about our website, do not hesitate to Contact through email at <b>townemotion764@gmail.com</b></Typography>
                </Box>
                <Box sx={{ marginBottom: '22px' }}>
                    <Typography fontWeight={600} fontSize='17px' marginBottom='6px'>👉 About <b>EmotionTown</b></Typography>
                    <Typography variant='body2'>This website founded in 9 FEB, 2023 by Prabir Khanra. We hope you enjoy Our services as much as we enjoy offering them to you. Sincerely, Prabir Khanra</Typography>
                </Box>
                <Box sx={{ marginBottom: '22px' }}>
                    <Typography fontWeight={600} fontSize='17px' marginBottom='6px'>🎯 Aim of <b>EmotionTown</b></Typography>
                    <Typography variant='body2'>Like any other social networking website, We want to enhance communication with an effective and interesting way. We will continue to provide different type of awesome features to you. If you have any question or query regarding our website, Please don't hesitate to contact us.</Typography>
                </Box>
                <Typography fontWeight={600} fontSize='17px' textAlign='center' mt={3}>
                    Thank you For Visiting Our Site
                </Typography>
                <Typography fontWeight={600} fontSize='17px' textAlign='center'>
                    Have a great day !
                </Typography>
                <Copyright sx={{ mt: 1 }} />
                <Box sx={{ padding: '10px', textAlign: 'center' }}>
                    <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
                    <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
                    <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
                    <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default AboutUs