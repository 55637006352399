import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Basiccard from '../Cards/Basiccard'
import Lovecard from '../Cards/Lovecard'
import NotFound from '../status/NotFound'
import WentWrong from '../status/WentWrong'
import MediaPoll from '../Cards/MediaPoll'
import SimplePoll from '../Cards/SimplePoll'
import { useDispatch, useSelector } from 'react-redux'
import MultiBasicCard from '../Cards/MultiBasicCard'
import MultiLovecard from '../Cards/MultiLovecard'
import { appendPostList, emptyPostList, setHasMorePost, setPostFeedType, setPostList, setPostListCallNo } from '../../store/postSlice'
import Loading from '../status/Loading'
import SharedGroupCard from '../Cards/SharedGroupCard';

// value is for ---- from which component or which reason this componenet is used
// in this way we can hide delete or allow button in the post
const MyPostInGroup = ({ group_id, current_profile_user_id }) => {
  const [status, setStatus] = React.useState('ok') // ok,error,not_found,loading
  const feedType = useSelector(state => state.post.feedType)
  const posts = useSelector(state => state.post.dataList)
  const callNo = useSelector(state => state.post.dataListCallNo)
  const hasMore = useSelector(state => state.post.hasMoreData)
  const token = JSON.parse(localStorage.getItem('token'))
  const dispatch = useDispatch()
  useEffect(() => {
    if (posts && posts.length > 0 && feedType === 'my_post_group_feed' && posts[0].creator_id === current_profile_user_id) {
      return
    }
    if ((feedType === 'my_post_group_feed' && posts && posts[0].creator_id !== current_profile_user_id) || feedType !== 'my_post_group_feed') {
      dispatch(emptyPostList({ feed_value: 'my_post_group_feed' }))
      window.scrollTo(0, 0)
    }
    if (feedType !== 'my_post_group_feed') {
      dispatch(setPostFeedType({ data: 'my_post_group_feed' }))
    }
    dispatch(setHasMorePost({ data: true, feed_value: 'my_post_group_feed' }))
    setStatus('loading')
    axios.get(`${process.env.REACT_APP_API_URL}/api/post/getMyAllPost/${current_profile_user_id}/${group_id}/${1}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length > 0) {
          setStatus('ok')
        } else {
          setStatus('not_found')
          return
        }
        if (response.data.data.length >= 5) {
          dispatch(setPostListCallNo({ feed_value: "my_post_group_feed" }))
          dispatch(setHasMorePost({ data: true, feed_value: 'my_post_group_feed' }))
          dispatch(setPostList({ data: response.data.data, feed_value: 'my_post_group_feed' }))
        } else {
          dispatch(setPostList({ data: response.data.data, feed_value: 'my_post_group_feed' }))
          dispatch(setHasMorePost({ data: false, feed_value: 'my_post_group_feed' }))
        }
      }).catch(error => {
        dispatch(setHasMorePost({ data: false, feed_value: 'my_post_group_feed' }))
        setStatus('error')
      })
  }, [group_id])

  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/post/getMyAllPost/${current_profile_user_id}/${group_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length >= 5) {
          dispatch(setPostListCallNo({ feed_value: 'my_post_group_feed' }))
          dispatch(appendPostList({ data: response.data.data, feed_value: 'my_post_group_feed' }))
        } else {
          dispatch(appendPostList({ data: response.data.data, feed_value: 'my_post_group_feed' }))
          dispatch(setHasMorePost({ datat: false, feed_value: 'my_post_group_feed' }))
        }
      }).catch(error => {
        dispatch(setHasMorePost({ datat: false, feed_value: 'my_post_group_feed' }))
      })
  }


  return (
    <>
      {(status === 'loading') && <Loading />}
      {(status === 'not_found') && <NotFound data={"No post yet"} subData={"Sorry, we could not found any post to show.Please try again later."} />}
      {(status === 'error') && <WentWrong />}
      <Box sx={{ marginTop: '16px' }}>
        {
          (status === 'ok' && posts.length > 0) &&
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              /></Box>
            }
            endMessage={
              posts.length !== 0 ? <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p> : null
            }
          >
            {
              posts.map((val, index) => {
                if (val.post_type === 'basic' && val.file_count > 1) {
                  return <MultiBasicCard key={index} val={val} value='my_post_group_feed' />
                }
                else if (val.post_type === 'love' && val.file_count > 1) {
                  return <MultiLovecard key={index} val={val} value='my_post_group_feed' />
                }
                else if (val.post_type === 'basic') {
                  return <Basiccard key={index} val={val} value="my_post_group_feed" />
                }
                else if (val.post_type === 'love') {
                  return <Lovecard key={index} val={val} value="my_post_group_feed" />
                }
                else if (val.post_type === 'textpoll') {
                  return <SimplePoll key={index} val={val} value="my_post_group_feed" />
                }
                else if (val.post_type === 'mediapoll') {
                  return <MediaPoll key={index} val={val} value="my_post_group_feed" />
                }
                else if (val.post_type === 'groupProfile') {
                  return <SharedGroupCard key={index} val={val} value='my_post_group_feed' />
                }
                else {
                  return
                }
              })
            }
          </InfiniteScroll>
        }
      </Box>
    </>
  )
}
export default MyPostInGroup