import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
// import { useSelector } from 'react-redux';
import { Backdrop, Box, Button, CircularProgress, Divider, Menu, MenuItem, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/PlaylistRemove';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import BlockIcon from '@mui/icons-material/Block';
import Unblock from '@mui/icons-material/NextPlanOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOffOutlined';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotFound from '../../status/NotFound';
import Loading from '../../status/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Save } from '@mui/icons-material';
import { setOpenSnack, setSnackMessage, setuserProfileInfoListing } from '../../../store/authSlice';
import { entities } from "../../HtmlEntity"

export default function LikedList() {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true)
  const [callNo, setCallNo] = React.useState(1)
  const [friendList, setFriendList] = React.useState([])
  // const {uid:user_id} = JSON.parse(localStorage.getItem("user"))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentFriend, setCurrentFriend] = React.useState({})
  const token = JSON.parse(localStorage.getItem("token"))
  // const savedStateUserProfileInfo = useSelector(state => state.auth.userProfileInfo)
  const { uid } = JSON.parse(localStorage.getItem('user'))
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  React.useEffect(() => {
    if (friendList.length <= 0) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/group/people_likes_me/${callNo}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.length > 0) {
            setCallNo(2)
            setHasMore(true)
            setFriendList(response.data.data)
          } else {
            setHasMore(false)
          }
        }).catch(error => {
          setHasMore(false)
        })
    }
  }, [])

  const handleClick = (event, friend) => {
    setAnchorEl(event.currentTarget);
    setCurrentFriend(friend)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/group/people_likes_me/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length > 0) {
          setCallNo(callNo + 1)
          setFriendList(friendList.concat(response.data.data))
        } else {
          setHasMore(false)
        }
      }).catch(error => {
        setHasMore(false)
      })
  }

  // const handleRemoveListing = (friend_id) => {
  //   axios.delete(`${process.env.REACT_APP_API_URL}/api/group/remove_from_list/${friend_id}`, { headers: { authorization: "Bearer " + token } })
  //     .then(response => {
  //       // setListed(false)
  //       if (savedStateUserProfileInfo.id && savedStateUserProfileInfo.id === friend_id) {
  //         dispatch(setuserProfileInfoListing(null))
  //       }
  //       setFriendList(friendList.filter(el => el.friend_id !== friend_id))
  //     }).catch(error => {
  //     })
  // }

  // block
  const handleBlockPeople = (friend_id) => {
    setOpenBackdrop(true)
    axios.put(`${process.env.REACT_APP_API_URL}/api/users/block/follower`, { to_user_id: friend_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status === 1) {
          // setFriendList(friends => friends.map(friend => friend.friend_id === friend_id ? { ...friend, status_from_item: 'blocked' } : friend))
          setFriendList(friendList.map(el => {
            if (el.friend_id === friend_id) {
              return { ...el, status_from_item: 'blocked' }
            } else {
              return el
            }
          }))
        }
        // dispatch(setSnackMessage(response.data.message))
        // dispatch(setOpenSnack(true))
        setOpenBackdrop(false)
      }).catch(error => {
        setOpenBackdrop(false)
        dispatch(setSnackMessage('Something went wrong.Try later.'))
        dispatch(setOpenSnack(true))
      })
  }

  // unblock
  const handleUnblockPeople = (friend_id) => {
    setOpenBackdrop(true)
    axios.put(`${process.env.REACT_APP_API_URL}/api/users/unblock/follower`, { to_user_id: friend_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status === 1) {
          // setFriendList(friends => friends.map(friend => friend.friend_id === friend_id ? { ...friend, status_from_item: 'unblocked' } : friend))
          setFriendList(friendList.map(el => {
            if (el.friend_id === friend_id) {
              return { ...el, status_from_item: 'unblocked' }
            } else {
              return el
            }
          }))
        }
        // dispatch(setSnackMessage(response.data.message))
        // dispatch(setOpenSnack(true))
        setOpenBackdrop(false)
      }).catch(error => {
        setOpenBackdrop(false)
        dispatch(setSnackMessage('Something went wrong.Try later.'))
        dispatch(setOpenSnack(true))
      })
  }

  // restrict
  const handleRestricted = (friend_id) => {
    setOpenBackdrop(true)
    axios.put(`${process.env.REACT_APP_API_URL}/api/users/restrict/follower`, { to_user_id: friend_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status === 1) {
          // setFriendList(friends => friends.map(friend => friend.friend_id === friend_id ? { ...friend, status_from_item: 'restricted' } : friend))
          setFriendList(friendList.map(el => {
            if (el.friend_id === friend_id) {
              return { ...el, status_from_item: 'restricted' }
            } else {
              return el
            }
          }))
        }
        // dispatch(setSnackMessage(response.data.message))
        // dispatch(setOpenSnack(true))
        setOpenBackdrop(false)
      }).catch(error => {
        setOpenBackdrop(false)
        dispatch(setSnackMessage('Something went wrong.Try later.'))
        dispatch(setOpenSnack(true))
      })
  }

  // restrict
  const handleRemove = (friend_id) => {
    setOpenBackdrop(true)
    axios.delete(`${process.env.REACT_APP_API_URL}/api/users/remove/follower/${friend_id}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status === 1) {
          setFriendList(friendList.filter(el => el.friend_id !== friend_id))
          dispatch(setSnackMessage(response.data.message))
          dispatch(setOpenSnack(true))
        }
        setOpenBackdrop(false)
      }).catch(error => {
        setOpenBackdrop(false)
        dispatch(setSnackMessage('Something went wrong.Try later.'))
        dispatch(setOpenSnack(true))
      })
  }

  return (
    <>
      {
        (hasMore === false && friendList.length <= 0) && <NotFound data={"No data found"} subData={"No one in your favourite list.First add some person to your favourite list then try again."} />
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        (friendList && friendList.length > 0) ?
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Box sx={{ padding: '16px 16px', backgroundColor: 'white' }}>
            <Typography variant='h6' >People who liked you</Typography>
          </Box>
          {
            <InfiniteScroll
              dataLength={friendList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                friendList.length !== 0 ? <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p> : null
              }
            >
              {
                friendList.map((friend, index) => {
                  return (
                    <React.Fragment key={friend.friend_id} >
                      <ListItem secondaryAction={
                        (location.state.current_user_id === uid) &&
                        <Avatar sx={{ width: 30, height: 30, backgroundColor: '#dfe6e9' }} onClick={(e) => handleClick(e, friend)}>
                          <MoreHorizOutlinedIcon fontSize='inherit' sx={{ color: '#636e72' }} />
                        </Avatar>
                      }>
                        <ListItemAvatar>
                          <Avatar sx={{ width: 30, height: 30 }} src={friend.profile_pic} onClick={() => navigate(`/profile/${friend.friend_id}/0`)}>
                            {/* <ImageIcon /> */}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={friend.name.replace(/&#?\w+;/g, match => entities[match])} sx={{ cursor: 'pointer' }} secondary={<Typography sx={{ fontSize: '10px', color: 'red' }}>{(friend.status_from_item !== 'unblocked') && friend.status_from_item}</Typography>} onClick={() => navigate(`/profile/${friend.friend_id}/0`)} />
                        {/* <ListItemText primary={friend.name.replace(/&#?\w+;/g, match => entities[match])} sx={{ cursor: 'pointer' }} secondary={friend.user_name} onClick={() => navigate(`/profile/${friend.friend_id}/0`)} /> */}
                      </ListItem>
                    </React.Fragment>
                  )
                })
              }
            </InfiniteScroll>
          }
        </List> : (hasMore === true) ? <Loading /> :null
      }
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {
          (currentFriend.status_from_item !== 'blocked') &&
          <MenuItem onClick={() => handleBlockPeople(currentFriend.friend_id)}>
            <BlockIcon fontSize='inherit' sx={{ marginRight: '6px', color: '#636e72' }} /> Block
          </MenuItem>
        }
        {
          (currentFriend.status_from_item !== 'unblocked') &&
          <MenuItem onClick={() => handleUnblockPeople(currentFriend.friend_id)}>
            <Unblock fontSize='inherit' sx={{ marginRight: '6px', color: '#636e72' }} /> {(currentFriend.status_from_item == 'restricted') ? 'Unrestrict' : 'Unblocked'}
          </MenuItem>
        }
        {
          (currentFriend.status_from_item !== 'restricted') &&
          <MenuItem onClick={() => handleRestricted(currentFriend.friend_id)}>
            <DoNotDisturbOffIcon fontSize='inherit' sx={{ marginRight: '6px', color: '#636e72' }} /> Restrict
          </MenuItem>
        }
        <MenuItem onClick={() => handleRemove(currentFriend.friend_id)}>
          <PlaylistRemoveIcon fontSize='inherit' sx={{ marginRight: '6px', color: '#636e72' }} /> Remove
        </MenuItem>
      </Menu>
    </>
  );
}
