import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
// import groupSlice from './groupSlice'


const channelSlice = createSlice({
    name: 'channel',
    initialState: {
        channelDetails: {},
        channelList: [],
        hasMoreChannel: true,
        channelListCallNo: 1,
        trendingBlogs: [],
    },
    reducers: {
        setChannelDetails(state, action) {
            state.channelDetails = action.payload.data
        },
        // ok ========================================  add channel tag
        updateChannelTag(state, action) {
            state.channelDetails = { ...state.channelDetails, tag: action.payload.tag }
        },
        // ok ========================================  update channel logo
        updateChannelLogo(state, action) {
            state.channelDetails = { ...state.channelDetails, logo: action.payload.logo }
        },
        // ok ========================================  update channel coverpic
        updateChannelCoverPic(state, action) {
            state.channelDetails = { ...state.channelDetails, channel_cover_pic: action.payload.channel_cover_pic }
        },

        // ok ========================================  update channel basic info
        updateChannelBasicInfo(state, action) {
            state.channelDetails = { ...state.channelDetails, name: action.payload.name, short_description: action.payload.short_description, description: action.payload.description }
        },

        // ok ========================================  follow / unfollow channel
        updateChannelFollowStatus(state, action) {
            state.channelDetails = { ...state.channelDetails, role: action.payload.role, status: action.payload.status }
        },

        // ok ========================================  channel handle
        updateChannelHandle(state, action) {
            state.channelDetails = { ...state.channelDetails, handle: action.payload.handle }
        },
        // ============================================
        setChannelList(state, action) {
            state.channelList = action.payload.data
        },
        appendChannelList(state, action) {
            state.channelList = state.channelList.concat(action.payload.data)
        },
        appendNewChannelIntList(state, action) {
            state.channelList = [action.payload.data, ...state.channelList]
        },
        setChannelListCallNo(state, action) {
            state.channelListCallNo = state.channelListCallNo + 1
        },
        setHasMoreChannel(state, action) {
            state.hasMoreChannel = action.payload.data
        },
        setTrendingBlogs(state, action) {
            state.trendingBlogs = action.payload.data
        },
        resetChannelSlice(state, action) {
            state.channelDetails= {}
            state.channelList= []
            state.hasMoreChannel= true
            state.channelListCallNo= 1
            state.trendingBlogs= []
        }
    }
})

export const { setChannelDetails, updateChannelTag, updateChannelLogo, updateChannelCoverPic, updateChannelBasicInfo, updateChannelHandle, updateChannelFollowStatus, setChannelList, appendChannelList, appendNewChannelIntList, setChannelListCallNo, setHasMoreChannel, resetChannelSlice, setTrendingBlogs } = channelSlice.actions
export default channelSlice.reducer