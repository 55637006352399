import axios from 'axios'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { setOpenSnack, setSnackMessage } from '../store/authSlice'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import Loading from './status/Loading'
import Error from './status/Error'
import NotiSimplePoll from './Post/NotificationPost/NotiSimplePoll'
// import NotiLovecard from './Post/NotificationPost/NotiLovecard'
import NotiMultiLovecard from './Post/NotificationPost/NotiMultiLovecard'
import NotiMediaPoll from './Post/NotificationPost/NotiMediaPoll'
import ArticleCard from './Cards/ArticleCard'
import NotiLovecard from './Post/NotificationPost/NotiLovecard'
import ReactGA from 'react-ga4'

const NotificationPost = () => {
    const [postDetails, setPostDetails] = React.useState({})
    const [status, setStatus] = React.useState(false)
    const token = JSON.parse(localStorage.getItem('token'))
    const dispatch = useDispatch()
    const location = useLocation()

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])
      
    React.useEffect(() => {
        if (!postDetails.options) {
            setStatus(`loading`)
            axios.get(`${process.env.REACT_APP_API_URL}/api/post/get_post/${location.state.post_id}/${location.state.user_who_fired_event}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    console.log(response.data.data)
                    setStatus(`ok`)
                    if (response.data.status === 1) {
                        // dispatch(setPollPostOptions({ id: val.xz12uied7, options: response.data.data }))
                        setPostDetails(response.data.data)
                    } else {
                        // show not found poll item
                    }
                }).catch(error => {
                    setStatus(`error`)
                    dispatch(setSnackMessage("Something went wrong. Try later."))
                    dispatch(setOpenSnack(true))
                })
        }
    }, [location])
    return (
        <>
            {
                (status === 'loading')  &&
                <Loading/>
            }
            {
                (status === 'error')  &&
                <Error/>
            }
            {
                ((postDetails.post_type === 'basic' || postDetails.post_type === 'love') && postDetails.file_count > 1) && <NotiMultiLovecard val={postDetails} />
                // ((postDetails.post_type === 'basic' || postDetails.post_type === 'love') && postDetails.file_count > 1) && <MultiLovecard val={postDetails} value='notification_post' />
            }
            {
                ((postDetails.post_type === 'basic' || postDetails.post_type === 'love') && postDetails.file_count < 2) && <NotiLovecard val={postDetails} />
            }
            {
                ( postDetails.post_type === 'article') && <ArticleCard val={postDetails} value='notification_post'/>
            }
            {
                (postDetails.post_type === 'textpoll') && <NotiSimplePoll val={postDetails} setVal={setPostDetails} />
            }
            {
                (postDetails.post_type === 'mediapoll') && <NotiMediaPoll val={postDetails} setVal={setPostDetails} />
            }
        </>
    )
}

export default NotificationPost