import { Backdrop, Box, Button, Checkbox, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewFolder, deleteFolder, setFoldersList, setSavedPostToFolder } from '../../store/postSlice'
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../status/Loading'
import NotFound from '../status/NotFound'
import WentWrong from '../status/WentWrong'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice'
import ReactGA from 'react-ga4'
import { entities } from "../HtmlEntity"

const Folders = () => {
    const [showCreateField, setShowCreateField] = React.useState(false)
    const [newFolderName, setNewFolderName] = React.useState('');
    const [checked, setChecked] = React.useState([]);
    const [foldersToSave, setFoldersToSave] = React.useState([]);
    const [status, setStatus] = React.useState('ok') // ok loading,not_found,error
    const [deletingFolderStatus, setDeletingFolderStatus] = React.useState(false)
    const [savePostStatus, setSavePostStatus] = React.useState(false)
    const dispatch = useDispatch()
    const { uid: user_id } = JSON.parse(localStorage.getItem('user'))
    const folders = useSelector(state => state.post.foldersList)
    const token = JSON.parse(localStorage.getItem("token"))
    const navigate = useNavigate()
    const { post_id, group_id, value } = useParams()
    const foldersEndRef = React.useRef(null);

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])

    React.useEffect(() => {
        if (showCreateField) {
            foldersEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [showCreateField])
    React.useEffect(() => {
        window.scrollTo(0, 0)
        const fetchFolders = async () => {
            if (folders.length <= 0) {
                setStatus('loading')
                try {
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/post/get_my_folders`, { headers: { authorization: "Bearer " + token } });
                    dispatch(setFoldersList(res.data.data))
                    if (res.data.data.length > 0) {
                        setStatus('ok')
                    }
                    else {
                        setStatus('not_found')
                    }
                } catch (error) {
                    setStatus('error')
                }
            }
        }
        fetchFolders()
    }, [post_id])

    const handleClose = () => {
        // return
        if (showCreateField) {
            setShowCreateField(false)
        } else if (foldersToSave.length > 0) {
            setChecked([])
            setFoldersToSave([])
            setNewFolderName("")
        } else {
            navigate(-1)
        }
    }
    const handleForCreateFolder = () => {
        setShowCreateField(true)
    }
    const handleSavePost = (event) => {
        event.preventDefault()
        setSavePostStatus(true)
        // try {
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/post_to_folder`, { post_id, group_id, folder_id: foldersToSave.toString(), folder_name: newFolderName }, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1 || res.data.status === 2) {
                    if (newFolderName.trim()) {
                        dispatch(addNewFolder({ folder_id: res.data.folder_id, folder_name: newFolderName, saved_post: 1 }))
                    }
                    // if(newFolderName.trim() === ""){
                    //     for (let index = 0; index < foldersToSave.length; index++) {
                    //         dispatch(increaseFolderContentCount(foldersToSave(index)))
                    //     }
                    // }
                    dispatch(setSavedPostToFolder({ feed_value: value, data: post_id }))
                }
                if (res.data.status === 0) {

                }
                dispatch(setSnackMessage(res.data.message))
                setNewFolderName("")
                dispatch(setOpenSnack(true))
                setSavePostStatus(false)
                setChecked([])
                setFoldersToSave([])
                navigate(-1)
            }).catch(error => {
                setSavePostStatus(false)
                dispatch(setSnackMessage(`Sorry, something went wrong. Try later.`))
                dispatch(setOpenSnack(true))
            })

        // } catch (error) {
        //     setSavePostStatus(false)
        //     dispatch(setSnackMessage(`Sorry, something went wrong. Try later.`))
        //     dispatch(setOpenSnack(true))
        // }
    }
    const handleToggle = (value, folder_id) => () => {
        const currentIndex = checked.indexOf(value);
        const currentFolderIdIndex = foldersToSave.indexOf(folder_id);
        const newChecked = [...checked];
        const newFolder = [...foldersToSave]

        if (currentIndex === -1) {
            newChecked.push(value);
            newFolder.push(folder_id)
        } else {
            newChecked.splice(currentIndex, 1);
            newFolder.splice(currentFolderIdIndex, 1)
        }
        setChecked(newChecked);
        setFoldersToSave(newFolder)
    };
    const handleDeleteFolder = async (folder_id, index) => {
        setChecked(checkedIndexes => checkedIndexes.filter(checkedIndex => checkedIndex !== index))
        setFoldersToSave(folderrs => folderrs.filter(folder => folder !== folder_id))
        setDeletingFolderStatus(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/delete_folder/${folder_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    dispatch(deleteFolder(folder_id))
                }
                dispatch(setSnackMessage(res.data.message))
                dispatch(setOpenSnack(true))
                setShowCreateField(false)
                setNewFolderName("")
                setDeletingFolderStatus(false)
            }).catch(error => {
                setDeletingFolderStatus(false)
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }
    return (
        <>
            {/* <Snackbar
                open={isSnackbarOpened}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={message}
                action={<IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnackbarClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>}
            /> */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={deletingFolderStatus}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ bgcolor: 'white', padding: '16px', marginTop: '10px' }}>
                {/* <Dialog
                
                open={isDialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
            > */}
                {/* <DialogTitle id="scroll-dialog-title"> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography component='span' variant='h6' fontSize='14px' fontWeight='600'>Add to Folder</Typography>
                    {
                        // showCreateField ? null : <Typography component='span' variant='h6' fontSize='14px' color='primary' sx={{ cursor: 'pointer' }} onClick={handleForCreateFolder}>Create New</Typography>
                        showCreateField ? null : <Button variant='contained' size='small' color='primary' onClick={handleForCreateFolder}>Create New Folder</Button>
                    }
                </Box>
                {/* </DialogTitle> */}
                {/* <DialogContent dividers={true} sx={{ padding: '0px 16px' }}> */}
                <Box>
                    {status === 'loading' ? <Loading /> : status === 'not_found' ? <NotFound data={"No folder found"} subData={"Sorry, we could not found any folder to show.Please try again later."} /> : status === 'error' ? <WentWrong /> :
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense={true}>
                            {
                                // status === 'loading' ? <CircularProgress/> : (status !== 'error') ?
                                folders.map((folder, index) => {
                                    const labelId = `checkbox-list-label-${index}`;
                                    return <ListItem
                                        key={index}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" size='small' onClick={() => handleDeleteFolder(folder.folder_id, index)}>
                                                <DeleteIcon fontSize='inherit' />
                                            </IconButton>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={handleToggle(index, folder.folder_id)} dense={true}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(index) !== -1}
                                                    // tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={folder.folder_name.replace(/&#?\w+;/g, match => entities[match])} />
                                        </ListItemButton>
                                    </ListItem>
                                })
                                // : null
                            }
                        </List>}
                </Box>
                {/* </DialogContent> */}
                <Box sx={{ my: 0, bgColor: 'red', padding: '0px 0px 10px' }}>
                    {/* <FoldersList checked={checked} setChecked={setChecked} /> */}
                    {
                        showCreateField ?
                            <>
                                {/* <Divider sx={{ mt: 1 }} /> */}
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%' },

                                        display: 'flex',
                                        margin: '16px 0px 0px'
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={handleSavePost}
                                    ref={foldersEndRef}
                                // onSubmit={handleSaveFolder}
                                >
                                    <input type='text' autoFocus value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} placeholder='new folder name' id="folder_name" name="folder_name" style={{
                                        width: '100%',
                                        padding: '12px 20px',
                                        // margin: '8px 0px',
                                        boxSizing: 'border-box',
                                        border: 'none',
                                        backgroundColor: '#f1f2f3',
                                        color: 'white',
                                        outlineColor: '#dcdde1',
                                        color: '#525960'
                                    }} />
                                    <Box sx={{ position: 'relative' }}>
                                        <Button type='submit' variant="contained" sx={{ ml: 1, textTransform: 'capitalize' }}>Create</Button>
                                        {savePostStatus && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: '#44bd32',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Box>
                                    {/* <Button type='submit' variant="contained" sx={{ ml: 1, textTransform: 'capitalize' }} disableElevation>Create</Button> */}
                                </Box>
                            </> : null
                    }
                </Box>
                {/* <DialogActions> */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                    <Box sx={{ ml: 2, position: 'relative' }}>
                        <Button variant='contained' disabled={foldersToSave.length > 0 ? false : true} onClick={handleSavePost}>Save Post</Button>
                        {savePostStatus && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
                {/* {
                    <Button disabled={foldersToSave.length > 0 ? false : true} onClick={handleSavePost}>Save</Button>
                } */}
                {/* </DialogActions>
            </Dialog> */}
            </Box>
        </>
    )
}

export default Folders