import { Avatar, Box, Button, CardActions, CardContent, Chip, CircularProgress, IconButton, LinearProgress, TextField, Typography } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ReactGA from 'react-ga4'
import { setOpenSnack, setSnackMessage } from '../../../../store/authSlice';
import { PostAdd } from '@mui/icons-material';
import { useLocation, useOutletContext } from 'react-router-dom';
import moment from 'moment/moment';
import { appendNewPostIntList } from '../../../../store/postSlice';


const PostButton = styled(Button)((theme) => ({
    backgroundColor: 'rgba(64,53,151,0.8)',
    borderRadius: '0px',
    border: '1px solid rgba(64,53,151,0.8)',
    color: 'white',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: 'rgba(64,53,151,0.9)',
    }
}))

const CancelButton = styled(Button)((theme) => ({
    border: '1px solid rgba(64,53,151,0.8)',
    // backgroundColor: 'transparent',
    borderRadius: '0px',
    color: 'rgba(64,53,151,0.8)',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: '#f5f6fa',
    }
}))
const OptionsChip = styled(Chip)((theme) => ({
    margin: '4px',
}))
const TextPollPost = ({ post_type }) => {
    const [title, setTitle] = React.useState("")
    const [pollOptions, setPollOptions] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [option, setOption] = React.useState("")
    const [error, setError] = React.useState("")
    const [uploadPercentage, setUploadPercentage] = React.useState(0);
    const [timeNeeded, setTimeNeeded] = React.useState(0)
    const [speed, setSpeed] = React.useState(0)
    // const user_id = useSelector((state) => state.auth.userInfo.id)
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const { name: group_name, type, role, is_colored } = useSelector(state => state.group.groupShortInfo)
    const token = JSON.parse(localStorage.getItem("token"))
    const socket = useOutletContext()
    // const group_id = useSelector((state) => state.group.groupProfileId)
    const dispatch = useDispatch()
    const location = useLocation()
    const handleDelete = (chipToDeleteKey) => {
        setPollOptions((chips) => chips.filter((chip) => chip.key !== chipToDeleteKey));
    }

    const convertToMinAndHour = (seconds) => {
        var toMin = Math.floor(seconds / 60)
        if (toMin > 60) {
            // convert to hour
            return `${Math.floor(toMin / 60) || 0} hour`
        } else if (toMin > 0 && toMin < 60) {
            // convert to minute
            return `${Math.floor(seconds / 60) || 0} min`
        } else {
            return `${seconds} sec`
        }
    }
    const convertToKBAndMB = (byte) => {
        var kbdata = Math.floor(byte / 1024)
        if (kbdata > 1024) {
            return `${Math.floor(kbdata / 1024) || 0} mb`
        } else if (byte < 1024) {
            return `${Math.floor(byte) || 0} b`
        } else {
            return `${kbdata} kb`
        }
    }

    const addOptions = () => {
        let key = Date.now()
        let obj = { key: key, text: option }
        setPollOptions([...pollOptions, obj])
        setOption('')
    }
    const handleCancelPost = () => {
        setTitle("")
        setPollOptions([])
        if (error) {
            // setOption("")
            setError("")
        }
    }
    const handleSubmit = (event) => {
        if (option.trim() !== '') {
            setError(`Do you want to add -${option} in poll?`)
        }
        event.preventDefault()
        ReactGA.event({
            category: 'create post',
            action: 'create post',
            label: `${post_type} post creation`
        })
        const data = { group_id: location.state.id, post_type, description: title, options: pollOptions }
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/simple_poll`, data, {
            headers: { authorization: "Bearer " + token },
            onUploadProgress: (data) => {
                setUploadPercentage(Math.round((data.loaded / data.total) * 100));
                setTimeNeeded(Math.floor(data.estimated || 0))
                setSpeed(Math.round(data.bytes))
            },
        })
            .then(response => {
                handleCancelPost()
                setLoading(false)
                setUploadPercentage(0)
                setTimeNeeded(0)
                if (response.data.status === 1) {
                    const post = {
                        "xz12uied7": response.data.post_id,
                        "group_id": location.state.id,
                        "group_name":group_name,
                        "creator_id": user_id,
                        "post_type": post_type,
                        "total_react": 0,
                        "total_share": 0,
                        "total_comment": 0,
                        "post_to": "all",
                        "description": title,
                        "status": "active",
                        "added_on": moment().format(),
                        "name": name,
                        'options': response.data.options,
                        'is_voted': null,
                        "profile_pic": profile_pic,
                        "react": null,
                        "is_saved": null,
                        "is_pinned": null,
                        "is_boosted": null,
                        "is_commented": null,
                        "is_colored": is_colored,
                        "type": type,
                        "role": role,
                    }
                    if (response.data.status !== 0) {
                        dispatch(appendNewPostIntList(post))
                    }
                    if (response.data.status === 1) {
                        socket?.emit('new-post', { roomId: location.state.id, group_name })
                    }
                }
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
                setLoading(false)
            })
        //   dispatch(createSimplePost(data))
    }
    return (
        <Box component='form' onSubmit={handleSubmit}>
            <CardContent>
                <TextField
                    fullWidth
                    id="text_poll_post_dialog_textfield_id"
                    label="write a title"
                    multiline
                    maxRows={3}
                    name="title about poll"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                // defaultValue="Default Value"
                />
                <Box sx={{ paddingTop: '10px' }}>
                    <Typography variant='subtitle2'>Options <small>(maximum 4 and minimum 2 options)</small></Typography>
                    {
                        pollOptions.map((item, index) => {
                            return <OptionsChip
                                label={item.text}
                                variant="outlined"
                                onDelete={() => handleDelete(item.key)}
                            />
                        })
                    }
                    {
                        (pollOptions.length < 4) &&
                        <Box sx={{ marginTop: '6px', display: 'flex', alignItems: 'start' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextField
                                    id="outlined-password-input"
                                    label="Add Options"
                                    type="text"
                                    fullWidth
                                    value={option}
                                    inputProps={{ maxLength: 32 }}
                                    onChange={(e) => {
                                        setOption(e.target.value)
                                    }}
                                />
                                <Typography variant='body2' textAlign='right' marginTop='6px'>{option.length}/32</Typography>
                            </Box>
                            <Avatar aria-label="add" sx={{ color: '#6c5ce7', backgroundColor: 'rgba(199, 201, 201,0.3)', marginLeft: '8px', marginTop: '7px' }} onClick={addOptions}>
                                <AddIcon fontSize="inherit" />
                            </Avatar>
                        </Box>
                    }

                </Box>
                <Typography variant='caption' color='red'>{error}</Typography>
                {
                    (uploadPercentage > 0) &&
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', marginRight: '4px' }}>
                                <LinearProgress variant="determinate" value={uploadPercentage} />
                            </Box>
                            <Box sx={{ minWidth: 35, textAlign: 'end' }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    uploadPercentage,
                                )}%`}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Button onClick={cancelUpload} variant='contained' size='small'>cancel</Button> */}
                            <Typography fontSize='13px' color='grey' mx={1}>{convertToMinAndHour(timeNeeded)}</Typography>
                            <Typography fontSize='13px' color='grey'>({convertToKBAndMB(speed)}/sec)</Typography>
                        </Box>
                    </>
                }
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Box sx={{ mr: 1, position: 'relative' }}>
                    {/* <Button
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                        onClick={handleButtonClick}
                    >
                        Accept terms
                    </Button> */}
                    <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={(loading || pollOptions.length < 2 || title.trim().length <= 0) ? true : false} disableElevation>Post</Button>

                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#44bd32',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
                {/* <Box>
                    <Button type='submit' variant="contained" startIcon={<PostAdd/>} disabled={(error || pollOptions.length < 2 ) ? true : false} disableElevation>Post</Button>
                </Box> */}
            </CardActions>
        </Box>
    )
}

export default TextPollPost