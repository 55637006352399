import { Avatar, Box, Button, CircularProgress, LinearProgress, Typography } from '@mui/material'
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment/moment';
import WentWrong from '../status/WentWrong';
import NotFound from '../status/NotFound';
import Loading from '../status/Loading';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';

const RateReactDetails = () => {
  const [reactCountStatus, setReactCountStatus] = React.useState('ok')
  const [reactCount, setReactCount] = React.useState({})
  const [reactDetails, setReactDetails] = React.useState([])
  const [showDetails, setShowDetails] = React.useState(false)
  const [callNo, setCallNo] = React.useState(1)
  const [hasMore, setHasMore] = React.useState(true)
  const token = JSON.parse(localStorage.getItem('token'))
  const navigate = useNavigate()
  const { post_id } = useParams()
  React.useEffect(() => {
    if (!reactCount.post_id) {
      setReactCountStatus('loading')
      axios.get(`${process.env.REACT_APP_API_URL}/api/post/rate_react_counting/${post_id}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.post_id) {
            setReactCountStatus('ok')
            setReactCount(response.data.data)
          } else {
            setReactCountStatus('not_found')
          }
        }).catch(error => {
          setReactCountStatus('error')
        })
    }
  }, [post_id])
  React.useEffect(() => {
    if (reactDetails.length <= 0 && showDetails && reactCount.post_id === post_id) {
      // setHasMore(true)
      axios.get(`${process.env.REACT_APP_API_URL}/api/post/total_react_details/${post_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.length >= 10) {
            setCallNo(callNo + 1)
            setReactDetails(response.data.data)
          } else {
            setReactDetails(response.data.data)
            setHasMore(false)
          }
        }).catch(error => {
        })
    }
  }, [showDetails])
  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/post/total_react_details/${post_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length >= 10) {
          setCallNo(callNo + 1)
          setReactDetails(reactDetails.concat(response.data.data))
        } else {
          setReactDetails(reactDetails.concat(response.data.data))
          setHasMore(false)
        }
      }).catch(error => {
      })
  }
  return (
    <Box sx={{ bgcolor: 'white', padding: '16px' }}>
      {
        reactCountStatus === 'loading' ? <Loading /> : reactCountStatus === 'not_found' ? <NotFound data={"No data found"} subData={"Sorry, we could not found any data to show.Please try again later."} /> : reactCountStatus === 'error' ? <WentWrong /> : reactCountStatus === 'ok' ?
          <>
            <Typography fontWeight='600' variant='h6'>Member Reviews</Typography>
            <Box sx={{ display: 'flex', alignItems: 'end', marginBottom: "20px" }}>
              <Typography fontWeight='800' variant='h2' component='span' >{reactCount.avg_rate}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '10px',
                  ml:1
                }}
              >
                <Rating
                  name="text-feedback"
                  value={reactCount.avg_rate}
                  readOnly
                  precision={0.1}
                  emptyIcon={<StarIcon style={{ color: '#dfe4ea' }} fontSize="inherit" />}
                />
                <Typography component='span' ml={3}>{reactCount.total_react} review{(reactCount.total_react > 1) && 's'}</Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography mr={1} component='span'>5</Typography>
                <LinearProgress color="inherit" sx={{ width: '85%', height: '10px', borderRadius: '2px', color: '#2ed573' }} variant='determinate' value={(reactCount.total_five/reactCount.total_react) * 100} />
                <Box sx={{ display: 'flex', width: '12%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography ml={2} variant="button">{(reactCount.total_five/reactCount.total_react) * 100}%</Typography>
                  <Typography color="gray" variant='overline'>{reactCount.total_five}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography mr={1} component='span'>4</Typography>
                <LinearProgress color="inherit" sx={{ width: '85%', height: '10px', borderRadius: '2px', color: '#32ff7e' }} variant='determinate' value={(reactCount.total_four/reactCount.total_react) * 100} />
                <Box sx={{ display: 'flex', width: '12%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography ml={2} variant="button">{(reactCount.total_four/reactCount.total_react) * 100}%</Typography>
                  <Typography color="gray" variant='overline'>{reactCount.total_four}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography mr={1} component='span'>3</Typography>
                <LinearProgress color="inherit" sx={{ width: '85%', height: '10px', borderRadius: '2px', color: '#fffa65' }} variant='determinate' value={(reactCount.total_three/reactCount.total_react) * 100} />
                <Box sx={{ display: 'flex', width: '12%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography ml={2} variant="button">{(reactCount.total_three/reactCount.total_react) * 100}%</Typography>
                  <Typography color="gray" variant='overline'>{reactCount.total_three}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography mr={1} component='span'>2</Typography>
                <LinearProgress color="inherit" sx={{ width: '85%', height: '10px', borderRadius: '2px', color: 'orange' }} variant='determinate' value={(reactCount.total_two/reactCount.total_react) * 100} />
                <Box sx={{ display: 'flex', width: '12%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography ml={2} variant="button">{(reactCount.total_two/reactCount.total_react) * 100}%</Typography>
                  <Typography color="gray" variant='overline'>{reactCount.total_two}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography mr={1} component='span'>1</Typography>
                <LinearProgress color="inherit" sx={{ width: '85%', height: '10px', borderRadius: '2px', color: 'tomato' }} variant='determinate' value={(reactCount.total_one/reactCount.total_react) * 100} />
                <Box sx={{ display: 'flex', width: '12%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography ml={2} variant="button">{(reactCount.total_one/reactCount.total_react) * 100}%</Typography>
                  <Typography color="gray" variant='overline'>{reactCount.total_one}</Typography>
                </Box>
              </Box>
            </Box>
            <Button variant="outlined" sx={{ margin: '16px 0px', textTransform: 'capitalize' }} endIcon={showDetails ? <ArrowDown /> : <ArrowUp/>} onClick={() => setShowDetails(!showDetails)}>View all reviews</Button>
          </> : null
      }
      {
        showDetails &&
        <>
          <InfiniteScroll
            dataLength={reactDetails.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            /></Box>}
            endMessage={
              reactDetails.length !== 0 ? <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p> : null
            }
          >
            {
              reactDetails.map((val, index) => {
                return <Box sx={{ display: 'flex', alignItems: 'start', margin: '16px 0px' }}>
                  <Avatar alt={val.name} src={val.profile_pic} onClick={() => navigate(`/profile/${val.user_id}/0`)}/>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography>{val.name}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Rating
                        name="text-feedback"
                        value={val.react}
                        readOnly
                        precision={1}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                      <Typography ml={3} color='body2' variant='overline' sx={{ textTransform: 'lowercase' }}>{moment(val.added_on).format('DD-MMM-YY')}</Typography>
                    </Box>
                  </Box>
                </Box>
              })
            }
          </InfiniteScroll>
        </>
      }
      {/* <Box sx={{ display: 'flex', alignItems: 'start', margin: '16px 0px' }}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Box sx={{ marginLeft: "10px" }}>
          <Typography>Prabir Khanra</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Rating
              name="text-feedback"
              value={2}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            <Typography ml={3} color='body2' variant='overline' sx={{ textTransform: 'lowercase' }}>3 days ago</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'start', margin: '16px 0px' }}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Box sx={{ marginLeft: "10px" }}>
          <Typography>Prabir Khanra</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Rating
              name="text-feedback"
              value={2}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            <Typography ml={3} color='body2' variant='overline' sx={{ textTransform: 'lowercase' }}>3 days ago</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'start', margin: '16px 0px' }}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Box sx={{ marginLeft: "10px" }}>
          <Typography>Prabir Khanra</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Rating
              name="text-feedback"
              value={2}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            <Typography ml={3} color='body2' variant='overline' sx={{ textTransform: 'lowercase' }}>3 days ago</Typography>
          </Box>
        </Box>
      </Box> */}
    </Box>
  )
}

export default RateReactDetails