import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
  return (
    <Box sx={{ height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',position:'relative' }}>
      <CircularProgress size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px'
        }} />
    </Box>
  )
}

export default Loading