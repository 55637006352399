import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import groupSlice from './groupSlice'
import postSlice from './postSlice'
import channelSlice from './channelSlice'

const store = configureStore({
    reducer:{
        group: groupSlice,
        auth: authSlice,
        post: postSlice,
        channel: channelSlice
    }
})

export default store