import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../Theme'
import { Alert, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import reset from '../../../media/images/reset.png'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { hasLowercase, hasNumber, hasSpecialCharacters, hasUppercase } from '../../../utils'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const SubmitButton = styled(Button)((theme) => ({
    fontWeight: '600',
    borderRadius: 0,
    backgroundColor: '#d1ddfa',
    color: '#4b7bec',
    padding: '12px 26px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#baccf8',
    }
}))

const ActiveButton = styled(Button)(({ theme }) => ({
    textTransform: 'capitalize',
    marginRight: '5px'
}))

// const theme = createTheme();
export default function ResetPassword() {
    const [showPassword, setShowPassword] = React.useState(false);
    const [data, setData] = React.useState({ password: '', confirm_password: '' })
    const [mobileNo, setMobileNo] = React.useState("")
    const [error, setError] = React.useState(false)
    const [has_number, set_has_number] = React.useState(false)
    const [has_upper_case, set_has_upper_case] = React.useState(false)
    const [has_lower_case, set_has_lower_case] = React.useState(false)
    const [has_special_character, set_has_special_character] = React.useState(false)
    const [is_6_10_length, set_is_6_10_length] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const token = JSON.parse(localStorage.getItem('token'))
    const location = useLocation()
    const navigate = useNavigate()
    React.useEffect(() => {
        if(!location.state.mobile_no){
            navigate(-1)
        }else{
            setMobileNo(location.state.mobile_no)
        }
    },[location])
    React.useEffect(() => {

        if (data.password.trim().length > 6) {
            set_is_6_10_length(true)
        } else {
            set_is_6_10_length(false)
        }

        if (hasNumber.test(data.password)) {
            set_has_number(true)
        } else {
            set_has_number(false)
        }

        if (hasLowercase.test(data.password)) {

            set_has_lower_case(true)
        } else {

            set_has_lower_case(false)
        }

        if (hasUppercase.test(data.password)) {
            set_has_upper_case(true)
        } else {

            set_has_upper_case(false)
        }

        if (hasSpecialCharacters.test(data.password)) {
            set_has_special_character(true)
        } else {
            set_has_special_character(false)
        }

        // }
        if (data.password.trim().length > 10) {
            setError(true)
        }
    }, [data.password])
    const handleSubmit = (e) => {
        e.preventDefault()
        if (data.password !== data.confirm_password) {
            setMessage(`Password didn't match`)
            return
        }
        axios.put(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, { mobileNo, password: data.password })
            .then(res => {
                if (res.data.status === 1) {
                    navigate('/signin', { replace: true })
                } else {
                    setMessage(`Something went wrong.Please try again later.`)
                }
            }).catch(error => {
                setMessage(`Something went wrong.Please try again later.`)
            })
    }
    return (
        <ThemeProvider theme={theme}>

            <Container component="main" maxWidth='xs' >
                {/* <CssBaseline /> */}
                <Box component='form'
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        bgcolor: 'white',
                        padding: '12px',
                        borderRadius: '4px'
                        // maxWidth:'xs'
                    }}
                    onSubmit={handleSubmit}
                >
                    <img src={reset} style={{ width: '200px' }} />
                    <Typography variant='h6' color='primary' mt={2} mb={1} fontWeight='600'>
                        Reset Password</Typography>
                    <Typography variant='body2' color='gray'>Reset password within  <strong>5 mins</strong></Typography>
                    {/* <Typography variant='body2' color='gray'>Enter a <strong>strong password</strong> for your account</Typography> */}
                    {/* <Alert sx={{width:'100%'}} severity="info">Enter the OTP sent to <strong>+{location.state.mobile_prefix}{location.state.mobile_no}</strong></Alert> */}
                    {
                        (message !== "") &&
                        <Alert severity="error" sx={{ width: '94%', mt: 2 }}>{message}</Alert>
                    }
                    {/* <TextField
                        fullWidth
                        error={error?true:false}
                        sx={{ mt: 2 }}
                        id="pass-field"
                        label="Enter Password"
                        type="password"
                        value={data.password || ''}
                        helperText={error?"Max length 10. Min 4.":""}
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                    // autoComplete="current-password"
                    /> */}
                    <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={data.password || ''}
                            onChange={(e) => setData({ ...data, password: e.target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((show) => !show)}
                                        onMouseDown={(event) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <Box>
                        <ActiveButton disableRipple size='small' color='success' variant="text" startIcon={is_6_10_length ? <CheckIcon /> : <ClearIcon />} disabled={is_6_10_length ? false : true}>
                            6-10 characters
                        </ActiveButton>
                        <ActiveButton disableRipple size='small' color='success' variant="text" startIcon={has_lower_case ? <CheckIcon /> : <ClearIcon />} disabled={has_lower_case ? false : true}>
                            Lower case
                        </ActiveButton>
                        <ActiveButton disableRipple size='small' color='success' variant="text" startIcon={has_upper_case ? <CheckIcon /> : <ClearIcon />} disabled={has_upper_case ? false : true}>
                            Upper case
                        </ActiveButton>
                        <ActiveButton disableRipple size='small' color='success' variant="text" startIcon={has_number ? <CheckIcon /> : <ClearIcon />} disabled={has_number ? false : true}>
                            Number
                        </ActiveButton>
                        <ActiveButton disableRipple size='small' color='success' variant="text" startIcon={has_special_character ? <CheckIcon /> : <ClearIcon />} disabled={has_special_character ? false : true}>
                            Symbol
                        </ActiveButton>
                    </Box>
                    {/* <TextField
                        fullWidth
                        sx={{ my: 2 }}
                        id="confirm-pass-field"
                        label="Enter Confirm Password"
                        type="password"
                        value={data.confirm_password || ''}
                        onChange={(e) => setData({ ...data, confirm_password: e.target.value })}
                    /> */}
                    <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="confirm-pass-field"
                            type={showPassword ? 'text' : 'password'}
                            value={data.confirm_password || ''}
                            onChange={(e) => setData({ ...data, confirm_password: e.target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword((show) => !show)}
                                        onMouseDown={(event) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                        />
                    </FormControl>
                    <Button type='submit' fullWidth variant='contained' disableElevation color='primary' sx={{ textTransform: 'capitalize' }}>Reset Password</Button>
                    {/* <Button fullWidth variant='text' color='primary' startIcon={<ArrowBackIcon />} sx={{ textTransform: 'capitalize' }}>Back to login</Button> */}
                </Box>
            </Container>
        </ThemeProvider>
    );
}
