import { Alert, AlertTitle, Box } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Loading from '../../status/Loading'
import NotFound from '../../status/NotFound'

const Report = () => {
    const [reports, setReports] = React.useState([])
    const [status, setStatus] = React.useState('ok') // ok // error // empty //loading
    // const group_id = useSelector(state => state.group.groupProfileId)
    const token = JSON.parse(localStorage.getItem("token"))
    const [searchparams] = useSearchParams()

    useEffect(() => {
        const getGroupReports = () => {
            setStatus('loading')
            axios.get(`${process.env.REACT_APP_API_URL}/api/group/get_reports/${searchparams.get('groupProfileId')}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.length === 0) {
                        setStatus('empty')
                    } else {
                        setReports(response.data.data)
                        setStatus('ok')
                    }
                    // dispatch(setUserRequestsListInGroup(response.data.data))
                }).catch(error => {
                    setStatus('error')
                })
        }
        getGroupReports()
    }, [searchparams.get('groupProfileId')])
    return (
        status === 'loading' ? <Loading /> : (status === 'empty' || status === 'error') ?
            <NotFound data={"No report found"} subData={"Sorry, we could not found any report to show.Please try again later."} /> :
            <Box sx={{ bgcolor: 'white', p: 2 }}>
                {
                    reports.map((report, index) => {
                        return <Alert key={index} severity="warning" sx={{ mb: 1 }}>
                            <AlertTitle>Warning</AlertTitle>
                            {report.reason} — <strong>check it out!</strong>
                        </Alert>
                    })
                }
            </Box>
    )
}

export default Report