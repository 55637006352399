import { Avatar, Box, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { SubTitle1, SubTitle2, SubTitle3, Title2 } from '../../CustomComponents'
import { Add, AddReactionOutlined, Favorite, MoreHorizRounded, NotificationsNone, PlayCircleOutlineOutlined, VideoChatOutlined, VideoChatRounded, Yard } from '@mui/icons-material'
import theme from '../../Theme'
import data from './messages.js'

const ChatPanel = () => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [addInputItemAnchorEl, setAddInputItemAnchorEl] = React.useState(null)
    const [inputMessage, setInputMessage] = React.useState('')
    const { uid = undefined } = JSON.parse(localStorage.getItem('user')) || {}
    const token = JSON.parse(localStorage.getItem('token'))
    const open = Boolean(anchorEl);
    const openInputItem = Boolean(addInputItemAnchorEl);
    const handleReaction = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleInputItemOpen = (e) => {
        setAddInputItemAnchorEl(e.currentTarget);
    }
    const handleInputItemClose = () => {
        setAddInputItemAnchorEl(null)
    }

    const handleMessage = (event) => {
        event.preventDefault()
        console.log(inputMessage)
        setInputMessage('')
    }

    return (
        <Box sx={{ position: 'relative', height: '100vh' }}>
            <Box sx={{ height: { xs: '54px', md: '63px' }, position: 'sticky', top: '0px', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', lineHeight: { xs: '54px', md: '63px' } }}>
                    <Avatar sx={{ width: 30, height: 30 }} src='https://images.pexels.com/photos/428364/pexels-photo-428364.jpeg?auto=compress&cs=tinysrgb&w=600' />
                    <Box>
                        <SubTitle1>Prabir Khanra</SubTitle1>
                        <SubTitle2 color='darkgray'>last seen 2 days ago</SubTitle2>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Avatar sx={{ width: 34, height: 34, backgroundColor: theme.palette.light.main }} variant='rounded'>
                        <VideoChatOutlined sx={{ color: theme.palette.dark.light }} fontSize='small' />
                    </Avatar>
                    <Avatar sx={{ width: 34, height: 34, backgroundColor: theme.palette.light.main }} variant='rounded'>
                        <NotificationsNone sx={{ color: theme.palette.dark.light }} fontSize='small' />
                    </Avatar>
                </Box>
            </Box>
            <Box sx={{ padding: '16px',overflowY:'scroll' }}>
                {
                    data.map((message, index) => {
                        return <>
                            {
                                (index === 0 || (index > 0 && message.sender_chatter_id !== data[index - 1].sender_chatter_id)) ?
                                <>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '6px', flexDirection: (uid !== message.sender_chatter_id) ? 'row' : 'row-reverse' }}>
                                        <Avatar src={message.profile_pic} sx={{ width: 25, height: 25 }} />
                                        <SubTitle2>{message.name}</SubTitle2>
                                        {/* <SubTitle3 color='darkgray'>3 mins ago</SubTitle3> */}
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexDirection: (uid !== message.sender_chatter_id) ? 'row' : 'row-reverse', padding: (uid !== message.sender_chatter_id) ? '0px 0px 0px 22px' : '0px 22px 0px 0px' }}>
                                        <Box sx={{ borderRadius: '15px', borderTopLeftRadius: (uid !== message.sender_chatter_id) ? '0px' : '15px', borderTopRightRadius: (uid === message.sender_chatter_id) ? '0px' : '15px', padding: '12px', border: (uid !== message.sender_chatter_id) ? '1px solid #c0c0d8' : '1px solid transparent', maxWidth: '70%', backgroundColor: (uid !== message.sender_chatter_id) ? 'white' : theme.palette.primary.main, color: (uid !== message.sender_chatter_id) ? `black` : 'white', marginBottom: '10px' }}>
                                            <SubTitle2>{message.chat}</SubTitle2>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: '6px', flexWrap: 'wrap',marginBottom:'3px' }}>
                                                {
                                                    (message.love_react !== 0) &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Favorite fontSize='14px' color='redish' />
                                                        <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.love_react}</SubTitle2>
                                                    </Box>
                                                }
                                                {
                                                    (message.appreciate_react !== 0) &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        👍
                                                        <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.appreciate_react}</SubTitle2>
                                                    </Box>
                                                }
                                                {
                                                    (message.lough_react !== 0) &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        😆
                                                        <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.lough_react}</SubTitle2>
                                                    </Box>
                                                }
                                                {
                                                    (message.angry_react !== 0) &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        😡
                                                        <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.angry_react}</SubTitle2>
                                                    </Box>
                                                }
                                                {
                                                    (message.cry_react !== 0) &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        😭
                                                        <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.cry_react}</SubTitle2>
                                                    </Box>
                                                }
                                                {
                                                    (message.welcome_react !== 0) &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        🙏
                                                        <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.welcome_react}</SubTitle2>
                                                    </Box>
                                                }
                                            </Box>
                                            <SubTitle3 color={(uid !== message.sender_chatter_id) ? 'disabled.main' : 'light'}>3:02 am</SubTitle3>
                                        </Box>
                                        {/* <AddReactionOutlined color='disabled' fontSize='15px' onClick={handleReaction} />
                                        <MoreHorizRounded color='disabled' fontSize='15px' onClick={handleReaction} /> */}
                                    </Box>
                                </> :
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexDirection: (uid !== message.sender_chatter_id) ? 'row' : 'row-reverse', padding: (uid !== message.sender_chatter_id) ? '0px 0px 0px 22px' : '0px 22px 0px 0px' }}>
                                    <Box sx={{ borderRadius: '15px', padding: '12px', border: (uid !== message.sender_chatter_id) ? '1px solid #c0c0d8' : '1px solid transparent', maxWidth: '70%', backgroundColor: (uid !== message.sender_chatter_id) ? 'white' : theme.palette.primary.main, color: (uid !== message.sender_chatter_id) ? `black` : 'white', marginBottom: '10px' }}>
                                        <SubTitle2>{message.chat}</SubTitle2>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: '6px', flexWrap: 'wrap',marginBottom:'3px' }}>
                                            {
                                                (message.love_react !== 0) &&
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Favorite fontSize='14px' color='redish' />
                                                    <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.love_react}</SubTitle2>
                                                </Box>
                                            }
                                            {
                                                (message.appreciate_react !== 0) &&
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    👍
                                                    <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.appreciate_react}</SubTitle2>
                                                </Box>
                                            }
                                            {
                                                (message.lough_react !== 0) &&
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    😆
                                                    <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.lough_react}</SubTitle2>
                                                </Box>
                                            }
                                            {
                                                (message.angry_react !== 0) &&
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    😡
                                                    <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.angry_react}</SubTitle2>
                                                </Box>
                                            }
                                            {
                                                (message.cry_react !== 0) &&
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    😭
                                                    <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.cry_react}</SubTitle2>
                                                </Box>
                                            }
                                            {
                                                (message.welcome_react !== 0) &&
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    🙏
                                                    <SubTitle2 color={ (uid !== message.sender_chatter_id) ? 'black' : `white`}>{message.welcome_react}</SubTitle2>
                                                </Box>
                                            }
                                        </Box>
                                        <SubTitle3 color={(uid !== message.sender_chatter_id) ? 'disabled.main' : 'light'}>3:02 am</SubTitle3>
                                    </Box>
                                    {/* <AddReactionOutlined color='disabled' fontSize='15px' onClick={handleReaction} />
                                    <MoreHorizRounded color='disabled' fontSize='15px' onClick={handleReaction} /> */}
                                </Box>
                            }
                        </>
                    })
                }
                {/* <Box sx={{ paddingLeft: '22px' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Box sx={{ borderRadius: '15px', padding: '12px', border: '1px solid #c0c0d8', maxWidth: '70%', backgroundColor: 'white', color: theme.palette.dark.light, marginBottom: '10px' }}>
                            <SubTitle2>Lorem ipsum dolor sit amet consectetur adipisicing.</SubTitle2>
                        </Box>
                        <AddReactionOutlined color='disabled' fontSize='15px' onClick={handleReaction} />
                        <MoreHorizRounded color='disabled' fontSize='15px' onClick={handleReaction} />
                    </Box>
                </Box> */}

            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    style: {
                        boxShadow: '1px 1px 7px #c0c0d8'
                    }
                }}
            >
                <MenuItem onClick={handleClose}>
                    ❣️ Like
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    👍 Appreciate
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    😡 Angry
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    😆 Smile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    😭 Cry
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    🙏 Welcome
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={addInputItemAnchorEl}
                id="account-menu"
                open={openInputItem}
                onClose={handleInputItemClose}
                onClick={handleInputItemClose}
                PaperProps={{
                    style: {
                        boxShadow: '1px 1px 7px #c0c0d8'
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleInputItemClose}>
                    <Yard /> Image
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleInputItemClose}>
                    <PlayCircleOutlineOutlined /> Video
                </MenuItem>
            </Menu>
            <Box sx={{ padding: '16px', backgroundColor: theme.palette.light.dark, display: 'flex', gap: 1, position: 'sticky', bottom: '0px', left: '0px', width: '100%' }}>
                <Avatar variant='rounded' onClick={handleInputItemOpen} sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
                    <Add />
                </Avatar>
                <form action="" onSubmit={handleMessage} style={{ width: '100%' }}>
                    <input id='message_input_id' value={inputMessage} onChange={(e) => setInputMessage(e.target.value)} style={{ borderRadius: '6px', padding: '10px', border: '1px solid darkgray', width: '100%', outline: 'none' }} />
                </form>
            </Box>
        </Box>
    )
}

export default ChatPanel