import * as React from 'react';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/PlaylistRemove';
import { Box, Button, Divider, Typography } from '@mui/material';
import Type from '@mui/icons-material/AdminPanelSettings';
import Subtype from '@mui/icons-material/LocalOffer';
import styled from '@emotion/styled';
import axios from 'axios';
// import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotFound from '../../status/NotFound';
import Loading from '../../status/Loading';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { entities } from "../../HtmlEntity"
import theme from '../../../Theme';

const AboutGroupBox = styled(Box)((theme) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
  color: 'black'
}))
const STypography = styled(Typography)((theme) => ({
  marginLeft: '5px'
}))
export default function GroupList({ selectDetails, setSelectDetails }) {
  const [groupList, setGroupList] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true)
  const [callNo, setCallNo] = React.useState(1)
  // const user_id = useSelector(state => state.auth.userInfo.id)
  const { uid: user_id } = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const navigate = useNavigate()
  React.useEffect(() => {
    if (groupList.length <= 0) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/group/my_group_list/${callNo}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.length > 0) {
            setGroupList(response.data.data)
          }
          if (response.data.length < 12) {
            setHasMore(false)
          } else {
            setCallNo(2)
            setHasMore(true)
          }
        }).catch(error => {
        })
    }
  }, [])

  const visitGroupProfile = (group_id) => {
    navigate({ pathname: `/group-profile`, search: createSearchParams({ groupProfileId: group_id }).toString() })
  }

  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/group/my_group_list/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length > 0) {
          setGroupList(groupList.concat(response.data.data))
        }
        if (response.data.data.length < 12) {
          setHasMore(false)
        } else {
          setCallNo(callNo + 1)
          setHasMore(true)
        }
      }).catch(error => {
        setHasMore(false)
      })
  }
  const handleRemoveListing = (group_id) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/api/group/remove_from_list/${group_id}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        // setListed(false)
        setGroupList(groupList.filter(el => el.group_id !== group_id))
      }).catch(error => {

      })
  }

  return (
    <>
      {
        (hasMore === false && groupList.length <= 0) && <NotFound data={"No data found"} subData={"Sorry, we could not found any data to show.Please try again later."} />
      }
      <List sx={{ bgcolor: 'background.paper', padding: '16px' }}>
        <InfiniteScroll
          dataLength={groupList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<Loading />}
          endMessage={
            groupList.length !== 0 ? <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p> : null
          }
        >
          {
            groupList.map((group, index) => {
              return (
                <React.Fragment key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'start', marginBottom: '16px', padding: '10px', borderRadius: '4px', backgroundColor: (index === selectDetails.index) ? theme.palette.primary.light : theme.palette.light.light }} onClick={() => {
                    setSelectDetails({ group_id: group.group_id, group_name: group.group_name, index, type: group.group_type, role: group.role })
                  }}>
                    <Avatar variant='rounded' sx={{ width: '35px', height: '35px' }} src={group.group_profile_pic} onClick={() => visitGroupProfile(group.group_id)} alt={group.group_name && group.group_name.charAt(0).toUpperCase()}></Avatar>
                    <Box sx={{ marginLeft: '16px' }}>
                      <Typography fontWeight='600' color='#2f3542' fontSize='14px'>{group.group_name && group.group_name.replace(/&#?\w+;/g, match => entities[match])}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <span style={{ borderRadius: '2px', padding: '0px 3px', display: "inline-block", backgroundColor: theme.palette.light.main, fontSize: '12px' }}>{group.group_type}</span>
                        <span style={{ borderRadius: '2px', padding: '0px 3px', display: "inline-block", color: theme.palette.dark.main, fontSize: '12px' }}>{group.role}</span>
                      </Box>
                    </Box>
                  </Box>
                </React.Fragment>
              )
            })
          }
        </InfiniteScroll>
      </List>
    </>
  );
}
