import { Avatar, Box, Button, Divider, Fab, Grid, IconButton, Rating, Typography } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import OwlCarousel from 'react-owl-carousel'
import { Logo } from '../../CustomComponents';
import ImageSlider from '../slider/ImageSlider';
import '../slider/slide.css'
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4'

const StyledText = styled(Typography)(({ theme }) => ({
    color: 'black',
    padding: 0,
    margin: 0,
    // fontWeight:400,
    fontSize: '14px',
    display: 'block'
}));
const StyledIcon = styled(IconButton)(({ theme }) => ({
    color: 'black',
    padding: 0,
    margin: 0,
    // fontWeight:400,
    // fontSize:'14px',
    display: 'block'
}));

const FullMultiImage = () => {
    const images = useSelector(state => state.post.fullPostDetails)
    // const [images,setImages] = React.useState([
    //     {image: 'https://images.pexels.com/photos/16853260/pexels-photo-16853260/free-photo-of-sea-city-restaurant-landscape.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=loads'},
    //     {image: 'https://images.pexels.com/photos/7973627/pexels-photo-7973627.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load'},
    //     {image: 'https://images.pexels.com/photos/16677734/pexels-photo-16677734/free-photo-of-bread-food-wood-pizza.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load'},
    //     {image: 'https://images.pexels.com/photos/15787344/pexels-photo-15787344/free-photo-of-model-in-a-fashionable-beige-outfit-posing-in-studio.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load'}
    // ])
    // const fullPostDetails = useSelector(state => state.post.fullPostDetails)
    const [searchparams] = useSearchParams()
    const react = 1
    const navigate = useNavigate()
    // const images = [
    //     {image:'<img src="https://images.pexels.com/photos/16853260/pexels-photo-16853260/free-photo-of-sea-city-restaurant-landscape.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=loads" style={{objectFit:"contain",height:"100px",width:"100px"}}/>'},
    //     {image:'<div style={{width:"300px",height:"300px",backgroundColor:"yellow"}}></div>'},
    //     {image:'<div style={{width:"300px",height:"300px",backgroundColor:"blue"}}></div>'},
    //     {image:'<div style={{width:"300px",height:"300px",backgroundColor:"green"}}></div>'}
    // ]

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])

    const handleEmojiNumber = () => {

    }

    return (
        <>
            <Grid sx={{ height: '100vh', boxSizing: 'border-box' }}>
                <Grid item sm={12} sx={{ backgroundColor: '#232629', textAlign: 'center', overflow: 'hidden' }}>
                    <Box sx={{ position: "absolute", top: '20px', left: '20px', display: 'flex', alignItems: 'center', zIndex: 99 }}>
                        <Avatar sx={{ width: 50, height: 50, bgcolor: '#ff7675' }} onClick={() => navigate(-1)}>
                            <CloseIcon />
                        </Avatar>
                        <Logo
                            variant='h4'
                            noWrap
                            mx={2}
                            color='white'
                            sx={{ margin: '0px 16px', fontWeight: 600, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', display: { xs: 'none', md: 'inline-block' } }}
                        >EmotionTown</Logo>
                    </Box>
                    <Box sx={{}}>
                        {/* <img src="https://images.pexels.com/photos/15787344/pexels-photo-15787344/free-photo-of-model-in-a-fashionable-beige-outfit-posing-in-studio.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" alt="" style={{height:'100vh'}} /> */}
                        <ImageSlider slides={images} />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
{/* <SimpleImageSlider
    width={500}
    height={380}
    images={images}
    // style={{objectFit:'contain',height:'100%'}}
    showBullets={true}
    showNavs={true}
/> */}

export default FullMultiImage