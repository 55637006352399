import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, IconButton, Paper, Radio, RadioGroup, Snackbar, TextField, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import axios from 'axios'
import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import no_profile_photo from '../../media/images/no_profile_photo.png'
import no_photo from '../../media/images/no_photo.png'
import WentWrong from '../status/WentWrong'
import Loading from '../status/Loading'
import NotFound from '../status/NotFound'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenSnack, setSnackMessage, setuserProfileInfo, setuserProfileInfoCoverPic, setuserProfileInfoProfilePic, setuserProfileInfoAbout, setuserProfileInfoBasicInfo } from '../../store/authSlice';
import { entities } from "../HtmlEntity"
import { EmotInput, FilledButton, StyledInputLabel, Title1, Title3 } from '../../CustomComponents';
import theme from '../../Theme';
import CreateUserName from '../Grid/CreateUserName';
import { AddAPhoto } from '@mui/icons-material';
import ReactGA from 'react-ga4'

const EditProfile = () => {
    // const [userDetails, setUserDetails] = React.useState({})
    const [userBasicInfo, setUserBasicInfo] = React.useState({ name: '', gender: '', about: '', website: '', youtube_link: '', linkedin_link: '' })
    const [file, setFile] = React.useState(null)
    const [coverFile, setCoverFile] = React.useState(null)
    const [status, setStatus] = React.useState('ok') // ok,error,loading,not_found
    const [isProfilePicUploading, setIsProfilePicUploading] = React.useState(false) // ok // error // status
    const [isCoverPicUploading, setIsCoverPicUploading] = React.useState(false) // ok // error // status
    const [basicUpdating, setBasicUpdating] = React.useState('ok') // ok // error // status
    const [aboutUpdating, setAboutUpdating] = React.useState('ok')
    const [linkUpdatingStatus, setLinkUpdatingStatus] = React.useState(false) // ok , processing
    const [linkUpdatingErrorMessage, setLinkUpdatingErrorMessage] = React.useState('') // ok , processing
    const userDetails = useSelector(state => state.auth.userProfileInfo)
    // const [addressUpdating, setAddressUpdating] = React.useState('ok')
    const { uid: user_id, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem('token'))
    const dispatch = useDispatch()

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])

    React.useEffect(() => {
        setUserBasicInfo({ name: userDetails.name.replace(/&#?\w+;/g, match => entities[match]), gender: userDetails.gender, about: userDetails.about.replace(/&#?\w+;/g, match => entities[match]) || ' ', website: userDetails.website, youtube_link: userDetails.youtube_link, linkedin_link: userDetails.linkedin_link })
    }, [userDetails.id])

    useEffect(() => {
        if (userDetails.id !== user_id) {
            // if (!userDetails.id) {
            setStatus('loading')
            axios.get(`${process.env.REACT_APP_API_URL}/api/users/getUser/${user_id}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.id) {
                        // setUserDetails(response.data.data)
                        dispatch(setuserProfileInfo(response.data.data))
                        setStatus('ok')
                    }
                    else {
                        setStatus('not_found')
                    }
                }).catch(error => {
                    setStatus('error')
                })
        }
    }, [])

    const updateLocalStorage = (updatedData) => {
        const profile = JSON.parse(localStorage.getItem('user'));
        Object.keys(updatedData).forEach((key) => {
            profile[key] = updatedData[key];
        });
        localStorage.setItem('user', JSON.stringify(profile));
    }

    const handleBasicDetails = (e) => {
        e.preventDefault()
        setBasicUpdating('loading')
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/update/basic_details`, { name: userBasicInfo.name, gender: userBasicInfo.gender }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.affectedRows === 0) {
                    setBasicUpdating('error')
                    dispatch(setSnackMessage("Something went wrong."))
                    dispatch(setOpenSnack(true))
                } else {
                    // setUserDetails(response.data.data)
                    setBasicUpdating('ok')
                    dispatch(setSnackMessage(response.data.message))
                    dispatch(setuserProfileInfoBasicInfo({ name: userBasicInfo.name, gender: userBasicInfo.gender }))
                    updateLocalStorage({ uid: user_id, name: userBasicInfo.name, profile_pic: userDetails.profile_pic })
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                setBasicUpdating('error')
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })

    }
    const handleAbout = (e) => {
        e.preventDefault()
        setAboutUpdating('loading')
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/update/about`, { about: userBasicInfo.about }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.affectedRows === 0) {
                    setAboutUpdating('error')
                    dispatch(setSnackMessage("Something went wrong."))
                    dispatch(setOpenSnack(true))
                } else {
                    // setUserDetails(response.data.data)
                    setAboutUpdating('ok')
                    dispatch(setSnackMessage(response.data.message))
                    dispatch(setOpenSnack(true))
                    dispatch(setuserProfileInfoAbout({ about: userBasicInfo.about }))

                }
                // setAboutUpdating('error')
            }).catch(error => {
                setAboutUpdating('error')
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }
    // const handleAddress = (e) => {
    //     e.preventDefault()
    //     setAddressUpdating('loading')
    //     axios.put(`${process.env.REACT_APP_API_URL}/api/users/update/address_details`, { country: userDetails.country, state: userDetails.state, city: userDetails.city, user_id }, { headers: { authorization: "Bearer " + token } })
    //         .then(response => {
    //             if (response.data.data.affectedRows === 0) {
    //                 setAddressUpdating('error')
    //                 dispatch(setSnackMessage("Something went wrong"))
    //                 dispatch(setOpenSnack(true))
    //             } else {
    //                 // setUserDetails(response.data.data)
    //                 setAddressUpdating('ok')
    //                 dispatch(setSnackMessage(response.data.message))
    //                 dispatch(setOpenSnack(true))
    //             }
    //         }).catch(error => {
    //             setAddressUpdating('error')
    //             dispatch(setSnackMessage("Something went wrong"))
    //             dispatch(setOpenSnack(true))
    //         })
    // }

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    function handleFileChange(event, type) {
        var file_extension = getExtension(event.target.files[0].name)
        if (event.target.files[0].size > 6e6) {
            dispatch(setSnackMessage("Please upload a file smaller than 6mb."))
            dispatch(setOpenSnack(true))
            return false;
        }
        if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
            if (type === 'cover') {
                setCoverFile(event.target.files[0])
            } else {
                setFile(event.target.files[0])
            }
            setFile(event.target.files[0])
        } else {
            dispatch(setSnackMessage("Sorry, this file format is not allowed.Use only png, jpg, jpeg file."))
            dispatch(setOpenSnack(true))
        }
        // setOpen(true)
    }

    const handleFileUpload = (event) => {
        event.preventDefault()
        setIsProfilePicUploading(true)
        const url = `${process.env.REACT_APP_API_URL}/api/users/add/profile_pic`;
        const formData = new FormData();
        formData.append('u_profile_pic', file);
        formData.append('fileName', file.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': "Bearer " + token
            },
        };
        axios.put(url, formData, config).then((response) => {
            // dispatch(setProfilePic(response.data.file))
            // setUserDetails(details => {
            //     return { ...details, profile_pic: response.data.file }
            // })
            updateLocalStorage({ uid: user_id, name: userDetails.name, profile_pic: response.data.file })
            dispatch(setSnackMessage(response.data.message))
            dispatch(setuserProfileInfoProfilePic({ profile_pic: response.data.file, org_profile_pic: response.data.org_file }))
            setFile(null)
            dispatch(setOpenSnack(true))
            setIsProfilePicUploading(false)
        }).catch(error => {
            setIsProfilePicUploading(false)
            dispatch(setSnackMessage("Something went wrong"))
            dispatch(setOpenSnack(true))
        });
    }

    const handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUserBasicInfo(info => ({ ...info, [name]: value }))
    }

    const handleCoverFileUpload = (event) => {
        event.preventDefault()
        setIsCoverPicUploading(true)
        const url = `${process.env.REACT_APP_API_URL}/api/users/add/cover_pic`;
        const formData = new FormData();
        formData.append('cover_pic', coverFile);
        formData.append('fileName', coverFile.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                authorization: "Bearer " + token
            },
        };
        axios.put(url, formData, config).then((response) => {
            // setUserDetails(details => {
            //     return { ...details, cover_pic: response.data.file }
            // })
            dispatch(setSnackMessage(response.data.message))
            dispatch(setOpenSnack(true))
            dispatch(setuserProfileInfoCoverPic({ cover_pic: response.data.file, org_cover_pic: response.data.org_file }))
            setCoverFile(null)
            setIsCoverPicUploading(false)
            // setIsDisabled(true)
        }).catch(error => {
            setIsCoverPicUploading(false)
            dispatch(setSnackMessage("Something went wrong."))
            dispatch(setOpenSnack(true))
        });
    }

    const handleSaveWebsite = () => {
        setLinkUpdatingStatus(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/add/website`, { web_link: userBasicInfo.website }, { headers: { authorization: "Bearer " + token } })
            .then((response) => {
                setLinkUpdatingStatus(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setLinkUpdatingStatus(false)
                dispatch(setSnackMessage("Failed to save website link.Try again."))
                dispatch(setOpenSnack(true))
            });
    }
    const handleSaveYoutubeLink = () => {
        setLinkUpdatingStatus(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/add/youtube`, { youtube_link: userBasicInfo.youtube_link }, { headers: { authorization: "Bearer " + token } })
            .then((response) => {
                setLinkUpdatingStatus(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setLinkUpdatingStatus(false)
                dispatch(setSnackMessage("Failed to save website link.Try again."))
                dispatch(setOpenSnack(true))
            });
    }
    const handleSaveLinkedinLink = () => {
        setLinkUpdatingStatus(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/add/linkedin`, { linkedin_link: userBasicInfo.linkedin_link }, { headers: { authorization: "Bearer " + token } })
            .then((response) => {
                setLinkUpdatingStatus(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setLinkUpdatingStatus(false)
                dispatch(setSnackMessage("Failed to save website link.Try again."))
                dispatch(setOpenSnack(true))
            });
    }

    return (
        status === 'error' ? <WentWrong /> : status === 'loading' ? <Loading /> : status === 'not_found' ? <NotFound data={"No Data found"} subData={"Sorry, we could not found any data to show or edit.Please try again later."} /> :
            <>
                {/* profile picture */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    > */}
                {/* 
                        <Typography variant='h6' fontWeight='600' mb={2}>Profile Picture</Typography>
                        <Box sx={{ textAlign: 'center' }}>
                            <img src={file !== null ? URL.createObjectURL(file) : userDetails.profile_pic !== null ? userDetails.profile_pic : no_profile_photo} width='200px' height='200px' style={{ borderRadius: '4px', objectFit: 'cover', backgroundColor: 'rgba(0,0,0,0.2)' }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button variant="contained" disableElevation component="label" sx={{ width: '49%', display: 'block', margin: '10px auto', textTransform: 'capitalize' }} onChange={handleFileChange} >
                                    Choose
                                    <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                </Button>
                                <Box sx={{ position: 'relative', width: '49%' }}>
                                    <Button type='submit' disableElevation variant="contained" disabled={(file === null || isProfilePicUploading) ? true : false} component="label" sx={{ width: '100%', display: 'block', margin: '10px auto', textTransform: 'capitalize' }} onClick={handleFileUpload} >
                                        Upload Image
                                    </Button>

                                    {isProfilePicUploading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#44bd32',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box> */}
                {/* ============================================================================================ */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box component="form"
                        // onSubmit={handleSubmit}
                        sx={{
                            marginTop: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '8px',
                            border: '1px solid #EAEAF2',
                            padding: '16px'
                        }}
                    >
                        <Title1 mb={1} htmlFor="about-room-field-id">
                            Profile Picture
                        </Title1>
                        <Box sx={{ width: '100px', height: '100px', border: '2px dashed darkgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                (userDetails.profile_pic !== null || file !== null) ?
                                    // <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={file !== null ? URL.createObjectURL(file) : channelDetails.logo} /> :
                                    <img style={{ width: '100%', height: '100%', objectFit: (file !== null || userDetails.profile_pic !== null) ? 'cover' : 'contain' }} src={file !== null ? URL.createObjectURL(file) : userDetails.profile_pic !== null ? userDetails.profile_pic : no_profile_photo} /> :
                                    <Box component='label' onChange={(event) => handleFileChange(event, 'profile')}>
                                        <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                                        <AddAPhoto color='dark' />
                                    </Box>
                            }
                            {/* hello */}
                        </Box>
                        <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
                            {
                                (userDetails.profile_pic !== null) &&
                                <Button variant='contained' size='small' type='submit' component="label" sx={{ whiteSpace: 'nowrap' }} onChange={(event) => handleFileChange(event, 'profile')}>
                                    <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                    Choose File
                                </Button>
                            }
                            <Box sx={{ position: 'relative' }}>
                                <Button type='submit' size='small' variant="contained" disabled={(file === null || isProfilePicUploading) ? true : false} component="label" sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }} onClick={handleFileUpload} >
                                    Upload File
                                </Button>

                                {isProfilePicUploading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#44bd32',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
                {/* ============================================================================================ */}
                {/* </Box>
                </Paper> */}
                {/* cover picture */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Cover Picture</Typography>
                        <Box sx={{ textAlign: 'center' }}>
                            <img src={coverFile !== null ? URL.createObjectURL(coverFile) : userDetails.cover_pic !== null ? userDetails.cover_pic : no_photo} width='100%' height='300px' style={{ backgroundColor: '#eef0f6', borderRadius: '4px', objectFit: (coverFile !== null || userDetails.cover_pic !== null) ? 'cover' : 'contain' }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button variant="contained" disableElevation component="label" sx={{ width: '49%', margin: '10px auto', textTransform: 'capitalize' }} onChange={(event) => {
                                    var file_extension = getExtension(event.target.files[0].name)
                                    if (event.target.files[0].size > 6e6) {
                                        dispatch(setSnackMessage("Please upload a file smaller than 6mb."))
                                        dispatch(setOpenSnack(true))
                                        return false;
                                    }
                                    if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
                                        setCoverFile(event.target.files[0])
                                    } else {
                                        dispatch(setSnackMessage("Sorry, this file format is not allowed.Use only png, jpg, jpeg file."))
                                        dispatch(setOpenSnack(true))
                                    }
                                }
                                }>
                                    Choose
                                    <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                </Button>
                                <Box sx={{ position: 'relative', width: '49%' }}>
                                    <Button type='submit' disableElevation variant="contained" disabled={(coverFile === null || isCoverPicUploading) ? true : false} component="label" sx={{ width: '100%', margin: '10px auto', textTransform: 'capitalize' }} onClick={handleCoverFileUpload} >
                                        Upload Image
                                    </Button>

                                    {isCoverPicUploading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#44bd32',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper> */}

                {/* ============================================================================ */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box component="form"
                        // onSubmit={handleSubmit}
                        sx={{
                            marginTop: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '8px',
                            border: '1px solid #EAEAF2',
                            padding: '16px'
                        }}
                    >
                        <Title1 mb={1} htmlFor="about-room-field-id">
                            Add cover pic
                        </Title1>
                        <Box sx={{ width: '220px', height: '120px', border: '2px dashed darkgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                (userDetails.cover_pic !== null || coverFile !== null) ?
                                    <img style={{ width: '100%', height: '100%', objectFit: (coverFile !== null || userDetails.cover_pic !== null) ? 'cover' : 'contain' }} src={coverFile !== null ? URL.createObjectURL(coverFile) : userDetails.cover_pic !== null ? userDetails.cover_pic : no_photo} /> :
                                    <Box component='label' onChange={(event) => handleFileChange(event, 'cover')}>
                                        <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                                        <AddAPhoto color='dark' />
                                    </Box>
                            }
                            {/* hello */}
                        </Box>
                        <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
                            {
                                (userDetails.cover_pic !== null) &&
                                <Button variant='contained' size='small' type='submit' component="label" sx={{ whiteSpace: 'nowrap' }} onChange={(event) => handleFileChange(event, 'cover')}>
                                    <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                    Choose File
                                </Button>
                            }
                            <Box sx={{ position: 'relative' }}>
                                <Button type='submit' size='small' variant="contained" disabled={(coverFile === null || isCoverPicUploading) ? true : false} component="label" sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }} onClick={handleCoverFileUpload} >
                                    Upload File
                                </Button>

                                {isCoverPicUploading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#44bd32',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Paper>

                {/* basic details */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Title1 mb={2}>Basic Details</Title1>
                        {/* <Typography>{userBasicInfo}</Typography> */}
                        <TextField
                            id="outlined-textarea2"
                            label="Your name"
                            placeholder="your name"
                            fullWidth
                            value={userBasicInfo.name}
                            name='name'
                            sx={{ mb: 2 }}
                            onChange={handleInput}
                        />
                        <FormControl sx={{ mb: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="gender"
                                value={userBasicInfo.gender}
                                onChange={handleInput}
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        </Box>
                        <Box >
                            <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                <Button
                                    type='submit'
                                    size='small'
                                    variant="contained"
                                    disabled={basicUpdating === 'loading' || userBasicInfo.name.length <= 0}
                                    onClick={handleBasicDetails}
                                >
                                    Save Changes
                                </Button>
                                {basicUpdating === 'loading' && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                            {
                                basicUpdating === 'error' ?
                                    <Typography color='red' variant='body2' ml={2}>something went wrong</Typography> :
                                    null
                            }
                        </Box>
                    </Box>
                </Paper>

                {/* About you */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>About you</Typography>
                        <TextField
                            id="outlined-textarea2"
                            label="About you"
                            placeholder="About you"
                            multiline
                            fullWidth
                            rows={3}
                            sx={{ mb: 2 }}
                            value={userBasicInfo.about}
                            onChange={handleInput}
                            name='about'
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            <Box sx={{ mt: 1, position: 'relative', display: 'inline-block' }}>
                                <Button
                                    type='submit'
                                    size='small'
                                    variant="contained"
                                    disabled={aboutUpdating === 'loading' || userBasicInfo.about.length <= 0}
                                    onClick={handleAbout}
                                >
                                    Save Changes
                                </Button>
                                {aboutUpdating === 'loading' && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        {
                            aboutUpdating === 'error' ?
                                <Typography color='red' variant='body2' ml={2}>something went wrong</Typography> :
                                null
                        }
                    </Box>
                </Paper>

                {/* Language */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl sx={{ mb: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Your Language</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group-for-room-language"
                                value={details.language}
                                onChange={(e) => setDetails({ ...details, language: e.target.value })}
                            >
                                <FormControlLabel value="bengali" control={<Radio />} label="বাংলা" />
                                <FormControlLabel value="hindi" control={<Radio />} label="हिंदी" />
                                <FormControlLabel value="english" control={<Radio />} label="English" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{ mb: 2 }}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Activiy</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group-for-room-language"
                                value={details.language}
                                onChange={(e) => setDetails({ ...details, language: e.target.value })}
                            >
                                <FormControlLabel value="bengali" control={<Radio />} label="Read" />
                                <FormControlLabel value="hindi" control={<Radio />} label="Write" />
                                <FormControlLabel value="english" control={<Radio />} label="Both" />
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            <Box sx={{ mt: 1, position: 'relative', display: 'inline-block' }}>
                                <Button
                                    type='submit'
                                    size='small'
                                    variant="contained"
                                    disabled={aboutUpdating === 'loading' || userBasicInfo.about.length <= 0}
                                    onClick={handleAbout}
                                >
                                    Save Changes
                                </Button>
                                {aboutUpdating === 'loading' && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        {
                            aboutUpdating === 'error' ?
                                <Typography color='red' variant='body2' ml={2}>something went wrong</Typography> :
                                null
                        }
                    </Box>
                </Paper> */}

                {/* social link */}
                {/* <Paper sx={{ marginBottom: '16px', padding: '16px' }}>
                    <Typography variant='h6' fontWeight='600' mb={2}>Social Link</Typography>
                    <Box
                        sx={{
                            boxSizing: 'border-box',
                            marginBottom: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <StyledInputLabel htmlFor="short-room-description-id">
                            Website Link
                        </StyledInputLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <EmotInput
                                fullWidth
                                value={userBasicInfo.website}
                                required
                                id="short-room-description-id"
                                sx={{}}
                                onChange={(e) => {
                                    setUserBasicInfo({ ...userBasicInfo, website: e.target.value })
                                }}
                            />
                            <FilledButton
                                type='submit'
                                variant="contained"
                                disabled={userBasicInfo.website ? false : true}
                                sx={{ whiteSpace: 'nowrap' }}
                                onClick={handleSaveWebsite}
                            >
                                Save
                            </FilledButton>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            boxSizing: 'border-box',
                            marginBottom: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <StyledInputLabel htmlFor="short-room-description-id">
                            Youtube Link
                        </StyledInputLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <EmotInput
                                fullWidth
                                value={userBasicInfo.youtube_link}
                                required
                                id="short-room-description-id"
                                sx={{}}
                                onChange={(e) => {
                                    setUserBasicInfo({ ...userBasicInfo, youtube_link: e.target.value })
                                }}
                            />
                            <FilledButton
                                type='submit'
                                variant="contained"
                                sx={{ whiteSpace: 'nowrap' }}
                                disabled={userBasicInfo.youtube_link ? false : true}
                                onClick={handleSaveYoutubeLink}
                            >
                                Save
                            </FilledButton>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            boxSizing: 'border-box',
                            marginBottom: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <StyledInputLabel htmlFor="short-room-description-id">
                            Linkedin Link
                        </StyledInputLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <EmotInput
                                value={userBasicInfo.linkedin_link}
                                fullWidth
                                required
                                id="short-room-description-id"
                                sx={{}}
                                onChange={(e) => {
                                    setUserBasicInfo({ ...userBasicInfo, linkedin_link: e.target.value })
                                }}
                            />
                            <FilledButton
                                type='submit'
                                variant="contained"
                                sx={{ whiteSpace: 'nowrap' }}
                                disabled={userBasicInfo.linkedin_link ? false : true}
                                onClick={handleSaveLinkedinLink}
                            >
                                Save
                            </FilledButton>
                        </Box>
                    </Box>
                    <Box sx={{backgroundColor: theme.palette.light.light, borderRadius:'10px',padding:'16px'}}>
                        {
                            linkUpdatingStatus &&
                            <Title3 color='primary' textAlign='center'>processing</Title3>
                        }
                    </Box>
                </Paper> */}


                {/* country */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Country</Typography>
                        <TextField
                            id="outlined-textarea2"
                            label="Your Country"
                            placeholder="your Coutry"
                            fullWidth
                            sx={{ mb: 2 }}
                            value={userDetails.country}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ mt: 1, position: 'relative', display: 'inline-block' }}>
                                <Button
                                    type='submit'
                                    variant="contained"
                                    disabled={true}
                                    onClick={handleAddress}
                                >
                                    Save Changes
                                </Button>
                                {addressUpdating === 'status' && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                            {
                                addressUpdating === 'error' ?
                                    <Typography color='red' variant='body2' ml={2}>something went wrong</Typography> :
                                    null
                            }
                        </Box>
                    </Box>
                </Paper> */}

                {/* <Paper sx={{ marginBottom: { xs: '60px', sm: '16px' } }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Your Interests</Typography>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={<Checkbox />}
                                    label="Beauty"
                                />
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox />}
                                    label="Tarvelling"
                                />
                                <FormControlLabel
                                    value="bottom"
                                    control={<Checkbox />}
                                    label="Entertainment"
                                />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Movie"
                                />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Yoga"
                                />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Gym"
                                />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Read"
                                />
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Gaming"
                                />
                            </FormGroup>
                        </FormControl>
                        <Box>
                            <Button type='submit' variant='contained'>Save Changes</Button>
                        </Box>
                    </Box>
                </Paper> */}
                <Paper sx={{ marginBottom: '16px', padding: '16px' }}>
                    <CreateUserName user_name={userBasicInfo.user_name} id={userBasicInfo.id} />
                </Paper>
            </>
    )
}

export default EditProfile