import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import ReactGA from 'react-ga4'
import { Box, Button, CircularProgress, IconButton, Snackbar, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import NotFound from '../status/NotFound';
import InfiniteScroll from 'react-infinite-scroll-component';
// import CloseIcon from '@mui/icons-material/Close';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import {entities} from "../HtmlEntity"
import InviteFromUserListItem from './Listitem/InviteFromUserListItem';

const MoreButton = styled(Button)((theme) => ({
    textTransform: 'none',
    transition: '0.5s',
    '&:hover': {
        backgroundColor: 'rgba(199, 201, 201,0.7)',
    }
}))

const InviteFromUserList = () => {
    const [checked, setChecked] = React.useState([]);
    const [toUserIds, setToUserIds] = React.useState([])
    const [current_group_id, set_current_group_id] = React.useState('')
    const [callNo, setCallNo] = React.useState(1)
    const [hasMore, setHasMore] = React.useState(true)
    const [inviting, setInviting] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const { uid: id, name } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem("token"))
    // const openSnack = useSelector(state => state.auth.openSnack)
    // const snackMessage = useSelector(state => state.auth.snackMessage)
    const socket = useOutletContext()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchparams] = useSearchParams()
    React.useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname})
      },[])
    React.useEffect(() => {
        if (searchparams.get("groupProfileId")) {
            set_current_group_id(searchparams.get("groupProfileId"))
        }
    }, [])

    useEffect(() => {
        if (users.length <= 0) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/group/fetch-user-list/${searchparams.get("groupProfileId")}/${callNo}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.length >= 8) {
                        setCallNo(2)
                        setHasMore(true)
                        setUsers(response.data.data)
                    } else {
                        setUsers(response.data.data)
                        setHasMore(false)
                    }
                }).catch(error => {

                })
        }
    }, [])

    const fetchMoreData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/group/fetch-user-list/${searchparams.get("groupProfileId")}/${callNo}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {

                if (response.data.data.length >= 8) {
                    setCallNo(callNo + 1)
                    //   dispatch(setNewGroupInList(response.data.data))
                    setUsers(users.concat(response.data.data))
                } else {
                    setUsers(users.concat(response.data.data))
                    setHasMore(false)
                }
            }).catch(error => {

            })
    }

    // so tricky
    const handleToggle = (value, group_id) => () => {
        const currentIndex = checked.indexOf(value);
        const currentGroupIdIndex = toUserIds.indexOf(group_id)
        const newChecked = [...checked];
        const newtoUserIds = [...toUserIds]

        if(toUserIds.length < 5) {
            if (currentIndex === -1) {
                // if (newChecked.length < 4) {
                newChecked.push(value);
                newtoUserIds.push(group_id)
            } else {
                newChecked.splice(currentIndex, 1);
                newtoUserIds.splice(currentGroupIdIndex, 1);
            }
    
            setChecked(newChecked);
            setToUserIds(newtoUserIds)
        }else{
            dispatch(setSnackMessage("You may invite maximum 5 friends at one time."))
            dispatch(setOpenSnack(true))
        }

    };
    //  group_id, user_id, post_type, file_type, file_count, files, post_to, description, optionns, status, original_post_id, shared_by, shared_by_name, shared_from_group_id, shared_from_group_name 
    const handleInviteFriends = () => {
            setInviting(true)
            axios.post(`${process.env.REACT_APP_API_URL}/api/group/invite_from_user_list`, { groupId: current_group_id, to_user_ids: toUserIds }, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if(res.data.status === 1) {
                    setInviting(false)
                    socket.emit("sendToManyInvitation",{receiverids:toUserIds})
                    navigate(-1)
                }
                dispatch(setSnackMessage(res.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setInviting(false)
                dispatch(setSnackMessage("Something went wrong. Try later."))
                dispatch(setOpenSnack(true))
            })

    }

    return (
        <Box sx={{ bgcolor: 'white', padding: '16px', marginTop:'10px' }}>
            <Typography variant='h6'>Invite to <strong style={{ color: '#6c63FF' }}>{searchparams.get('group')}</strong></Typography>
            {/* {(groups.length >= 0) ? <NotFound /> : */}
            <>
                <InfiniteScroll
                    dataLength={users.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
                        size={24}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    /></Box>}
                >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {users.map((user, index) => {
                            // const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                                // <ListItem
                                //     key={index}
                                //     secondaryAction={
                                //         <Checkbox
                                //             edge="end"
                                //             onChange={handleToggle(index, user.u_id)}
                                //             checked={checked.indexOf(index) !== -1}
                                //             inputProps={{ 'aria-labelledby': labelId }}
                                //             disabled={(user.is_invited || user.is_member) ? true : false}
                                //         />
                                //     }
                                //     disablePadding
                                // >
                                //     <ListItemButton>
                                //         <ListItemAvatar>
                                //             <Avatar
                                //                 // alt={`${user.name} image`}
                                //                 src={user.profile_pic}
                                //             />
                                //             {/* {user.name.replace(/&#?\w+;/g, match => entities[match]).charAt(0).toUpperCase()}</Avatar> */}
                                //         </ListItemAvatar>
                                //         <ListItemText id={labelId} primary={user.name.replace(/&#?\w+;/g, match => entities[match])}
                                //             secondary={<Typography variant='caption'>{(user.is_invited) && <span style={{ color: 'red' }}>Already invited</span>} {(user.is_member) && <span style={{ color: '#6c63FF' }}>Already a member</span>}</Typography>} />
                                //     </ListItemButton>
                                // </ListItem>
                                <InviteFromUserListItem key={user.u_id} group_id={current_group_id} socket={socket} user={user} token={token}/>
                            );
                        })}
                    </List>
                </InfiniteScroll>
                {/* {
                    (hasMore === false && users.length <= 0) ? <NotFound data={"No user in your list"} subData={"Sorry, we could not found any user in your list.Please add some user in your list and then invite."} /> :
                        <Box sx={{ position: 'relative', marginBottom: '15px', marginTop: '15px' }}>
                            <MoreButton disabled={(inviting || (checked.length <= 0)) ? true : false} disableElevation variant="contained" fullWidth onClick={handleInviteFriends} sx={{ bgcolor: 'green', color: 'white' }}>Invite</MoreButton>
                            {inviting && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'orange',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                } */}
            </>
            {/* <Snackbar
                open={openSnack}
                autoHideDuration={3000}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }

                    dispatch(setOpenSnack(false));
                }}
                message={snackMessage}
                action={<IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(event, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }

                        dispatch(setOpenSnack(false));
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>}
            /> */}
        </Box>
    )
}

export default InviteFromUserList