import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControl, FormControlLabel, FormLabel, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React from 'react'
import { EmotInput, StyledInputLabel, FormFieldAlertBox, FilledButton, DisabledButton, OutlinedButton, Title1, SubTitle3, Title3, Title4 } from '../../CustomComponents'
import { AddAPhoto, ArrowBackIosNewOutlined, SaveAsOutlined } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import theme from '../../Theme'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice'
import axios from 'axios'
import CreateChannelHandle from '../Grid/CreateChannelHandle'
import { setChannelDetails, updateChannelBasicInfo, updateChannelCoverPic, updateChannelLogo, updateChannelTag } from '../../store/channelSlice'
import CreateGroupName from '../Grid/CreateGroupName'
import NotFound from '../status/NotFound'
import no_photo from '../../media/images/no_photo.png'
import ReactGA from 'react-ga4'

const topics = [
  'Art & Design',
  'Business',
  'Cooking',
  'Cultural Activity',
  'Dance',
  'Entertainment',
  'Fashion',
  'Festivals',
  'Film',
  'Fitness',
  'Game',
  'Investing',
  'Music',
  'Photography',
  'Reading',
  'Sports',
  'Technology',
  'Travel',
  'Writing'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, selectTheme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? selectTheme.typography.fontWeightRegular
        : selectTheme.typography.fontWeightMedium,
  };
}

const RoomCreate = () => {
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const [coverFile, setCoverFile] = React.useState(null)
  const [isLogoUploading, setIsLogoUploading] = React.useState(false) // ok // error // status
  const [isCoverPicUploading, setIsCoverPicUploading] = React.useState(false) // ok // error // status
  const [status, setStatus] = React.useState('ok') // error // not found
  const [message, setMessage] = React.useState('')
  const [details, setDetails] = React.useState({
    name: '',
    short_description: '',
    description: '',
  })
  const [name_count, set_name_count] = React.useState(0)
  const [short_desc_count, set_short_desc_count] = React.useState(0)
  const [tag_count, set_tag_count] = React.useState(0)
  const [tag, setTag] = React.useState('')
  const channelDetails = useSelector(state => state.channel.channelDetails)
  const token = JSON.parse(localStorage.getItem('token'))
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])
  React.useEffect(() => {
    if(channelDetails.name) {
      set_name_count(channelDetails.name?.length)
      set_tag_count(channelDetails.tag?.length)
      set_short_desc_count(channelDetails.short_description?.length)
    }
    
  }, [channelDetails])

  React.useEffect(() => {
    if (!location.state.channel_id) {
      navigate(-1)
    }
    if (location.state.channel_id === channelDetails?.group_id) {
      setDetails({
        name: channelDetails.name,
        short_description: channelDetails.short_description,
        description: channelDetails.description
      })
      setTag(channelDetails.tag)
    } else {
      setOpen(true)
      axios.get(`${process.env.REACT_APP_API_URL}/api/bi-xjV/channel/${location.state.channel_id}`, { headers: { authorization: "Bearer " + token } })
        .then(res => {
          setOpen(false)
          if (res.data.status === 1) {
            // setRoomDetails(res.data.data)
            dispatch(setChannelDetails({ data: res.data.data }))
          }
          if (res.data.status === 0) {
            setStatus('not_found')
            return
          }
        }).catch(error => {
          setStatus('error')
          setOpen(false)
        })
    }
  }, [channelDetails, location])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (details.name.trim().length <= 2) {
      setMessage('name required.')
      return
    }
    if (details.short_description.trim() === '') {
      setMessage('short description required.')
      return
    }
    if (details.description.trim() === '') {
      setMessage('description required.')
      return
    }
    setOpen(true)
    axios.put(`${process.env.REACT_APP_API_URL}/api/bi-xjV/channel`, { ...details, ksdf: channelDetails.group_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        setOpen(false)
        if (response.data.status === 1) {
          dispatch(updateChannelBasicInfo({
            naem: details.name,
            description: details.description,
            short_description: details.short_description
          }))
          // dispatch(setNewGroupInList(response.data.data))
          // setDetails({
          //   name: '',
          //   short_description: '',
          //   about: '',
          //   language: 'bengali',
          //   topic: '',
          //   is_for_under_18: false
          // })
          // navigate(`/channel-profile/:chan_id/${channelDetails.group_id}`)
        }
        setDetails({
          name: '',
          short_description: '',
          description: '',
        })
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      })
      .catch(error => {
        setOpen(false)
        dispatch(setSnackMessage(`Sorry, something went wrong.`))
        dispatch(setOpenSnack(true))
      });
  }
  const handleTagSubmit = (event) => {
    event.preventDefault()
    if (tag.trim().length <= 2) {
      // setMessage('name')
      dispatch(setSnackMessage('tag is too short'))
      dispatch(setOpenSnack(true))
      return
    }
    setOpen(true)
    axios.put(`${process.env.REACT_APP_API_URL}/api/bi-xjV/channel-tag`, { tag, ksdf: channelDetails.group_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        setOpen(false)
        if (response.data.status === 1) {
          dispatch(updateChannelTag({
            tag
          }))
          // navigate(`/channel-profile/:chan_id/${channelDetails.group_id}`)
        }
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      })
      .catch(error => {
        setOpen(false)
        dispatch(setSnackMessage(`Sorry, something went wrong.`))
        dispatch(setOpenSnack(true))
      });
  }

  function getExtension(filename) {
    return filename.split('.').pop()
  }

  function handleFileChange(event, type) {
    var file_extension = getExtension(event.target.files[0].name)
    if (event.target.files[0].size > 6e6) {
      dispatch(setSnackMessage("Please upload a file smaller than 6mb."))
      dispatch(setOpenSnack(true))
      return false;
    }
    if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
      if (type === 'cover') {
        setCoverFile(event.target.files[0])
      } else {
        setFile(event.target.files[0])
      }
    } else {
      dispatch(setSnackMessage("Sorry, this file format is not allowed.Use only png, jpg, jpeg file."))
      dispatch(setOpenSnack(true))
    }
    // setOpen(true)
  }

  const handleFileUpload = (event) => {
    event.preventDefault()
    setIsLogoUploading(true)
    const url = `${process.env.REACT_APP_API_URL}/api/bi-xjV/add/logo`;
    const formData = new FormData();
    formData.append('logo', file);
    formData.append('fileName', file.name);
    formData.append('room_id', channelDetails.group_id);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'authorization': "Bearer " + token
      },
    };
    axios.put(url, formData, config).then((response) => {
      // updateLocalStorage({ uid: user_id, name: userDetails.name, profile_pic: response.data.file })
      dispatch(setSnackMessage(response.data.message))
      dispatch(updateChannelLogo({ logo: response.data.logo }))
      setFile(null)
      dispatch(setOpenSnack(true))
      setIsLogoUploading(false)
    }).catch(error => {
      setIsLogoUploading(false)
      dispatch(setSnackMessage("Something went wrong"))
      dispatch(setOpenSnack(true))
    });
  }

  const handleCoverFileUpload = (event) => {
    event.preventDefault()
    setIsCoverPicUploading(true)
    const url = `${process.env.REACT_APP_API_URL}/api/bi-xjV/add/cover_pic`;
    const formData = new FormData();
    formData.append('cover_pic', coverFile);
    formData.append('fileName', coverFile.name);
    formData.append('room_id', channelDetails.group_id);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        authorization: "Bearer " + token
      },
    };
    axios.put(url, formData, config).then((response) => {
      // setUserDetails(details => {
      //     return { ...details, cover_pic: response.data.file }
      // })
      dispatch(setSnackMessage(response.data.message))
      dispatch(setOpenSnack(true))
      dispatch(updateChannelCoverPic({ channel_cover_pic: response.data.channel_cover_pic }))
      setCoverFile(null)
      setIsCoverPicUploading(false)
      // setIsDisabled(true)
    }).catch(error => {
      setIsCoverPicUploading(false)
      dispatch(setSnackMessage("Something went wrong."))
      dispatch(setOpenSnack(true))
    });
  }

  return (
    status === 'not found' ?
      <NotFound data={"No Data Found"} subData={"Sorry, we could not found any data to show.Please try again later."} /> :
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '8px'
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        // onClick={() => {
        //   setOpen(false)
        // }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <Container maxWidth="sm">
          <Title1>Edit Channel</Title1>
          <Box component="form"
            // onSubmit={handleSubmit}
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              border: '1px solid #EAEAF2',
              padding: '16px'
            }}
          >
            <StyledInputLabel htmlFor="about-room-field-id">
              Add Logo
            </StyledInputLabel>
            <Box sx={{ width: '100px', height: '100px', border: '2px dashed darkgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                (channelDetails.logo !== null || file !== null) ?
                  // <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={file !== null ? URL.createObjectURL(file) : channelDetails.logo} /> :
                  <img style={{ width: '100%', height: '100%', objectFit: (file !== null || channelDetails.logo !== null) ? 'cover' : 'contain' }} src={file !== null ? URL.createObjectURL(file) : channelDetails.logo !== null ? channelDetails.logo : no_photo} /> :
                  <Box component='label' onChange={(event) => handleFileChange(event, 'logo')}>
                    <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                    <AddAPhoto color='dark' />
                  </Box>
              }
              {/* hello */}
            </Box>
            <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
              {
                (channelDetails.logo !== null) &&
                <Button variant='contained' size='small' type='submit' component="label" sx={{ whiteSpace: 'nowrap' }} onChange={(event) => handleFileChange(event, 'logo')}>
                  <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                  Choose File
                </Button>
              }
              <Box sx={{ position: 'relative' }}>
                <Button type='submit' size='small' variant="contained" disabled={(file === null || isLogoUploading) ? true : false} component="label" sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }} onClick={handleFileUpload} >
                  Upload Logo
                </Button>

                {isLogoUploading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: '#44bd32',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {/* add cover pic ===================================================== */}
          {/* =================================================================== */}
          <Box component="form"
            // onSubmit={handleSubmit}
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              border: '1px solid #EAEAF2',
              padding: '16px'
            }}
          >
            <StyledInputLabel htmlFor="about-room-field-id">
              Add cover pic
            </StyledInputLabel>
            <Box sx={{ width: '220px', height: '120px', border: '2px dashed darkgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                (channelDetails.channel_cover_pic !== null || coverFile !== null) ?
                  <img style={{ width: '100%', height: '100%', objectFit: (coverFile !== null || channelDetails.channel_cover_pic !== null) ? 'cover' : 'contain' }} src={coverFile !== null ? URL.createObjectURL(coverFile) : channelDetails.channel_cover_pic !== null ? channelDetails.channel_cover_pic : no_photo} /> :
                  <Box component='label' onChange={(event) => handleFileChange(event, 'cover')}>
                    <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                    <AddAPhoto color='dark' />
                  </Box>
              }
              {/* hello */}
            </Box>
            <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
              {
                (channelDetails.channel_cover_pic !== null) &&
                <Button variant='contained' size='small' type='submit' component="label" sx={{ whiteSpace: 'nowrap' }} onChange={(event) => handleFileChange(event, 'cover')}>
                  <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                  Choose File
                </Button>
              }
              <Box sx={{ position: 'relative' }}>
                <Button type='submit' size='small' variant="contained" disabled={(coverFile === null || isCoverPicUploading) ? true : false} component="label" sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }} onClick={handleCoverFileUpload} >
                  Upload File
                </Button>

                {isCoverPicUploading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: '#44bd32',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {/* Channel Basic Info ================================================= */}
          {/* ==================================================================== */}
          <Box component="form"
            onSubmit={handleSubmit}
            sx={{
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              border: '1px solid #EAEAF2',
              padding: '16px'
            }}
          >
            <StyledInputLabel htmlFor="room-name-field-id">
              Your Channel Name*
            </StyledInputLabel>
            <EmotInput
              required
              fullWidth
              id="room-name-field-id"
              sx={{ mb: 2 }}
              value={details.name}
              onChange={(event) => {
                set_name_count(name_count + 1)
                if (name_count < 80) {
                  setDetails({ ...details, name: event.target.value })
                }
              }}
            />
            <Title4 textAlign='end' mb={2} mt={1} color='darkgray'>{name_count} / 80</Title4>
            <StyledInputLabel htmlFor="short-room-description-id">
              Short Descripiton*
            </StyledInputLabel>
            <EmotInput
              required
              id="short-room-description-id"
              value={details.short_description}
              onChange={(event) => {
                set_short_desc_count(short_desc_count + 1)
                if (short_desc_count < 150) {
                  setDetails({ ...details, short_description: event.target.value })
                }
              }}
            />
            <Title4 textAlign='end' mb={2} mt={1} color='darkgray'>{short_desc_count} / 150</Title4>
            <StyledInputLabel htmlFor="description-room-field-id">
              About Channel*
            </StyledInputLabel>
            <EmotInput
              required
              variant='standard'
              multiline
              rows={3}
              id="description-room-field-id"
              sx={{ mb: 2 }}
              value={details.description}
              onChange={(event) => setDetails({ ...details, description: event.target.value })}
            />
            {
              (message !== '') &&
              <Box sx={{ p: 2, backgroundColor: 'rgba(255, 203, 112,0.8)' }}>
                {message}
              </Box>
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
              {/* <Button variant='contained' color='light' sx={{ color: theme.palette.dark.light }} startIcon={<ArrowBackIosNewOutlined />} onClick={() => navigate(-1)}>Back</Button> */}
              <Button type='submit' size='small' variant='contained' startIcon={<SaveAsOutlined />}>
                Submit
              </Button>
            </Box>
          </Box>
          {/* add tag */}
          <Box component="form"
            onSubmit={handleTagSubmit}
            sx={{
              marginTop: 3,
              marginBottom: 3,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              border: '1px solid #EAEAF2',
              padding: '16px'
            }}
          >
            <StyledInputLabel htmlFor="about-room-field-id">
              Add Tag
            </StyledInputLabel>
            <EmotInput
              required
              variant='standard'
              multiline
              rows={3}
              id="about-room-field-id"
              placeholder='Ex. Travel, Forest, Darjeeling'
              value={tag}
              onChange={(event) => {
                set_tag_count(tag_count + 1)
                if (tag_count < 100) {
                  setTag(event.target.value)
                }
              }}
            />
            <Title4 textAlign='end' mb={2} mt={1} color='darkgray'>{tag_count} / 100</Title4>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              {/* <Button variant='contained' color='light' sx={{ color: theme.palette.dark.light }} startIcon={<ArrowBackIosNewOutlined />} onClick={() => navigate(-1)}>Back</Button> */}
              <Button type='submit' size='small' variant='contained' disabled={tag?.length < 2 ? true : false} startIcon={<SaveAsOutlined />}>
                Submit
              </Button>
            </Box>
          </Box>

          {/* <Paper sx={{ marginBottom: '16px', padding: '16px' }}> */}
          <CreateGroupName name={channelDetails.name} id={channelDetails.group_id} group_name={channelDetails.handle} type='channel' />
          {/* </Paper> */}
        </Container>
      </Box>
  )
}

export default RoomCreate