import { Alert, Box, CircularProgress, Divider, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import CommentItem from './CommentItem'
import { decreasePostCommentCount } from '../../store/postSlice'

const Comment = ({ value, post_id, role, is_colored, total_comments, set_total_comments, newComment, setNewComment,type }) => {
    const [comments, setComments] = React.useState([])
    const [deleteCommentId, setDeleteCommentId] = React.useState('')
    const [commentStatus, setCommentStatus] = React.useState('ok')
    const { uid: user_id, profile_pic, name } = JSON.parse(localStorage.getItem('user'))
    const token = JSON.parse(localStorage.getItem('token'))
    const dispatch = useDispatch()
    // React.useEffect(() => {
    //     if (expanded === true && comment_list.length <= 0) {
    //       axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/comment/${val.xz12uied7}/${user_id}`, { headers: { authorization: "Bearer " + token } })
    //         .then(response => {
    //           // dispatch(setFetchedComment(response.data.data))
    //           setComment_list(response.data.data)
    //         }).catch(error => {
    //         })
    //     }
    //   }, [expanded])
    // React.useEffect(() => {

    // },[])
    React.useEffect(() => {
        if (comments.length <= 0) {
            setCommentStatus('loading')
            axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/comment/${post_id}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.length === 0) {
                        setCommentStatus('empty')
                    } else {
                        setCommentStatus('ok')
                        setComments(response.data.data)
                    }
                }).catch(error => {
                    setCommentStatus('error')
                })
        }
    }, [post_id])

    React.useEffect(() => {
        if (newComment.comment_id) {
            setComments(comments => {
                return [newComment, ...comments]
            })
        }
        setNewComment({})
    }, [newComment.comment_id])

    const deleteComment = (comment_id) => {
        setDeleteCommentId(comment_id)
        set_total_comments(total_comments - 1)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/deleteComment/${comment_id}/${post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setDeleteCommentId('')
                setComments(comments.filter(el => el.comment_id !== comment_id))
                // if (value === 'pin') {
                //     dispatch(decreasePinPostCommentCount(post_id))
                // }else if(value === 'myPost') {
                //     dispatch(decreaseMyPostCommentCount(post_id))
                // }else if(value === 'saved') { 
                //     dispatch(decreaseSavePostCommentCount(post_id))
                // }else if(value === 'notification_post') {
                //         // dispatch(setSnackMessage("Something went wrong. Try later."))
                //         // dispatch(setOpenSnack(true))
                // }else {
                //     dispatch(decreasePostCommentCount(post_id))
                // }
                dispatch(decreasePostCommentCount({ feed_value: value, data: post_id }))
            }).catch(error => {
            })
    }


    return (
        <>
            <Typography ml={1}>Comments({total_comments})</Typography>
            <Divider sx={{ mb: 2, mt: 1 }} />
            {
                (commentStatus === 'loading') &&
                <Box sx={{ textAlign: 'center', p: 1 }}>
                    <CircularProgress
                        size={24}
                        sx={{
                            color: '#44bd32',
                        }}
                    />
                </Box>
            }
            {
                (commentStatus === 'error') &&
                <Alert severity="error">Sorry, something went wrong</Alert>
            }
            {
                (commentStatus === 'empty') &&
                <Alert severity="info">No comments found.</Alert>
            }
            <Box>
                {
                    (commentStatus === 'ok' && comments.length !== 0) &&
                    comments.map((comment, index) => {
                        return <CommentItem key={comment.comment_id} profile_pic={profile_pic} name={name} user_id={user_id} role={role} is_colored={is_colored} deleteCommentId={deleteCommentId} comment={comment} deleteComment={deleteComment} token={token} post_id={post_id} type={type} />
                    })
                }
            </Box>
        </>
    )
}

export default Comment