import { Avatar, Box, Button, Divider, Fab, Grid, IconButton, Rating, Typography } from '@mui/material'
import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import styled from '@emotion/styled';
import Like from '@mui/icons-material/FavoriteBorderOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ClapOutlined from '@mui/icons-material/SignLanguageOutlined';
import Clap from '@mui/icons-material/SignLanguage';
import { Logo } from '../../CustomComponents';
import ReactGA from 'react-ga4'

const labels = {
    1: 'Bad',
    2: 'Okay',
    3: 'Good',
    4: 'Great',
    5: 'Excellent',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const StyledText = styled(Typography)(({ theme }) => ({
    color: 'black',
    padding: 0,
    margin: 0,
    // fontWeight:400,
    fontSize: '14px',
    display: 'block'
}));
const StyledIcon = styled(IconButton)(({ theme }) => ({
    color: 'black',
    padding: 0,
    margin: 0,
    // fontWeight:400,
    // fontSize:'14px',
    display: 'block'
}));

const FullImage = () => {
    const [value, setValue] = React.useState(2);
    const [hover, setHover] = React.useState(-1);
    const img_file = useSelector(state => state.post.fullPostDetails)
    // const posts = useSelector(state => state.post.groupPostList)
    // const [searchparams] = useSearchParams()
    const react = 1
    const navigate = useNavigate()

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])

    const handleRoute = () => {
        navigate(-1)
    }

    const handleEmojiNumber = () => {

    }
    return (
        <>
            <Grid sx={{ height: '100vh', boxSizing: 'border-box' }}>
                <Grid item sm={12} sx={{ backgroundColor: '#232629', textAlign: 'center', overflow: 'hidden', }}>
                    <Box sx={{ position: "absolute", top: '20px', left: '20px', display: 'flex', alignItems: 'center', zIndex: 99 }}>
                        <Avatar sx={{ width: 50, height: 50, bgcolor: '#ff7675' }} onClick={() => navigate(-1)}>
                            <CloseIcon />
                        </Avatar>
                        <Logo
                            variant='h4'
                            noWrap
                            mx={2}
                            color='white'
                            sx={{ margin: '0px 16px', fontWeight: 600, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', display: { xs: 'none', md: 'inline-block' } }}
                        >EmotionTown</Logo>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100vh' }}>
                        <img src={img_file} alt="full view" style={{ height: '100%', width: "100%", objectFit: 'contain' }} />
                        {/* <img src={fullPostDetails.files} alt="full view" style={{ height: 'calc(100vh - 70px)', objectFit: 'contain' }} /> */}
                        {/* <Box
                            sx={{
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                height: '70px',
                                justifyContent: 'center'
                            }}
                        >
                            <IconButton aria-label="add to shopping cart" size={(react !== null) && (react === 1) ? 'large' : 'small'} sx={{ padding: '0px', color: '#f0932b', marginRight: '6px' }} onClick={() => handleEmojiNumber(1)}>
                                <Clap fontSize='inherit' />
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 2) ? 'large' : 'small'} sx={{ padding: '0px' }} onClick={() => handleEmojiNumber(2)}>
                                😆
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 3) ? 'large' : 'small'} sx={{ padding: '0px', }} onClick={() => handleEmojiNumber(3)}>
                                😍
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 4) ? 'large' : 'small'} sx={{ padding: '0px' }} onClick={() => handleEmojiNumber(4)}>
                                😮
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 5) ? 'large' : 'small'} sx={{ padding: '0px', }} onClick={() => handleEmojiNumber(5)}>
                                😘
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 6) ? 'large' : 'small'} sx={{ padding: '0px' }} onClick={() => handleEmojiNumber(6)}>
                                😋
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 7) ? 'large' : 'small'} sx={{ padding: '0px' }} onClick={() => handleEmojiNumber(7)}>
                                😠
                            </IconButton>
                            <IconButton color="primary" aria-label="add to shopping cart" size={(react !== null) && (react == 8) ? 'large' : 'small'} sx={{ padding: '0px' }} onClick={() => handleEmojiNumber(8)}>
                                😢
                            </IconButton>
                            <Typography pl={1} variant='caption'> | 128 people reacted</Typography>
                        </Box> */}
                        {/* <Box sx={{ position: 'absolute', bottom: '90px', right: '20px', zIndex: '99', border: '2px solid #f1f2f3', backgroundColor: 'white', padding: '10px', borderRadius: '6px' }}>
                            <Box sx={{ mb: 3 }}>
                                <StyledIcon color="primary" aria-label="add to shopping cart" size='small'>
                                    <MessageOutlinedIcon fontSize='inherit' />
                                </StyledIcon>
                                <StyledText variant='caption'>12</StyledText>
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <StyledIcon color="primary" aria-label="add to shopping cart" size='small'>
                                    <SendOutlinedIcon fontSize='inherit' />
                                </StyledIcon>
                                <StyledText>12</StyledText>
                            </Box>
                            <Box>
                                <StyledIcon color="primary" aria-label="add to shopping cart" size='small'>
                                    <BookmarkAdd fontSize='inherit' />
                                </StyledIcon>
                                <StyledText>12</StyledText>
                            </Box>
                        </Box> */}
                    </Box>
                </Grid>
                {/* <Grid item sm={3} sx={{ backgroundColor: '#f1f2f3', p: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Avatar alt="Remy Sharp" src={fullPostDetails.profile_pic} sx={{ width: 30, height: 30 }} />
                        <Box sx={{ ml: 1 }}>
                            <Typography color='#232629'>{fullPostDetails.name}</Typography>
                            <Typography variant='cpation' component='div' color='#6a737c' fontSize='12px'>{format(fullPostDetails.added_on) + ' . ' + moment(fullPostDetails.added_on).format('DD-MMM-YY')}</Typography>
                        </Box>
                    </Box>
                    <Typography variant='caption' fontSize='14px' component='div' color='#6a737c'>
                        {fullPostDetails.description}
                    </Typography>
                    <Typography variant='h6' fontSize='16px' mt={3} mb={1}>
                        Comments: 12
                    </Typography>
                    <Divider />
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },

                            display: 'flex',
                            // flexDirection: 'column',
                            // alignItems: 'end',
                            margin: '10px 0px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <input type='text' placeholder='Comment something...' id="fname" name="fname" style={{
                            width: '100%',
                            padding: '12px 20px',
                            // margin: '8px 0px',
                            marginRight: '10px',
                            boxSizing: 'border-box',
                            border: 'none',
                            backgroundColor: '#e3e6e8',
                            // color: 'white',
                            outlineColor: '#d6d9dc',
                            color: '#525960'
                        }} />
                        <IconButton color="primary" aria-label="send comment" sx={{ backgroundColor: '#1a8cff', color: 'white' }}>
                            <SendOutlinedIcon />
                        </IconButton>
                    </Box>

                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
                            <Avatar alt="Remy Sharp" src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600" sx={{ width: 30, height: 30 }} />
                            <Box sx={{ ml: 1 }}>
                                <Typography color='#232629'>Arundhuti Mahapatra</Typography>
                                <Typography variant='cpation' component='div' color='#6a737c' fontSize='12px'>{format(fullPostDetails.added_on) + ' . ' + moment(fullPostDetails.added_on).format('DD-MMM-YY')}</Typography>
                                <Typography variant='cpation' component='div' color='#6a737c' fontSize='14px' margin='10px 0px'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Typography>
                                <Box>
                                    <IconButton color="primary" aria-label="add to shopping cart" size='small'>
                                        <Like fontSize='inherit' />
                                    </IconButton>
                                    <Typography variant='cpation' fontSize='14px' fontWeight='600' color='primary' mr={3}>3</Typography>
                                    <Button variant='text' sx={{ textTransform: 'lowercase' }}>reply 12</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ bgcolor: '#dae0f1', padding: '6px', marginLeft: '8px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
                                <Avatar alt="Remy Sharp" src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600" sx={{ width: 30, height: 30 }} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography color='#232629'>Arundhuti Mahapatra</Typography>
                                    <Typography variant='cpation' component='div' color='#6a737c' fontSize='12px'>{format(fullPostDetails.added_on) + ' . ' + moment(fullPostDetails.added_on).format('DD-MMM-YY')}</Typography>
                                    <Typography variant='cpation' component='div' color='#6a737c' fontSize='14px' margin='10px 0px'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Typography>
                                    <Box>
                                        <IconButton color="primary" aria-label="add to shopping cart" size='small'>
                                            <Like fontSize='inherit' />
                                        </IconButton>
                                        <Typography variant='cpation' fontSize='14px' fontWeight='600' color='primary' mr={3}>3</Typography>
                                        <Button variant='text' sx={{ textTransform: 'lowercase' }}>reply 12</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
                                <Avatar alt="Remy Sharp" src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600" sx={{ width: 30, height: 30 }} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography color='#232629'>Arundhuti Mahapatra</Typography>
                                    <Typography variant='cpation' component='div' color='#6a737c' fontSize='12px'>{format(fullPostDetails.added_on) + ' . ' + moment(fullPostDetails.added_on).format('DD-MMM-YY')}</Typography>
                                    <Typography variant='cpation' component='div' color='#6a737c' fontSize='14px' margin='10px 0px'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Typography>
                                    <Box>
                                        <IconButton color="primary" aria-label="add to shopping cart" size='small'>
                                            <Like fontSize='inherit' />
                                        </IconButton>
                                        <Typography variant='cpation' fontSize='14px' fontWeight='600' color='primary' mr={3}>3</Typography>
                                        <Button variant='text' sx={{ textTransform: 'lowercase' }}>reply 12</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
                                <Avatar alt="Remy Sharp" src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600" sx={{ width: 30, height: 30 }} />
                                <Box sx={{ ml: 1 }}>
                                    <Typography color='#232629'>Arundhuti Mahapatra</Typography>
                                    <Typography variant='cpation' component='div' color='#6a737c' fontSize='12px'>{format(fullPostDetails.added_on) + ' . ' + moment(fullPostDetails.added_on).format('DD-MMM-YY')}</Typography>
                                    <Typography variant='cpation' component='div' color='#6a737c' fontSize='14px' margin='10px 0px'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Typography>
                                    <Box>
                                        <IconButton color="primary" aria-label="add to shopping cart" size='small'>
                                            <Like fontSize='inherit' />
                                        </IconButton>
                                        <Typography variant='cpation' fontSize='14px' fontWeight='600' color='primary' mr={3}>3</Typography>
                                        <Button variant='text' sx={{ textTransform: 'lowercase' }}>reply 12</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid> */}
            </Grid>
        </>
    )
}

export default FullImage