const data = [
        {
            chat_room_id:1,
            name:'Amalesh Maity',
            profile_pic:'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`0b6b00bf-cae6-4e7d-b195-e13360bf38c8`,
            chat:'Hello Jini',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:12,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Amalesh Maity',
            profile_pic:'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`0b6b00bf-cae6-4e7d-b195-e13360bf38c8`,
            chat:'Kemon accho?',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Anima Samanta (Giri)',
            profile_pic:'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`ec3dec01-7183-4e32-9fdf-48dd4b320dfb`,
            chat:'Bhalo achhi. Tumi?',
            seen_status:'yes',
            love_react:3,
            appreciate_react:12,
            lough_react:15,
            angry_react:0,
            cry_react:0,
            welcome_react:2,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Amalesh Maity',
            profile_pic:'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`0b6b00bf-cae6-4e7d-b195-e13360bf38c8`,
            chat:'chole jachche',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Anima Samanta (Giri)',
            profile_pic:'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`ec3dec01-7183-4e32-9fdf-48dd4b320dfb`,
            chat:'Barir sabai kemon achche?',
            seen_status:'yes',
            love_react:0,
            appreciate_react:5,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Amalesh Maity',
            profile_pic:'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`0b6b00bf-cae6-4e7d-b195-e13360bf38c8`,
            chat:'Tor boy friend er khabar ki?',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:1,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Amalesh Maity',
            profile_pic:'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`0b6b00bf-cae6-4e7d-b195-e13360bf38c8`,
            chat:'ki re kono reply dichchis na keno?',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Anima Samanta (Giri)',
            profile_pic:'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`ec3dec01-7183-4e32-9fdf-48dd4b320dfb`,
            chat:'Amalesh kal katha hobe. Barite guest esechche.',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
        {
            chat_room_id:1,
            name:'Anima Samanta (Giri)',
            profile_pic:'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600',
            sender_chatter_id:`ec3dec01-7183-4e32-9fdf-48dd4b320dfb`,
            chat:'Bye! 🙋‍♀️',
            seen_status:'yes',
            love_react:0,
            appreciate_react:0,
            lough_react:0,
            angry_react:0,
            cry_react:0,
            welcome_react:0,
            added_on:`2023-07-18 04:24:04`
        },
    ]

    export default data