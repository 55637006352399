import React from 'react'
import { SubTitle2, Title1 } from '../CustomComponents'
import { Avatar, Box, Typography } from '@mui/material'
import { Groups } from '@mui/icons-material'
import Users from './Users'
import GroupsTypeCount from './GroupsTypeCount'

const Home = () => {
    return (
        <>
            <Title1 mt={2} mb={1}>Today Updates</Title1>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', backgroundColor: 'white', padding: '16px 20px' }}>
                    <Avatar></Avatar>
                    <Box>
                        <Typography fontSize='24px' fontWeight={800} color='dark.light'>1239</Typography>
                        <SubTitle2 color='disabled.main'>Total New Uses</SubTitle2>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', backgroundColor: 'white', padding: '16px 20px' }}>
                    <Avatar><Groups fontSize='small' /></Avatar>
                    <Box>
                        <Typography fontSize='24px' fontWeight={800} color='dark.light'>23</Typography>
                        <SubTitle2 color='disabled.main'>Total New Groups</SubTitle2>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', backgroundColor: 'white', padding: '16px 20px' }}>
                    <Avatar><Groups fontSize='small' /></Avatar>
                    <Box>
                        <Typography fontSize='24px' fontWeight={800} color='dark.light'>23</Typography>
                        <SubTitle2 color='disabled.main'>Total New Post</SubTitle2>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'flex-start' }}>
                    <Box sx={{ flex: 4 }}>
                        <Title1 mt={2} mb={1}>All Users Data</Title1>
                        <Users />
                    </Box>
                    <Box sx={{ flex: 2, }}>
                        <Title1 mt={2} mb={1}>Total Groups Type</Title1>
                        <GroupsTypeCount />
                    </Box>
                </Box>

            </Box>
        </>
    )
}

export default Home