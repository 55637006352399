import { AppBar, Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import React, { Suspense, lazy } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ReactGA from 'react-ga4'
import { setOpenUaDialog } from '../../store/groupSlice'
import { ArrowBackIos } from '@mui/icons-material'
import { Title } from '../../CustomComponents'
import theme from '../../Theme'
// import GroupSearching from './Searching/GroupSearching'
// import PeopleSearching from './Searching/PeopleSearching'
// import PopularSearching from './Searching/PopularSearching'
// import ChannelSearching from './Searching/ChannelSearching'
const GroupSearching = lazy(() => import('./Searching/GroupSearching'))
const PeopleSearching = lazy(() => import('./Searching/PeopleSearching'))
const PopularSearching = lazy(() => import('./Searching/PopularSearching'))
const ChannelSearching = lazy(() => import('./Searching/ChannelSearching'))

const SearchedContent = () => {
    const [selectedItem, setSelectedItem] = React.useState(0)
    const [peopleList, setPeopleList] = React.useState([])
    const [groupList, setGroupList] = React.useState([])
    const [channelList, setchannelList] = React.useState([])
    const token = JSON.parse(localStorage.getItem('token'))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchparams] = useSearchParams()

    React.useEffect(() => {
        if (searchparams.get("status") === 'popular') {
            setSelectedItem(3)
        } else if (searchparams.get("status") === 'channel') {
            setSelectedItem(2)
        } else if (searchparams.get("status") === 'user') {
            setSelectedItem(1)
        } else {
            setSelectedItem(0)
        }
    }, [searchparams])

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])
    return (
        <Box sx={{ bgcolor: 'white' }}>
            {/* <AppBar position='sticky' sx={{ boxShadow: 'none', bgcolor: '#e6eeff', color: '#2d3436', top: { xs: '53px', sm: '63px' } }}>
                <Tabs onChange={handleTabs} value={value} indicatorColor="secondary"
                    textColor="inherit">
                    <Tab label='Group' />
                    <Tab label='People' />
                </Tabs>
            </AppBar> */}
            <Box sx={{ position: 'sticky', backgroundColor: 'white', top: { xs: '54px', sm: '63px' }, zIndex:5 }}>
                {/* <Box sx={{ padding: '10px 10px', display: 'flex', gap: 2 }}>
                    <ArrowBackIos sx={{ fontSize: '18px', color: theme.palette.dark.main }} onClick={() => navigate(-1)} />
                    <Title>Searched List</Title>
                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flex: 1, padding: '6px', textAlign: 'center', borderBottom: (selectedItem === 0) ? '2px solid #10ac84' : '2px solid #F2F2F7' }} onClick={() => {
                        navigate({ pathname: '/search', search: createSearchParams({ searchedContent: searchparams.get("searchedContent"), status: 'group' }).toString() })
                        setSelectedItem(0)
                    }}>
                        <Typography color={(selectedItem === 0) ? theme.palette.primary.main : theme.palette.disabled.main} >Group</Typography>
                    </Box>
                    <Box sx={{ flex: 1, padding: '6px', borderBottom: (selectedItem === 1) ? '2px solid #10ac84' : '2px solid #F2F2F7', textAlign: 'center' }} onClick={() => {
                        if (!token) {
                            dispatch(setOpenUaDialog(true))
                            return
                        }
                        navigate({ pathname: '/search', search: createSearchParams({ searchedContent: searchparams.get("searchedContent"), status: 'user' }).toString() })
                        setSelectedItem(1)
                    }}>
                        <Typography color={(selectedItem === 1) ? theme.palette.primary.main : theme.palette.disabled.main} >User</Typography>
                    </Box>
                    <Box sx={{ flex: 1, padding: '6px', borderBottom: (selectedItem === 2) ? '2px solid #10ac84' : '2px solid #F2F2F7', textAlign: 'center' }} onClick={() => {
                        // if (!token) {
                        //     dispatch(setOpenUaDialog(true)
                        //     return
                        // }
                        navigate({ pathname: '/search', search: createSearchParams({ searchedContent: searchparams.get("searchedContent"), status: 'channel' }).toString() })
                        setSelectedItem(2)
                    }}>
                        <Typography color={(selectedItem === 2) ? theme.palette.primary.main : theme.palette.disabled.main} >Channel</Typography>
                    </Box>
                    <Box sx={{ flex: 1, padding: '6px', borderBottom: (selectedItem === 3) ? '2px solid #10ac84' : '2px solid #F2F2F7', textAlign: 'center' }} onClick={() => {
                        // if (!token) {
                        //     dispatch(setOpenUaDialog(true))
                        //     return
                        // }
                        navigate({ pathname: '/search', search: createSearchParams({ searchedContent: searchparams.get("searchedContent"), status: 'popular' }).toString() })
                        setSelectedItem(3)
                    }}>
                        <Typography color={(selectedItem === 3) ? theme.palette.primary.main : theme.palette.disabled.main} >Popular</Typography>
                    </Box>
                </Box>
            </Box>
            {
                (selectedItem === 0) ?
                    <Suspense fallback={<div>Loading..</div>}>
                        <GroupSearching token={token} searchedContent={searchparams.get("searchedContent")} groupList={groupList} setGroupList={setGroupList} />
                    </Suspense> :
                    (selectedItem === 1) ?
                        <Suspense fallback={<div>Loading..</div>}>
                            <PeopleSearching token={token} searchedContent={searchparams.get("searchedContent")} peopleList={peopleList} setPeopleList={setPeopleList} />
                        </Suspense> :
                        (selectedItem === 2) ?
                            <Suspense fallback={<div>Loading..</div>}>
                                <ChannelSearching token={token} searchedContent={searchparams.get("searchedContent")} channelList={channelList} setchannelList={setchannelList} />
                            </Suspense> :
                            <Suspense fallback={<div>Loading..</div>}>
                                <PopularSearching token={token} searchedContent={searchparams.get("searchedContent")} />
                            </Suspense>
            }
            {/* <TabPanel value={value} index={0}>
                <GroupSearching token={token} searchedContent={searchparams.get("searchedContent")} groupList={groupList} setGroupList={setGroupList} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {
                    (!token) ?
                        dispatch(setOpenUaDialog(true)) :
                        <PeopleSearching token={token} searchedContent={searchparams.get("searchedContent")} peopleList={peopleList} setPeopleList={setPeopleList} />
                }
            </TabPanel> */}
        </Box>
    )
}

// function TabPanel(props) {
//     const { children, value, index } = props
//     return (
//         <div>
//             {
//                 value === index && (
//                     <div>{children}</div>
//                 )
//             }
//         </div>
//     )
// }

export default SearchedContent