import { Avatar, Box, Button, Chip, CircularProgress, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import React, { Suspense, lazy } from 'react'
import { DisabledButton, FilledButton, LightAvatar, OutlinedButton, SubTitle, SubTitle1, SubTitle2, SubTitle3, Title, Title1, Title2, Title3, Title4 } from '../../CustomComponents'
import theme from '../../Theme'
import { AddAPhotoOutlined, ArticleOutlined, AttachFileRounded, CameraOutdoor, ContentCopy, CreateOutlined, DirectionsOffOutlined, DirectionsOutlined, PhotoCameraOutlined, SmsOutlined, YardOutlined } from '@mui/icons-material'
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { appendNewChannelIntList, setChannelDetails, updateChannelFollowStatus } from '../../store/channelSlice'
import no_photo from '../../media/images/no_photo.png'
import { clearDestination, setOpenSnack, setSnackMessage } from '../../store/authSlice'
import { setOpenUaDialog } from '../../store/groupSlice'
import NotFound from '../status/NotFound'
// import ChannelSimplePostFeed from './ChannelSimplePostFeed'
// import ChannelPost from './ChannelPost'
const ChannelPost = lazy(() => import('./ChannelPost'));
const ChannelSimplePostFeed = lazy(() => import('./ChannelSimplePostFeed'));

const RoomProfile = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [roomDetails, setRoomDetails] = React.useState({})
    const [showItem, setShowItem] = React.useState(0)
    const [status, setStatus] = React.useState('loading')
    const [joinChannelStatus, setJoinChannelStatus] = React.useState(false)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const roomDetails = useSelector(state => state.channel.channelDetails)
    const channels = useSelector(state => state.channel.channelList)
    const { type, id } = useSelector(state => state.auth.destination)
    const token = JSON.parse(localStorage.getItem("token"))
    const socket = useOutletContext()
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { groupProfileId } = useParams()

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
        if (type !== '' || id !== '') {
            dispatch(clearDestination())
        }
    }, [])

    React.useEffect(() => {
        if(roomDetails?.group_id === groupProfileId) {
            setStatus('ok')
            return
        }
        setStatus('loading')
        axios.get(`${process.env.REACT_APP_API_URL}/api/bi-xjV/channel/${groupProfileId}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    setStatus('ok')
                    // setRoomDetails(res.data.data)
                    dispatch(setChannelDetails({ data: res.data.data }))
                }
                if (res.data.status === 0) {
                    setStatus('not_found')
                    return
                }
            }).catch(error => {
                setStatus('error')
            })
    }, [groupProfileId])
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = (value) => {
        setAnchorEl(null);
        // write simple post
        if (value === 1) {
            navigate('/add-article', {
                state: {
                    channel_id: groupProfileId,
                    post_type: 'love',
                    channel_name: roomDetails.name
                }
            })
        }
        // write article
        if (value === 2) {
            navigate('/add-article', {
                state: {
                    channel_id: groupProfileId,
                    post_type: 'article',
                    channel_name: roomDetails.name
                }
            })
        }
    };
    const handleTopic = () => {

    }
    const handleCopyLink = (link_text) => {
        // ====================================================
        ReactGA.event({
            category: 'copy',
            action: 'copied channel link',
            label: 'copied channel link'
        })
        navigator.clipboard.writeText(link_text)
            .then(result => {
                dispatch(setSnackMessage("Link copied successfully."))
                dispatch(setOpenSnack(true))
            }).catch(error => {
            })
    }
    const handleFollowChannel = () => {
        ReactGA.event({
            category: 'follow',
            action: 'follow channel',
            label: 'follow channel'
        })
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        setJoinChannelStatus(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/group/join_group`, { group_id: roomDetails.group_id }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.status !== 0) {
                    socket?.emit('join-channel', ({ roomId: roomDetails.group_id }))
                    let data = {
                        name: roomDetails.name,
                        logo: roomDetails.logo,
                        channel_id: roomDetails.group_id,
                        group_type: "channel",
                        role: "General",
                        status: "active",
                        total_member: roomDetails.total_member + 1
                    }
                    if (channels.length > 0) {
                        dispatch(appendNewChannelIntList({ data }))
                    }
                    dispatch(updateChannelFollowStatus({ status: 'active', role: 'General' }))
                }
                setJoinChannelStatus(true)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setJoinChannelStatus(false)
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
            })
    }

    const handleUnfollowChannel = () => {
        ReactGA.event({
            category: 'unfollow',
            action: 'unfollow channel',
            label: 'unfollow channel'
        })
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        setJoinChannelStatus(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/group/exit_group/${roomDetails.group_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    socket?.emit('exit-channel', ({ roomId: roomDetails.group_id }))
                    dispatch(updateChannelFollowStatus({ status: null, role: null }))
                }
                setJoinChannelStatus(false)
                dispatch(setSnackMessage(res.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setJoinChannelStatus(false)
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
            })
    }
    const toggleReadMore = () => setIsShowMore((show) => !show);

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <Menu
                id="create_post_menu_id"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose(1)}>
                    <ListItemIcon>
                        <YardOutlined fontSize="small" />
                    </ListItemIcon>
                    Create Simple Post
                </MenuItem>
                <MenuItem onClick={() => handleClose(2)}>
                    <ListItemIcon>
                        <ArticleOutlined fontSize="small" />
                    </ListItemIcon>
                    Write Article
                </MenuItem>
            </Menu>
            {
                (status === 'loading') ?
                <Box sx={{height:'70px', display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <CircularProgress size={24}/>
                </Box>: (status === 'not_found' || status === 'error') ? <NotFound data={"No data found"} subData={"Sorry, we could not found any data to show.Please try again later."} /> :
            <>
                <Box sx={{ height: '220px', width: '100%', position: 'relative', backgroundColor: theme.palette.light.light, position: 'relative' }}>
                    <img src={roomDetails.channel_cover_pic !== null ? roomDetails.channel_cover_pic : no_photo} alt="Channel Cover Pic" style={{ objectFit: roomDetails.channel_cover_pic !== null ? 'cover' : 'contain', width: '100%', height: '100%', opacity: roomDetails.channel_cover_pic !== null ? 1.0 : 0.3 }} />
                    <Avatar variant='rounded' src={roomDetails.logo} sx={{ width: 80, height: 80, border: '4px solid white', position: 'absolute', left: '16px', top: '176px' }}><CameraOutdoor fontSize='small' /></Avatar>
                </Box>
                <Title1 marginTop='8px' marginLeft='100px' pr={2}>{roomDetails.name}</Title1>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px', alignItems: 'flex-start', px: 2, my: 1 }}>
                    {
                        (roomDetails.handle) &&
                        <SubTitle3>@{roomDetails.handle}</SubTitle3>
                    }
                    <SubTitle2>{roomDetails.short_description?.substring(0, 150)}</SubTitle2>
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                        <Title3>{roomDetails.total_member} Follower{roomDetails.total_member > 1 ? 's' : ''}</Title3>
                        <Title3>{roomDetails.total_post} content{roomDetails.total_post > 1 ? 's' : ''}</Title3>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {
                            (roomDetails.role === null) &&
                            <Button variant='contained' size='small' disabled={joinChannelStatus === true ? true : false} startIcon={<DirectionsOutlined />} onClick={handleFollowChannel}>Follow</Button>
                        }
                        {
                            (roomDetails.role !== null && roomDetails.role !== 'Super Admin') &&
                            <Button variant='contained' size='small' disabled={joinChannelStatus === true ? true : false} color='light' sx={{ color: theme.palette.dark.light }} startIcon={<DirectionsOffOutlined />} onClick={handleUnfollowChannel}>Unfollow</Button>
                        }
                        {
                            (roomDetails.role !== 'Super Admin') &&
                            <LightAvatar variant='rounded' onClick={() => handleCopyLink(`https://www.emotiontown.com/channel-profile/${groupProfileId}`)}>
                                <ContentCopy fontSize='small' sx={{ color: theme.palette.dark.light }} />
                            </LightAvatar>
                        }
                    </Box>
                    {/* <LightAvatar variant='rounded' >
                        <SmsOutlined sx={{ color: theme.palette.primary.main }} />
                    </LightAvatar> */}

                    {/* </Box> */}
                    {
                        (roomDetails.role === 'Super Admin') &&
                        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Button variant='contained' startIcon={<AddAPhotoOutlined />} onClick={handleClick} size='small'>Upload Photo/Video</Button> */}
                            <Button variant='contained' startIcon={<AddAPhotoOutlined />} onClick={(event) => {
                                // navigate('/add-article', {
                                //     state: {
                                //         channel_id: groupProfileId
                                //     }
                                // })
                                setAnchorEl(event.currentTarget);
                            }} size='small'>Upload Photo/Video</Button>
                            <LightAvatar variant='rounded' onClick={() => {
                                navigate("/edit-room", {
                                    state: {
                                        channel_id: groupProfileId
                                    },
                                    // replace: true
                                })
                            }}>
                                <CreateOutlined fontSize='small' sx={{ color: theme.palette.dark.light }} />
                            </LightAvatar>
                            <LightAvatar variant='rounded' onClick={() => handleCopyLink(`https://www.emotiontown.com/channel-profile/${groupProfileId}`)}>
                                <ContentCopy fontSize='small' sx={{ color: theme.palette.dark.light }} />
                            </LightAvatar>
                        </Box>
                    }
                </Box>

                <Divider />
                <Box sx={{ px: 2 }}>
                    <Title3 my={1}>About</Title3>
                    {/* <SubTitle2>{roomDetails.description}</SubTitle2> */}
                    {(roomDetails.description?.length > 250 && !isShowMore) ?
                        <>
                            <SubTitle2 lineHeight="18px" sx={{ whiteSpace: 'pre-line' }}>
                                {roomDetails.description?.substring(0, 250)}...
                            </SubTitle2>
                            <SubTitle2 component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</SubTitle2>
                        </>
                        :
                        <>
                            {
                                <SubTitle2 lineHeight="18px" sx={{ whiteSpace: 'pre-line' }}>
                                    {roomDetails.description}
                                </SubTitle2>
                            }
                            {
                                roomDetails.description?.length > 250 ?
                                    <SubTitle2 sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</SubTitle2> :
                                    null
                            }
                        </>
                    }
                </Box>

                <Box sx={{ backgroundColor: 'white', display: 'flex', alignItems: "center", justifyContent: "flex-start", gap: 1, pt: 1, position: 'sticky', top: { xs: '53px', sm: '63px' }, borderTop: '1px solid #EAEAF2', borderBottom: '1px solid #EAEAF2', zIndex: 4, pl: 2, mt: 2 }}>
                    {/* <Chip variant='outlined' label='All' />
                <Chip variant='filled' label='Popular' />
                <Chip variant='outlined' label='Series' /> */}
                    {/* <Chip variant='outlined' label='Article' />
                <Chip variant='outlined' label='Post' />
                <Chip variant='outlined' label='About' /> */}
                    <Box sx={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'center', padding: '8px', minWidth: '80px', borderBottom: showItem === 0 ? '3px solid #10ac84' : '3px solid white', color: showItem === 0 ? theme.palette.dark.light : theme.palette.disabled.main, cursor: 'pointer' }} onClick={() => setShowItem(0)}>Article</Box>
                    <Box sx={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'center', padding: '8px', minWidth: '80px', borderBottom: showItem === 1 ? '3px solid #10ac84' : '3px solid white', color: showItem === 1 ? theme.palette.dark.light : theme.palette.disabled.main, cursor: 'pointer' }} onClick={() => setShowItem(1)}>Post</Box>
                </Box>
            </>
            }
            <Box sx={{ px: { xs: 0, md: 3 }, py: 2 }}>
                {
                    (showItem === 0) &&
                    <Suspense fallback={<div>Loading...</div>}>
                        <ChannelPost channel_id={groupProfileId} role={roomDetails.role} />
                    </Suspense>
                }
                {
                    (showItem === 1) &&
                    <Suspense fallback={<div>Loading...</div>}>
                        <ChannelSimplePostFeed channel_id={groupProfileId} />
                    </Suspense>
                }
                {/* <Box sx={{}}>
                        <Box sx={{ height: '160px' }}>
                            <img src='https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                        <Box sx={{ padding: '8px' }}>
                            <Title3>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Title3>
                            <SubTitle2>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </SubTitle2>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box> */}
                {/* <Box sx={{}}>
                        <Box sx={{ height: '160px' }}>
                            <img src='https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                        <Box sx={{ padding: '8px' }}>
                            <Title2>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Title2>
                            <SubTitle2>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </SubTitle2>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box> */}
                {/* <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                <Title>Code With Vishal</Title>
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/2804327/pexels-photo-2804327.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px' }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                Code With Vishal
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/50707/cocoa-man-colombia-peasant-50707.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px' }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                Code With Vishal
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/2787829/pexels-photo-2787829.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px' }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                Code With Vishal
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/2832382/pexels-photo-2832382.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px' }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                Code With Vishal
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/3944091/pexels-photo-3944091.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px' }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                Code With Vishal
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', marginBottom: '10px', borderRadius: '10px', width: 'calc(50% - 15px)', backgroundColor: theme.palette.light.light }}>
                        <img src="https://images.pexels.com/photos/1552242/pexels-photo-1552242.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: '100%', height: "200px", objectFit: "cover" }} />
                        <Box sx={{ padding: '8px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px' }}>
                                <Avatar sx={{ height: 20, width: 20 }} src="https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                                Code With Vishal
                            </Box>
                            <Typography fontSize='14px' fontWeight={600}>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquid, provident!
                            </Typography>
                            <Typography fontSize='12px'>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit eligendi neque similique vel quidem autem.
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='caption'>1.2k views</Typography>
                                <Typography variant='caption'>3 years ago</Typography>
                            </Box>
                        </Box>
                    </Box> */}
            </Box>
        </Box>
    )
}

export default RoomProfile