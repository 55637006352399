import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Collapse, Divider, Skeleton, TextField, Typography } from '@mui/material'
import LikeOutline from '@mui/icons-material/FavoriteBorder';
import Like from '@mui/icons-material/Favorite';
// import Comment from '@mui/icons-material/ChatBubble';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import CancelShare from '@mui/icons-material/SyncDisabled';
import Share from '@mui/icons-material/Loop';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import React, { useRef } from 'react'
import CardTopHeader from './CardTopHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import moment from 'moment/moment';
import Comment from './Comment';
import { entities } from "../HtmlEntity"
import { setOpenUaDialog } from '../../store/groupSlice';
import AllPostCardTopHeader from '../AllPost/AllPostCardTopHeader';
import { editPostDescription, increasePostCommentCount, setCurrentPostInfo, setFullPostDetails, setOpenBookmarkDialog, setOpenReactDialog, setPostToSave, setReactOnPost, setReactPostInfo, setRemovePostFromFolder, setRemovePostFromSavedFeed, setfileList } from '../../store/postSlice';
import { FilledButton, SubTitle1, SubTitle3 } from '../../CustomComponents';
import SharePostMenu from '../Menu/SharePostMenu';
import { BookmarkAddOutlined, BookmarkAddRounded, BookmarkAddedRounded, CommentRounded, FavoriteRounded } from '@mui/icons-material';
import theme from '../../Theme';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const MultiLovecard = ({ val, value }) => {
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false)
    const [description, setDescription] = React.useState()
    const [isEditable, setisEditable] = React.useState(false)
    const [comment, setComment] = React.useState("")
    const [newComment, setNewComment] = React.useState({})
    const [totalComment, setTotalComment] = React.useState(0)
    const [files, setFiles] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
    const [is_saved, set_is_saved] = React.useState(false)
    const [is_commented, set_is_commented] = React.useState(false)
    const [is_liked, set_is_liked] = React.useState(false)
    const [isPinned, setIsPinned] = React.useState(null)
    const [totalLiked, setTotalLiked] = React.useState(0)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [updating, setUpdating] = React.useState(false)
    const { role } = useSelector(state => state.group.groupShortInfo)
    const socket = useOutletContext()
    // const [lineCount, setLineCount] = React.useState(0);
    const dispatch = useDispatch()
    const openOptionMenu = Boolean(anchorE2)
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user")) || {}
    const token = JSON.parse(localStorage.getItem('token'))
    const navigate = useNavigate()
    const location = useLocation()
    const ref = useRef(null)
    React.useEffect(() => {
        setTotalComment(val.total_comment)
        // set_is_saved(val.is_saved)
        if (val.is_commented) {
            if (val.is_commented !== null) {
                set_is_commented(true)
            } else {
                set_is_commented(false)
            }
        } else {
            set_is_commented(false)
        }
        if (val.react !== null) {
            set_is_liked(true)
        }
        if (val.total_react !== 0) {
            setTotalLiked(val.total_react)
        }
        if (val.is_pinned !== null) {
            setIsPinned(val.is_pinned)
        }
        if (val.description && val.description.length > 0) {
            setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
        }
        if (files.length <= 0 && val.fileList && val.fileList.length > 1) {
            setFiles(val.fileList)
        }
    }, [])

    React.useEffect(() => {
        if (location.pathname.split('/')[1] === 'saved-post' || (val.is_saved && val.is_saved !== null)) {
            set_is_saved(true)
        } else {
            set_is_saved(false)
        }
    }, [val.is_saved])

    const toggleReadMore = () => setIsShowMore((show) => !show);

    const handleRemovePost = () => {
        set_is_saved(false)
    }

    const callBackFunction = (entries) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    const options = {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(callBackFunction, options)
        if (ref.current) {
            // observer.observe(refs)
            observer.observe(ref.current)
            if (isVisible) {
                if (!val.fileList && loading === false) {
                    setLoading(true)
                    axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/post_all_files/${val.xz12uied7}`)
                        .then(response => {
                            setLoading(false)
                            if (response.data.status === 1) {
                                setFiles(response.data.data)
                                dispatch(setfileList({ id: val.xz12uied7, files: response.data.data, feed_value: value }))
                            } else {
                                // show not found poll item
                            }
                        }).catch(error => {
                            setLoading(false)
                            dispatch(setSnackMessage("Something went wrong. Try later."))
                            dispatch(setOpenSnack(true))
                        })
                }
            }
        }
        return () => observer.disconnect()
    }, [ref, options])


    const handleCommentSubmit = (e) => {
        e.preventDefault()
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if(comment.trim() == '') {
            return
        }
        setCreateCommentStatus('loading')
        var notification_message = ''
        if (val.group_id && val.group_name) {
            notification_message = `comments on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`
        } else {
            notification_message = `comments on your post`
        }
        const data = { post_id: val.xz12uied7, group_id: val.group_id, comment, message: notification_message, user_to_notify: val.u_id, from_where: val.type, topic: 'postComment' }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                const new_comment = {
                    comment_id: response.data.comment_id,
                    comment,
                    added_on: moment().format(),
                    user_id,
                    profile_pic,
                    total_reply: 0,
                    total_reacted: 0,
                    is_reacted: null,
                    name
                }
                setNewComment(new_comment)
                set_is_commented(true)
                setTotalComment(totalComment + 1)
                // if (value === 'pin') {
                //     dispatch(increasePinPostCommentCount(val.xz12uied7))
                // } else if (value === 'saved') {
                //     dispatch(increaseSavePostCommentCount(val.xz12uied7))
                // } else if (value === 'myPost') {
                //     dispatch(increaseMyPostCommentCount(val.xz12uied7))
                // } else {
                // }
                dispatch(increasePostCommentCount({ feed_value: value, data: val.xz12uied7 }))
                if (response.data.status === 1) {
                    if (val.creator_id !== user_id) {
                        socket?.emit('commentOnPost', { receiverid: val.u_id })
                    }
                }
                setCreateCommentStatus('ok')
            }).catch(error => {
                setCreateCommentStatus('error')
            })
        // dispatch(submitComment(data))
        setComment("")
    };

    const fullImageHandler = () => {
        dispatch(setFullPostDetails(files))
        navigate(`/full-multi-image`)
    }

    const handleVisitSharedPeopleProfile = (shared_by_user_id, shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // navigate(`/profile/${shared_by_user_id}/${shared_from_group_id}`)
        navigate("/profile", {
            state: {
                group_id: shared_from_group_id,
                user_id: shared_by_user_id
            },
            // replace: true
        })
    }
    const handleVisitSharedGroupProfile = (shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        navigate(`/group-profile/${shared_from_group_id}`)
    }

    const handleSharePost = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (val.group_type === 'public') {
            dispatch(setPostToSave({ feed_value: value, data: val.xz12uied7 }))
            navigate("/shared-to-group", {
                state: {
                    value: value
                },
            })
        } else {
            dispatch(setSnackMessage(`You can't share from a ${val.type} group.`))
            dispatch(setOpenSnack(true))
        }
    }

    const handleUpdateDescription = (e) => {
        e.preventDefault()
        setUpdating(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/post/updatePost`, { post_id: val.xz12uied7, description }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setisEditable(false)
                setUpdating(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                // if (value === 'pin') {
                //     dispatch(editPinPostDescription({ post_id: val.xz12uied7, description }))
                // } else if (value === 'saved') {
                //     dispatch(editSavePostDescription({ post_id: val.xz12uied7, description }))
                // } else if (value === 'myPost') {
                //     dispatch(editMyPostDescription({ post_id: val.xz12uied7, description }))
                // } else {
                // }
                dispatch(editPostDescription({ feed_value: value, post_id: val.xz12uied7, description }))
            }).catch(error => {
                setUpdating(false)
                dispatch(setSnackMessage("Something went wrong. Try later."))
                dispatch(setOpenSnack(true))
            })
    }

    const removeFromFolder = (now_post_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (value !== 'saved') {
            handleRemovePost()
        }
        if (value === 'saved') {
            dispatch(setRemovePostFromSavedFeed({ data: now_post_id }))
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/removed_saved_post/${now_post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    // if (value === 'pin') {
                    //     dispatch(setRemovePinPostFromFolder(now_post_id))
                    // } else if (value === 'saved') {

                    // } else if (value === 'myPost') {
                    //     dispatch(setRemoveMyPostFromFolder(now_post_id))
                    // } else {
                    // }
                    if (value !== 'saved') {
                        dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
                    }
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                } else {
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }

    const handleReaction = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        const val_copy = { ...val }
        // if (val.react) {
        if (is_liked) {
            // remove reaction 
            val_copy.react = 0
            val_copy.total_react = (val_copy.total_react - 1)
            // val_copy.my_reaction = 0
            set_is_liked(false)
            setTotalLiked(totalLiked - 1)
            axios.delete(`${process.env.REACT_APP_API_URL}/api/post/remove/react_post/${val.xz12uied7}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    dispatch(setReactOnPost({ feed_value: value, id: val.xz12uied7, val_copy }))
                }).catch(error => {
                })
        } else {
            // create reaction
            val_copy.react = 1
            val_copy.total_react = (val_copy.total_react + 1)
            set_is_liked(true)
            setTotalLiked(totalLiked + 1)
            var notification_message = ''
            if (val.group_id && val.group_name) {
                notification_message = `reacted on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`
            } else {
                notification_message = `reacted on your post`
            }
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/react_post`, { post_id: val.xz12uied7, react_value: 1, group_id: val.group_id, post_type: 'love', message: notification_message, notified_user_id: val.u_id, from_where: val.type, topic: 'postReact' }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    dispatch(setReactOnPost({ feed_value: value, id: val.xz12uied7, val_copy }))
                    if (response.data.status === 1) {
                        if (val.creator_id !== user_id) {
                            socket?.emit("reactOnPost", { receiverid: val.u_id })
                        }
                    }
                }).catch(error => {
                })
        }
    }
    const handleBookmarkPost = (event) => {

        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (location.pathname.split('/')[1] !== 'saved-post' && val.is_saved === null) {
            dispatch(setCurrentPostInfo({ post_item_id: val.xzu34ie0d98, feed_value: value }))
            dispatch(setOpenBookmarkDialog(true))
        } else {
            removeFromFolder(val.xzu34ie0d98)
        }
    }

    const goToReactDetails = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (totalLiked === 1 && is_liked !== false) {
            dispatch(setSnackMessage("Only you react on this post."))
            dispatch(setOpenSnack(true))
            return
        }
        if (totalLiked > 0) {
            var post_group_id;
            if (val.group_id) {
                post_group_id = val.group_id
            } else {
                post_group_id = 0
            }
            dispatch(setReactPostInfo({ total_react: totalLiked, post_id: val.xz12uied7, post_type: 'love', group_id: post_group_id }))
            dispatch(setOpenReactDialog(true))
        }
    }

    return (
        <>
            <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type={val.group_type} type={val.type} post_id={val.xz12uied7} value={value} group_id={val.group_id} creator_id={val.creator_id} group_name={val.group_name} />
            <Card elevation={0} ref={ref} sx={{ backgroundColor: val.is_colored === 'no' ? 'white' : val.role === 'Super Admin' ? '#d2f9de' : val.role === 'Admin' ? '#e6eeff' : 'white', width: '100%', marginBottom: 2, borderRadius: { xs: '0px', sm: '10px' }, border: '1px solid #E4E9F2' }}>
                {
                    (val.shared_by && val.shared_by_name !== val.name) &&
                    <>
                        {
                            (val.shared_from_group_id === 'null' || val.shared_from_group_id === null) ?
                                <SubTitle1 padding='10px' sx={{ backgroundColor: '#F7F9FC', color: theme.palette.dark.main }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, 0)}>{val.shared_by_name}</strong>
                                </SubTitle1> :
                                <SubTitle1 padding='10px' sx={{ backgroundColor: '#F7F9FC', color: theme.palette.dark.main }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
                                </SubTitle1>
                        }
                        <Divider />
                    </>
                }
                {/* {
                    (val.group_id) ?
                        <AllPostCardTopHeader isPinned={isPinned} post_type='love' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} group_name={val.group_name} group_profile_pic={val.group_profile_pic} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} file_count={val.file_count} shared_from_group_id={val.shared_from_group_id}/> :
                        <CardTopHeader isPinned={isPinned} post_type='love' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} file_count={val.file_count} />
                } */}
                {
                    (val.group_id && value !== 'group_feed' && value !== 'pin_post_feed' && value !== 'group_post' && value !== 'myPost') ?
                        <AllPostCardTopHeader isPinned={isPinned} status={val.status} post_type='love' group_type={val.group_type} setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} group_name={val.group_name} group_profile_pic={val.group_profile_pic} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} file_count={val.file_count} shared_from_group_id={val.shared_from_group_id} /> :
                        <CardTopHeader isPinned={isPinned} status={val.status} post_type='love' group_type={val.group_type} setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} file_count={val.file_count} />
                }
                <CardContent sx={{ pt: 0, pb: 1 }}>
                    {(description && description.length > 250 && !isShowMore) ?
                        <>
                            <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                                {description.substring(0, 250)}...
                            </Typography>
                            <Typography component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</Typography>
                        </>
                        :
                        <>
                            {
                                <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                                    {description}
                                </Typography>
                            }
                            {
                                description && description.length > 250 ?
                                    <Typography sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</Typography> :
                                    null
                            }
                        </>
                    }
                    {
                        isEditable &&
                        <Box component='form' onSubmit={handleUpdateDescription}>
                            <TextField
                                fullWidth
                                value={description}
                                id="edit_basic_post_dialog_textfield_id"
                                label="edit post description"
                                multiline
                                rows={2}
                                name="edit_description_id"
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ mb: 1, mt: 2 }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Button variant="contained" color='light' sx={{ color: theme.palette.dark.light }} onClick={() => setisEditable(false)}>Cancel</Button>
                                <Box sx={{ position: 'relative', ml: 1 }}>
                                    <Button type='submit' disabled={(!description || updating) ? true : false} variant="contained">Update</Button>
                                    {updating && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#44bd32',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        (val.link) &&
                        <Box>
                            {(val.link && val.link.length > 100) ?
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                    {val.link.substring(0, 100)} ...
                                </a> :
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                    {val.link}
                                </a>
                            }
                        </Box>
                    }
                </CardContent>
                {(files.length > 1 && loading === false) ?
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                        <Box sx={{ flex: 1, height: '350px', backgroundColor: '#2C3A47' }} onClick={fullImageHandler}>
                            <img src={files[0].org_file} alt="no pic found" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                        <Box sx={{ flex: 1, height: "350px", }}>
                            <Box sx={{ height: files.length > 2 ? '170px' : '350px', marginBottom: '10px', backgroundColor: '#2C3A47' }} onClick={fullImageHandler}>
                                <img src={files[1].org_file} alt="no pic found" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </Box>
                            {
                                (files.length > 2) &&
                                <Box sx={{ position: 'relative', height: '170px', backgroundColor: '#2C3A47' }} onClick={fullImageHandler}>
                                    {
                                        (files.length > 3) &&
                                        <Box sx={{ width: '100%', cursor: 'pointer', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', fontSize: "30px", fontWeight: 700, color: 'white', position: 'absolute', top: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {val.file_count - 3} + posts
                                        </Box>
                                    }
                                    <img src={files[2].org_file} alt="no pic found" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                            }
                        </Box>
                    </Box> :
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Skeleton animation='wave' variant='rectangular' width='100%'>
                            <Box sx={{ height: '350px' }}></Box>
                        </Skeleton>
                        <Skeleton animation='wave' variant='rectangular' width='100%'>
                            <Box sx={{ height: '350px' }}></Box>
                        </Skeleton>
                    </Box>
                }
                <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                            {/* {
                            (is_liked) ?
                                <Like fontSize='small' sx={{ marginRight: '5px', color: "red" }} onClick={handleReaction} /> :
                                <LikeOutline fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} onClick={handleReaction} />
                        } */}
                            <Checkbox
                                checked={is_liked || false}
                                onChange={handleReaction}
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<FavoriteRounded fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                                checkedIcon={<FavoriteRounded color='redish' fontSize='small' />}
                            />
                            <SubTitle3 color='dark' sx={{ cursor: 'pointer' }} onClick={goToReactDetails}>
                                {totalLiked} Likes
                            </SubTitle3>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                            setExpanded(!expanded)
                        }}>
                            <Checkbox
                                checked={is_commented || false}
                                // onChange={handleReaction}
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<CommentIcon fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                                checkedIcon={<CommentRounded color='redish' fontSize='small' />}
                            />
                            <SubTitle3 color='dark' sx={{ cursor: 'pointer' }} >
                                {totalComment} Comments
                            </SubTitle3>
                        </Box>
                        {
                            (val.group_type !== 'private' && val.group_type !== 'protected') &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(event) => {
                                if (!token) {
                                    dispatch(setOpenUaDialog(true))
                                    return
                                }
                                dispatch(setCurrentPostInfo({ feed_value: value }))
                                setAnchorE2(event.currentTarget)
                            }
                            }
                            >
                                <Share fontSize='small' sx={{ marginRight: '5px', color: theme.palette.light.dark }} />
                                <SubTitle3 color='dark' sx={{ cursor: 'pointer' }} >
                                    Shares
                                    {/* {val.total_share} Shares */}
                                </SubTitle3>
                            </Box>
                        }
                        <Checkbox
                            checked={is_saved || false}
                            onChange={handleBookmarkPost}
                            inputProps={{ 'aria-label': 'controlled' }}
                            icon={<BookmarkAddRounded fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                            checkedIcon={<BookmarkAddedRounded color='dark' fontSize='small' />}
                        />
                        {/* {
                        is_saved ?
                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => removeFromFolder(val.xz12uied7)}>
                                <BookmarkAddedIcon fontSize='small' sx={{ mr: 1, color: "#2d3436" }} />
                            </Box> :
                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                                if (!token) {
                                    dispatch(setOpenUaDialog(true))
                                    return
                                }
                                set_is_saved(true)
                                navigate(`/folders/${val.xz12uied7}/${val.group_id}/${value}`)
                            }
                            }
                            >
                                <BookmarkAddOutlinedIcon fontSize='small' sx={{ mr: 1, color: "#2d3436" }} />
                            </Box>
                    } */}
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                </CardActions>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                        display: 'flex',
                        margin: '6px 10px 10px'
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleCommentSubmit}
                >
                    <Avatar variant='rounded' src={profile_pic} sx={{ width: 32, height: 32 }} />
                    <input
                        id="basic_comment_textarea_id"
                        value={comment}
                        placeholder='comment something'
                        style={{
                            backgroundColor: (val.is_colored === 'yes' && val.role !== 'General') ? 'white' : theme.palette.light.light,
                            padding: '8px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            borderRadius: '6px',
                            margin: "0px 10px"
                        }}
                        name="description"
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Box sx={{ position: 'relative' }}>
                        <FilledButton type='submit' size='small' variant="contained" disabled={(createCommentStatus === 'loading') ? true : false} disableElevation>Send</FilledButton>

                        {(createCommentStatus === 'loading') && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: '0px 8px 24px' }}>
                        {
                            (!token) ?
                                <Typography mt={1} sx={{ textAlign: 'center', fontWeight: 600, color: '#2d3436', fontSize: '14px' }}>Sign In for read comments</Typography> :
                                <Comment post_id={val.xz12uied7} role={val.role} is_colored={val.is_colored} value={value} newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} type={val.type} />
                        }
                    </CardContent>
                </Collapse>
            </Card>
        </>
    )
}

export default MultiLovecard