import { Avatar, Box, Button, CircularProgress, Collapse, TextField, Typography } from '@mui/material'
import React from 'react'
import Reply from './Reply'
import axios from 'axios'
import ClapOutlined from '@mui/icons-material/SignLanguageOutlined';
import Clap from '@mui/icons-material/SignLanguage';
import Delete from '@mui/icons-material/Delete'
import moment from 'moment/moment'
import { entities } from "../HtmlEntity"
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import theme from '../../Theme';
import { FilledButton } from '../../CustomComponents';

const CommentItem = ({ comment, role, is_colored, deleteCommentId, deleteComment, token, user_id, profile_pic, name, post_id,type }) => {

    const [newReply, setNewReply] = React.useState('')
    const [expanded, setExpanded] = React.useState(false);
    const [reply, setReply] = React.useState('')
    const [is_reacted, set_is_reacted] = React.useState(false)
    const [total_reacted, set_total_reacted] = React.useState(0)
    const [createReplyStatus, setCreateReplyStatus] = React.useState('ok') // loading, error
    const [total_replys, set_total_replys] = React.useState(0)
    const { name: group_name } = useSelector(state => state.group.groupShortInfo)
    const socket = useOutletContext()

    React.useEffect(() => {
        set_total_replys(comment.total_reply)
        set_total_reacted(comment.total_reacted)
        if (comment.is_reacted === null) {
            set_is_reacted(false)
        } else {
            set_is_reacted(true)
        }
    }, [])

    const handleUnClap = () => {
        set_is_reacted(!is_reacted)
        set_total_reacted(total_reacted - 1)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/commentAction/${comment.comment_id}/${post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                // setCreateReplyStatus('ok')
            }).catch(error => {
                // setCreateReplyStatus('error')
            })
    }

    const handleClap = () => {
        set_is_reacted(!is_reacted)
        set_total_reacted(total_reacted + 1)
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/commentAction`, { comment_id: comment.comment_id, message: `clapped on your comment`, post_id, notified_user_id: comment.user_id,from_where:type,topic:'commentReplyReact' }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                // setCreateReplyStatus('ok')
                if (comment.user_id !== user_id) {
                    socket?.emit('reactOnComment', { receiverid: comment.user_id })
                }
            }).catch(error => {
                // setCreateReplyStatus('error')
            })
    }

    const handleReplySubmit = (e) => {
        e.preventDefault()
        setCreateReplyStatus('loading')
        // const data = { comment_id: comment.comment_id, reply, message: `replied on your comment in ${group_name} group`, post_id, notified_user_id: comment.user_id,from_where:type,topic:'commentReply' }
        const data = { comment_id: comment.comment_id, reply, message: `replied on your comment`, post_id, notified_user_id: comment.user_id,from_where:type,topic:'commentReply' }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/replyComment`, data, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                const new_reply = {
                    reply_id: response.data.reply_id,
                    reply,
                    added_on: moment().format(),
                    user_id,
                    profile_pic,
                    is_reacted: null,
                    total_reacted: 0,
                    name
                }
                setNewReply(new_reply)
                // set_is_replyed(true)
                set_total_replys(total_replys + 1)
                setCreateReplyStatus('ok')
                if (response.data.status === 1) {
                    if (comment.user_id !== user_id) {
                        socket?.emit('replyOnComment', { receiverid: comment.user_id })
                    }
                }
            }).catch(error => {
                setCreateReplyStatus('error')
            })
        setReply("")
    };

    return (
        <Box><Box key={comment.comment_id} sx={{ display: 'flex', alignItems: 'start', my: 1, backgroundColor: theme.palette.light.light, px: 2, py: 1, borderRadius: '8px', width: '82%' }}>
            <Avatar src={comment.profile_pic} sx={{ width: 30, height: 30 }} />
            <Box sx={{ ml: 2, flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <Typography mr={1} sx={{ fontSize: '12px', fontWeight: '600' }}>{comment.name.replace(/&#?\w+;/g, match => entities[match])}</Typography>
                    {
                        (user_id === comment.user_id) ?
                            (deleteCommentId === comment.comment_id) ? <CircularProgress size={24} sx={{ color: '#44bd32', }} /> : <Delete fontSize='small' color='primary' onClick={() => deleteComment(comment.comment_id)} /> : null
                    }
                </Box>
                <Typography color='#4b4b4b' fontSize='12px'>{comment.comment.replace(/&#?\w+;/g, match => entities[match])}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize='12px' color="#2f3537">{total_reacted}</Typography>
                        {
                            is_reacted ?
                                <Clap fontSize='small' sx={{ color: theme.palette.primary.main, marginLeft: '6px' }} onClick={handleUnClap} /> :
                                <ClapOutlined fontSize='small' sx={{ color: theme.palette.primary.main, marginLeft: '6px' }} onClick={handleClap} />
                        }
                    </Box>
                    <Typography fontSize='12px' color="#2f3537" mx={3} sx={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>{total_replys} reply</Typography>
                    <Typography fontSize='10px' color="#636e72" sx={{ marginLeft: 'auto' }}>{moment(comment.added_on).fromNow()}</Typography>
                </Box>
            </Box>
        </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        margin: '4px 0px',
                        gap:1
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleReplySubmit}
                >
                    {/* <TextField
                        size='small'
                        fullWidth
                        value={reply}
                        id="basic_reply_textarea_id"
                        placeholder='Reply something...'
                        multiline
                        maxRows={2}
                        sx={{
                            backgroundColor: (is_colored === 'yes' && role !== 'General') ? 'white' : '#f1f2f3',
                        }}
                        name="reply"
                        onChange={(e) => setReply(e.target.value)}
                    /> */}
                    <input
                        id="basic_reply_textarea_id"
                        size='small'
                        value={reply}
                        placeholder='reply something'
                        style={{
                            backgroundColor: (is_colored === 'yes' && role !== 'General') ? 'white' : theme.palette.light.light,
                            padding: '8px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            borderRadius: '6px',
                        }}
                        name="reply"
                        onChange={(e) => setReply(e.target.value)}
                    />
                    <Box sx={{ position: 'relative' }}>
                        {/* <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={loading ? true : (basicOrRatedPostData.file !== '' || basicOrRatedPostData.description !== '') ? false : true} disableElevation>Post</Button> */}
                        <FilledButton type='submit' size='small' variant="contained" sx={{ textTransform: 'capitalize' }} disabled={(createReplyStatus === 'loading') ? true : false} disableElevation>Send</FilledButton>

                        {(createReplyStatus === 'loading') && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Reply comment_id={comment.comment_id} newReply={newReply} setNewReply={setNewReply} total_replys={total_replys} set_total_replys={set_total_replys} post_id={post_id} type={type} commented_by={comment.user_id}/>
            </Collapse>
        </Box>
    )
}

export default CommentItem