import { Box, Typography, CircularProgress, Avatar, Button, Divider } from '@mui/material';
import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { setHasInvitation } from '../store/authSlice';
import Error from './status/Error';
import Loading from './status/Loading'
import NotFound from './status/NotFound';
import {entities} from './HtmlEntity'
import ReactGA from 'react-ga4'

const Invitations = () => {
  const [invitations, setInvitations] = React.useState([])
  const [status, setStatus] = React.useState('ok')
  const token = JSON.parse(localStorage.getItem('token'))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  React.useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
  },[])
  React.useEffect(() => {
    const getInvitations = async () => {
      if (invitations.length <= 0) {
        setStatus('loading')
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/group/my_all_invitation`, { headers: { authorization: "Bearer " + token } });
          if(res.data.data.length === 0) {
            setStatus('empty')
            dispatch(setHasInvitation(0))
            return
          }else{
            dispatch(setHasInvitation(1))
          }
          setInvitations(res.data.data)
          setStatus('ok')
        } catch (error) {
          setStatus('error')
        }
      }
    }
    getInvitations()
  }, [])

  const acceptInvitation = () => {

  }
  const showGroup = (group_id) => {
    navigate(`/group-profile/${group_id}`)
  }
  return (
    <Box sx={{p:2,backgroundColor:invitations.length > 0 ? 'white' : 'transparent',mt:2,borderRadius:'10px'}}>
      {
        (invitations.length > 0) &&
        <Box sx={{mb:2,pb:1,borderBottom:'1px solid #c0c0d8'}}>
        <Typography fontSize='22px' fontWeight={600} color='primary'>Your Invitations</Typography>
        </Box>
      }
      {
        (status === 'loading') ? <Loading /> : (status === 'error') ? <Error /> :(status === 'empty')? <NotFound data="No Invitation found" subData="No invitation found.Please try again later."/>:
          invitations.map((invitation, index) => {
            return <Box key={invitation.group_id} sx={{ display: 'flex', alignItems: 'start', mb: 2, backgroundColor: '#e7e6ff', p: 2, borderRadius: '8px' }} onClick={() => showGroup(invitation.group_id)}>
              <Avatar variant='rounded' alt={invitation.name} src={invitation.group_profile_pic} sx={{ width: 30, height: 30 }} >{invitation.name.charAt(0).toUpperCase()}</Avatar>
              <Box sx={{ flexGrow: 1, ml: 2, display: 'flex', alignItems: 'start' }}>
                <Box sx={{}}>
                  <Typography variant='body2' fontWeight={600}>{invitation.name.replace(/&#?\w+;/g, match => entities[match])}<span style={{ fontWeight: 400, backgroundColor: '#ff4d4d', color: 'white', display: 'inline-block', marginLeft: '2px', borderRadius: '3px', padding: '0px 2px', fontSize: '12px' }}>{invitation.group_type}</span></Typography>
                  <Typography variant='caption'>{invitation.total_invitation_from_group} member{invitation.total_invitation_from_group > 1 ? 's' : ''} invited</Typography>
                </Box>
                {/* {
                                            (true) ?
                                            (false) ? <CircularProgress size={24} sx={{ color: '#44bd32', }} /> : <Button variant="contained" size="small" onClick={() => acceptInvitation(1)}>Accept</Button> : null
                                        } */}
              </Box>
            </Box>
          })
      }
    </Box>
  )
}

export default Invitations