import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Protected from '@mui/icons-material/VpnLock';
import Private from '@mui/icons-material/Security';
import Public from '@mui/icons-material/Public';
// import HomeIcon from '@mui/icons-material/HomeWork';
// import CarIcon from '@mui/icons-material/LocalShipping';
// import PetsIcon from '@mui/icons-material/Pets';
import styled from '@emotion/styled';
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setCreateGroupType } from '../../../store/groupSlice'
import { style } from '@mui/system';
import ReactGA from 'react-ga4'

const ColorButton = styled(Button)((theme) => ({
    backgroundColor: 'rgba(64,53,151,0.9)',
    borderRadius: 0,
    color: 'white',
    display: 'block',
    margin: 'auto',
    marginTop: '40px',
    '&:hover': {
        backgroundColor: 'rgb(64,53,151)',
    }
}))
const BorderButton = styled(Button)((theme) => ({
    border: '1px solid rgba(64,53,151,0.9)',
    borderRadius: 0,
    color: 'rgba(64,53,151,0.9)',
    display: 'block',
    margin: 'auto',
    marginTop: '40px',
    // '&:hover': {
    //     backgroundColor: 'rgb(64,53,151)',
    // }
}))
const NextButton = styled(Button)((theme) => ({
    fontWeight: '600',
    backgroundColor: 'rgba(64,53,151,0.2)',
    borderRadius: 0,
    color: 'rgb(64,53,151)',
    marginTop: '20px',
    // display:'inline-block',
    padding: '12px 22px',
    '&:hover': {
        backgroundColor: 'rgba(64,53,151,0.5)',
    }
}))

const PaperItem = styled(Paper)((theme) => ({
    padding: '60px 20px 20px',
    // display:'flex',
    // flexDirection:'column',
    textAlign: 'center',
    // backgroundColor:'coral',
    // width:'calc(100% - 100px)'
}))

const GroupType = () => {
    const [item, setItem] = React.useState('public')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleNextButtonClicked = () => {
        ReactGA.event({
            category:'create group',
            action:'choosing group type',
            label: `choosing ${item} group`
          })
        if(item) {
            dispatch(setCreateGroupType(item))
            navigate(`/create-group`)
        }
    }

    return (
        <Box maxWidth='xs' sx={{ bgcolor: 'white', padding: '10px 16px 20px' }}>
            <Box className="heading_container">
                {/* <Typography variant='body1'>We love to hear from our happy customers</Typography> */}
                <Typography variant='h5' fontWeight='600' my={2}>Choose group type</Typography>
            </Box>
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <PaperItem variant='outlined' sx={{ bgcolor: 'rgba(64,53,151,0.05)' }}>
                    <Public sx={{ color: 'rgba(64,53,151,0.5)' }} />
                    <Typography variant='h6' fontWeight='700' color='rgb(64,53,151)'>Public</Typography>
                    <Typography variant='caption'>No permission need to join group</Typography>
                    {
                        item === 'public' ? <ColorButton onClick={() => setItem(null)} >Choose</ColorButton> : <BorderButton onClick={() => setItem('public')} >Choose</BorderButton>
                    }
                </PaperItem>
                <PaperItem variant='outlined' sx={{ margin: '0px 10px', bgcolor: 'rgba(64,53,151,0.05)' }}>
                    <Protected sx={{ color: 'rgba(64,53,151,0.5)' }} />
                    <Typography variant='h6' fontWeight='700' color='rgb(64,53,151)'>Protected</Typography>
                    <Typography variant='caption'>Need permission to Join group</Typography>
                    {
                        item === 'protected' ? <ColorButton onClick={() => setItem(null)} >Choose</ColorButton> : <BorderButton onClick={() => setItem('protected')} >Choose</BorderButton>
                    }
                </PaperItem>
                <PaperItem variant='outlined' sx={{ bgcolor: 'rgba(64,53,151,0.05)' }}>
                    <Private sx={{ color: 'rgba(64,53,151,0.5)' }} />
                    <Typography variant='h6' fontWeight='700' color='rgb(64,53,151)'>Private</Typography>
                    <Typography variant='caption'>Not searchable.Only join by invitation</Typography>
                    {
                        item === 'private' ? <ColorButton onClick={() => setItem(null)} >Choose</ColorButton> : <BorderButton onClick={() => setItem('private')} >Choose</BorderButton>
                    }
                </PaperItem>
            </Box> */}
            <Grid container spacing={2}>
                <Grid item xs={6} lg={4}>
                    <PaperItem variant='outlined' sx={{ bgcolor: 'rgba(64,53,151,0.05)' }}>
                        <Public sx={{ color: 'rgba(64,53,151,0.5)' }} />
                        <Typography variant='h6' fontWeight='700' color='rgb(64,53,151)'>Public</Typography>
                        <Typography variant='caption'>No permission need to join group</Typography>
                        {
                            item === 'public' ? <ColorButton onClick={() => setItem(null)} >Choose</ColorButton> : <BorderButton onClick={() => setItem('public')} >Choose</BorderButton>
                        }
                    </PaperItem>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <PaperItem variant='outlined' sx={{ margin: '0px', bgcolor: 'rgba(64,53,151,0.05)' }}>
                        <Protected sx={{ color: 'rgba(64,53,151,0.5)' }} />
                        <Typography variant='h6' fontWeight='700' color='rgb(64,53,151)'>Protected</Typography>
                        <Typography variant='caption'>Need permission to join this group</Typography>
                        {
                            item === 'protected' ? <ColorButton onClick={() => setItem(null)} >Choose</ColorButton> : <BorderButton onClick={() => setItem('protected')} >Choose</BorderButton>
                        }
                    </PaperItem>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <PaperItem variant='outlined' sx={{ bgcolor: 'rgba(64,53,151,0.05)' }}>
                        <Private sx={{ color: 'rgba(64,53,151,0.5)' }} />
                        <Typography variant='h6' fontWeight='700' color='rgb(64,53,151)'>Private</Typography>
                        <Typography variant='caption'>Not searchable.Only join by invitation</Typography>
                        {
                            item === 'private' ? <ColorButton onClick={() => setItem(null)} >Choose</ColorButton> : <BorderButton onClick={() => setItem('private')} >Choose</BorderButton>
                        }
                    </PaperItem>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <NextButton disabled={item === null ? true : false} disableElevation variant="contained" endIcon={<ArrowRightIcon />} onClick={handleNextButtonClicked}>
                    Next
                </NextButton>
            </Box>
        </Box>
    )
}

export default GroupType
