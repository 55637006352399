import { Box, Button, Card, CardContent, Paper, TextField, Typography, CircularProgress, Switch } from '@mui/material'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentGroupInfo, setCurrentGroupInfoBasicInfo, setCurrentGroupInfoColorOptionsChange, setCurrentGroupInfoGroupProfilePic, setGroupProfilePicInGroupList, setGroupShortInfoColorOptionsChange } from '../../store/groupSlice'
import no_photo from '../../media/images/no_photo.png'
import Protected from '@mui/icons-material/VpnLock';
import Private from '@mui/icons-material/Security';
import Public from '@mui/icons-material/Public';
import { useParams } from 'react-router-dom'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import NotFound from '../status/NotFound'
import Loading from '../status/Loading'
import WentWrong from '../status/WentWrong'
import { entities } from "../HtmlEntity"
import { emptyPostList } from '../../store/postSlice'
import CreateGroupName from '../Grid/CreateGroupName'
import { SubTitle2, SubTitle3, Title1, Title2 } from '../../CustomComponents'
import theme from '../../Theme'
import { AddAPhoto } from '@mui/icons-material'
import ReactGA from 'react-ga4'

const EditGroupProfile = () => {
    const [file, setFile] = React.useState(null)
    const [isGroupProfilePicUploading, setIsGroupProfilePicUploading] = React.useState(false)
    // const [type, setType] = React.useState("")
    const [basicUpdating, setBasicUpdating] = React.useState(false)
    const [colorPostUpdating, setColorPostUpdating] = React.useState(false)
    const [status, setStatus] = React.useState('ok') // ok,error,loading,not_found
    const [typeUpdating, setTypeUpdating] = React.useState('ok')
    const [groupBasicDetails, setGroupBasicDetails] = React.useState({ name: '', description: '', type: '' })
    const [checked, setChecked] = React.useState(false);
    const token = JSON.parse(localStorage.getItem("token"))
    const dispatch = useDispatch()
    // const groupProfileId = useSelector(state => state.group.groupProfileId);
    const groupDetails = useSelector(state => state.group.currentGroupInfo)
    const { id, is_colored } = useSelector(state => state.group.groupShortInfo)
    const { group_id: groupProfileId } = useParams()
    const { uid } = JSON.parse(localStorage.getItem("user"))
    // const currentGroupInfo = useSelector(state => state.group.currentGroupInfo)

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])
      
    useEffect(() => {
        if (groupDetails.group_id !== groupProfileId) {
            // setGroupDetails(currentGroupInfo)
            setStatus('loading')
            axios.get(`${process.env.REACT_APP_API_URL}/api/group/group/${groupProfileId}`, { headers: { authorization: "Bearer " + token } })
                .then(res => {
                    if (res.data.status === 1) {
                        dispatch(setCurrentGroupInfo(res.data.data))
                        setStatus('ok')
                    }
                    if (res.data.status === 0) {
                        dispatch(setSnackMessage(res.data.message))
                        dispatch(setOpenSnack(true))
                        setStatus('not_found')
                    }
                    // setGroupDetails(res.data.data)
                }).catch(error => {
                    setStatus('error')
                })
        }
    }, [])
    React.useEffect(() => {
        setGroupBasicDetails({ name: groupDetails.name.replace(/&#?\w+;/g, match => entities[match]), description: groupDetails.description.replace(/&#?\w+;/g, match => entities[match]), type: groupDetails.group_type })
        if (groupDetails.is_colored === 'yes') {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [groupDetails.group_id])

    const handleBasicDetails = (e) => {
        e.preventDefault()
        setBasicUpdating(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/group/group_basic_details`, { name: groupBasicDetails.name, description: groupBasicDetails.description, id: groupDetails.group_id }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setBasicUpdating(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                dispatch(setCurrentGroupInfoBasicInfo({ name: groupBasicDetails.name, description: groupBasicDetails.description }))
                // }
                // setAboutUpdating('error')
            }).catch(error => {
                setBasicUpdating(false)
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }
    const handleType = (e) => {
        e.preventDefault()
        setTypeUpdating('loading')
        ReactGA.event({
            category: 'change type',
            action: 'change group type',
            label: 'change group type'
          })
        axios.put(`${process.env.REACT_APP_API_URL}/api/group/group_type`, { type: groupBasicDetails.type, old_type: groupDetails.group_type, id: groupDetails.group_id }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setTypeUpdating('ok')
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                // }
            }).catch(error => {
                setTypeUpdating('error')
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }
    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const handleFileChange = (event) => {
        var file_extension = getExtension(event.target.files[0].name)
        if (event.target.files[0].size > 6e6) {
            dispatch(setSnackMessage("Please upload a file smaller than 6 mb."))
            dispatch(setOpenSnack(true))
            return false;
        }
        if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
            setFile(event.target.files[0])
        } else {
            dispatch(setSnackMessage("Sorry, this file format is not allowed.Use only png, jpg, jpeg file."))
            dispatch(setOpenSnack(true))
        }
    }
    const handleFileUpload = (event) => {
        event.preventDefault()
        setIsGroupProfilePicUploading(true)
        const url = `${process.env.REACT_APP_API_URL}/api/group/add/group_profile_pic`;
        const formData = new FormData();
        formData.append('group_profile_pic', file);
        formData.append('fileName', file.name);
        formData.append('id', groupDetails.group_id);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                authorization: "Bearer " + token
            },
        };
        axios.put(url, formData, config).then((response) => {
            const newData = {
                group_profile_pic: response.data.file,
                id: groupDetails.group_id,
            }
            // dispatch(setGroupProfilePicInGroupList(newData))
            dispatch(setGroupProfilePicInGroupList(newData))
            dispatch(setSnackMessage(response.data.message))
            // setGroupDetails({...groupDetails,group_profile_pic:response.data.file})
            dispatch(setCurrentGroupInfoGroupProfilePic({ group_profile_pic: response.data.file, org_group_profile_pic: response.data.org_file }))
            dispatch(setOpenSnack(true))
            setFile(null)
            // setIsDisabled(true)
            setIsGroupProfilePicUploading(false)
        }).catch(error => {
            setIsGroupProfilePicUploading(false)
            dispatch(setSnackMessage("Something went wrong."))
            dispatch(setOpenSnack(true))
        });
    }

    const handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        setGroupBasicDetails(details => ({ ...details, [name]: value }))
    }
    const handleTypeInput = (group_type) => {
        if (groupBasicDetails.type === group_type) {
            setGroupBasicDetails(details => ({ ...details, type: '' }))
        } else {
            setGroupBasicDetails(details => ({ ...details, type: group_type }))
        }
    }
    const handleColorPostChanged = (event) => {
        setColorPostUpdating(true)
        let val;
        if (checked) {
            val = 'no'
        } else {
            val = 'yes'
        }
        axios.put(`${process.env.REACT_APP_API_URL}/api/group/colored_post`, { group_id: groupDetails.group_id, is_colored: val }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.status === 1) {
                    if (val === 'yes') {
                        setChecked(true);
                        dispatch(setCurrentGroupInfoColorOptionsChange('yes'))
                        dispatch(setGroupShortInfoColorOptionsChange('yes'))
                    } else {
                        setChecked(false);
                        dispatch(setCurrentGroupInfoColorOptionsChange('no'))
                        dispatch(setGroupShortInfoColorOptionsChange('no'))
                    }
                }
                setColorPostUpdating(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                if (groupDetails.group_id === id) {
                    dispatch(emptyPostList())
                }
                // }
                // setAboutUpdating('error')
            }).catch(error => {
                setColorPostUpdating(false)
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    };
    return (
        status === 'error' ? <WentWrong /> : status === 'loading' ? <Loading /> : status === 'not_found' ? <NotFound data={"No Data found"} subData={"Sorry, we could not found any data to show or edit.Please try again later."} /> :
            <>
                {/* <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Group Profile Picture</Typography>
                        <Box sx={{ textAlign: 'center' }}>
                            <img src={file !== null ? URL.createObjectURL(file) : groupDetails.group_profile_pic !== null ? groupDetails.group_profile_pic : no_photo} width='100%' height='300px' style={{ backgroundColor: '#eef0f6', borderRadius: '4px', objectFit: (file !== null || groupDetails.group_profile_pic !== null) ? 'cover' : 'contain' }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button variant="contained" component="label" sx={{ width: '49%', margin: '10px auto', textTransform: 'capitalize' }} onChange={handleFileChange}>
                                    Choose
                                    <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                </Button>
                                <Box sx={{ position: 'relative', width: '49%' }}>
                                    <Button type='submit' variant="contained" disabled={(file === null || isGroupProfilePicUploading) ? true : false} component="label" sx={{ width: '100%', margin: '10px auto', textTransform: 'capitalize' }} onClick={handleFileUpload} >
                                        Upload Image
                                    </Button>

                                    {isGroupProfilePicUploading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#44bd32',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper> */}
                {/* ====================================================================== */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box component="form"
                        // onSubmit={handleSubmit}
                        sx={{
                            marginTop: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '8px',
                            border: '1px solid #EAEAF2',
                            padding: '16px'
                        }}
                    >
                        <Title1 mb={1} htmlFor="about-room-field-id">
                        Group Profile Picture
                        </Title1>
                        <Box sx={{ width: '220px', height: '120px', border: '2px dashed darkgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                (groupDetails.group_profile_pic !== null || file !== null) ?
                                    <img style={{ width: '100%', height: '100%', objectFit: (file !== null || groupDetails.group_profile_pic !== null) ? 'cover' : 'contain' }} src={file !== null ? URL.createObjectURL(file) : groupDetails.group_profile_pic !== null ? groupDetails.group_profile_pic : no_photo} /> :
                                    <Box component='label' onChange={handleFileChange}>
                                        <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                                        <AddAPhoto color='dark' />
                                    </Box>
                            }
                            {/* hello */}
                        </Box>
                        <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
                            {
                                (groupDetails.group_profile_pic !== null) &&
                                <Button variant='contained' size='small' type='submit' component="label" sx={{ whiteSpace: 'nowrap' }} onChange={handleFileChange}>
                                    <input hidden accept="image/png,image/jpeg,image/jpg" type="file" />
                                    Choose File
                                </Button>
                            }
                            <Box sx={{ position: 'relative' }}>
                                <Button type='submit' size='small' variant="contained" disabled={(file === null || isGroupProfilePicUploading) ? true : false} component="label" sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }} onClick={handleFileUpload} >
                                    Upload File
                                </Button>

                                {isGroupProfilePicUploading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#44bd32',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
                {/* basic details ######################## */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    // onSubmit={handleBasicDetails}
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Group Details</Typography>
                        <TextField
                            id="group_name_textfield"
                            label="Group name"
                            placeholder="Group name"
                            fullWidth
                            name='name'
                            sx={{ mb: 2 }}
                            value={groupBasicDetails.name}
                            onChange={handleInput}
                        />

                        <TextField
                            id="group_description_textfield"
                            label="Description"
                            rows={4}
                            multiline
                            placeholder="Description"
                            fullWidth
                            name='description'
                            sx={{ mb: 2 }}
                            value={groupBasicDetails.description}
                            onChange={handleInput}
                        />
                        {/* <Box>
                        <Button type='submit' variant='contained'>Save Changes</Button>
                    </Box> */}
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                            <Button type='submit' variant="contained" disabled={(basicUpdating === true || groupBasicDetails.name.length <= 0 || groupBasicDetails.description.length <= 0) ? true : false} component="label" sx={{ width: '100%', margin: '10px auto', textTransform: 'capitalize' }} onClick={handleBasicDetails}>
                                Save Changes
                            </Button>

                            {basicUpdating && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: '#44bd32',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Paper>
                {/* color post */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Post color setting</Typography>
                        <Box sx={{ display: 'flex', alignItems: colorPostUpdating ? 'center' : 'start', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography>Color Post</Typography>
                                <Typography sx={{ fontSize: '11px', color: 'gray' }}>this will change admin and super admin post color. So that, general member can differenciate with other posst</Typography>
                            </Box>
                            <Switch
                                disabled={colorPostUpdating === true ? true : false}
                                checked={checked}
                                onChange={handleColorPostChanged}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />

                        </Box>
                    </Box>
                </Paper> */}

                {/* group type ############################# */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <Box
                        // component="form"
                        sx={{
                            boxSizing: 'border-box',
                            padding: '16px'
                        }}
                        noValidate
                        autoComplete="off"
                        // onSubmit={handleType}
                    >
                        <Typography variant='h6' fontWeight='600' mb={2}>Change Group Type</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexDirection:{xs:'column', md: 'row'}, mb: 2, gap: '10px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', borderRadius: '4px', backgroundColor: (groupBasicDetails.type === 'public') ? theme.palette.primary.main : theme.palette.light.light, flex: 1, padding: '10px' }} onClick={() => handleTypeInput("public")}>
                                <Public fontSize='small' color={(groupBasicDetails.type === 'public') ? 'light' : 'primary'} />
                                <Title2 sx={{ color: (groupBasicDetails.type === 'public') ? 'white' : theme.palette.primary.dark }}>Public</Title2>
                                <SubTitle2 color={(groupBasicDetails.type === 'public') ? 'white' : 'primary'}>No permission need to join group</SubTitle2>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', borderRadius: '4px', backgroundColor: (groupBasicDetails.type === 'protected') ? theme.palette.primary.main : theme.palette.light.light, flex: 1, padding: '10px' }} onClick={() => handleTypeInput("protected")}>
                                <Protected fontSize='small' color={(groupBasicDetails.type === 'protected') ? 'light' : 'primary'} />
                                <Title2 sx={{ color: (groupBasicDetails.type === 'protected') ? 'white' : theme.palette.primary.dark }}>Protected</Title2>
                                <SubTitle2 color={(groupBasicDetails.type === 'protected') ? 'white' : 'primary'}>Need permission to join group</SubTitle2>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', borderRadius: '4px', backgroundColor: (groupBasicDetails.type === 'private') ? theme.palette.primary.main : theme.palette.light.light, flex: 1, padding: '10px' }} onClick={() => handleTypeInput("private")}>
                                <Private fontSize='small' color={(groupBasicDetails.type === 'private') ? 'light' : 'primary'} />
                                <Title2 sx={{ color: (groupBasicDetails.type === 'private') ? 'white' : theme.palette.primary.dark }}>Private</Title2>
                                <SubTitle2 color={(groupBasicDetails.type === 'private') ? 'white' : 'primary'}>Need permission to join group and not searchable. Join through invitation</SubTitle2>
                            </Box>
                        </Box>
                        {/* <Box>
                            <Button type='submit' variant='contained'>Save Changes</Button>
                        </Box> */}
                        <Box sx={{ position: 'relative', display: 'inline-block' }}>
                            <Button type='submit' variant="contained" disabled={(typeUpdating === 'loading' || groupBasicDetails.type === groupDetails.group_type) ? true : false} component="label" sx={{ width: '100%', margin: '10px auto', textTransform: 'capitalize' }} onClick={handleType}>
                                Save Changes
                            </Button>

                            {typeUpdating==='loading' && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: '#44bd32',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Paper>

                {/* sub category ############################### */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                <Box
                    component="form"
                    sx={{
                        boxSizing: 'border-box',
                        padding: '16px'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography variant='h6' fontWeight='600' mb={2}>Sub Category</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Card sx={{ bgcolor: '#F8EFBA', padding: '16px', width: 'calc(50% - 8px)' }}>
                            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <Avatar>R</Avatar>
                                <Box sx={{ marginLeft: '16px' }}>
                                    <Typography gutterBottom fontWeight='600' component="p">
                                        Friendly
                                    </Typography>
                                    <Typography variant='body2' component='p'>No Restriction for post</Typography>
                                </Box>
                            </Box>
                        </Card>
                        <Card sx={{ bgcolor: '#f1f2f6', padding: '16px', width: 'calc(50% - 8px)' }}>
                            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <Avatar>R</Avatar>
                                <Box sx={{ marginLeft: '16px' }}>
                                    <Typography gutterBottom fontWeight='600' component="p">
                                        Restricted
                                    </Typography>
                                    <Typography variant='body2' component='p'>Post need to allowed by Admin/Super Admin</Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box>
                        <Button type='submit' variant='contained'>Save Changes</Button>
                    </Box>
                </Box>
            </Paper> */}
                {/* <Paper sx={{ marginBottom: '16px' }}>
                <Box
                    component="form"
                    sx={{
                        boxSizing: 'border-box',
                        padding: '16px'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography variant='h6' fontWeight='600' mb={2}>Topics</Typography>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="top"
                                control={<Checkbox />}
                                label="Beauty"
                            />
                            <FormControlLabel
                                value="start"
                                control={<Checkbox />}
                                label="Tarvelling"
                            />
                            <FormControlLabel
                                value="bottom"
                                control={<Checkbox />}
                                label="Entertainment"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Movie"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Yoga"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Gym"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Read"
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Gaming"
                            />
                        </FormGroup>
                    </FormControl>
                    <Box>
                        <Button type='submit' variant='contained'>Save Changes</Button>
                    </Box>
                </Box>
            </Paper> */}
                <Paper sx={{ marginBottom: '16px' }}>
                    <CreateGroupName id={groupDetails.group_id} name={groupDetails.name} group_name={groupDetails.group_name} type='group' />
                </Paper>
            </>
    )
}

export default EditGroupProfile