import { Box, Divider } from '@mui/material'
import React from 'react'
import { SubTitle1 } from '../CustomComponents'

const GroupsTypeCount = () => {
    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding:'12px' }}>
                <SubTitle1 color='dark.light'>Public groups</SubTitle1>
                <SubTitle1 color='dark.light'>1.2k</SubTitle1>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding:'12px' }}>
                <SubTitle1 color='dark.light'>Protected groups</SubTitle1>
                <SubTitle1 color='dark.light'>2.3k</SubTitle1>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding:'12px' }}>
                <SubTitle1 color='dark.light'>Private groups</SubTitle1>
                <SubTitle1 color='dark.light'>327</SubTitle1>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding:'12px' }}>
                <SubTitle1 color='dark.light'>Channels</SubTitle1>
                <SubTitle1 color='dark.light'>771</SubTitle1>
            </Box>
        </Box>
    )
}

export default GroupsTypeCount