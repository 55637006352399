import { Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

const AffiliateDisclosure = () => {
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])
    return (
        <Container sx={{ backgroundColor: 'white', py: 1 }}>
            <h1>Affiliate Disclosure</h1>

            <p>
                There are links on this site that can be defined as affiliate links. This means that I may receive a small commission (at no cost to you) if you purchase something through the links provided on this website.</p>
                {/* <br/> */}

                <p>Emotiontown is a participant in the Amazon Services LLC Associates Program an affiliate advertising program designed to provide a means for website owners to earn advertising fees by advertising and linking to amazon.in, and any other website that may be affiliated with Amazon Service LLC Associates Program. If you got any questions about this to feel free to <Link to='/contact_us'>contact us</Link>
            </p>
        </Container>
    )
}

export default AffiliateDisclosure