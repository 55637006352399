import React, { Suspense } from 'react'
import { Box, Button, InputBase, TextField, Typography } from "@mui/material"
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import theme from '../../Theme';
import NotificationFilter from '../Filter/NotificationFilter';
import RightbarAffiliate from '../Affiliate/RightbarAffiliate';
// import RecommendedGroups from "./RecommendedGroups"
// const Activities = React.lazy(() => import('./Activities'));
// const InvitationList = React.lazy(() => import('./InvitationList'))
const RecommendedGroups = React.lazy(() => import(`./RecommendedGroups`))

const MoreButton = styled(Button)((theme) => ({
  backgroundColor: 'rgba(199, 201, 201,0.3)',
  color: 'blue',
  textTransform: 'none',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: 'rgba(199, 201, 201,0.7)',
  }
}))
const StyledButton = styled(Box)((theme) => ({
  color: 'white',
  // color:'#e3e4e8',
  display: 'inline-flex',
  // backgroundColor:'red',
  alignItems: 'center',
  marginRight: '10px',
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 20,
    backgroundColor: theme.palette.light1.main,
    fontSize: 16,
    // width: 'auto',
    padding: '10px 12px'
    // Use the system font instead of the default Roboto font.
  },
}));


const Newright = () => {
  // const location = useLocation()
  // const group_info = useSelector(state => state.group.groupShortInfo)
  return (
    // <Box sx={{ padding: '10px 60px 10px 0px' }}>
    //   <Box sx={{ overflowY: 'scroll',height: (group_info.role !== "General") && (group_info.type === "private" || group_info.type === "protected") && (group_info.subtype === "Restricted") && (location.pathname === '/feed' || location.pathname === '/posts' || location.pathname === '/requests' || location.pathname === '/report') ? 'calc(100vh - 114px)' : 'calc(100vh - 64px)' }}>
    //     <Box sx={{ backgroundColor: 'white',p:4 }}>
    // <input
    //   placeholder='search something'
    //   style={{
    //     backgroundColor: theme.palette.primary.main,
    //     // backgroundColor: theme.palette.light1.main,
    //     padding: '12px',
    //     display: 'flex',
    //     justifyContent:'space-between',
    //     marginRight:'16px',
    //     // flexGrow:1,
    //     flex:1,
    //     // flexGrow: 1,
    //     border: 'none',
    //     outline: 'none',
    //     width:'100%',
    //     borderRadius: '30px',
    //     color: theme.palette.black.main
    //   }}
    //   />
    //     </Box>
    //     <Suspense fallback={<div>Loading</div>}>
    //       {/* <InvitationList /> */}
    //       <RecommendedGroups />
    //       {/* <Activities/> */}
    //     </Suspense>
    //   </Box>
    // </Box>
    <Box sx={{ padding: '10px 10px 10px 0px',overflowY:'scroll' }}>
      {/* <NotificationFilter/> */}
      <Suspense fallback={<div>Loading</div>}>
      {/* <InvitationList /> */}
      <RecommendedGroups />
      {/* <Activities/> */}
      </Suspense>
      {/* <RightbarAffiliate/> */}
    </Box>
  )
}

export default Newright