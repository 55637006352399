import { Avatar, Box, CircularProgress, Typography } from '@mui/material'
import ClapOutlined from '@mui/icons-material/SignLanguageOutlined';
import Clap from '@mui/icons-material/SignLanguage';
import { entities } from "../HtmlEntity"
import { Delete } from '@mui/icons-material'
import React from 'react'
import moment from 'moment/moment';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import theme from '../../Theme';

const ReplyItem = ({reply,deleteReplyId,user_id,deleteReply,token,post_id,type,commented_by}) => {
    const [total_reacted,set_total_reacted] = React.useState(0)
    const [is_reacted,set_is_reacted] = React.useState(false)
    const socket = useOutletContext()

    React.useEffect(() => {
        set_total_reacted(reply.total_reacted)
        if(reply.is_reacted === null) {
            set_is_reacted(false)
        }else{
            set_is_reacted(true)
        }
    },[])

    const handleUnClap = () => {
        set_is_reacted(!is_reacted)
        set_total_reacted(total_reacted - 1)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/replyAction/${reply.reply_id}/${post_id}`,{ headers: { authorization: "Bearer " + token } })
            .then(response => {
            }).catch(error => {
            })
    }

    const handleClap = () => {
        set_is_reacted(!is_reacted)
        set_total_reacted(total_reacted + 1)
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/replyAction`, {reply_id:reply.reply_id, message: `clapped on your reply`, post_id, notified_user_id: commented_by,from_where:type,topic:'commentReplyReact'}, { headers: { authorization: "Bearer " + token } })
        .then(response => {
            if (reply.user_id !== user_id) {
                socket?.emit('reactOnReply', { receiverid: reply.user_id })
            }
        }).catch(error => {
        })
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Box key={reply.reply_id} sx={{ display: 'flex', alignItems: 'start', mb: 2, backgroundColor: theme.palette.light.light, px: 2, py: 1, borderRadius: '8px', width: '82%' }}>
            <Avatar src={reply.profile_pic} sx={{ width: 30, height: 30 }} />
            <Box sx={{ ml: 2, flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <Typography mr={1} sx={{ fontSize: '12px', fontWeight: '600' }}>{reply.name.replace(/&#?\w+;/g, match => entities[match])}</Typography>
                    {
                        (user_id === reply.user_id) ?
                            (deleteReplyId === reply.reply_id) ? <CircularProgress size={24} sx={{ color: '#44bd32', }} /> : <Delete fontSize='small' color='primary' onClick={() => deleteReply(reply.reply_id)} /> : null
                    }
                </Box>
                <Typography color='#4b4b4b' fontSize='12px'>{reply.reply.replace(/&#?\w+;/g, match => entities[match])}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize='12px' color="#2f3537">{total_reacted}</Typography>
                        {
                        is_reacted ?
                        <Clap fontSize='small' sx={{ color: theme.palette.primary.main, marginLeft:'6px' }} onClick={handleUnClap}/>:
                            <ClapOutlined fontSize='small' sx={{ color: theme.palette.primary.main, marginLeft:'6px' }} onClick={handleClap}/>
                    }
                    </Box>
                    <Typography fontSize='10px' color="#636e72" sx={{ marginLeft: 'auto' }}>{moment(reply.added_on).fromNow()}</Typography>
                </Box>
            </Box>
        </Box>
        </Box>
    )
}

export default ReplyItem