import { Avatar, Badge, Box } from '@mui/material'
import React from 'react'
import { EmotInput, Logo, SubTitle1, SubTitle2, Title2 } from '../../CustomComponents'
import { useNavigate } from 'react-router-dom'

const Conversation = () => {
    const [searchData, setSearchedData] = React.useState('')
    const [category, setCategory] = React.useState(0)
    const navigate = useNavigate()
    const handlePeopleSearch = () => {
        // axios.get(`${process.env.REACT_APP_API_URL}/api/users/search-user/${searchedContent}`, { headers: { authorization: "Bearer " + token } })
        // .then(response => {
        //     setPeopleList(response.data.data)
        //     if (response.data.data.length <= 0) {
        //         setStatus('not_found')
        //     } else {
        //         setStatus('ok')
        //     }
        // }).catch(error => {
        //     setStatus(`error`)
        // })
    }
    const handleGroupSearch = () => {
        // axios.get(`${process.env.REACT_APP_API_URL}/api/group/search-group/${searchedContent}`, { headers: { authorization: "Bearer " + token } })
        // .then(response => {
        //     setGroupList(response.data.data)
        //     if (response.data.data.length <= 0) {
        //         setStatus('not_found')
        //     } else {
        //         setStatus('ok')
        //     }
        // }).catch(error => {
        //     setStatus('error')
        // })
    }

    return (
        <Box>
            <Box sx={{ height: { xs: '54px', md: '63px' },position:'sticky',top:'0px' }}>
                <Logo
                    variant='h4'
                    noWrap
                    //  component="span"
                    sx={{ fontSize: '20px', fontWeight: 600, lineHeight: { xs: '54px', md: '63px' }, marginLeft: '16px', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}
                    onClick={() => {
                        // if (!token) {
                        //     dispatch(setOpenUaDialog(true))
                        //     return
                        // }
                        navigate("/")
                    }}
                >
                    EmotionTown
                </Logo>
            </Box>
            <Box sx={{ padding: '0px 16px 16px' }}>
                <EmotInput
                    required
                    fullWidth
                    value={searchData}
                    id="search_chat_input"
                    placeholder='search people, groups'
                    onChange={(e) => setSearchedData(e.target.value)}
                />
                {/* <Box sx={{maxHeight:'200px',position:'fixed',backgroundColor:'orange',width:'350px',overflowY:'scroll'}}>
                    hello
                    hello
                    hello
                </Box> */}
                <Box sx={{ display: 'flex', gap: 3, margin: '16px 0px' }}>
                    <Title2 color={category === 0 ? 'primary' : 'dark.light'} sx={{ borderBottom: category === 0 ? '3px solid #10ac84' : '3px solid transparent', paddingBottom: '10px' }}>People</Title2>
                    <Title2 color={category === 1 ? 'primary' : 'dark.light'} sx={{ borderBottom: category === 1 ? '3px solid #10ac84' : '3px solid transparent', paddingBottom: '10px' }}>Group</Title2>
                </Box>
                {/* <Box sx={{marginTop:'16px'}}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center',marginBottom:'22px' }}>
                        <Badge badgeContent={4} color="success">
                            <Avatar sx={{ width: 30, height: 30 }} src='https://images.pexels.com/photos/428361/pexels-photo-428361.jpeg?auto=compress&cs=tinysrgb&w=600'/>
                        </Badge>
                        <Box>
                            <SubTitle1>Prabir Khanra</SubTitle1>
                            <SubTitle2 color='darkgray'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</SubTitle2>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center',marginBottom:'22px' }}>
                        <Badge badgeContent={4} color="success">
                            <Avatar sx={{ width: 30, height: 30 }} src='https://images.pexels.com/photos/1687675/pexels-photo-1687675.jpeg?auto=compress&cs=tinysrgb&w=600'/>
                        </Badge>
                        <Box>
                            <SubTitle1>Prabir Khanra</SubTitle1>
                            <SubTitle2 color='darkgray'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</SubTitle2>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center',marginBottom:'22px' }}>
                        <Badge badgeContent={0} color="success">
                            <Avatar sx={{ width: 30, height: 30 }} src='https://images.pexels.com/photos/1462980/pexels-photo-1462980.jpeg?auto=compress&cs=tinysrgb&w=600'/>
                        </Badge>
                        <Box>
                            <SubTitle1>Prabir Khanra</SubTitle1>
                            <SubTitle2 color='darkgray'>Lorem ipsum dolor sit ctetur adipis.</SubTitle2>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center',marginBottom:'22px' }}>
                        <Badge badgeContent={0} color="success">
                            <Avatar sx={{ width: 30, height: 30 }} src='https://images.pexels.com/photos/1081685/pexels-photo-1081685.jpeg?auto=compress&cs=tinysrgb&w=600'/>
                        </Badge>
                        <Box>
                            <SubTitle1>Prabir Khanra</SubTitle1>
                            <SubTitle2 color='darkgray'>Lorem ipsum dolor sit, amet adipis.</SubTitle2>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center',marginBottom:'22px' }}>
                        <Badge badgeContent={4} color="success">
                            <Avatar sx={{ width: 30, height: 30 }} />
                        </Badge>
                        <Box>
                            <SubTitle1>Prabir Khanra</SubTitle1>
                            <SubTitle2 color='darkgray'>Lorem ipsum dolor consectetur adipisicing elit.</SubTitle2>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center',marginBottom:'22px' }}>
                        <Badge badgeContent={0} color="success">
                            <Avatar sx={{ width: '30px', height: '30px' }} src='https://images.pexels.com/photos/34534/people-peoples-homeless-male.jpg?auto=compress&cs=tinysrgb&w=600' >A</Avatar>
                        </Badge>
                        <Box>
                            <SubTitle1>Prabir Khanra</SubTitle1>
                            <SubTitle2 color='darkgray'>Lorem ipsum amet consectetur adipisicing elit.</SubTitle2>
                        </Box>
                    </Box>
                </Box> */}
            </Box>
        </Box>
    )
}

export default Conversation