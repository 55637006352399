import { Alert, Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, List, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { blue } from "@mui/material/colors";
import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../../Theme";
import ReactGA from 'react-ga4'

const report_options = [
  'Sexual Content or Nudity',
  'Violent or repulsive content',
  'Harmful or dangerous acts',
  'Child abuse',
  'Promotes Terrorism',
];

export default function ReportAction() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  const { reporter_id, get_reported_id, posted_group_id, content_creator_id, reported_for, report_to } = useParams()
  const token = JSON.parse(localStorage.getItem('token'))

  React.useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
  },[])

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleReport = () => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/api/group/report`, { reporter_id, get_reported_id, posted_group_id, content_creator_id, reported_for, reason: value, report_to }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        setLoading(false)
        navigate(-1)
      }).catch(error => {
        setLoading(false)
      })
  }
  const cancelReport = () => {
    navigate(-1)
  }

  return (
    <Box sx={{ padding: '16px', bgcolor: 'white' }}>
      <Typography fontWeight='600' variant='h6' mb={2}>Reason For Reporting</Typography>
      <RadioGroup
        aria-label="ringtone"
        name="ringtone"
        value={value}
        onChange={handleChange}
      >
        {report_options.map((option, index) => (
          <FormControlLabel
            value={index + 1}
            key={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
      <Alert severity="info" sx={{ my: 2 }}>We will not share your information.You are totally safe.We will take a necessary step as soon as possible.</Alert>
      <Button autoFocus variant='contained' color="light" sx={{ mr: 2,color:theme.palette.dark.light }} onClick={cancelReport}>
        Cancel
      </Button>
      {
        (value !== 0) &&
        <Box sx={{ mt: 1, position: 'relative', display: 'inline-block' }}>
          <Button
            type='submit'
            variant="contained"
            disabled={loading ? true : false}
            onClick={handleReport}
          >
            Ok
          </Button>
          {
            loading && (
            <CircularProgress
              size={24}
              sx={{
                color: '#44bd32',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      }
      {/* {
        (value !== 0) &&
        <Button variant='contained' disableElevation onClick={handleReport}>Ok</Button>
      } */}
    </Box>
  );
}

// Report.propTypes = {
//   // onClose: PropTypes.func.isRequired,
//   // open: PropTypes.bool.isRequired,
//   // selectedValue: PropTypes.string.isRequired,
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   value: PropTypes.string.isRequired,
// };