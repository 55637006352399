import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Collapse, Divider, IconButton, ListItemIcon, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import LikeOutline from '@mui/icons-material/FavoriteBorder';
import Like from '@mui/icons-material/Favorite';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import CancelShare from '@mui/icons-material/SyncDisabled';
import Share from '@mui/icons-material/Loop';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import React, { useRef } from 'react'
import CardTopHeader from './CardTopHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import moment from 'moment/moment';
import Comment from './Comment';
import { entities } from "../HtmlEntity"
import { setOpenUaDialog } from '../../store/groupSlice';
import AllPostCardTopHeader from '../AllPost/AllPostCardTopHeader';
import { editPostDescription, increasePostCommentCount, setCurrentPostInfo, setFullPostDetails, setOpenBookmarkDialog, setOpenReactDialog, setOpenShareDialog, setPostToSave, setReactOnPost, setReactPostInfo, setRemovePostFromFolder, setRemovePostFromSavedFeed } from '../../store/postSlice';
import { AutoAwesomeMosaicOutlined, BookmarkAdd, BookmarkAddOutlined, BookmarkAddRounded, BookmarkAddedRounded, CommentRounded, ContentCopy, Diversity1Outlined, Diversity2Outlined, FavoriteRounded } from '@mui/icons-material';
import { FilledButton, SubTitle1, SubTitle3 } from '../../CustomComponents';
import SharePostMenu from '../Menu/SharePostMenu';
import theme from '../../Theme';
import ReactGA from 'react-ga4'
import LazyImage from '../Lazy/LazyImage';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Lovecard = ({ val, value }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false)
    const [description, setDescription] = React.useState()
    const [isEditable, setisEditable] = React.useState(false)
    const [comment, setComment] = React.useState("")
    const [newComment, setNewComment] = React.useState({})
    const [totalComment, setTotalComment] = React.useState(0)
    const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
    const [is_saved, set_is_saved] = React.useState(false)
    const [is_commented, set_is_commented] = React.useState(false)
    const [is_liked, set_is_liked] = React.useState(false)
    const [isPinned, setIsPinned] = React.useState(null)
    const [totalLiked, setTotalLiked] = React.useState(0)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [updating, setUpdating] = React.useState(false)
    const { role } = useSelector(state => state.group.groupShortInfo)
    const openOptionMenu = Boolean(anchorE2)
    const socket = useOutletContext()
    const location = useLocation()
    // const [lineCount, setLineCount] = React.useState(0);
    const dispatch = useDispatch()
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user")) || {}
    const token = JSON.parse(localStorage.getItem('token'))
    const navigate = useNavigate()
    const ref = useRef(null)
    React.useEffect(() => {
        setTotalComment(val.total_comment)
        // set_is_saved(val.is_saved)
        // set_is_commented(val.is_commented)
        // if(location.pathname.split('/')[1] === 'saved-post' || val.is_saved !== null) {
        //     set_is_saved(true)
        // }else{
        //     set_is_saved(false)
        // }
        if (val.is_commented) {
            if (val.is_commented !== null) {
                set_is_commented(true)
            } else {
                set_is_commented(false)
            }
        } else {
            set_is_commented(false)
        }
        if (val.react !== null) {
            set_is_liked(true)
        }
        if (val.total_react !== 0) {
            setTotalLiked(val.total_react)
        }
        if (val.is_pinned !== null) {
            setIsPinned(val.is_pinned)
        }
        if (val.description && val.description.length > 0) {
            setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
        }
    }, [])

    React.useEffect(() => {
        if (location.pathname.split('/')[1] === 'saved-post' || (val.is_saved && val.is_saved !== null)) {
            set_is_saved(true)
        } else {
            set_is_saved(false)
        }
    }, [val.is_saved])

    const toggleReadMore = () => setIsShowMore((show) => !show);

    const handleRemovePost = () => {
        set_is_saved(false)
    }

    // const callBackFunction = (entries) => {
    //     const [entry] = entries
    //     setIsVisible(entry.isIntersecting)
    // }

    let callBackFunction = (entries, observer) => {
        // entries.forEach((entry) => {
        //     if (entry.isIntersecting) {
        //         setIsVisible(true)
        //     }
        // });
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    };

    const options = {
        root: null,
        rootMargin: '-150px',
        threshold: 0.1
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(callBackFunction, options)
        if (ref?.current) {
            observer.observe(ref.current)
            if (isVisible) {
                if (val.file_type === "video") {
                    ref.current.play()
                }
            }
        }
        return () => {
            if (ref?.current) {
                if (val.file_type === "video") {
                    ref.current.pause()
                }
                observer.unobserve(ref.current)
            }
        }
    }, [ref, options])


    const handleCommentSubmit = (e) => {
        e.preventDefault()
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if(comment.trim() == '') {
            return
        }
        setCreateCommentStatus('loading')
        var notification_message = ''
        if (val.group_id && val.group_name) {
            notification_message = `comments on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`
        } else {
            notification_message = `comments on your post`
        }
        const data = { post_id: val.xz12uied7, group_id: val.group_id, comment, message: notification_message, user_to_notify: val.u_id, from_where: val.type, topic: 'postComment' }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                const new_comment = {
                    comment_id: response.data.comment_id,
                    comment,
                    added_on: moment().format(),
                    user_id,
                    profile_pic,
                    total_reply: 0,
                    total_reacted: 0,
                    is_reacted: null,
                    name
                }
                setNewComment(new_comment)
                set_is_commented(true)
                setTotalComment(totalComment + 1)
                // if (value === 'pin') {
                //     dispatch(increasePinPostCommentCount(val.xz12uied7))
                // } else if (value === 'saved') {
                //     dispatch(increaseSavePostCommentCount(val.xz12uied7))
                // } else if (value === 'myPost') {
                //     dispatch(increaseMyPostCommentCount(val.xz12uied7))
                // } else {
                // }
                if (value !== 'show_post') {
                    dispatch(increasePostCommentCount({ feed_value: value, data: val.xz12uied7 }))
                }
                if (response.data.status === 1) {
                    if (val.creator_id !== user_id) {
                        socket?.emit('commentOnPost', { receiverid: val.u_id })
                    }
                }
                setCreateCommentStatus('ok')
            }).catch(error => {
                setCreateCommentStatus('error')
            })
        setComment("")
    };

    const fullImageHandler = () => {
        dispatch(setFullPostDetails(val.org_file))
        navigate("/full-image")
    }

    const handleVisitSharedPeopleProfile = (shared_by_user_id, shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // navigate(`/profile/${shared_by_user_id}/${shared_from_group_id}`)
        navigate("/profile", {
            state: {
                group_id: shared_from_group_id,
                user_id: shared_by_user_id
            },
            // replace: true
        })
    }
    const handleVisitSharedGroupProfile = (shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        navigate(`/group-profile/${shared_from_group_id}`)
    }

    const handleSharePost = (share_to) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        setAnchorE2(null)
        if (val.group_type === 'public' || val.type === 'friend' || val.type === 'follower') {
            if (share_to === 'group') {
                if (value !== 'show_post') {
                    dispatch(setPostToSave({ feed_value: value, data: val.xz12uied7 }))
                }
                dispatch(setOpenShareDialog(true))
            } else {

            }
        } else {
            dispatch(setSnackMessage(`You can't share... from a ${val.group_type} group.`))
            dispatch(setOpenSnack(true))
        }
    }

    const handleUpdateDescription = (e) => {
        e.preventDefault()
        setUpdating(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/post/updatePost`, { post_id: val.xz12uied7, description }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setisEditable(false)
                setUpdating(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                // if (value === 'pin') {
                //     dispatch(editPinPostDescription({ post_id: val.xz12uied7, description }))
                // } else if (value === 'saved') {
                //     dispatch(editSavePostDescription({ post_id: val.xz12uied7, description }))
                // } else if (value === 'myPost') {
                //     dispatch(editMyPostDescription({ post_id: val.xz12uied7, description }))
                // } else {
                // }
                if (value !== 'show_post') {
                    dispatch(editPostDescription({ feed_value: value, post_id: val.xz12uied7, description }))
                }
            }).catch(error => {
                setUpdating(false)
                dispatch(setSnackMessage("Something went wrong. Try later."))
                dispatch(setOpenSnack(true))
            })
    }

    const removeFromFolder = (now_post_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (value !== 'saved') {
            handleRemovePost()
        }
        if (value === 'saved') {
            dispatch(setRemovePostFromSavedFeed({ data: now_post_id }))
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/removed_saved_post/${now_post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    if (value !== 'saved' && value !== 'show_post') {
                        dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
                    }
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                } else {
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }
    const handleReaction = (event) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // set_is_liked(event.target.checked)
        // return
        const val_copy = { ...val }
        // if (val.react) {
        if (is_liked) {
            // remove reaction 
            // val_copy.react = 0
            // val_copy.total_react = (val_copy.total_react - 1)
            // val_copy.my_reaction = 0
            set_is_liked(false)
            setTotalLiked(totalLiked - 1)
            axios.delete(`${process.env.REACT_APP_API_URL}/api/post/remove/react_post/${val.xz12uied7}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (value !== 'show_post') {
                        dispatch(setReactOnPost({ feed_value: value, id: val.xz12uied7, react: 0, total_react: val.total_react - 1 }))
                    }
                }).catch(error => {
                })
        } else {
            // create reaction
            val_copy.react = 1
            val_copy.total_react = (val_copy.total_react + 1)
            set_is_liked(true)
            setTotalLiked(totalLiked + 1)
            var notification_message = ''
            if (val.group_id && val.group_name) {
                notification_message = `reacted on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`
            } else {
                notification_message = `reacted on your post`
            }
            // create react     // user_id,post_id,react_value,group_id
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/react_post`, { post_id: val.xz12uied7, react_value: 1, group_id: val.group_id, post_type: 'love', message: notification_message, notified_user_id: val.u_id, from_where: val.type, topic: 'postReact' }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (value !== 'show_post') {
                        dispatch(setReactOnPost({ feed_value: value, id: val.xz12uied7, val_copy }))
                    }
                    if (response.data.status === 1) {
                        if (val.creator_id !== user_id) {
                            socket?.emit("reactOnPost", { receiverid: val.u_id })
                        }
                    }
                }).catch(error => {
                })
        }
    }

    const handleBookmarkPost = (event) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (location.pathname.split('/')[1] !== 'saved-post' && val.is_saved === null) {
            dispatch(setCurrentPostInfo({ post_item_id: val.xzu34ie0d98, feed_value: value }))
            dispatch(setOpenBookmarkDialog(true))
        } else {
            removeFromFolder(val.xzu34ie0d98)
        }
        // set_is_saved(event.target.checked)
        // navigate(`/folders/${val.xz12uied7}/${val.group_id}/${value}`)
    }

    const goToReactDetails = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (totalLiked === 1 && is_liked !== false) {
            dispatch(setSnackMessage("Only you react on this post."))
            dispatch(setOpenSnack(true))
            return
        }
        if (totalLiked > 0) {
            var post_group_id;
            if (val.group_id) {
                post_group_id = val.group_id
            } else {
                post_group_id = 0
            }
            dispatch(setReactPostInfo({ total_react: totalLiked, post_id: val.xz12uied7, post_type: 'love', group_id: post_group_id }))
            dispatch(setOpenReactDialog(true))
        }
    }

    return (
        <>
            {/* <Menu
                id="love_post_share_menu_id"
                anchorEl={anchorE2}
                open={openOptionMenu}
                onClose={() => setAnchorE2(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        boxShadow: '1px 1px 7px #c0c0d8'
                    }
                }}
            >
                <MenuItem onClick={() => handleSharePost('friend')}>
                    <ListItemIcon>
                        <Diversity2Outlined fontSize="small" />
                    </ListItemIcon>
                    Share To Friends
                </MenuItem>
                <MenuItem onClick={() => handleSharePost('follower')}>
                    <ListItemIcon>
                        <Diversity1Outlined fontSize="small" />
                    </ListItemIcon>
                    Share To Followers
                </MenuItem>
                <MenuItem onClick={() => handleSharePost('group')}>
                    <ListItemIcon>
                        <AutoAwesomeMosaicOutlined fontSize="small" />
                    </ListItemIcon>
                    Share To Groups
                </MenuItem>
            </Menu> */}
            <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type={val.group_type} type={val.type} post_id={val.xz12uied7} value={value} group_id={val.group_id} creator_id={val.creator_id} group_name={val.group_name} />
            <Card elevation={0} sx={{ backgroundColor: val.is_colored === 'no' ? 'white' : val.role === 'Super Admin' ? '#d2f9de' : val.role === 'Admin' ? '#e6eeff' : 'white', width: '100%', marginBottom: 2, borderRadius: { xs: '0px', sm: '10px' }, border: '1px solid #E4E9F2' }}>
                {/* {
                    val.shared_by &&
                    <Alert icon={false} severity="primary" sx={{ borderRadius: '0px' }}>
                        shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
                    </Alert>
                } */}
                {
                    (val.shared_by && val.shared_by_name !== val.name) &&
                    <>
                        {
                            (val.shared_from_group_id === 'null' || val.shared_from_group_id === null) ?
                                <SubTitle1 padding='10px' sx={{ backgroundColor: '#F7F9FC', color: theme.palette.dark.main }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, 0)}>{val.shared_by_name}</strong>
                                </SubTitle1> :
                                <SubTitle1 padding='10px' sx={{ backgroundColor: '#F7F9FC', color: theme.palette.dark.main }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
                                </SubTitle1>
                        }
                        {/* {
                            (val.shared_from_group_id !== 'null') &&
                            <SubTitle1 padding='10px' sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.light.main }}>
                                shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_by_group_name}</strong> group
                            </SubTitle1>
                        } */}
                        <Divider />
                    </>
                }
                {
                    (val.group_id && value !== 'group_feed' && value !== 'pin_post_feed' && value !== 'group_post' && value !== 'myPost') ?
                        <AllPostCardTopHeader isPinned={isPinned} status={val.status} post_type='love' group_type={val.group_type} setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} group_name={val.group_name} group_profile_pic={val.group_profile_pic} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} file_count={val.file_count} shared_from_group_id={val.shared_from_group_id} /> :
                        <CardTopHeader isPinned={isPinned} status={val.status} post_type='love' group_type={val.group_type} setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} file_count={val.file_count} />
                }
                <CardContent sx={{ pt: 0, pb: 1 }}>
                    {(description && description.length > 250 && !isShowMore) ?
                        <>
                            <SubTitle1 lineHeight="18px" sx={{ whiteSpace: 'pre-line' }}>
                                {description.substring(0, 250)}...
                            </SubTitle1>
                            <SubTitle1 component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</SubTitle1>
                        </>
                        :
                        <>
                            {
                                <SubTitle1 lineHeight="18px" sx={{ whiteSpace: 'pre-line' }}>
                                    {description}
                                </SubTitle1>
                            }
                            {
                                description && description.length > 250 ?
                                    <SubTitle1 sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</SubTitle1> :
                                    null
                            }
                        </>
                    }
                    {
                        isEditable &&
                        <Box component='form' onSubmit={handleUpdateDescription}>
                            <TextField
                                fullWidth
                                value={description}
                                id="edit_basic_post_dialog_textfield_id"
                                label="edit post description"
                                multiline
                                rows={2}
                                name="edit_description_id"
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ mb: 1, mt: 2 }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Button color='light' sx={{ color: theme.palette.dark.light }} variant="contained" onClick={() => setisEditable(false)}>Cancel</Button>
                                <Box sx={{ position: 'relative', ml: 1 }}>
                                    <Button type='submit' disabled={(!description || updating) ? true : false} variant="contained">Update</Button>
                                    {updating && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#44bd32',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        (val.link) &&
                        <Box>
                            {(val.link && val.link.length > 100) ?
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                    {val.link.substring(0, 100)} ...
                                </a> :
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                    {val.link}
                                </a>
                            }
                        </Box>
                    }
                </CardContent>
                {
                    val.file_count > 0 && (val.file_type === 'image' || val.file_type === 'gif') ?
                        // <LazyImage ref={ref} alt='No pic'
                        <LazyImage alt='No pic'
                            // src="https://images.pexels.com/photos/2166711/pexels-photo-2166711.jpeg?auto=compress&cs=tinysrgb&w=600"
                            src={val.file_type === 'image' ? val.files : val.org_file}
                            onClick={fullImageHandler}
                            style={{ objectFit: 'cover', width: '100%', backgroundColor: '#2C3A47', minHeight: "200px", maxHeight: '90vh', minWidth: '100%' }} loading='lazy' />
                        : val.file_count > 0 && val.file_type === 'video' ?
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                <video ref={ref} src={val.org_file} style={{ maxHeight: '500px', height: '100%', width: '100%' }} controls controlsList='nodownload' />
                            </Box>
                            : null
                }
                {/* <SubTitle3 sx={{textAlign:'end',px:2}} color='dark.light'>{moment(val.added_on).fromNow() + ' . ' + moment(val.added_on).format('DD-MMM-YY')}</SubTitle3> */}
                <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                            <Checkbox
                                checked={is_liked || false}
                                onChange={handleReaction}
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<FavoriteRounded fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                                checkedIcon={<FavoriteRounded color='redish' fontSize='small' />}
                            />
                            {/* {
                            (is_liked) ?
                                <Like fontSize='small' sx={{ marginRight: '5px', color: "red" }} onClick={handleReaction} /> :
                                <LikeOutline fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} onClick={handleReaction} />
                        } */}
                            <SubTitle3 color='dark' sx={{ cursor: 'pointer' }} onClick={goToReactDetails}>
                                {totalLiked} Likes
                            </SubTitle3>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                            setExpanded(!expanded)
                        }}>
                            {/* {
                                (is_commented) ?
                                    <CommentIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} /> :
                                    <CommentOutlinedIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} />
                            } */}
                            <Checkbox
                                checked={is_commented || false}
                                // onChange={handleReaction}
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<CommentIcon fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                                checkedIcon={<CommentRounded color='redish' fontSize='small' />}
                            />
                            {/* <Comment fontSize='small' sx={{ mr: 1, color: "#2d3436" }} /> */}
                            {/* <Typography color={is_commented ? 'primary' : 'inherit'} sx={{ fontSize: '12px' }}>
                                {totalComment}
                            </Typography> */}
                            <SubTitle3 color='dark' sx={{ cursor: 'pointer' }} >
                                {totalComment} Comments
                            </SubTitle3>
                        </Box>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(event) => setAnchorE2(event.currentTarget)}>
                            {
                                (val.type === "public") ?
                                    <Share fontSize='small' sx={{ marginRight: '5px', color: theme.palette.light.dark }} /> :
                                    <CancelShare fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} />
                            }
                            {
                                // (val.type === 'public' || (val.shared_by && val.shared_by !== null)) &&
                                (val.type === 'public' || (!val.shared_by)) &&
                                <Typography sx={{ fontSize: '12px' }}>
                                    {val.total_share}
                                </Typography>
                            }
                        </Box> */}
                        {
                            // (val.group_type !== 'private' && val.group_type !== 'protected') &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(event) => {
                                if (!token) {
                                    dispatch(setOpenUaDialog(true))
                                    return
                                }
                                dispatch(setCurrentPostInfo({ feed_value: value }))
                                setAnchorE2(event.currentTarget)
                            }
                            }
                            >
                                <Tooltip title="Share post">
                                    <Share fontSize='small' sx={{ marginRight: '5px', color: theme.palette.light.dark }} />
                                </Tooltip>
                                {/* <SubTitle3 color='dark' sx={{ cursor: 'pointer' }} >
                                    Shares
                                </SubTitle3> */}
                            </Box>
                        }
                        {/* {
                            (val.group_type !== 'private' && val.group_type !== 'protected') &&
                            <Tooltip title="Copy post link">
                                <IconButton aria-label="delete" size='small' onClick={() => {
                                    // handleCopyLink(`https://www.emotiontown.com/read/post/${post.post_id}`)
                                    ReactGA.event({
                                        category: 'copy',
                                        action: 'copied love card post link',
                                        label: 'copied love card post link'
                                    })
                                    navigator.clipboard.writeText(`https://www.emotiontown.com/post/${val.xz12uied7}`)
                                        .then(result => {
                                            dispatch(setSnackMessage("Link copied successfully."))
                                            dispatch(setOpenSnack(true))
                                        }).catch(error => {
                                        })
                                }}>
                                    <ContentCopy fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        } */}
                        <Tooltip title="Bookmark post">
                            <Checkbox
                                checked={is_saved || false}
                                onChange={handleBookmarkPost}
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<BookmarkAddRounded fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                                checkedIcon={<BookmarkAddedRounded color='dark' fontSize='small' />}
                            />
                        </Tooltip>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => removeFromFolder(val.xz12uied7)}>
                                <BookmarkAddedIcon fontSize='small' sx={{ mr: 1, color: "#2d3436" }} />
                            </Box> :
                            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                                if (!token) {
                                    dispatch(setOpenUaDialog(true))
                                    return
                                }
                                set_is_saved(true)
                                navigate(`/folders/${val.xz12uied7}/${val.group_id}/${value}`)
                            }
                            }
                            >
                                <BookmarkAddOutlinedIcon fontSize='small' sx={{ mr: 1, color: "#2d3436" }} />
                            </Box> */}
                        {/* } */}
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                </CardActions>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                        display: 'flex',
                        margin: '6px 10px 10px',
                        alignItems: 'center',
                        gap: 1
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleCommentSubmit}
                >
                    {/* <TextField
                    size='small'
                    fullWidth
                    value={comment}
                    id="basic_comment_textarea_id"
                    placeholder='Comment something...'
                    multiline
                    maxRows={2}
                    sx={{
                        backgroundColor: (val.is_colored === 'yes' && val.role !== 'General') ? 'white' : '#f1f2f3',
                    }}
                    name="description"
                    onChange={(e) => setComment(e.target.value)}
                /> */}
                    <Avatar variant='rounded' src={profile_pic} sx={{ width: 32, height: 32 }} />
                    <input
                        id="basic_comment_textarea_id"
                        value={comment}
                        placeholder='comment something'
                        style={{
                            backgroundColor: (val.is_colored === 'yes' && val.role !== 'General') ? 'white' : theme.palette.light.light,
                            padding: '8px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            borderRadius: '6px',
                        }}
                        name="description"
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Box sx={{ position: 'relative' }}>
                        <FilledButton type='submit' size='small' variant="contained" disabled={(createCommentStatus === 'loading') ? true : false} disableElevation>Send</FilledButton>

                        {(createCommentStatus === 'loading') && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: '0px 8px 24px' }}>
                        {
                            (!token) ?
                                <Typography mt={1} sx={{ textAlign: 'center', fontWeight: 600, color: '#2d3436', fontSize: '14px' }}>Sign In for read comments</Typography> :
                                <Comment post_id={val.xz12uied7} role={val.role} is_colored={val.is_colored} value={value} newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} type={val.type} />
                        }
                    </CardContent>
                </Collapse>
            </Card>
        </>
    )
}

export default Lovecard