import { Box, Button, Grid, Typography } from '@mui/material'
import Goback from '@mui/icons-material/ArrowBackIosOutlined';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import error_message from '../../media/images/error_message.png'
import ReactGA from 'react-ga4'

const WentWrong = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname})
    })
    return (
        <Box sx={{ height: '100vh', bgcolor: 'white', position: 'relative', padding: '0px 30px' }}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', textAlign: 'center' }}>
                <img src={error_message} style={{ objectFit: 'contain', width: '280px', height: '280px' }} />
                <Typography variant='h2' fontWeight='900' color='#2d3436' >404</Typography>
                <Typography variant='h6' my={2} color='#636e72' >Sorry, something went wrong.Please try again later.</Typography>
                <Button variant='outlined' onClick={() => navigate(-1)} startIcon={<Goback />}>Go Back</Button>
            </Box>
        </Box>
    )
}

export default WentWrong