import styled from '@emotion/styled'
import { Box, Button, CardActions, CardContent, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'

const PostButton = styled(Button)((theme) => ({
    backgroundColor: 'rgba(64,53,151,0.8)',
    borderRadius: '0px',
    border: '1px solid rgba(64,53,151,0.8)',
    color: 'white',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: 'rgba(64,53,151,0.9)',
    }
}))

const CancelButton = styled(Button)((theme) => ({
    border: '1px solid rgba(214, 48, 49,0.8)',
    backgroundColor: 'rgba(214, 48, 49,0.8)',
    borderRadius: '0px',
    color: 'white',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: '#f5f6fa',
    }
}))

const NoticePost = ({setIsNoticeClicked,group_id}) => {
    const [notice,setNotice] = React.useState("")
    const [errorStatus,setErrorStatus] = React.useState("")
    // const { uid:user_id } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem("token"))
    const handleNoticeClose = () => {
        setIsNoticeClicked(false)
        setErrorStatus("")
    }
    const handleNoticeSave = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/notices`,{group_id,notice}, { headers: { authorization: "Bearer " + token } })
        .then(response => {
            setErrorStatus("")
            setNotice("")
            setIsNoticeClicked(false)
        })
        .catch(error => {
            setErrorStatus("Something went wrong.Try again later.")
        })
    }
  return (
    <>
    <CardContent>
        <TextField
            fullWidth
            id="basic_post_dialog_textfield_id"
            label="Write something"
            multiline
            rows={2}
            onChange={(e) => setNotice(e.target.value)}
        // defaultValue="Default Value"
        />
            <Typography variant='caption' color='red'>
                {errorStatus}
            </Typography>
    </CardContent>
    <CardActions  sx={{ justifyContent: 'flex-end' }}>
        <Box>
            <CancelButton variant='outlined' onClick={handleNoticeClose}>Cancel</CancelButton>
            <PostButton onClick={handleNoticeSave}>Submit</PostButton>
        </Box>
    </CardActions>
</>
  )
}

export default NoticePost