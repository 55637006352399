import { Avatar, Box, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React from 'react'
import no_photo from '../../../media/images/no_photo.png'
import { createSearchParams, useNavigate } from 'react-router-dom';
import { entities } from '../../HtmlEntity'
import { SubTitle1, SubTitle2, Title3 } from '../../../CustomComponents';
import { Groups } from '@mui/icons-material';

const RecommendedGroupListItem = ({ handleRecommendedAction, is_recommended, description, type, subtype, pic, role, name, id, owner }) => {
    const navigate = useNavigate()
    const recommendingAction = (recommended_status, group_id) => {
        handleRecommendedAction(recommended_status, group_id)
    }

    const goToGroupProfile = () => {
          navigate(`/group-profile/${id}`)
    }
    return (
        // <Box sx={{ marginBottom: '15px', paddingBottom: '10px', borderBottom: '1px solid #d2d9e0',width:'100%' }}>
        //     <Box sx={{ width: '100%', height: { xs: '150px', sm: '220px' }, borderRadius: '8px', marginBottom: '10px', backgroundColor: '#2d3436', overflow: 'hidden' }} onClick={() => navigate(`/group-profile/${id}`)}>
        //         <img src={(pic && pic !== null) ? pic : `https://api.emotiontown.com/upload/image/no_photo.png`} alt="No pic" style={{ width: '100%', height: '100%', objectFit: pic ? 'cover' : 'contain', opacity: pic ? 1.0 : 0.3 }} />
        //     </Box>
        //     {/* <img src={(pic && pic !== null) ? pic : 'https://images.pexels.com/photos/1043471/pexels-photo-1043471.jpeg?auto=compress&cs=tinysrgb&w=600'} alt="No pic" style={{ width: '100%', height: { xs: '150px', sm: '220px' }, objectFit: pic ? 'cover' : 'contain', borderRadius: '8px', marginBottom: '10px', opacity: pic ? 1.0 : 0.3 }} onClick={() => navigate(`/group-profile/${id}`)} /> */}
        //     <Typography fontSize='17px' fontWeight={600} sx={{ cursor: 'pointer' }} onClick={() => navigate(`/group-profile/${id}`)}>{name.replace(/&#?\w+;/g, match => entities[match])}</Typography>
        //     <Typography variant='body2' color='text.secondary' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: { xs: '270px', sm: '370px' } }}>{description && description.replace(/&#?\w+;/g, match => entities[match]).substring(0, 250)}...</Typography>
        //     <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '25px', marginTop: '6px' }}>
        //         <Typography variant='caption' sx={{ backgroundColor: '#dfe6e9', padding: '1px 4px', borderRadius: '2px', display: 'inline-block' }}>{type}</Typography>
        //         <Typography variant='caption' sx={{ flexGrow: 1, marginLeft: '14px' }}>{role} {role === 'General' ? 'member' : ''}</Typography>
        //         {
        //             (owner) ? (is_recommended === "true") ?
        //                 <IconButton edge="end" aria-label="visibilityOnId" onClick={() => recommendingAction("false", id)}>
        //                     <VisibilityIcon />
        //                 </IconButton> :
        //                 <IconButton edge="end" aria-label="visibilityOffId" onClick={() => recommendingAction("true", id)}>
        //                     <VisibilityOffIcon />
        //                 </IconButton>:
        //                 null
        //         }
        //         {/* {
        //             (owner) ? (is_recommended === "true") ?
        //                 <Typography variant='caption' sx={{ cursor: 'pointer', color: 'tomato', fontWeight:600 }} onClick={() => recommendingAction('false', id)}>Hide</Typography> :
        //                 <Typography variant='caption' sx={{ cursor: 'pointer', color: '#6C63FF', fontWeight: 600 }} onClick={() => recommendingAction('true', id)}>Show</Typography> :
        //                 null
        //         } */}
        //     </Box>
        // </Box>
        // <>
        // <ListItem secondaryAction={
        //     (owner) ? (is_recommended === "true") ?
        //         <IconButton edge="end" aria-label="visibilityOnId" onClick={() => recommendingAction("false", id)}>
        //             <VisibilityIcon />
        //         </IconButton> :
        //         <IconButton edge="end" aria-label="visibilityOffId" onClick={() => recommendingAction("true", id)}>
        //             <VisibilityOffIcon />
        //         </IconButton> : null
        // }>

        //     <ListItemButton
        // onClick={() => navigate({ pathname: '/group-profile', search: createSearchParams({ groupProfileId: id }).toString() })}
        // onClick={() => { 
        //     navigate({pathname:"/group-profile",search:createSearchParams({groupProfileId:id})}) }}
        // >
        //  <ListItemIcon onClick={() => navigate({ pathname: '/group-profile', search: createSearchParams({ groupProfileId: id }).toString() })} */}
        //                     <ListItemIcon onClick={() => navigate(`/group-profile/${id}`)}
        // >
        //                         <Avatar sx={{ width: '30px', height: '30px' }} alt="Cindy Baker" src={pic} >{name.charAt(0).toUpperCase()}</Avatar>
        //                     </ListItemIcon>
        //                     <ListItemText primary={name.replace(/&#?\w+;/g, match => entities[match])} secondary={`${type} group . ${role} member`}
        //                     onClick={() => {
        //                         navigate("/feed", {
        //                             state: {
        //                                 id
        //                             },
        //                             // replace: true
        //                         })
        //                     }}
        // secondary={
        //     <React.Fragment>
        //         <Typography
        //             sx={{ display: 'inline' }}
        //             component="span"
        //             variant="body2"
        //             color="text.primary"
        //             mr={1}
        //         >
        //             {role} .
        //         </Typography>
        //         {type}
        //     </React.Fragment>
        // }
        //         />
        //         {/* <ListItemText primary={name} secondary={role} /> */}
        //     </ListItemButton>
        // </ListItem>
        // </>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '12px' }}>
            <Avatar variant='rounded' src={pic} sx={{ height: 30, width: 30 }} alt={name} onClick={goToGroupProfile}><Groups/></Avatar>
            <Box flexGrow={1}>
                <SubTitle1 color='black' >{name.replace(/&#?\w+;/g, match => entities[match])}</SubTitle1>
                <SubTitle2 color='gray' flexGrow={1}>{role}</SubTitle2>
            </Box>
            {
                (owner) ? (is_recommended === "true") ?
                    <IconButton edge="end" aria-label="visibilityOnId" onClick={() => recommendingAction("false", id)}>
                        <VisibilityIcon fontSize='small' />
                    </IconButton> :
                    <IconButton edge="end" aria-label="visibilityOffId" onClick={() => recommendingAction("true", id)}>
                        <VisibilityOffIcon fontSize='small' />
                    </IconButton> :
                    null
            }
        </Box>
    )
}

export default RecommendedGroupListItem