import { Box } from '@mui/material'
import React from 'react'
import { EmotInput, Title2 } from '../../CustomComponents'
import theme from '../../Theme'
import GroupConversation from './GroupConversation'
import PeopleConversation from './PeopleConversation'

const M_conversation = () => {
    const [searchData, setSearchedData] = React.useState('')
    const [category, setCategory] = React.useState(0)
    return (
        <Box sx={{ padding: '16px', backgroundColor:"white",mt:2 }}>
            <EmotInput
                required
                fullWidth
                value={searchData}
                id="search_chat_input"
                placeholder='search people, groups'
                onChange={(e) => setSearchedData(e.target.value)}
            />
            {/* <input
                required
                value={searchData}
                id="search_chat_input"
                placeholder='search people, groups'
                onChange={(e) => setSearchedData(e.target.value)}
                style={{backgroundColor:'white',outline:'none',border:"none",borderRadius:'6px',padding:'12px',width:'100%'}}
            /> */}
            <Box sx={{ display: 'flex', gap: 3, margin: '16px 0px' }}>
                <Title2 color={category === 0 ? 'primary' : 'dark.light'} sx={{ borderBottom: category === 0 ? '3px solid #10ac84' : '3px solid transparent', paddingBottom: '10px' }} onClick={() => setCategory(0)}>People</Title2>
                <Title2 color={category === 1 ? 'primary' : 'dark.light'} sx={{ borderBottom: category === 1 ? '3px solid #10ac84' : '3px solid transparent', paddingBottom: '10px' }} onClick={() => setCategory(1)}>Group</Title2>
            </Box>
            <Box>
                {(category === 1) ? <GroupConversation/> : <PeopleConversation/> }
            </Box>
        </Box>
    )
}

export default M_conversation