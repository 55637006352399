import { Alert, Box, Button, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setOpenSnack, setSnackMessage, setuserProfileInfoUserName } from '../../store/authSlice'
import { validUsername } from "../Regex"
import { FilledButton } from '../../CustomComponents'

const CreateUserName = ({ id, user_name }) => {
    const [available, setAvailable] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [sorry, setSorry] = React.useState(false)
    const [data, setData] = React.useState("")
    // const [searchparams] = useSearchParams()
    const token = JSON.parse(localStorage.getItem("token"))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    React.useEffect(() => {
        if (user_name !== "null") {
            setData(user_name)
        }
    }, [id])
    const getUserName = (event) => {
        setData(event.target.value)
        if (event.target.value.length > 2) {
            if (!validUsername.test(event.target.value)) {
                setAvailable(false)
                setSorry(true)
                setMessage("use only a-z,A-Z,0-9 or '_'.")
                return
            } else {
                setSorry(false)
            }
            axios.get(`${process.env.REACT_APP_API_URL}/api/users/user_name/${event.target.value}`, { headers: { authorization: "Bearer " + token } })
                .then(res => {
                    if (res.data.status === 0) {
                        setAvailable(false)
                        setSorry(true)
                        setMessage("Sorry,this name is already taken.")
                    } else {
                        setAvailable(true)
                        setSorry(false)
                    }
                }).catch(error => {

                })
        } else {
            setAvailable(false)
            setSorry(true)
            setMessage("Atleast 3 letters needed.")
        }
    }

    const saveUserName = (e) => {
        e.preventDefault()
        if (!validUsername.test(data)) {
            setAvailable(false)
            setSorry(true)
            setMessage("use only a-z,A-Z,0-9 or '_'.")
            return
        } else {
            setSorry(false)
        }
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/user_name`, { user_name: data }, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    dispatch(setuserProfileInfoUserName(res.data.user_name))
                    // navigate(-1)
                }
                dispatch(setSnackMessage(res.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                dispatch(setSnackMessage("Failed to save. Try later."))
                dispatch(setOpenSnack(true))
            })
    }
    return (
        <>
            <Typography variant='h6' fontWeight='600'>Edit or Create user name</Typography>
            <Typography variant='body2' mb={2}>@_123_username</Typography>
            <Box
                component="form"
                // sx={{
                //     padding:'16px'
                // }}
                noValidate
                autoComplete="off"
                onSubmit={saveUserName}
            >
                <TextField id="outlined-basic" value={data} label="create user name" variant="outlined" fullWidth sx={{ marginBottom: '16px' }} onChange={getUserName} helperText="Atleast 3 letters needed" />
                <Typography variant='body2' mb={2}>user name will be unique and contain alpha numeric character. Ex. a-z,A-Z,0-9</Typography>
                {
                    available &&
                    <Alert severity="success" sx={{ mb: 1 }}>You may choose this name.</Alert>
                }{
                    sorry &&
                    <Alert severity="error" sx={{ mb: 1 }}>{message}</Alert>
                }
                <FilledButton type='submit' variant='contained' disabled={available ? false : true}>Save</FilledButton>
            </Box>
        </>
    )
}

export default CreateUserName