import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setFullPostDetails } from '../../store/postSlice'

const LazyImage = (props) => {
    const [inview, setInview] = React.useState(false)
    const ref = useRef(null)
    const dispatch = useDispatch()

    let callback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                //   console.log(entry)
                setInview(true)
            }
        });
    };
    useEffect(() => {
        let observer = new IntersectionObserver(callback);
        if (ref?.current) {
            observer.observe(ref.current)
        }

        return () => {
            // if(ref?.current) {
            //     observer.unobserve(ref.current)
            // }
            observer.disconnect()
        }

    }, [])

    // const fullImageHandler = () => {
    //     console.log(props.org_file)
    //     return
    //     dispatch(setFullPostDetails(props.org_file))
    //     navigate("/full-image")
    // }

    return inview ? (
        <img {...props} />
    ) : (
        <img ref={ref} style={{ width: '100%', minWidth: '100%', minHeight: '240px', backgroundColor: '#2C3A47' }} />
    )
}

export default LazyImage