import { Avatar, Card, CardHeader, Divider, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import BaRatedPost from '../Grid/Form/Post/BaRatedPost';
import { entities } from '../HtmlEntity';
import { Title2, Title3 } from '../../CustomComponents';
import SimpleFriendAndFollowPost from './SimpleFriendAndFollowPost';
import ReactGA from 'react-ga4'

const CreateFriendAndFollowerPost = () => {
    // const [selected, setSelected] = React.useState("love")
    // const [anchorEl,setAnchorEl] = React.useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const { uid, name, profile_pic } = JSON.parse(localStorage.getItem("user"))

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])

    const goToProfile = () => {
        navigate("/profile", {
            state: {
                group_id: 0,
                user_id: uid
            },
        })
    }
    // const handleClose = (value) => {
    //     setSelected(value)
    //     setAnchorEl(null)
    // }
    return (
        <Card sx={{ marginBottom: 2, boxShadow: 'none', mt:2 }}>
            <Title2 padding='10px' color='primary'>Post to {location.state.post_to}</Title2>
            <Divider/>
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose('love')}>Love Post</MenuItem>
                <MenuItem onClick={() => handleClose('special')}>Special Post</MenuItem>
                <MenuItem onClick={() => handleClose('textpoll')}>Text Poll</MenuItem>
                <MenuItem onClick={() => handleClose('mediapoll')}>Media Poll</MenuItem>
            </Menu> */}
            <CardHeader
                avatar={
                    <Avatar variant='rounded' sx={{ width: 30, height: 30 }} aria-label="recipe" 
                    src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600'
                     onClick={goToProfile} />
                }
                title={
                    <>
                        <Typography sx={{ maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 600 }}>
                            {name.replace(/&#?\w+;/g, match => entities[match])}
                        </Typography>
                    </>

                }
            />
            {/* {
                isNoticeClicked ? <NoticePost group_id={group_id} setIsNoticeClicked={setIsNoticeClicked} /> : (selected === 'basic' || selected === 'love') ? <BaRatedPost post_type={selected} group_id={group_id} /> : (selected === 'compare' || selected === 'mediapoll') ? <MediaPollPost post_type={selected} group_id={group_id} /> : selected === 'textpoll' ? <TextPollPost post_type={selected} group_id={group_id} /> : null
            } */}
            <SimpleFriendAndFollowPost post_to={location.state.post_to}  post_type='love' />
        </Card>
    )
}

export default CreateFriendAndFollowerPost