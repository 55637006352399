import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


// ############## =============================================================
// 1. How it works .... setCreateAccountInfo in reducers
// ############## =============================================================


export const STATUS = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading'
})
const groupSlice = createSlice({
    name: 'group',
    initialState: {
        openUaDialog: false,
        // ok ======================================== groupprofile.jsx
        currentGroupInfo: { name: "", description: "" },
        // ok ========================================
        groupShortInfo: {
            name: '',
            role: '',
            type: '',
            subtype: '',
            id: '',
            is_colored: '',
            group_profile_pic: '',
            total_request: '',
            total_pin_post: 0
        },
        groupMemberList: [], // ok ======================================== groupMember.jsx
        createGropType: '', // ok ========================================
        groupList: [], // ok ========================================
        groupListCallNo: 1, // ok ========================================
        hasMoreGroups: true, // ok ========================================
        myAllGroupList: [], // ok ========================================
        groupInvitationList: [], // ok =================================
        activityList: [], // ok =======================================
        // groupProfileId: '', // ok ======================================== GroupMember.jsx | Request.jsx
        // ok ========================================
        recommendedGroupList: [], // ok ========================================
    },
    createAccountInfo: {},
    reducers: {
        setGroupProfileInfoListing(state, action) {
            state.currentGroupInfo = { ...state.currentGroupInfo, is_listed: action.payload }
        },
        // ok ===========================
        setOpenUaDialog(state, action) {
            state.openUaDialog = action.payload
        },
        // ok ========================================
        setUserRequestsListInGroup(state, action) {
            state.userRequestsListInGroup = action.payload
        },
        // ok ========================================
        setTotalRequestsInGroupShortInfo(state, action) {
            state.groupShortInfo = { ...state.groupShortInfo, total_request: action.payload }
        },
        increaseTotalPinPostInGroupShortInfo(state, action) {
            state.groupShortInfo = { ...state.groupShortInfo, total_pin_post: state.groupShortInfo.total_pin_post + 1 }
        },
        decreaseTotalPinPostInGroupShortInfo(state, action) {
            state.groupShortInfo = { ...state.groupShortInfo, total_pin_post: state.groupShortInfo.total_pin_post - 1 }
        },
        // ok ========================================
        // action => group_id .... total_request 
        // setTotalRequestsInGroup(state, action) {
        //     state.groupList = state.groupList.map(el => {
        //         if(el.id === action.payload.group_id) {
        //             return {...el,total_request:action.payload.total_request}
        //         }else{
        //             return el
        //         }
        //     })
        // },
        // ok ========================================
        setGroupShortInfo(state, action) {
            state.groupShortInfo = action.payload
        },
        // ok ========================================
        removeUserRequestsFromListInGroup(state, action) {
            state.userRequestsListInGroup = state.userRequestsListInGroup.filter(request => request.uid !== action.payload)
        },
        // ok ========================================
        removeGroupMember(state, action) {
            state.groupMemberList = state.groupMemberList.filter(member => member.user_id !== action.payload)
        },
        // ok ========================================
        setRoleInGroup(state, action) {
            state.roleInGroup = action.payload
        },
        // ok ========================================
        setCreateGroupType(state, action) {
            state.createGropType = action.payload;
        },
        // setCreateGroupInformation (state,action) {
        //     Object.assign(state.createGropInfo,action.payload)
        //     // state.createGropInfo.as
        //     // state.createGropInfo.info = action.payload;
        // },
        setCurrentGroupInfo(state, action) {
            state.currentGroupInfo = action.payload
        },
        setCurrentGroupInfoChangeRoleAndStatus(state, action) {
            state.currentGroupInfo = { ...state.currentGroupInfo, role: action.payload.role, status: action.payload.status }
        },
        setCurrentGroupInfoBasicInfo(state, action) {
            state.currentGroupInfo = { ...state.currentGroupInfo, name: action.payload.name, description: action.payload.description }
        },
        setCurrentGroupInfoGroupProfilePic(state, action) {
            state.currentGroupInfo = { ...state.currentGroupInfo, group_profile_pic: action.payload.group_profile_pic, org_group_profile_pic: action.payload.org_group_profile_pic }
        },
        setCurrentGroupInfoGroupName(state, action) {
            state.currentGroupInfo = { ...state.currentGroupInfo, group_name: action.payload }
        },
        // setStatus(state, action) {
        //     state.status = action.payload
        // },

        // setGroupStatus(state, action) {
        //     state.currentGroupInfo.status = action.payload
        // },

        // setCreateAccountInfo(state, action) {
        //     Object.assign(state.accountInfo, action.payload)
        // },

        // ok ========================================
        setGroupList(state, action) {

            state.groupList = action.payload
        },
        setGroupListCallNo(state, action) {

            state.groupListCallNo = (state.groupListCallNo + 1)
        },
        setHasMoreGroups(state, action) {
            state.hasMoreGroups = action.payload
        },
        setMyAllGroupList(state, action) {

            state.myAllGroupList = action.payload
        },

        // ok ========================================
        setNewGroupInList(state, action) {
            state.groupList = state.groupList.concat(action.payload)
        },

        // ok ========================================
        removeGroupFromList(state, action) {
            state.groupList = state.groupList.filter(el => el.id !== action.payload)
        },
        // ok =========================================
        setGroupInvitationList(state, action) {
            state.groupInvitationList = action.payload
        },
        // ok =========================================
        setMoreGroupInvitationInList(state, action) {
            state.groupInvitationList = state.groupInvitationList.concat(action.payload)
        },
        // ok =========================================
        removeGroupInvitationFromList(state, action) {
            state.groupInvitationList = state.groupInvitationList.filter(invitation => invitation.id !== action.payload)
        },
        // ok =========================================
        setActivityList(state, action) {
            state.activityList = action.payload
        },
        // ok =========================================
        setMoreActivityInList(state, action) {
            state.activityList = state.activityList.concat(action.payload)
        },
        // ok ========================================
        setGroupProfileId(state, action) {
            state.groupProfileId = action.payload
        },
        setRecommendedGroupList(state, action) {
            state.recommendedGroupList = action.payload
        },
        setGroupMemberList(state, action) {
            state.groupMemberList = action.payload
        },
        updateMemberRoleInGroup(state, action) {
            state.groupMemberList = state.groupMemberList.map(el => {
                if (el.user_id === action.payload.member_id) {
                    return { ...el, role: action.payload.role }
                } else {
                    return el
                }
            })
        },
        changeSuperAdminInGroup(state, action) {
            state.groupMemberList = state.groupMemberList.map(el => {
                state.currentGroupInfo = {...state.currentGroupInfo,role:'Admin'}
                if (el.user_id === action.payload.new_super_admin_id) {
                    return { ...el, role: 'Super Admin' }
                } else {
                    if (el.user_id === action.payload.new_admin_id) {
                        return { ...el, role: 'Admin' }
                    } else {
                        return el
                    }
                }
            })
        },

        // ok ========================================
        setGroupProfilePicInGroupList(state, action) { // id , group_profile_pic
            state.groupList = state.groupList.map(el => el.id === action.payload.id ? { ...el, group_profile_pic: action.payload.group_profile_pic } : el)
        },
        setCurrentGroupInfoColorOptionsChange(state, action) {
            state.currentGroupInfo = { ...state.currentGroupInfo, is_colored: action.payload }
        },
        setGroupShortInfoColorOptionsChange(state, action) {
            state.groupShortInfo = { ...state.groupShortInfo, is_colored: action.payload }
        },
        resetGroupSlice(state, action) {
            state.openUaDialog = false
            // ok ======================================== groupprofile.jsx
            state.currentGroupInfo = { name: "", description: "" }
            // ok ========================================
            state.groupShortInfo = {
                name: '',
                role: '',
                type: '',
                subtype: '',
                id: '',
                is_colored: '',
                group_profile_pic: '',
                total_request: '',
                total_pin_post: 0
            }
            state.groupMemberList = [] // ok ======================================== groupMember.jsx
            state.createGropType = '' // ok ========================================
            state.groupList = [] // ok ========================================
            state.groupListCallNo = 1 // ok ========================================
            state.hasMoreGroups = true // ok ========================================
            state.myAllGroupList = [] // ok ========================================
            state.groupInvitationList = [] // ok =================================
            state.activityList = [] // ok =======================================
            // groupProfileId = '' // ok ======================================== GroupMember.jsx | Request.jsx
            // ok ========================================
            state.recommendedGroupList = [] // ok ========================================
        }
    }
})

// export function createGroup(info) {
//     return async function createGroupThunk(dispatch, getState) {
//         dispatch(setStatus(STATUS.LOADING))
//         axios.post('/api/group/create/group', info)
//             .then(response => {
//                 
//                 // return response.status
//                 dispatch(setStatus(STATUS.IDLE))
//             })
//             .catch(error => {
//                 
//                 dispatch(setStatus(STATUS.ERROR))
//             });
//     }
// }

// ok ========================================
export function fetchGroupMembers(data) {
    return async function fetchGroupMembersThunk(dispatch, getState) {
        // dispatch.setStatus(STATUS.LOADING)
        // axios.get('/api/group/')
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/group/group_member/${data.group_id}`, { headers: { authorization: "Bearer " + data.token } })
            dispatch(setGroupMemberList(res.data.data))

        } catch (error) {

        }

    }
}


// ============================================
// get group by group name
export function getGroupByGroupName(group_name) {
    return async function getGroupByGroupNameThunk(dispatch, getState) {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/group/group_name/${group_name}`);
            // no dispatch here 
            // dispatch(setGroupInvitationList(res.data.data))

        } catch (error) {
        }
    }
}

// recommend a specific group
// group_id, user_id

// ok ========================================
export function recommendThisGroup(data) {
    return async function recommendThisGroupThunk(dispatch, getState) {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/group/recommend_this_group`, data);
        } catch (error) {
        }
    }
}



export const { resetGroupSlice, setOpenUaDialog, setCreateGroupType, setCurrentGroupInfoColorOptionsChange, setGroupShortInfoColorOptionsChange, increaseTotalPinPostInGroupShortInfo, decreaseTotalPinPostInGroupShortInfo, setActivityList, setMoreActivityInList, setMoreGroupInvitationInList, removeGroupInvitationFromList, setGroupInvitationList, setCurrentGroupInfoChangeRoleAndStatus, setCurrentGroupInfo, setCurrentGroupInfoBasicInfo, setCurrentGroupInfoGroupName, setCurrentGroupInfoGroupProfilePic, setHasMoreGroups, setGroupListCallNo, changeSuperAdminInGroup, setMyAllGroupList, removeGroupMember, setTotalRequestsInGroup, setTotalRequestsInGroupShortInfo, setGroupProfilePicInGroupList, removeGroupFromList, setGroupProfilePic, setNewGroupInList, setGroupShortInfo, updateMemberRoleInGroup, removeUserRequestsFromListInGroup, setUserRequestsListInGroup, setGroupMemberList, setRoleInGroup, setReportDialogOpenOrNot, setStatus, setGroupList, setGroupStatus, setGroupProfileId, setRecommendedGroupList, setInviteToGroupDialogOpenOrNot, setGroupProfileInfoListing } = groupSlice.actions
export default groupSlice.reducer