import { Avatar, Divider, ListItemSecondaryAction, Typography } from '@mui/material'
import React, { Suspense } from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collection from '@mui/icons-material/CollectionsBookmarkOutlined';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InvitationRoundedIcon from '@mui/icons-material/EmailRounded';
import { Engineering, CollectionsBookmarkOutlined, CollectionsBookmark, GroupAddOutlined, GroupAdd, HomeOutlined, Home, InsertInvitationOutlined, InsertInvitation, FavoriteBorderOutlined, FavoriteBorder, TipsAndUpdates, AddHome, AddHomeOutlined, Groups, GroupsOutlined } from '@mui/icons-material';
import New from '@mui/icons-material/FiberNew';
import theme from '../../Theme';
import { SubTitle1 } from '../../CustomComponents';
// import groups from '../../media/icons/groups.png'
import groups from '../../media/icons/groups2.png'
import bookmark from '../../media/icons/bookmark.png'
import home from '../../media/icons/home.png'
import invitation from '../../media/icons/invitation.png'
import createGroup from '../../media/icons/createGroup.png'
import library from '../../media/icons/photo.png'
import request from '../../media/icons/request.png'
import channel from '../../media/icons/channel.png'
import create_channel from '../../media/icons/create_channel.png'
import { setOpenUaDialog } from '../../store/groupSlice';
import { useDispatch, useSelector } from 'react-redux';

const SidebarGroupList = React.lazy(() => import('./SidebarGroupList'))

const NewSidebar = ({ handleChatOpen }) => {
  const [open, setOpen] = React.useState(false);
  // const [prompt, setPrompt] = React.useState(null)
  const token = JSON.parse(localStorage.getItem('token'))
  const { total_invitation, total_fav_request, total_group_request } = useSelector(state => state.post.invitationRequestInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // React.useEffect(() => {
  //   const handler = (event) => {
  //     setPrompt(event)
  //   }

  //   window.addEventListener('beforeinstallprompt', handler)

  //   return () => {
  //     window.removeEventListener('beforeinstallprompt', handler)
  //   }
  // }, [])

  // const handleAddToHomeScreenClick = () => {
  //   console.log(`valuesdfjlajl`)
  //   prompt.prompt()

  //   prompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //       // console.log('The app was added to the home screen')
  //     } else {
  //       // console.log('The app was not added to the home screen')
  //     }
  //   })
  // }
  // const hasInvitation = useSelector(state => state.auth.hasInvitation)
  // useEffect(() => {
  //   if (isSavedFolderOpen && folderList.length <= 0 && status !== 'empty') {
  //     setStatus('loading')
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/post/get_my_folders/${user_id}`, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {
  //         if (response.data.data.length <= 0) {
  //           setStatus('empty')
  //         } else {
  //           // setFolderList(response.data.data)
  //           dispatch(setFoldersList(response.data.data))
  //           setStatus('ok')
  //         }
  //       }).catch(error => {
  //         setStatus('error')
  //       })
  //   }
  // }, [isSavedFolderOpen])

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSavedFolderOpen = () => {

  }

  const createGrop = () => {
    navigate('/group-type')
    // navigate('/create-group')
  }
  const chatHistory = () => {
    navigate('/chat-history')
    // navigate('/create-group')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'white' }}>
      {/* <List> */}
      <Box sx={{ padding: '10px 15px', }}>
        <Box sx={{ fontSize: '14px', display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/' ? theme.palette.light.light : 'transparent', color: location.pathname === '/' ? theme.palette.primary.main : theme.palette.dark.main, borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={
            () => {
              if (!token) {
                dispatch(setOpenUaDialog(true))
                return
              }
              navigate('/')
            }
          }
        >
          <img src={home} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Home</SubTitle1>
        </Box>
        {/* <Box sx={{ color: theme.palette.dark.main, fontSize: '14px', display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/group-type' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate('/group-type')
          }}
        >
          <img src={createGroup} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Create Group</SubTitle1>
        </Box> */}
        {/* <Box sx={{ color: theme.palette.dark.main, fontSize: '14px', display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/create-room' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate("/create-room", {
              state: {
                status: 'create'
              }
            })
          }}
        >
          <img src={create_channel} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Create Channel</SubTitle1>
        </Box> */}
        <Box sx={{ color: theme.palette.dark.main, fontSize: '14px', display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/favourite-list' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate("/favourite-list")
          }}
        >
          <img src={bookmark} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Favourites</SubTitle1>
        </Box>
        <Box sx={{ color: theme.palette.dark.main, fontSize: '14px', display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/all_folders' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }} onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate("/all_folders")
        }}>
          {/* {
              (location.pathname === '/all_folders') ?
                <CollectionsBookmark sx={{ marginRight: "15px", fontSize: '20px' }} /> :
                <CollectionsBookmarkOutlined sx={{ marginRight: "15px", fontSize: '20px' }} />
            Saved Post
            } */}
          <img src={library} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Saved Post</SubTitle1>
        </Box>
        <Box sx={{ color: theme.palette.dark.main, fontSize: '14px', gap: '10px', display: 'flex', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/invitations' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate('/invitations')
          }}
        >
          {/* {
            (location.pathname === '/invitations') ?
              <InsertInvitation sx={{ marginRight: "15px", fontSize: '20px' }} /> :
              <InsertInvitationOutlined sx={{ marginRight: "15px", fontSize: '20px' }} />
          }
          Invitations */}
          <img src={invitation} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark' sx={{ flexGrow: 1 }}>Invitations</SubTitle1>
          {
            (total_invitation > 0) &&
            <span style={{ display: 'inline-block', backgroundColor: 'red', color: 'white', borderRadius: '20px', fontSize: '11px', lineHeight: '18px', width: '18px', height: '18px', textAlign: 'center' }}>{total_invitation}</span>
          }
        </Box>
        <Box sx={{ color: theme.palette.dark.main, fontSize: '14px', display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/requests' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate('/requests')
          }}
        >
          <img src={request} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark' sx={{ flexGrow: 1 }}>Requests</SubTitle1>
          {
            (total_fav_request > 0 || total_group_request > 0) &&
            <span style={{ display: 'inline-block', backgroundColor: 'red', color: 'white', borderRadius: '20px', fontSize: '11px', lineHeight: '18px', width: '18px', height: '18px', textAlign: 'center' }}>{total_fav_request + total_group_request}</span>
          }
        </Box>
        <Box sx={{ color: theme.palette.dark.main, display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/all_groups' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate('/all_groups')
          }}
        >
          <img src={groups} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Groups</SubTitle1>
        </Box>
        <Box sx={{ color: theme.palette.dark.main, display: 'flex', gap: '10px', alignItems: 'center', fontWeight: 600, backgroundColor: location.pathname === '/watch/room' ? theme.palette.light.light : 'transparent', borderRadius: '10px', padding: '12px', cursor: 'pointer' }}
          onClick={() => {
            if (!token) {
              dispatch(setOpenUaDialog(true))
              return
            }
            navigate("/all_channels")
          }}
        >
          <img src={channel} style={{ height: '25px', width: '25px' }} />
          <SubTitle1 color='dark'>Channel</SubTitle1>
        </Box>
        {/* <button onClick={handleAddToHomeScreenClick}>Add to home screen</button> */}
        {/* <Divider />
        <ListItemButton onClick={handleAddToHomeScreenClick}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "#5352ed", width: '30px', height: '30px' }} ><AddHomeOutlined fontSize='small'/></Avatar>
          </ListItemIcon>
          <ListItemText primary="Add to home screen" secondary="Create a shortcut" />
        </ListItemButton> */}
        <Divider />
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate("/help-us")
        }}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "#2ecc71", width: '30px', height: '30px' }}>ET</Avatar>
          </ListItemIcon>
          <ListItemText primary="ET Office" secondary="Help us to improve our service" />
        </ListItemButton>
        <Suspense fallback={<div>Loading</div>}>
          {
            (token) &&
            <SidebarGroupList handleChatOpen={handleChatOpen} />
          }
        </Suspense>
      </Box>
      {/* <Divider sx={{ mt: 1 }} /> */}
      {/* <Box sx={{ display: 'flex', gap: 1, padding: '16px', backgroundColor: '#cfccff', borderRadius: '4px', cursor: 'pointer' }} onClick={() => navigate("/", { replace: true })}>
            <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
              <HomeRoundedIcon color='primary' fontSize='small' />
            </Avatar>
            <Typography fontWeight={600} ml={3}>Home</Typography>
          </Box>
          <ListItemButton onClick={createGrop}>
            <ListItemIcon>
              <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
                <CreateGroup color='primary' fontSize='small' />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary="Create Group"
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/invitations")}>
            <ListItemIcon>
              <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
                <InvitationRoundedIcon fontSize='small' color='primary' />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary="Invitations"
            />
            <ListItemSecondaryAction>
              <New sx={{ color: 'red' }} fontSize='small' />
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton onClick={() => { navigate("/favourite-post", { replace: true }) }}>
            <ListItemIcon>
              <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
                <WhatshotIcon fontSize='small' color='primary' />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary="Hot Post"
            />
          </ListItemButton>
          <ListItemButton onClick={() => { navigate("/favourite-list") }}>
            <ListItemIcon>
              <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
                <Favorite fontSize='small' color='primary' />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary="Favourites"
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/all_folders")}>
            <ListItemIcon>
              <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
                <Collection color='primary' fontSize='small' />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Saved Post" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/activities")}>
            <ListItemIcon>
              <Avatar sx={{ height: 30, width: 30, backgroundColor: '#ecf0f1' }}>
                <Engineering color='primary' fontSize='small' />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Activities" />
          </ListItemButton>
          <Divider /> */}
      {/* <ListItemButton onClick={() => { navigate("/help-us") }}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "#2ecc71", width: '30px', height: '30px' }}>ET</Avatar>
          </ListItemIcon>
          <ListItemText primary="ET Office" secondary="Help us to improve our service" />
        </ListItemButton>
        <Suspense fallback={<div>Loading</div>}>
          <SidebarGroupList handleChatOpen={handleChatOpen} />
        </Suspense> */}
      {/* <ListItemButton sx={{ backgroundColor: '#f1f2f6', borderRadius: '4px', mt: 1 }} onClick={() => { navigate("/next_update") }}>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: "#cfccff", width: '30px', height: '30px' }}>
                <TipsAndUpdates color='primary' fontSize='inherit' />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Next update" secondary="upcoming features info" />
          </ListItemButton> */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={() => navigate('/affiliate-disclosure')}>Affiliate Disclosure</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
        <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
      </Box>
      {/* </List> */}
    </Box>
  )
}

export default NewSidebar