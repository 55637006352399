import * as React from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../Theme'
import { Alert, Button, CircularProgress, Container, IconButton, Typography } from '@mui/material';
import sms from '../../../media/images/sms.png'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyIcon from '@mui/icons-material/Key';
import axios from 'axios';


const SubmitButton = styled(Button)((theme) => ({
    fontWeight: '600',
    borderRadius: 0,
    backgroundColor: '#d1ddfa',
    color: '#4b7bec',
    padding: '12px 26px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#baccf8',
    }
}))

// const theme = createTheme();
export default function ForgetPassword() {
    const [mobileNo, setMobileNo] = React.useState("")
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("")
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        if (mobileNo.trim().length < 8) {
            setMessage("Invalid mobile no.")
            return
        }
        setLoading(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/auth/forget-password`, { mobileNo: mobileNo.trim() })
            .then(res => {
                if (res.data.status === 1) {
                    navigate('/verification', { state: { mobile_no: res.data.data.mobile_no, mobile_prefix: res.data.data.mobile_prefix, dest: 'old_user' }, replace: true })

                } else {
                    setMessage("Something went wrong.")
                }
                setLoading(false)
            }).catch(error => {
                setMessage("Something went wrong.")
                setLoading(false)
            })
    }
    return (
        <ThemeProvider theme={theme}>

            <Container component="main" maxWidth='xs' >
                {/* <CssBaseline /> */}
                <Box component='form'
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        bgcolor: 'white',
                        padding: '12px',
                        borderRadius: '4px'
                        // maxWidth:'xs'
                    }}
                    onSubmit={handleSubmit}
                >
                    {/* <Typography variant='h6' color='gray'>Forget Password</Typography> */}
                    <img src={sms} style={{ width: '200px' }} />
                    <Typography variant='h6' color='primary' my={2} fontWeight='600'>
                        <IconButton color="primary" aria-label="upload picture" component="label">
                            <KeyIcon />
                        </IconButton>
                        Forget Password</Typography>
                    <Typography variant='body2' color='gray'>Enter the mobile no associated with your account</Typography>
                    {/* <Alert sx={{width:'100%'}} severity="info">Enter the OTP sent to <strong>+{location.state.mobile_prefix}{location.state.mobile_no}</strong></Alert> */}
                    {
                        (message !== "") &&
                        <Alert severity="error" sx={{ width: '96%', mt: 2 }}>{message}</Alert>
                    }
                    <TextField
                        fullWidth
                        sx={{ my: 2 }}
                        id="mobile-no-field"
                        label="Enter Mobile no."
                        type="number"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                    // autoComplete="current-password"
                    />
                    {/* <Box sx={{ textAlign: 'end', width: '100%' }}>
                        <SubmitButton type='submit'>Submit</SubmitButton>
                    </Box> */}
                    {/* <Button fullWidth variant='contained' disableElevation color='primary' sx={{ textTransform: 'capitalize' }}>Send OTP</Button> */}


                    <Box sx={{ m: 1, position: 'relative', width: '100%' }}>
                        <Button
                            type='submit'
                            fullWidth
                            disableElevation
                            color='primary'
                            variant="contained"
                            sx={{ textTransform: 'capitalize' }}
                            disabled={loading}
                        // onClick={handleButtonClick}
                        >
                            Send OTP
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#6C63FF',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    <Button fullWidth variant='text' color='primary' startIcon={<ArrowBackIcon />} sx={{ textTransform: 'capitalize' }} onClick={() => navigate(-1)}>Back to login</Button>
                </Box>
                {/* <Box sx={{height:'30px',bgcolor:'red'}}>hello</Box> */}
            </Container>
        </ThemeProvider>
    );
}
