import { AppBar, Backdrop, Box, Button, Chip, CircularProgress, Container, Divider, Toolbar, Typography } from '@mui/material'
import theme from "../../../Theme"
import { ThemeProvider } from '@mui/material/styles';
import React from 'react'
import { Logo } from '../../../CustomComponents';
import InterestItem from './InterestItem';
import { Spa, Handshake, Cookie, VolunteerActivism, EmojiPeople, LocalFlorist, Festival, Theaters, FitnessCenter, SportsEsports, AttachMoney, MusicNote, CenterFocusStrong, AutoStories, SportsHandball, Laptop, Kitesurfing, Draw, TagFacesOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const GroupTopic = () => {
    const [items, setItems] = React.useState([])
    const [processing, setProcessing] = React.useState(false)
    const interests = [{ label: "Art & Design", icon: <Spa /> }, { label: "Business", icon: <Handshake /> }, { label: "Cooking", icon: <Cookie /> }, { label: "Cultural Activity", icon: <VolunteerActivism /> }, { label: "Dance", icon: <EmojiPeople /> }, { label: "Entertainment", icon: <TagFacesOutlined /> }, { label: "Fashion", icon: <LocalFlorist /> }, { label: "Festivals", icon: <Festival /> }, { label: 'Film', icon: <Theaters /> }, { label: "Fitness", icon: <FitnessCenter /> }, { label: "Game", icon: <SportsEsports /> }, { label: "Investing", icon: <AttachMoney /> }, { label: "Music", icon: <MusicNote /> }, { label: "Photography", icon: <CenterFocusStrong /> }, { label: "Reading", icon: <AutoStories /> }, { label: "Sports", icon: <SportsHandball /> }, { label: "Technology", icon: <Laptop /> }, { label: "Travel", icon: <Kitesurfing /> }, { label: "Writing", icon: <Draw /> }]
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const token = JSON.parse(localStorage.getItem("token"))
    const handleSubmitTopics = () => {
        setProcessing(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/group/group/topics`, {topics:items,groupId:location.state.id}, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setProcessing(false)
                // navigate("/interests", { replace: true })
                // navigate("/group_feed", {
                //     state: {
                //         id:location.state.id
                //     },
                //     replace: true
                // })
                navigate("/")
            }).catch(error => {
                setProcessing(false)
            })
    }
    return (
        <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'white' }}>
            <ThemeProvider theme={theme}>
                {
                    (location.pathname === "/interests") &&
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="sticky">
                            <Toolbar>
                                <Logo variant="h4" sx={{ flexGrow: 1 }}>
                                    EmotionTown
                                </Logo>
                            </Toolbar>
                        </AppBar>
                    </Box>
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container sx={{ mt: 1, backgroundColor: 'white', p: 2 }} maxWidth='xs'>
                    <Typography fontWeight={600} my={2}>{location.pathname === '/interests' ? "Choose your interests:" : "Choose Group Topics:"} </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {
                            interests.map((interest, index) => {
                                return <InterestItem key={index} index={index} item={interest} setItems={setItems}/>
                            })
                        }
                    </Box>
                    <Divider sx={{ margin: '20px 0px' }} />
                    <Box sx={{ textAlign: 'left' }}>
                                <Button variant='contained' sx={{ ml: 1 }} disabled={items.length > 0 ? false : true} onClick={handleSubmitTopics}>Save Topic</Button>
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    )
}

export default GroupTopic