import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Collapse, Divider, LinearProgress, TextField, Typography } from '@mui/material'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import CancelShare from '@mui/icons-material/SyncDisabled';
import Share from '@mui/icons-material/Loop';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import React, { useRef } from 'react'
import CardTopHeader from './CardTopHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import moment from 'moment/moment';
import Comment from './Comment';
import { entities } from "../HtmlEntity"
import { setOpenUaDialog } from '../../store/groupSlice';
import AllPostCardTopHeader from '../AllPost/AllPostCardTopHeader';
import { editPostDescription, increasePostCommentCount, setCurrentPostInfo, setFullPostDetails, setOpenBookmarkDialog, setPollPostOptions, setPostToSave, setRemovePostFromFolder, setVoteInPost } from '../../store/postSlice';
import { FilledButton } from '../../CustomComponents';
import SharePostMenu from '../Menu/SharePostMenu';
import { BookmarkAddOutlined } from '@mui/icons-material';

const MediaPoll = ({ val, value }) => {
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false)
    const [description, setDescription] = React.useState()
    const [isEditable, setisEditable] = React.useState(false)
    const [comment, setComment] = React.useState("")
    const [newComment, setNewComment] = React.useState({})
    const [totalComment, setTotalComment] = React.useState(0)
    const [pollOptions, setPollOptions] = React.useState([])
    const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
    const [is_saved, set_is_saved] = React.useState(false)
    const [is_commented, set_is_commented] = React.useState(false)
    const [is_reacted, set_is_reacted] = React.useState(null)
    const [isPinned, setIsPinned] = React.useState(null)
    const [totalVoted, setTotalVoted] = React.useState(0)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [updating, setUpdating] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const { role } = useSelector(state => state.group.groupShortInfo)
    const socket = useOutletContext()
    const location = useLocation()
    // const [lineCount, setLineCount] = React.useState(0);
    const dispatch = useDispatch()
    const openOptionMenu = Boolean(anchorE2)
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user")) || {}
    const token = JSON.parse(localStorage.getItem('token'))
    const navigate = useNavigate()
    const ref = useRef(null)
    React.useEffect(() => {
        setTotalComment(val.total_comment)
        set_is_saved(val.is_saved)
        set_is_commented(val.is_commented)
        if (val.react !== null) {
            set_is_reacted(val.react)
        }
        if (val.total_react !== 0) {
            setTotalVoted(val.total_react)
        }
        if (val.is_pinned !== null) {
            setIsPinned(val.is_pinned)
        }
        if (val.description && val.description.length > 0) {
            setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
        }
        if (pollOptions.length <= 0 && val.options && val.options.length > 1) {
            setPollOptions(val.options)
        }
    }, [])

    React.useEffect(() => {
        set_is_saved(val.is_saved)
    },[val.is_saved])

    const toggleReadMore = () => setIsShowMore((show) => !show);

    const handleRemovePost = () => {
        set_is_saved(false)
    }

    const callBackFunction = (entries) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    const options = {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(callBackFunction, options)
        if (ref.current) {
            // observer.observe(refs)
            observer.observe(ref.current)
            if (isVisible) {
                if (!val.options && loading === false) {
                    setLoading(true)
                    axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/poll_options/${val.xz12uied7}`)
                        .then(response => {
                            setLoading(false)
                            if (response.data.status === 1) {
                                setPollOptions(response.data.data)
                                // if (value === 'pin') {
                                //     dispatch(setPollPinPostOptions({ id: val.xz12uied7, options: response.data.data }))
                                // } else if (value === 'saved') {
                                //     dispatch(setPollSavePostOptions({ id: val.xz12uied7, options: response.data.data }))
                                // } else if (value === 'myPost') {
                                //     dispatch(setPollMyPostOptions({ id: val.xz12uied7, options: response.data.data }))
                                // } else {
                                // }
                                dispatch(setPollPostOptions({ feed_value: value, id: val.xz12uied7, options: response.data.data }))
                            } else {
                                // show not found poll item
                            }
                        }).catch(error => {
                            setLoading(false)
                            dispatch(setSnackMessage("Something went wrong. Try later."))
                            dispatch(setOpenSnack(true))
                        })
                }
            }
        }
        return () => observer.disconnect()
    }, [ref, options])


    const handleCommentSubmit = (e) => {
        e.preventDefault()
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        setCreateCommentStatus('loading')
        const data = { post_id: val.xz12uied7, group_id: val.group_id, comment, message: `comments on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`, user_to_notify: val.u_id,from_where:val.type,topic:'postComment' }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                const new_comment = {
                    comment_id: response.data.comment_id,
                    comment,
                    added_on: moment().format(),
                    user_id,
                    profile_pic,
                    total_reply: 0,
                    total_reacted: 0,
                    is_reacted: null,
                    name
                }
                setNewComment(new_comment)
                set_is_commented(true)
                setTotalComment(totalComment + 1)
                // if (value === 'pin') {
                //     dispatch(increasePinPostCommentCount(val.xz12uied7))
                // } else if (value === 'saved') {
                //     dispatch(increaseSavePostCommentCount(val.xz12uied7))
                // } else if (value === 'myPost') {
                //     dispatch(increaseMyPostCommentCount(val.xz12uied7))
                // } else {
                // }
                dispatch(increasePostCommentCount({ feed_value: value, data: val.xz12uied7 }))
                if (response.data.status === 1) {
                    if (val.creator_id !== user_id) {
                        socket?.emit('commentOnPost', { receiverid: val.u_id })
                    }
                }
                setCreateCommentStatus('ok')
            }).catch(error => {
                setCreateCommentStatus('error')
            })
        // dispatch(submitComment(data))
        setComment("")
    };

    const fullImageHandler = () => {
        dispatch(setFullPostDetails(val.org_file))
        navigate("/full-image")
    }

    const handleVisitSharedPeopleProfile = (shared_by_user_id, shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        navigate(`/profile/${shared_by_user_id}/${shared_from_group_id}`)
    }
    const handleVisitSharedGroupProfile = (shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        navigate(`/group-profile/${shared_from_group_id}`)
    }

    const handleSharePost = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (val.type === 'public') {
            // if (value === 'myPost') {
            //     dispatch(setMyPostToSave(val.xz12uied7))
            // } else if (value === 'saved') {
            //     dispatch(setSavePostToSave(val.xz12uied7))
            // } else if (value === 'pin') {
            //     dispatch(setPinPostToSave(val.xz12uied7))
            // } else {
            // }
            dispatch(setPostToSave({ feed_value: value, data: val.xz12uied7 }))
            navigate("/shared-to-group", {
                state: {
                    value: value
                },
            })
        } else {
            dispatch(setSnackMessage(`You can't share from a ${val.type} group.`))
            dispatch(setOpenSnack(true))
        }
    }

    const handleUpdateDescription = (e) => {
        e.preventDefault()
        setUpdating(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/post/updatePost`, { post_id: val.xz12uied7, description }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setisEditable(false)
                setUpdating(false)
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
                // if (value === 'pin') {
                //     dispatch(editPinPostDescription({ post_id: val.xz12uied7, description }))
                // } else if (value === 'saved') {
                //     dispatch(editSavePostDescription({ post_id: val.xz12uied7, description }))
                // } else if (value === 'myPost') {
                //     dispatch(editMyPostDescription({ post_id: val.xz12uied7, description }))
                // } else {
                // }
                dispatch(editPostDescription({ feed_value: value, post_id: val.xz12uied7, description }))
            }).catch(error => {
                setUpdating(false)
                dispatch(setSnackMessage("Something went wrong. Try later."))
                dispatch(setOpenSnack(true))
            })
    }

    const removeFromFolder = (now_post_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (value !== 'saved') {
            handleRemovePost()
        }
        if (value === 'saved') {
            dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/removed_saved_post/${now_post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    // if (value === 'pin') {
                    //     dispatch(setRemovePinPostFromFolder(now_post_id))
                    // } else if (value === 'saved') {

                    // } else if (value === 'myPost') {
                    //     dispatch(setRemoveMyPostFromFolder(now_post_id))
                    // } else {
                    // }
                    dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                } else {
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }

    const handleReaction = (option_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // if(is_reacted !== null) {
        //     return
        // }
        // if (is_reacted === option_id) {
        //     // remove reaction 
        //     set_is_reacted(null)
        //     setTotalVoted(totalVoted - 1)
        //     axios.delete(`${process.env.REACT_APP_API_URL}/api/post/remove/vote/${val.xz12uied7}/${option_id}`, { headers: { authorization: "Bearer " + token } })
        //         .then(response => {
        //             if (value === 'pin') {
        //                 dispatch(setVoteInPinPost({ id: val.xz12uied7, is_voted: null, total_react:totalVoted }))
        //             } else if (value === 'saved') {

        //             } else {
        //                 dispatch(setVoteInPost({ id: val.xz12uied7, is_voted: null, total_react:totalVoted }))
        //             }
        //             // setEmojiNumber(null)
        //         }).catch(error => {
        //         })
        // } 
        // else if(is_reacted && is_reacted !== option_id) {
        //         // update vote 
        //         set_is_reacted(option_id)
        //         let data = {react_value:option_id,post_id:val.xz12uied7}
        //         axios.delete(`${process.env.REACT_APP_API_URL}/api/post/update/react_post`,data, { headers: { authorization: "Bearer " + token } })
        //         .then(response => {
        //             if (value === 'pin') {
        //                 dispatch(setVoteInPinPost({ id: val.xz12uied7, is_voted: option_id, total_react:totalVoted }))
        //             } else if (value === 'saved') {

        //             } else {
        //                 dispatch(setVoteInPost({ id: val.xz12uied7, is_voted: option_id, total_react:totalVoted }))
        //             }
        //             // setEmojiNumber(null)
        //         }).catch(error => {
        //         })
        // }
        if (is_reacted === null) {
            // create reaction
            set_is_reacted(option_id)
            setTotalVoted(totalVoted + 1)
            var options = pollOptions.map((option, index) => {
                if (option.option_id === option_id) {
                    return { ...option, total_vote: option.total_vote + 1 }
                } else {
                    return option
                }
            })
            setPollOptions(options)
            // create react     // user_id,post_id,react_value,group_id
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/vote`, { post_id: val.xz12uied7, react_value: option_id, option_id, group_id: val.group_id, post_type: 'textpoll', message: `added a vote on your post in ${val.group_name} group`, notified_user_id: val.u_id,from_where:val.type,topic:'postReact' }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    // if (value === 'pin') {
                    //     dispatch(setVoteInPinPost({ id: val.xz12uied7, is_voted: option_id, total_react: val.total_react + 1, options }))
                    // } else if (value === 'saved') {
                    //     dispatch(setVoteInSavePost({ id: val.xz12uied7, is_voted: option_id, total_react: val.total_react + 1, options }))
                    // } else if (value === 'myPost') {
                    //     dispatch(setVoteInMyPost({ id: val.xz12uied7, is_voted: option_id, total_react: val.total_react + 1, options }))
                    // } else {
                    // }
                    dispatch(setVoteInPost({ feed_value: value, id: val.xz12uied7, is_voted: option_id, total_react: val.total_react + 1, options }))
                    if (response.data.status === 1) {
                        if (val.creator_id !== user_id) {
                            socket?.emit("reactOnPost", { receiverid: val.u_id })
                        }
                    }
                    // setEmojiNumber(emojiNo)
                }).catch(error => {
                })
        }
    }

    const handleImageView = (file) => {
        dispatch(setFullPostDetails(file))
        navigate(`/full-image`)
    }
    const handleBookmarkPost = (event) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if(val.is_saved === null) {
            dispatch(setCurrentPostInfo({ post_id: val.xz12uied7, feed_value: value }))
            dispatch(setOpenBookmarkDialog(true))
        }else{
            removeFromFolder(val.xz12uied7)
        }
    }

    return (
        <>
            <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type={val.type} post_id={val.xz12uied7} value={value} group_id={val.group_id} creator_id={val.creator_id} group_name={val.group_name}/>
            <Card elevation={0} ref={ref} sx={{ backgroundColor: val.is_colored === 'no' ? 'white' : val.role === 'Super Admin' ? '#d2f9de' : val.role === 'Admin' ? '#e6eeff' : 'white', width: '100%', borderRadius: { xs: '0px', sm: '10px' }, marginBottom: 2 }}>
                {
                    val.shared_by &&
                    <Alert icon={false} severity="info" sx={{ borderRadius: '0px' }}>
                        shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
                    </Alert>
                }
                {/* hello */}
                {
                    (location.pathname === '/') ?
                        <AllPostCardTopHeader isPinned={isPinned} post_type='mediapoll' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} group_name={val.group_name} group_profile_pic={val.group_profile_pic} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} /> :
                        <CardTopHeader isPinned={isPinned} post_type='mediapoll' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} />
                }
                {/* <CardTopHeader isPinned={isPinned} setIsPinned={setIsPinned} post_type='mediapoll' setisEditable={setisEditable} role={role} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} /> */}
                <CardContent sx={{ pt: 0, pb: 1 }}>
                    {(description && description.length > 250 && !isShowMore) ?
                        <>
                            <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                                {description.substring(0, 250)}...
                            </Typography>
                            <Typography component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</Typography>
                        </>
                        :
                        <>
                            {
                                <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                                    {description}
                                </Typography>
                            }
                            {
                                description && description.length > 250 ?
                                    <Typography sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</Typography> :
                                    null
                            }
                        </>
                    }
                    {
                        isEditable &&
                        <Box component='form' onSubmit={handleUpdateDescription}>
                            <TextField
                                fullWidth
                                value={description}
                                id="edit_basic_post_dialog_textfield_id"
                                label="edit post description"
                                multiline
                                rows={2}
                                name="edit_description_id"
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ mb: 1, mt: 2 }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Button disableElevation variant="outlined" onClick={() => setisEditable(false)}>Cancel</Button>
                                <Box sx={{ position: 'relative', ml: 1 }}>
                                    <Button disableElevation type='submit' disabled={(!description || updating) ? true : false} variant="contained">Update</Button>
                                    {updating && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#44bd32',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        (val.link) &&
                        <Box>
                            {(val.link && val.link.length > 100) ?
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                    {val.link.substring(0, 100)} ...
                                </a> :
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                    {val.link}
                                </a>
                            }
                        </Box>
                    }
                </CardContent>
                <Box sx={{ overflowX: 'scroll', display: 'flex' }}>
                    {
                        pollOptions && (pollOptions.length > 1) && pollOptions.map((option, index) => {
                            var percentage_value = Math.round((option.total_vote * 100) / totalVoted) || 0
                            // var imgData = ['https://images.pexels.com/photos/13811701/pexels-photo-13811701.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load','https://images.pexels.com/photos/8329677/pexels-photo-8329677.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load']
                            return <Box key={option.option_id} sx={{ padding: '2px', width: '300px', marginX: '10px', backgroundColor: 'white', paddingBottom: '10px', border: '1px solid #b2bec3' }}>
                                <Box sx={{ width: '300px', height: '300px', backgroundColor: '#2d3436' }} onClick={() => handleImageView(option.file)}>
                                    <img src={option.file} alt="no pic" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                                <Box sx={{ px: 1 }}>

                                    <Typography variant='subtitle2' marginBottom='5px'>{option.text_option !== 'null' ? option.text_option : ''}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {
                                            (is_reacted === null) &&
                                            <Button variant='contained' sx={{ width: '75%' }} color={(option.option_id === is_reacted) ? 'primary' : 'inherit'} onClick={() => handleReaction(option.option_id)}>Vote</Button>
                                        }
                                        {/* {
                                        (is_reacted !== null) &&
                                        <Typography sx={{ width: '25%', paddingLeft: '10px', color: 'gray' }}>{percentage_value}%</Typography>
                                    } */}
                                        {
                                            (is_reacted !== null || !token) &&
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <Box sx={{ width: '100%', mr: 1 }}>
                                                    <LinearProgress variant="determinate" color={option.option_id === is_reacted ? 'primary' : 'inherit'} value={percentage_value} sx={{ height: '12px', borderRadius: '2px', color: 'gray' }} />
                                                </Box>
                                                <Box sx={{ minWidth: 35 }}>
                                                    <Typography variant="body2" color="text.secondary">{percentage_value}%</Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Box>

                        })
                    }
                    {
                        (!val.options && loading === true) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px', width: '100%' }}>
                            <CircularProgress size={24} />
                        </Box>
                    }
                </Box>
                <Typography fontSize='12px' color='grey' mx={2} mt={1}>{totalVoted} votes</Typography>
                <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                            setExpanded(!expanded)
                        }}>
                            {
                                (is_commented) ?
                                    <CommentIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} /> :
                                    <CommentOutlinedIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} />
                            }
                            {/* <Comment fontSize='small' sx={{ mr: 1, color: "#2d3436" }} /> */}
                            <Typography color={is_commented ? 'primary' : 'inherit'} sx={{ fontSize: '12px' }}>
                                {totalComment}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(event) => setAnchorE2(event.currentTarget)}>
                            {
                                (val.type === "public") ?
                                    <Share fontSize='small' sx={{ marginRight: '5px', color: "#2d3436" }} /> :
                                    <CancelShare fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} />
                            }
                            {
                                // (val.type === 'public' || (val.shared_by && val.shared_by !== null)) &&
                                (val.type === 'public' || (!val.shared_by)) &&
                                <Typography sx={{ fontSize: '12px' }}>
                                    {val.total_share}
                                </Typography>
                            }
                        </Box>
                        <Checkbox
                            checked={is_saved || false}
                            onChange={handleBookmarkPost}
                            inputProps={{ 'aria-label': 'controlled' }}
                            icon={<BookmarkAddOutlined fontSize='small' />}
                            checkedIcon={<BookmarkAddedIcon color='dark' fontSize='small' />}
                        />
                        {/* {
                            is_saved ?
                                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => removeFromFolder(val.xz12uied7)}>
                                    <BookmarkAddedIcon fontSize='small' sx={{ mr: 1, color: "#2d3436" }} />
                                </Box> :
                                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                                    if (!token) {
                                        dispatch(setOpenUaDialog(true))
                                        return
                                    }
                                    set_is_saved(true)
                                    navigate(`/folders/${val.xz12uied7}/${val.group_id}/${value}`)
                                }
                                }
                                >
                                    <BookmarkAddOutlinedIcon fontSize='small' sx={{ mr: 1, color: "#2d3436" }} />
                                </Box>
                        } */}
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                </CardActions>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                        display: 'flex',
                        margin: '6px 10px 10px'
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleCommentSubmit}
                >
                    <Avatar variant='rounded' src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{ width: 32, height: 32 }} />
                    <input
                        id="basic_comment_textarea_id"
                        value={comment}
                        placeholder='comment something'
                        style={{
                            backgroundColor: '#EAEAF2',
                            padding: '8px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            borderRadius: '6px',
                            margin: "0px 10px"
                        }}
                        name="description"
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Box sx={{ position: 'relative' }}>
                        {/* <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={loading ? true : (basicOrRatedPostData.file !== '' || basicOrRatedPostData.description !== '') ? false : true} disableElevation>Post</Button> */}
                        <FilledButton type='submit' size='small' variant="contained" disabled={(createCommentStatus === 'loading') ? true : false} disableElevation>Send</FilledButton>

                        {(createCommentStatus === 'loading') && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: '0px 8px 24px' }}>
                        {
                            (!token) ?
                                <Typography mt={1} sx={{ textAlign: 'center', fontWeight: 600, color: '#2d3436', fontSize: '14px' }}>Sign In for read comments</Typography> :
                                <Comment post_id={val.xz12uied7} role={val.role} is_colored={val.is_colored} value={value} newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} type={val.type}/>
                        }
                    </CardContent>
                </Collapse>
            </Card>
        </>
    )
}

export default MediaPoll