import { AppBar, Backdrop, Box, Button, Chip, CircularProgress, Container, Divider, Toolbar, Typography } from '@mui/material'
import theme from "../../../Theme"
import { ThemeProvider } from '@mui/material/styles';
import React from 'react'
import { Logo } from '../../../CustomComponents';
import InterestItem from './InterestItem';
import { Spa, Handshake, Cookie, VolunteerActivism, EmojiPeople, LocalFlorist, Festival, Theaters, FitnessCenter, SportsEsports, AttachMoney, MusicNote, CenterFocusStrong, AutoStories, SportsHandball, Laptop, Kitesurfing, Draw, TagFacesOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4'
import { setOpenSnack, setSnackMessage } from '../../../store/authSlice';
import { useDispatch } from 'react-redux';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Emotiontown.com'}
            {/* <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link> */}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Interests = () => {
    const [items, setItems] = React.useState([])
    const [processing, setProcessing] = React.useState(false)
    const interests = [{ label: "Art & Design", icon: <Spa /> }, { label: "Business", icon: <Handshake /> }, { label: "Cooking", icon: <Cookie /> }, { label: "Cultural Activity", icon: <VolunteerActivism /> }, { label: "Dance", icon: <EmojiPeople /> }, { label: "Entertainment", icon: <TagFacesOutlined /> }, { label: "Fashion", icon: <LocalFlorist /> }, { label: "Festivals", icon: <Festival /> }, { label: 'Film', icon: <Theaters /> }, { label: "Fitness", icon: <FitnessCenter /> }, { label: "Game", icon: <SportsEsports /> }, { label: "Investing", icon: <AttachMoney /> }, { label: "Music", icon: <MusicNote /> }, { label: "Photography", icon: <CenterFocusStrong /> }, { label: "Reading", icon: <AutoStories /> }, { label: "Sports", icon: <SportsHandball /> }, { label: "Technology", icon: <Laptop /> }, { label: "Travel", icon: <Kitesurfing /> }, { label: "Writing", icon: <Draw /> }]
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])
    const handleSubmitInterests = () => {
        setProcessing(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/create/interests`, { interests: items, ncv: location.state.ncv })
            .then(response => {
                setProcessing(false)
                if (response.data.status === 1) {
                    navigate("/signin", { replace: true })
                } else {
                    dispatch(setSnackMessage(`Something went wrong. Try later.`))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                setProcessing(false)
            })
    }
    return (
        // <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'white' }}>
        //     <ThemeProvider theme={theme}>
        //         {
        //             (location.pathname === "/interests") &&
        //             <Box sx={{ flexGrow: 1 }}>
        //                 <AppBar position="sticky">
        //                     <Toolbar>
        //                         <Logo variant="h4" sx={{ flexGrow: 1 }}>
        //                             EmotionTown
        //                         </Logo>
        //                     </Toolbar>
        //                 </AppBar>
        //             </Box>
        //         }
        // <Backdrop
        //     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //     open={processing}
        // >
        //     <CircularProgress color="inherit" />
        // </Backdrop>
        //         <Container sx={{ mt: 1, backgroundColor: 'white', p: 2 }} maxWidth='xs'>
        //             <Typography fontWeight={600} my={2}>{location.pathname === '/interests' ? "Choose your interests:" : "Choose Group Topics:"} </Typography>
        //             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        //                 {
        //                     interests.map((interest, index) => {
        //                         return <InterestItem key={index} index={index} item={interest} setItems={setItems} />
        //                     })
        //                 }
        //             </Box>
        //             <Divider sx={{ margin: '20px 0px' }} />
        //             <Box sx={{ textAlign: 'left' }}>
        //                 <Button variant='contained' sx={{ ml: 1 }} disabled={items.length > 0 ? false : true} onClick={handleSubmitInterests}>Submit Interests</Button> :
        //             </Box>
        //         </Container>
        //     </ThemeProvider>
        // </Box>
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', backgroundColor: theme.palette.primary.main, height: '100vh' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={processing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box className="img_container" sx={{ flex: 6, display: { xs: 'none', md: 'block' }, position: 'relative', height: '100%', overflow: 'hidden' }}>
                    <img src='https://images.pexels.com/photos/7219325/pexels-photo-7219325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    {/* <img src='https://images.pexels.com/photos/8954915/pexels-photo-8954915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{width:'100%',height:'100%',objectFit:'cover'}}/> */}
                    {/* <img src='https://images.pexels.com/photos/7219325/pexels-photo-7219325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' style={{width:'100%',height:'100%',objectFit:'cover',filter: 'grayscale(1000%)',WebkitFilter:'grayscale(100%)'}}/> */}
                    {/* <Avatar className='avatar' src='https://images.pexels.com/photos/1037989/pexels-photo-1037989.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'120px',height:'120px',position:'absolute',top:"202px",left:'50%'}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/1205033/pexels-photo-1205033.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',top:"318px",right:'100px',opacity:0.7}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/5225115/pexels-photo-5225115.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=699.825&fit=crop&h=1133.05' sx={{width:'80px',height:'80px',position:'absolute',bottom:"30%",right:'345px',display:{md:'none',lg:'inline-block'},opacity:0.6}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/4658128/pexels-photo-4658128.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',top:"100px",right:'20px',opacity:0.7}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/1498337/pexels-photo-1498337.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'95px',height:'95px',position:'absolute',top:"20px",left:'40%',opacity:0.7}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/1310522/pexels-photo-1310522.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',bottom:"45%",left:'100px',opacity:0.7}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/1090393/pexels-photo-1090393.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'130px',height:'130px',position:'absolute',bottom:"20px",right:'-56px'}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/783243/pexels-photo-783243.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'130px',height:'130px',position:'absolute',top:"20px",left:'20px'}}/>
                  <Avatar className='avatar' src='https://images.pexels.com/photos/2531093/pexels-photo-2531093.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{width:'100px',height:'100px',position:'absolute',bottom:"80px",left:'28%',opacity:0.7}}/> */}
                </Box>
                <Box sx={{ flex: 3, backgroundColor: 'white', p: 3 }}>
                    <Typography fontSize='25px' color='dark' fontWeight={800} my={4} pl={1}>Emotiontown</Typography>
                    <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {
                            interests.map((interest, index) => {
                                return <InterestItem key={index} index={index} item={interest} setItems={setItems} />
                            })
                        }
                    </Box>
                    <Box sx={{ textAlign: 'end', mt: 2 }}>
                        <Button variant='contained' disabled={items.length > 0 ? false : true} onClick={handleSubmitInterests}>Submit Interests</Button>
                    </Box>
                    <Copyright sx={{ mt: 2 }} />
                    <Box sx={{ padding: '10px', textAlign: 'center', mt: 2,display:'flex',flexWrap:'wrap' }}>
                        {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
                        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
                        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
                        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/affiliate-disclosure')}>Affiliate Disclosure</Typography>
                        <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
                        <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Interests