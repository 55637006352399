import { Alert, Avatar, Box, CircularProgress, Divider, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { decreasePinPostCommentCount, decreasePostCommentCount } from '../../store/postSlice'
import ReplyItem from './ReplyItem'

const Reply = ({ comment_id, total_replys, set_total_replys, newReply, setNewReply,post_id,type,commented_by }) => {
    const [replys, setReplys] = React.useState([])
    const [deleteReplyId, setDeleteReplyId] = React.useState('')
    const [replyStatus, setReplyStatus] = React.useState('ok')
    const { uid: user_id } = JSON.parse(localStorage.getItem('user'))
    const token = JSON.parse(localStorage.getItem('token'))
    const dispatch = useDispatch()
    // React.useEffect(() => {
    //     if (expanded === true && comment_list.length <= 0) {
    //       axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/comment/${val.xz12uied7}/${user_id}`, { headers: { authorization: "Bearer " + token } })
    //         .then(response => {
    //           // dispatch(setFetchedComment(response.data.data))
    //           setComment_list(response.data.data)
    //         }).catch(error => {
    //         })
    //     }
    //   }, [expanded])
    React.useEffect(() => {
        if (replys.length <= 0) {
            setReplyStatus('loading')
            axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/reply/${comment_id}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.length === 0) {
                        setReplyStatus('empty')
                    } else {
                        setReplyStatus('ok')
                        setReplys(response.data.data)
                    }
                }).catch(error => {
                    setReplyStatus('error')
                })
        }
    }, [comment_id])

    React.useEffect(() => {
        if (newReply.reply_id) {
            setReplys(replys => {
                return [newReply, ...replys]
            })
        }
        setNewReply({})
    }, [newReply.reply_id])

    const deleteReply = (reply_id) => {
        setDeleteReplyId(reply_id)
        set_total_replys(total_replys - 1)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/deleteReply/${reply_id}/${comment_id}/${post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setDeleteReplyId('')
                setReplys(replys.filter(el => el.reply_id !== reply_id))
            }).catch(error => {
            })
    }

    return (
        <>
            {
                (replyStatus === 'loading') &&
                <Box sx={{ textAlign: 'center', p: 1 }}>
                    <CircularProgress
                        size={24}
                        sx={{
                            color: '#44bd32',
                        }}
                    />
                </Box>
            }
            {
                (replyStatus === 'error') &&
                <Alert severity="error">Sorry, something went wrong</Alert>
            }
            <Box>
                {
                    (replyStatus === 'ok' && replys.length !== 0) &&
                    replys.map((reply, index) => {
                        return <ReplyItem reply={reply} key={reply.reply_id} user_id={user_id} token={token} deleteReplyId={deleteReplyId} deleteReply={deleteReply} post_id={post_id} type={type} commented_by={commented_by}/>
                    })
                }
            </Box>
        </>
    )
}

export default Reply