import { Avatar, Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { FilledButton, OutlinedAvatar, SubTitle3, Title2 } from '../../CustomComponents'
import { ClearRounded, DoneRounded, MarkAsUnread } from '@mui/icons-material'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { removeFromRequestList, setOpenSnack, setSnackMessage, updateRequestListStatus } from '../../store/authSlice'
import theme from '../../Theme'
import moment from 'moment/moment'
import { setInvitationRequestUpdateInfo } from '../../store/postSlice'

const RequestListItem = ({ request,token, requests,setRequests, total_fav_request, socket }) => {
    const [status, setStatus] = React.useState(false)
    const dispatch = useDispatch()

    const handleCancleRequest = (user_id) => {
        setStatus(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/users/cancel/request/to-make_favourite/${user_id}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setStatus(false)
                // if (response.data.status !== 0) {
                //     dispatch(setuserProfileInfoFavouriteRelation(null))
                // }
                // dispatch(removeFromRequestList({ user_id }))
                if(response.data.status === 1) {
                    setRequests(requests.filter(el => el.user_id !== user_id))
                    dispatch(setInvitationRequestUpdateInfo({ status: 'favourite', value: total_fav_request - 1 }))
                }
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setStatus(false)
                dispatch(setSnackMessage('Sorry, something went wrong.Try later.'))
                dispatch(setOpenSnack(true))
            })
    }

    const handleAgreeRequest = (user_id) => {
        setStatus(true)
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/accept/to-make_favourite-request`, { follower_id:user_id }, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                setStatus(false)
                if(response.data.status !== 0) {
                    // setRequests(requests.map(el => {
                    //     if (el.user_id === user_id) {
                    //         return { ...el, status: 'active' }
                    //     } else {
                    //         return el
                    //     }
                    // }))
                    setRequests(requests.filter(el => el.user_id !== user_id))
                    dispatch(setInvitationRequestUpdateInfo({ status: 'favourite', value: total_fav_request - 1 }))
                }
                if(response.data.status === 1) {
                    socket?.emit("acceptRequest", { receiverid: user_id })
                }
                dispatch(setSnackMessage(response.data.message))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                setStatus(false)
                dispatch(setSnackMessage('Sorry, something went wrong.Try later.'))
                dispatch(setOpenSnack(true))
            })
    }

    return (
        <Box sx={{ display: 'flex', alignItems: "center", gap: 1, mb: 3 }}>
            <Avatar variant='rounded' src='' sx={{ width: 32, height: 32 }} />
            <Box sx={{ flexGrow: 1 }}>
                <Title2 color='dark.light' sx={{ flexGrow: 1 }}>{request.name}</Title2>
                <SubTitle3 color='dark.light'>{moment(request.added_on).fromNow()}</SubTitle3>
            </Box>
            {
                (status === true) ?
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px', width: "100px" }}>
                        <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
                    </Box> :
                    <>
                        {
                            (request.status === 'pending') ?
                                <>
                                    <Button variant='contained' size='small' onClick={() => handleAgreeRequest(request.user_id)}>agree</Button>
                                    <OutlinedAvatar variant='rounded' onClick={() => handleCancleRequest(request.user_id)}>
                                        <ClearRounded color='primary' />
                                    </OutlinedAvatar>
                                </> :
                                <OutlinedAvatar variant='rounded'>
                                    <DoneRounded color='primary' />
                                </OutlinedAvatar>
                        }
                    </>
            }
        </Box>
    )
}

export default RequestListItem