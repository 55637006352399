import { Box, Card, CardActions, CardContent, Divider, Typography } from '@mui/material'
import Share from '@mui/icons-material/Loop';
import React, { useRef } from 'react'
import CardTopHeader from './CardTopHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { entities } from "../HtmlEntity"
import { setOpenUaDialog } from '../../store/groupSlice';
import AllPostCardTopHeader from '../AllPost/AllPostCardTopHeader';
import { setFullPostDetails } from '../../store/postSlice';
import { SubTitle1, SubTitle3 } from '../../CustomComponents';
import SharePostMenu from '../Menu/SharePostMenu';
import theme from '../../Theme';

const SharedGroupCard = ({ val, value }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [description, setDescription] = React.useState()
    const [isEditable, setisEditable] = React.useState(false)
    const [isPinned, setIsPinned] = React.useState(null)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const { role } = useSelector(state => state.group.groupShortInfo)
    const openOptionMenu = Boolean(anchorE2)
    const socket = useOutletContext()
    const location = useLocation()
    // const [lineCount, setLineCount] = React.useState(0);
    const dispatch = useDispatch()
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user")) || {}
    const token = JSON.parse(localStorage.getItem('token'))
    const navigate = useNavigate()
    React.useEffect(() => {
        if (val.description && val.description.length > 0) {
            setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
        }
    }, [])

    // React.useEffect(() => {
    //     set_is_saved(val.is_saved)
    // }, [val.is_saved])

    const toggleReadMore = () => setIsShowMore((show) => !show);

    const fullImageHandler = () => {
        dispatch(setFullPostDetails(val.org_file))
        navigate("/full-image")
    }

    const handleVisitSharedPeopleProfile = (shared_by_user_id, shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // navigate(`/profile/${shared_by_user_id}/${shared_from_group_id}`)
        navigate("/profile", {
            state: {
              group_id: shared_from_group_id,
              user_id: shared_by_user_id
            },
            // replace: true
          })
    }
    const handleVisitSharedGroupProfile = (shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        navigate(`/group-profile/${shared_from_group_id}`)
    }

    // const handleBookmarkPost = (event) => {
    //     if (!token) {
    //         dispatch(setOpenUaDialog(true))
    //         return
    //     }
    //     if (val.is_saved === null) {
    //         dispatch(setCurrentPostInfo({ post_id: val.xz12uied7, feed_value: value }))
    //         dispatch(setOpenBookmarkDialog(true))
    //     } else {
    //         removeFromFolder(val.xz12uied7)
    //     }
    // }

    return (
        <>
            <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type={val.group_type} type={val.type} post_id={val.xz12uied7} value={value} group_id={val.group_id} creator_id={val.creator_id} group_name={val.group_name} />
            <Card elevation={0} sx={{ backgroundColor: val.is_colored === 'no' ? 'white' : val.role === 'Super Admin' ? '#d2f9de' : val.role === 'Admin' ? '#e6eeff' : 'white', width: '100%', marginBottom: 2, borderRadius: { xs: '0px', sm: '10px' } }}>
                {
                    (val.shared_by && val.shared_by_name !== val.name) &&
                    <>
                        {
                            (val.shared_from_group_id === 'null' || val.shared_from_group_id === null) ?
                                <SubTitle1 padding='10px' sx={{ backgroundColor: 'white', color: theme.palette.dark.light }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, 0)}>{val.shared_by_name}</strong>
                                </SubTitle1> :
                                <SubTitle1 padding='10px' sx={{ backgroundColor: 'white', color: theme.palette.dark.light }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
                                </SubTitle1>
                        }
                        <Divider />
                    </>
                }
                {
                    (val.group_id) ?
                        <AllPostCardTopHeader isPinned={isPinned} post_type='groupProfile' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} group_name={val.group_name} group_profile_pic={val.group_profile_pic} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} /> :
                        <CardTopHeader isPinned={isPinned} post_type='groupProfile' setIsPinned={setIsPinned} setisEditable={setisEditable} anchorEl={anchorEl} setAnchorEl={setAnchorEl} group_id={val.group_id} user_id={val.creator_id} profile_pic={val.profile_pic} value={value} role={role} name={val.name} added_on={val.added_on} post_id={val.xz12uied7} />
                }
                <CardContent sx={{ pt: 0, pb: 1 }}>
                    <SubTitle1 lineHeight="18px" sx={{ whiteSpace: 'pre-line' }}>
                        {description}
                    </SubTitle1>
                    {
                        (val.link) &&
                        <Box>
                            {(val.link && val.link.length > 100) ?
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word',fontSize:'15px' }}>
                                    {val.link.substring(0, 100)} ...
                                </a> :
                                <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word',fontSize:'15px' }}>
                                    {val.link}
                                </a>
                            }
                        </Box>
                    }
                </CardContent>
                <Box sx={{ borderRadius: '10px', overflow: 'hidden', height: '200px', margin: '0px 16px' }}>
                    <img alt='No pic'
                        src={(val.files !== '' || val.files !== null) ? val.files : `https://api.emotiontown.com/upload/image/no_photo.png`}
                        style={{ objectFit: val.files ? 'cover' : 'contain', width: '100%', height: '100%', opacity: val.files ? 1.0 : 0.3 }} loading='lazy' />
                </Box>
                <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                    <Box sx={{ p: 1 }}>
                        {
                            (val.group_type !== 'protected' && val.group_type !== 'private') &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(event) => setAnchorE2(event.currentTarget)}>
                                <Share sx={{ fontSize: '18px', marginRight: '5px', color: "#2d3436" }} />
                                <SubTitle3 color='#202631'>
                                    Share
                                </SubTitle3>
                            </Box>
                        }
                    </Box>
                </CardActions>
            </Card>
        </>
    )
}

export default SharedGroupCard