import { Avatar, Box, Chip, CircularProgress, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import NotFound from '../status/NotFound';
import Loading from '../status/Loading';
import ReactGA from 'react-ga4'
import { appendPostList, emptyPostList, setHasMorePost, setPostFeedType, setPostList, setPostListCallNo } from '../../store/postSlice';
import Explorecard from '../Post/RoomPost/ExploreCard';
import { useLocation } from 'react-router-dom';
import RoomCard from '../Post/RoomPost/RoomCard';
import { AutoAwesomeMosaicOutlined, Diversity1Outlined, FilterAltOutlined, GifBoxOutlined, LiveTvOutlined, PollOutlined, YardOutlined } from '@mui/icons-material';


const ExploreFeed = () => {
  const dispatch = useDispatch()
  const [loadingStatus, setLoadingStatus] = React.useState('ok') // ok, loading, error
  const [anchorEl, setAnchorEl] = React.useState(null);
  const posts = useSelector(state => state.post.dataList)
  const feedType = useSelector(state => state.post.feedType)
  const callNo = useSelector(state => state.post.dataListCallNo)
  const hasMore = useSelector(state => state.post.hasMoreData)
  const token = JSON.parse(localStorage.getItem("token"))
  const open = Boolean(anchorEl);
  const location = useLocation()

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])

  useEffect(() => {

    // if(posts.length > 0 && feedType === 'explore_post' && posts[0].room_id === location.state.id) {
    if (posts.length > 0 && feedType === 'explore_post') {
      // when user come back from full image view
      return
    }
    // if ((feedType === 'explore_post' && posts[0].room_id !== location.state.id) || feedType !== 'explore_post') {
    if ((feedType === 'explore_post') || feedType !== 'explore_post') {
      dispatch(emptyPostList({ feed_value: 'explore_post' }))
      window.scrollTo(0, 0)
    }
    if (feedType !== 'explore_post') {
      dispatch(setPostFeedType({ data: 'explore_post' }))
    }


    dispatch(setHasMorePost({ data: true, feed_value: 'explore_post' }))
    setLoadingStatus('loading')
    axios.get(`${process.env.REACT_APP_API_URL}/api/room-xjV/explore-post/${1}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length === 0) {
          setLoadingStatus('empty')
        } else {
          setLoadingStatus('ok')
        }
        if (response.data.data.length >= 5) {
          dispatch(setPostListCallNo({ "feed_value": 'explore_post' }))
          dispatch(setHasMorePost({ data: true, feed_value: 'explore_post' }))
          dispatch(setPostList({ data: response.data.data, feed_value: 'explore_post' }))
        } else {
          dispatch(setPostList({ data: response.data.data, feed_value: 'explore_post' }))
          dispatch(setHasMorePost({ data: false, feed_value: 'explore_post' }))
        }
      }).catch(error => {
        dispatch(setHasMorePost({ data: false, feed_value: 'explore_post' }))
        setLoadingStatus('error')
      })
  }, [])


  const fetchMoreData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/room-xjV/explore-post/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length >= 5) {
          dispatch(setPostListCallNo({ "feed_value": 'explore_post' }))
          dispatch(appendPostList({ data: response.data.data, feed_value: 'explore_post' }))
        } else {
          dispatch(appendPostList({ data: response.data.data, feed_value: 'explore_post' }))
          dispatch(setHasMorePost({ data: false, feed_value: 'explore_post' }))
        }
      }).catch(error => {
        dispatch(setHasMorePost({ data: false, feed_value: 'explore_post' }))
      })
  }

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };
  const handleTopic = () => {

  }

  return (
    <>
      <Box sx={{ paddingBottom: '10px' }}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <LiveTvOutlined fontSize="small" />
            </ListItemIcon>
            Video
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <YardOutlined fontSize="small" />
            </ListItemIcon>
            Image
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <GifBoxOutlined fontSize="small" />
            </ListItemIcon>
            GIF
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PollOutlined fontSize="small" />
            </ListItemIcon>
            Poll
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Diversity1Outlined fontSize="small" />
            </ListItemIcon>
            Group Post
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <AutoAwesomeMosaicOutlined fontSize="small" />
            </ListItemIcon>
            Member Post
          </MenuItem>
        </Menu>
        <Box sx={{ display: 'flex', alignItems: 'center',position: 'sticky',top:{xs:'53px',sm:'100px'},backdropFilter: 'blur(5px)',backgroundColor:'rgba(255,255,255,0.1)', zIndex:5,px:1 }}>
          <Tooltip title="Filter Content">
            <Avatar variant='rounded' sx={{ width: 32, height: 32 }} onClick={handleClick}><FilterAltOutlined fontSize='inherit' /></Avatar>
          </Tooltip>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 1, overflowX: 'scroll', py: 1, pl: 1 }} >
            <Chip onClick={handleTopic} label="Travel" variant="outlined" />
            <Chip onClick={handleTopic} label="Entertainment" variant="outlined" />
            <Chip onClick={handleTopic} label="Dance" variant="outlined" />
            <Chip onClick={handleTopic} label="Politics" variant="outlined" />
            <Chip onClick={handleTopic} label="Football" variant="outlined" />
            <Chip onClick={handleTopic} label="Vlog" variant="outlined" />
            <Chip onClick={handleTopic} label="Art" variant="outlined" />
            <Chip onClick={handleTopic} label="Gym" variant="outlined" />
            <Chip onClick={handleTopic} label="Social" variant="outlined" />
          </Box>
        </Box>
        {(loadingStatus === 'loading') && <Loading />}
        {
          (hasMore === false && posts.length <= 0 && loadingStatus !== 'ok') &&
          <NotFound data={"No post yet"} subData={"Sorry, we could not found any post to show.Please try again later."} />
        }
        {
          (loadingStatus === 'ok' && posts.length > 0) &&
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            /></Box>}
            endMessage={
              posts.length !== 0 ? <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p> : null
            }
          >
            {
              posts.map((val, index) => {
                if (val.file_count > 1) {
                  return
                  // return <MultiLovecard key={val.xz12uied7} val={val} value='explore_post' />
                }
                else {
                  // return <Explorecard key={index} val={val} value='explore_post' />
                  return <RoomCard key={index} val={val} token={token}/>
                }
              })
            }
          </InfiniteScroll>
        }
      </Box>
    </>
  )
}

export default ExploreFeed