import { Avatar, Box, Button, Checkbox, CircularProgress, Collapse, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { Suspense, lazy } from 'react'
import { EmotInput, SubTitle1, SubTitle2, SubTitle3, Title1, Title2, Title3, Title4 } from '../../CustomComponents'
import CommentIcon from '@mui/icons-material/Comment';
import theme from '../../Theme'
import { createSearchParams, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ArrowBackIosRounded, BookmarkAddedOutlined, BookmarkBorderOutlined, CameraOutdoorOutlined, ContentCopy, FavoriteRounded, HomeRounded, Share } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice'
import axios from 'axios'
import { setOpenUaDialog } from '../../store/groupSlice'
import { entities } from '../HtmlEntity'
import { increasePostCommentCount, setCurrentPostInfo, setOpenBookmarkDialog, setReadPostDataToPostToSave } from '../../store/postSlice'
import moment from 'moment/moment'
import Comment from '../Cards/Comment'
import ReactGA from 'react-ga4'
import SharePostMenu from '../Menu/SharePostMenu';
import not_available from '../../media/images/not_available.png'
import { light } from '@mui/material/styles/createPalette';
// import SimilarBlog from './SimilarBlog'
const SimilarBlog = lazy(() => import('./SimilarBlog'));

const ReadPost = () => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [post, setPost] = React.useState({})
  const [is_liked, set_is_liked] = React.useState(false)
  const [is_saved, set_is_saved] = React.useState(false)
  const [totalLiked, setTotalLiked] = React.useState(0)
  const [expanded, setExpanded] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [comment, setComment] = React.useState("")
  const [newComment, setNewComment] = React.useState({})
  const [joinChannelStatus, setJoinChannelStatus] = React.useState(false)
  const [totalComment, setTotalComment] = React.useState(0)
  const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
  const token = JSON.parse(localStorage.getItem('token'))
  const { uid: user_id, profile_pic, name } = JSON.parse(localStorage.getItem("user")) || {}
  const socket = useOutletContext()
  const openOptionMenu = Boolean(anchorE2)
  const { post_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])

  React.useEffect(() => {
    // set_is_saved(post.is_saved)
    setTotalComment(post.total_comment)
    if (post.is_saved !== null) {
      set_is_saved(true)
    } else {
      set_is_saved(false)
    }

    if (post.is_reacted !== null) {
      set_is_liked(true)
    } else {
      set_is_liked(false)
    }

    // if (post.total_react !== 0) {
    setTotalLiked(post.total_react)
    // }
  }, [post])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/bi-xjV/article/${post_id}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        setIsLoading(false)
        if (response.data.status === 1) {
          setPost(response.data.data)
        }
      }).catch(error => {
        setIsLoading(false)
        dispatch(setSnackMessage("Something went wrong. Try later."))
        dispatch(setOpenSnack(true))
      })
  }, [post_id])

  React.useEffect(() => {
    axios.put(`${process.env.REACT_APP_API_URL}/api/bi-xjV/increase/total_view`, { skdjffXgj: post_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {

      }).catch(error => {
        // dispatch(setSnackMessage("Something went wrong. Try later."))
        // dispatch(setOpenSnack(true))
      })
  }, [])

  const handleReaction = (event) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    if (is_liked) {
      set_is_liked(false)
      setTotalLiked(totalLiked - 1)
      axios.delete(`${process.env.REACT_APP_API_URL}/api/post/remove/react_post/${post_id}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          // dispatch(setReactOnPost({ feed_value: value, id: post.post_id, react: 0, total_react: post.total_react - 1 }))
        }).catch(error => {
        })
    } else {
      set_is_liked(true)
      setTotalLiked(totalLiked + 1)
      var notification_message = `reacted on your article "${post.title.substring(0, 100)}"`
      // create react     // user_id,post_id,react_value,group_id
      axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/react_post`, { post_id, react_value: 1, group_id: post.group_id, post_type: 'article', message: notification_message, notified_user_id: post.creator_id, from_where: post.type, topic: 'postReact' }, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          // dispatch(setReactOnPost({ feed_value: value, id: post.post_id, val_copy }))
          if (response.data.status === 1) {
            if (post.creator_id !== user_id) {
              socket?.emit("reactOnPost", { receiverid: post.u_id })
            }
          }
        }).catch(error => {
        })
    }
  }
  const handleCommentSubmit = (e) => {
    e.preventDefault()
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    if(comment.trim() == '') {
      return
  }
    setCreateCommentStatus('loading')
    var notification_message = `comments on your article in ${post.group_name.replace(/&#?\w+;/g, match => entities[match])}`
    const data = { post_id: post.post_id, group_id: post.group_id, comment, message: notification_message, user_to_notify: post.creator_id, from_where: post.type, topic: 'postComment' }
    axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        const new_comment = {
          comment_id: response.data.comment_id,
          comment,
          added_on: moment().format(),
          user_id,
          profile_pic,
          total_reply: 0,
          total_reacted: 0,
          is_reacted: null,
          name
        }
        setNewComment(new_comment)
        // set_is_commented(true)
        setTotalComment(totalComment + 1)
        // if (value === 'pin') {
        //     dispatch(increasePinPostCommentCount(val.post_id))
        // } else if (value === 'saved') {
        //     dispatch(increaseSavePostCommentCount(val.post_id))
        // } else if (value === 'myPost') {
        //     dispatch(increaseMyPostCommentCount(val.post_id))
        // } else {
        // }
        dispatch(increasePostCommentCount({ feed_value: 'channel_article', data: post.post_id }))
        if (response.data.status === 1) {
          // if (val.creator_id !== user_id) {
          //     socket?.emit('commentOnPost', { receiverid: post.u_id })
          // }
        }
        setCreateCommentStatus('ok')
      }).catch(error => {
        setCreateCommentStatus('error')
      })
    setComment("")
  };
  const removeFromFolder = (now_post_id) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    // if (feed_value !== 'saved') {
    set_is_saved(false)
    // if (feed_value === 'saved') {
    //   dispatch(setRemovePostFromSavedFeed({ data: now_post_id }))
    // }
    axios.delete(`${process.env.REACT_APP_API_URL}/api/post/removed_saved_post/${now_post_id}`, { headers: { authorization: "Bearer " + token } })
      .then(res => {
        if (res.data.status === 1) {
          // if (feed_value !== 'saved') {
          //     dispatch(setRemovePostFromFolder({ feed_value, data: now_post_id }))
          // }
          dispatch(setSnackMessage(res.data.message))
          dispatch(setOpenSnack(true))
        } else {
          dispatch(setSnackMessage(res.data.message))
          dispatch(setOpenSnack(true))
        }
      }).catch(error => {
        dispatch(setSnackMessage("Something went wrong."))
        dispatch(setOpenSnack(true))
      })
    // }

  }
  const handleBookmarkPost = (event) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    if (is_saved === null) {
      dispatch(setCurrentPostInfo({ post_id: post.post_id, feed_value: null }))
      dispatch(setOpenBookmarkDialog(true))
    } else {
      removeFromFolder(post.post_id)
    }
    // set_is_saved(event.target.checked)
    // navigate(`/folders/${post.post_id}/${post.group_id}/${value}`)
  }
  const handleFollowChannel = () => {
    ReactGA.event({
      category: 'follow',
      action: 'follow channel',
      label: 'follow channel'
    })
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    setJoinChannelStatus(true)
    axios.post(`${process.env.REACT_APP_API_URL}/api/group/join_group`, { group_id: post.group_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status !== 0) {
          socket?.emit('join-channel', ({ roomId: post.group_id }))
          setPost({ ...post, status: 'active', role: 'General' })
        }
        setJoinChannelStatus(true)
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      }).catch(error => {
        setJoinChannelStatus(false)
        dispatch(setSnackMessage("Something went wrong.Try later."))
        dispatch(setOpenSnack(true))
      })
  }

  const handleUnfollowChannel = () => {
    ReactGA.event({
      category: 'unfollow',
      action: 'unfollow channel',
      label: 'unfollow channel'
    })
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    setJoinChannelStatus(true)
    axios.delete(`${process.env.REACT_APP_API_URL}/api/group/exit_group/${post.group_id}`, { headers: { authorization: "Bearer " + token } })
      .then(res => {
        if (res.data.status === 1) {
          socket?.emit('exit-channel', ({ roomId: post.group_id }))
          // dispatch(updateChannelFollowStatus({ status: null, role: null }))
          setPost({ ...post, status: null, role: null })
        }
        setJoinChannelStatus(false)
        dispatch(setSnackMessage(res.data.message))
        dispatch(setOpenSnack(true))
      }).catch(error => {
        setJoinChannelStatus(false)
        dispatch(setSnackMessage("Something went wrong.Try later."))
        dispatch(setOpenSnack(true))
      })
  }
  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type='channel' type={post.type} post_id={post.post_id} value='channel_article' group_id={post.group_id} creator_id={post.creator_id} group_name={post.group_name} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', p: 2 }}>
        <Avatar variant='rounded' src={post.group_profile_pic} sx={{ width: 30, height: 30 }} onClick={
          () => navigate(`/channel-profile/${post.group_id}`)
        }>
          <CameraOutdoorOutlined fontSize='small' />
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Title2>{post.group_name}</Title2>
          <SubTitle2>{post.total_member} follower{post.total_member > 1 ? 's' : ''}</SubTitle2>
          {/* <SubTitle2>Aug 13, 2023, 7 min read</SubTitle2> */}
        </Box>
        {
          (post.status === 'active') ?
            <Button variant='contained' color='light' disabled={joinChannelStatus === true ? true : false} size='small' sx={{ color: theme.palette.dark.light }} onClick={handleUnfollowChannel}>Unfollow</Button> :
            <Button variant='contained' size='small' onClick={handleFollowChannel} disabled={joinChannelStatus === true ? true : false} >Follow</Button>
        }
      </Box>
      <Box sx={{ px: 2, pb: 2 }}>
        {
          (isLoading) ?
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
              <CircularProgress size={30} />
            </Box> :
            <>
              <Box sx={{ height: { xs: '250px', md: '400px' } }}>
                <img style={{ width: '100%', height: "100%", objectFit: 'cover' }} src={post.files} />
              </Box>
              <Title1 mt={2} sx={{ color: theme.palette.dark.main }}>{post.title}</Title1>
              <Box sx={{ fontSize: '16px', color: '#0984e3', my: 1, cursor: 'pointer' }}>
                {
                  post.tag?.split(",").map((tag, index) => {
                    return <span key={index} style={{ marginRight: '10px', fontSize: '14px' }} onClick={() => {
                      navigate({ pathname: '/search', search: createSearchParams({ searchedContent: tag, status: 'popular' }).toString() })
                    }}>#{tag}</span>
                  })
                }
              </Box>
              <SubTitle1 lineHeight="22px" sx={{ color: theme.palette.dark.main, whiteSpace: 'pre-line' }}>
                {post.description}
              </SubTitle1>
              <SubTitle2 sx={{ marginTop: '6px', mb: 2, color: 'gray' }}>posted on {moment(post.added_on).format('MMM DD YYYY')}&nbsp;▪️&nbsp; {post.total_view} view{post.total_view > 1 ? 's' : ''}</SubTitle2>
              <Box sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={is_liked || false}
                    onChange={handleReaction}
                    inputProps={{ 'aria-label': 'controlled' }}
                    icon={<FavoriteRounded fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                    checkedIcon={<FavoriteRounded color='redish' fontSize='small' />}
                  />
                  <SubTitle3 color='dark' sx={{ cursor: 'pointer' }}>
                    {totalLiked} Like{totalLiked > 1 ? 's' : ''}
                  </SubTitle3>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                  setExpanded(!expanded)
                }}>
                  <Checkbox
                    inputProps={{ 'aria-label': 'controlled' }}
                    icon={<CommentIcon fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                    checkedIcon={<CommentIcon color='redish' fontSize='small' />}
                  />
                  <SubTitle3 color='dark' sx={{ cursor: 'pointer' }}>
                    {totalComment} Comments
                  </SubTitle3>
                </Box>
                <Share fontSize='small' sx={{ color: theme.palette.light.dark }} onClick={(event) => {
                  if (!token) {
                    dispatch(setOpenUaDialog(true))
                    return
                  }
                  // dispatch(setCurrentPostInfo({ feed_value: 'read_post' }))
                  dispatch(setReadPostDataToPostToSave({ data: post }))
                  setAnchorE2(event.currentTarget)
                }
                } />
                <Tooltip title="Copy post link">
                  <IconButton aria-label="delete" size='small' onClick={() => {
                    // handleCopyLink(`https://www.emotiontown.com/read/post/${post.post_id}`)
                    ReactGA.event({
                      category: 'copy',
                      action: 'copied article link',
                      label: 'copied article link'
                    })
                    navigator.clipboard.writeText(`https://www.emotiontown.com/read/post/${post.post_id}`)
                      .then(result => {
                        dispatch(setSnackMessage("Link copied successfully."))
                        dispatch(setOpenSnack(true))
                      }).catch(error => {
                      })
                  }}>
                    <ContentCopy fontSize='inherit' />
                  </IconButton>
                </Tooltip>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={is_saved || false}
                    onChange={handleBookmarkPost}
                    inputProps={{ 'aria-label': 'controlled' }}
                    icon={<BookmarkBorderOutlined fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                    checkedIcon={<BookmarkAddedOutlined color='dark' fontSize='small' />}
                  />
                </Box>
              </Box>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { width: '100%' },
                  display: 'flex',
                  margin: '6px 10px 10px',
                  alignItems: 'center',
                  gap: 1
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleCommentSubmit}
              >
                <Avatar variant='rounded' src={profile_pic} sx={{ width: 32, height: 32 }} />
                <input
                  id="basic_comment_textarea_id"
                  value={comment}
                  placeholder='comment something'
                  style={{
                    backgroundColor: theme.palette.light.light,
                    padding: '8px',
                    border: 'none',
                    outline: 'none',
                    width: '100%',
                    borderRadius: '6px',
                  }}
                  name="description"
                  onChange={(e) => setComment(e.target.value)}
                />
                <Box sx={{ position: 'relative' }}>
                  <Button type='submit' size='small' variant="contained" disabled={(createCommentStatus === 'loading') ? true : false} disableElevation>Send</Button>

                  {(createCommentStatus === 'loading') && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: '#44bd32',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {/* <CardContent sx={{ padding: '0px 8px 24px' }}> */}
                {
                  (!token) ?
                    <Typography mt={1} sx={{ textAlign: 'center', fontWeight: 600, color: '#2d3436', fontSize: '14px' }}>Sign In for read comments</Typography> :
                    <Comment post_id={post.post_id} role={post.role} is_colored='no' value='channel_article' newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} type={post.type} />
                }
                {/* </CardContent> */}
              </Collapse>
            </>
        }
        <Box sx={{ py: 2 }}>
          <Title1 mb={2} color='dark.light'>Popular Posts</Title1>
          {/* <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-start', mb: 2 }}>
            <img style={{ width: '100px', height: "70px", objectFit: 'cover' }} src='https://images.pexels.com/photos/3763578/pexels-photo-3763578.jpeg?auto=compress&cs=tinysrgb&w=600' />
            <Box>
              <Title4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo sequi illo earum cupiditate nam</Title4>
              <Box sx={{ display: 'flex', gap: '15px' }}>
                <SubTitle3>1.2k views</SubTitle3>
                <SubTitle3>445 likes</SubTitle3>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-start', mb: 2 }}>
            <img style={{ width: '100px', height: "70px", objectFit: 'cover' }} src='https://images.pexels.com/photos/1001914/pexels-photo-1001914.jpeg?auto=compress&cs=tinysrgb&w=600' />
            <Box>
              <Title4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo sequi illo earum cupiditate nam</Title4>
              <Box sx={{ display: 'flex', gap: '15px' }}>
                <SubTitle3>1.2k views</SubTitle3>
                <SubTitle3>445 likes</SubTitle3>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-start', mb: 2 }}>
            <img style={{ width: '100px', height: "70px", objectFit: 'cover' }} src='https://images.pexels.com/photos/2598630/pexels-photo-2598630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
            <Box>
              <Title4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo sequi illo earum cupiditate nam</Title4>
              <Box sx={{ display: 'flex', gap: '15px' }}>
                <SubTitle3>1.2k views</SubTitle3>
                <SubTitle3>445 likes</SubTitle3>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-start', mb: 2 }}>
            <img style={{ width: '100px', height: "70px", objectFit: 'cover' }} src='https://images.pexels.com/photos/3652097/pexels-photo-3652097.jpeg?auto=compress&cs=tinysrgb&w=600' />
            <Box>
              <Title4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo sequi illo earum cupiditate nam</Title4>
              <Box sx={{ display: 'flex', gap: '15px' }}>
                <SubTitle3>1.2k views</SubTitle3>
                <SubTitle3>445 likes</SubTitle3>
              </Box>
            </Box>
          </Box> */}
          <Suspense fallback={<div>Loading...</div>}>
            <SimilarBlog current_post_id={post_id} />
          </Suspense>
        </Box>
      </Box>
      {/* </Box> */}
    </Box>
  )
}

export default ReadPost