import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { Box } from '@mui/system';
import { Button, CircularProgress, IconButton, Snackbar, styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import NotFound from '../status/NotFound';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setOpenSnack, setSnackMessage, setuserProfileInfoListing } from '../../store/authSlice';
// import CloseIcon from '@mui/icons-material/Close';
import Error from '../status/Error';
import { setMoreActivityInList } from '../../store/groupSlice';
import moment from 'moment/moment';
import ReactGA from 'react-ga4'
import {entities} from "../HtmlEntity"
import InvieToGroupListItem from './Listitem/InvieToGroupListItem';

const MoreButton = styled(Button)((theme) => ({
  textTransform: 'none',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: 'rgba(199, 201, 201,0.7)',
  }
}))

const InviteToGroup = () => {
  const [checked, setChecked] = React.useState([]);
  const [callNo, setCallNo] = React.useState(1)
  const [hasMore, setHasMore] = React.useState(true)
  const [groupIds, setGroupIds] = React.useState([])
  const [inviting, setInviting] = React.useState(false)
  const [groups, setGroups] = React.useState([])
  const [status, setStatus] = React.useState('ok') // ok error not_found
  const { uid: id } = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  // const openSnack = useSelector(state => state.auth.openSnack)
  // const snackMessage = useSelector(state => state.auth.snackMessage)
  // const { is_listed } = useSelector(state => state.auth.userProfileInfo)
  const socket = useOutletContext()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchparams] = useSearchParams()

  React.useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
  },[])

  useEffect(() => {
    if (groups.length <= 0) {
      setStatus('loading')
      axios.get(`${process.env.REACT_APP_API_URL}/api/group/invite_to_my_groups/${searchparams.get('to_user_id')}/${callNo}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.length <= 0) {
            setStatus('not_found')
            return
          }
          if (response.data.data.length < 10) {
            setGroups(response.data.data)
            setHasMore(false)
          } else {
            setCallNo(2)
            setHasMore(true)
            setGroups(response.data.data)
          }
          setStatus('ok')
        }).catch(error => {
          setHasMore(false)
          setStatus('error')
        })
    }
  }, [])

  const fetchMoreData = () => {
    // setStatus('loading')
    axios.get(`${process.env.REACT_APP_API_URL}/api/group/invite_to_my_groups/${searchparams.get('to_user_id')}/${callNo}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.data.length < 10) {
          setGroups(groups.concat(response.data.data))
          setHasMore(false)
        } else {
          setHasMore(true)
          setCallNo(callNo + 1)
          setGroups(groups.concat(response.data.data))
        }
        // setStatus('ok')
      }).catch(error => {
        setHasMore(false)
        // setStatus('error')
      })
  }

  // so tricky
  const handleToggle = (value, group_id) => () => {
    const currentIndex = checked.indexOf(value);
    const currentGroupIdIndex = groupIds.indexOf(group_id)
    const newChecked = [...checked];
    const newGroupIds = [...groupIds]
    if(groupIds.length < 5) {
      if (currentIndex === -1) {
        // if (newChecked.length < 4) {
        newChecked.push(value);
        newGroupIds.push(group_id)
      } else {
        newChecked.splice(currentIndex, 1);
        newGroupIds.splice(currentGroupIdIndex, 1);
      }
  
      setChecked(newChecked);
      setGroupIds(newGroupIds)
    }else{
      dispatch(setSnackMessage("You may invite to maximum 5 groups at one time."))
      dispatch(setOpenSnack(true))
    }

  };
  //  group_id, user_id, post_type, file_type, file_count, files, post_to, description, optionns, status, original_post_id, shared_by, shared_by_name, shared_from_group_id, shared_from_group_name 
  const handleInviteFriends = () => {
    setInviting(true)
    axios.post(`${process.env.REACT_APP_API_URL}/api/group/invite_to_group`, { groupIds, to_user_id: searchparams.get('to_user_id') }, { headers: { authorization: "Bearer " + token } })
      .then((res) => {
        setInviting(false)
        if (res.data.status === 1) {
          // invitation send
          navigate(-1)
        }
        if (res.data.status === 2) {
          // invitation send and notification saved
          // dispatch(setuserProfileInfoListing(searchparams.get('to_user_id')))
          navigate(-1)
        }
        if (res.data.status === 3) {
          // invitation send and failed to save notification
          navigate(-1)
        }
        if(res.data.status !== 0  && res.data.status !== 3) {
          // invitation sent
          socket.emit("sendToOneManInvitation",{receiverid:searchparams.get('to_user_id')}) 
        }
        dispatch(setSnackMessage(res.data.message))
        dispatch(setOpenSnack(true))
      }).catch(error => {
        setInviting(false)
        dispatch(setSnackMessage("Something went wrong.Please try again later."))
        dispatch(setOpenSnack(true))
      })

  }

  return (
    <Box sx={{ bgcolor: 'white', padding: '16px',marginTop:'10px' }}>
      {/* {(groups.length >= 0) ? <NotFound /> : */}
      {
        (status === 'not_found') && <NotFound data={"No Group found"} subData={"Sorry, we could not found any group to show.May be you did not join or create any group.Please join or create any group and then try again"} />
      }
      {
        (status === 'error') && <Error />
      }
      <>
        <Typography variant='h6'>Invite <strong style={{ color: '#6c63FF' }}>{searchparams.get('name')}  </strong>to:</Typography>
        <InfiniteScroll
          dataLength={groups.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          /></Box>}
        >
          <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {groups.map((group, index) => {
              // const labelId = `checkbox-list-secondary-label-${index}`;
              return (
                // <ListItem
                //   key={group.group_id}
                //   secondaryAction={
                //     // <Checkbox
                //     //   edge="end"
                //     //   onChange={handleToggle(index, group.group_id)}
                //     //   checked={checked.indexOf(index) !== -1}
                //     //   inputProps={{ 'aria-labelledby': labelId }}
                //     //   disabled={(group.is_invited || group.is_member) ? true : false}
                //     // />
                //     <Button size='small' variant='contained' disabled={(group.is_invited || group.is_member) ? true : false}>Invite</Button>
                //   }
                //   disablePadding
                // >
                //   <ListItemButton>
                //     <ListItemAvatar>
                //       <Avatar
                //         alt={`${group.name.replace(/&#?\w+;/g, match => entities[match])} image`}
                //         src={group.group_profile_pic}
                //       >{group.name.replace(/&#?\w+;/g, match => entities[match]).charAt(0).toUpperCase()}</Avatar>
                //     </ListItemAvatar>
                //     <ListItemText id={labelId}
                //       primary={group.name.replace(/&#?\w+;/g, match => entities[match])}
                //       //  secondary="hello"
                //       secondary={<Typography variant='caption' color='gray'>{group.type} . {(group.is_invited) && <span style={{ color: 'red' }}>Already invited . </span>}{(group.is_member) && <span style={{ color: '#6c63FF' }}>Already a member . </span>}</Typography>}
                //     />
                //   </ListItemButton>
                // </ListItem>
                <InvieToGroupListItem key={group.group_id} group={group} token={token} socket={socket} to_user_id={ searchparams.get('to_user_id')}/>
              );
            })}
          </List>
        </InfiniteScroll>
        {/* <Box sx={{ position: 'relative', marginBottom: '15px', marginTop: '15px' }}>
          <MoreButton disabled={(inviting || (checked.length <= 0)) ? true : false} disableElevation variant="contained" fullWidth onClick={handleInviteFriends} sx={{ bgcolor: 'green', color: 'white' }}>Invite</MoreButton>
          {inviting && (
            <CircularProgress
              size={24}
              sx={{
                color: 'orange',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box> */}
      </>
      {/* } */}
      {/* <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          dispatch(setOpenSnack(false));
        }}
        message={snackMessage}
        action={<IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }

            dispatch(setOpenSnack(false));
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>}
      /> */}
    </Box>
  )
}

export default InviteToGroup