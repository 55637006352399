import styled from '@emotion/styled'
import { Avatar, Box, Button, Container, Grid, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { createAccount, setCreateAccountInfo } from '../../../store/authSlice'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SubmitButton = styled(Button)((theme) => ({
    // fontWeight: '600',
    backgroundColor: 'rgba(64,53,151,0.9)',
    borderRadius: 0,
    color: 'white',
    marginTop: '20px',
    // display:'inline-block',
    padding: '12px 22px',
    transition: '0.5s',
    '&:hover': {
        backgroundColor: 'rgb(64,53,151)',
    }
}))

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// name, mobile_no, password, gender, confirm_password, dob, interests, country, state, city, about, proffession
const RegisterFourth = () => {
    const [data, setData] = React.useState({ password: '', confirm_password: '' })
    const [showPassword, setShowPassword] = React.useState(false)
    const dispatch = useDispatch()
    const selector = useSelector(state => state.auth.accountInfo)
    const navigate = useNavigate()
    const handleSubmit = (event) => {
        event.preventDefault()
        const info = { ...selector, ...data }
        axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, info)
            .then(response => {
                dispatch(setCreateAccountInfo(Object.assign(data, response.data.id)))
                navigate('/')
            }).catch(error => {

            })
    }
    return (
        <Grid item xs={6}>
            <Container component='main'>
                {/* <CssBaseline/> */}
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    id="password_input_id"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    name='password'
                                    value={data.password}
                                    onChange={(e) => setData({ ...data, password: e.target.value })}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="confirm_password_input_id"
                                    label="Confirm Password"
                                    type="password"
                                    autoComplete="current-password"
                                    name='confirm_password'
                                    value={data.confirm_password}
                                    onChange={(e) => setData({ ...data, confirm_password: e.target.value })}
                                // name, mobile_no, password, gender, confirm_password, dob, interests, country, state, city, about, proffession
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <SubmitButton type='submit'>Create Account</SubmitButton>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Grid>
    )
}

export default RegisterFourth