import { GifBoxOutlined, InsertPhotoOutlined, SlideshowOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material'
import React from 'react'
import theme from '../../../../Theme'
import GroupList from '../../List/GroupList';
import CreatePost from './CreatePost';
import ReactGA from 'react-ga4'

const steps = ['Select Group', 'Create Post'];
const NewCreatePost = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [selectDetails, setSelectDetails] = React.useState({})
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const handleNext = () => {
        if(selectDetails.group_id) {            
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
              newSkipped = new Set(newSkipped.values());
              newSkipped.delete(activeStep);
            }
        
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    return (
        <Box sx={{ backgroundColor: 'white', borderRadius: '10px', padding: '16px', marginTop: '10px' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    //   if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //       <Typography variant="caption">Optional</Typography>
                    //     );
                    //   }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {/* <Box>
                <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, marginTop: '30px', marginBottom: '10px' }}>
                    <Avatar sx={{ width: 32, height: 32 }} variant='rounded' src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600' />
                    <Box>
                        <Typography fontSize='14px' fontWeight={600}>Anima Samanta</Typography>
                        <Typography fontSize='12px'>Create</Typography>
                    </Box>
                </Box>
                <Box>
                    <TextField
                        id="outlined-multiline-static"
                        fullWidth
                        placeholder="what's on your mind?"
                        multiline
                        rows={4}
                        variant='standard'
                        defaultValue="Default Value"
                        sx={{
                            // borderBottom:'none',
                            underline: {
                                "&&&:before": {
                                    borderBottom: "none"
                                },
                                "&&:after": {
                                    borderBottom: "none"
                                }
                            },
                            '& .MuiInputBase-input': {

                                border: 'none',
                                outline: 'none'
                            }
                        }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: theme.palette.dark.main, marginTop: '8px' }}>
                        <InsertPhotoOutlined />
                        <SlideshowOutlined />
                        <GifBoxOutlined />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button>Cancel</Button>
                    <Button variant='contained'>Post</Button>
                </Box>
            </Box> */}
            {/* content */}
            {/* group list */}
            {/* create post */}
            {
                (activeStep === 0) ?
                <GroupList selectDetails={selectDetails} setSelectDetails={setSelectDetails}/>:
                <CreatePost group_id={selectDetails.group_id} group_name={selectDetails.group_name} type={selectDetails.type} role={selectDetails.role}/>
            }
            <Box sx={{position:'sticky', bottom:'0px'}}>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                <Box sx={{ display: 'flex', pt: 2,justifyContent:'flex-end' }}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    {
                        (activeStep === 0) &&
                        <Button onClick={handleNext} variant='contained'>
                            {/* {
                                (activeStep === 1) ? 'Submit' : 'Next'
                            } */}
                            Next
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default NewCreatePost