import { Avatar, Box, Button, Checkbox, CircularProgress, Collapse, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { FilledButton, MiniTitle, SubTitle, Title } from '../../../CustomComponents'
import theme from '../../../Theme'
import { AccessTime, BookmarkBorderOutlined, BorderTop, CachedOutlined, Chat, ChatOutlined, Favorite, FavoriteBorderOutlined, MoreHorizOutlined, VisibilityOutlined } from '@mui/icons-material'
import { setOpenBookmarkDialog, setOpenShareDialog } from '../../../store/postSlice'
import { useDispatch } from 'react-redux'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const RoomCard = ({ val, token }) => {
    const [anchorE1, setAnchorE1] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [comment, setComment] = React.useState('')
    const [createCommentStatus, setCreateCommentStatus] = React.useState(false)
    const dispatch = useDispatch()

    const openOptionMenu = Boolean(anchorE1)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCommentSubmit = () => {

    }

    const handleClose = (item_no) => {
        setAnchorE1(null)
        if (item_no === 1) {
            dispatch(setOpenShareDialog(true))
        } else if (item_no === 2) {
            dispatch(setOpenBookmarkDialog(true))
        } else {
        }
    }
    return (
        <Box sx={{ borderRadius: { sm: '10px' }, backgroundColor: 'white', p: 2, mb: 2 }}>
            <Menu
                id="create_post_menu_id"
                anchorEl={anchorE1}
                open={openOptionMenu}
                onClose={() => setAnchorE1(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose(1)}>
                    <ListItemIcon>
                        <CachedOutlined fontSize="small" />
                    </ListItemIcon>
                    Share
                </MenuItem>
                <MenuItem onClick={() => handleClose(2)}>
                    <ListItemIcon>
                        <BookmarkBorderOutlined fontSize="small" />
                    </ListItemIcon>
                    Bookmark
                </MenuItem>
            </Menu>
            <Box sx={{ width: '100%', height: '200px', overflow: 'hidden', borderRadius: '10px', position: 'relative' }}>
                <Avatar sx={{ height: 30, width: 30, position: 'absolute', top: '15px', right: '15px' }} variant='rounded'  onClick={(event) => setAnchorE1(event.currentTarget)}>
                    <MoreHorizOutlined/>
                </Avatar>
                {
                    (val.file_type === 'image') ?
                        <img src={val.small_file} alt='no_image' style={{ objectFit: 'cover', width: '100%', height: '100%' }} /> :
                        <video src={val.small_file} style={{ height: '100%', width: '100%', objectFit: 'cover' }} controls controlsList='nodownload' />
                }
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: '12px', my: 1 }}>
                <Avatar sx={{ height: 20, width: 20 }} variant='rounded' src={val.logo} />
                <Typography sx={{ fontSize: '12px', fontWeight: 600, color: theme.palette.primary.main, flexGrow: 1 }}>{val.name}</Typography>
                <span>3 years ago</span>
                {/* <Avatar sx={{ height: 20, width: 20 }}> */}
                {/* <MoreHorizOutlined sx={{ color: theme.palette.disabled.main, mr: 1 }} onClick={(event) => setAnchorE1(event.currentTarget)} /> */}
                {/* </Avatar> */}
            </Box>
            {
                (val.description && val.description.length > 80 && val.post_type === 'article') ?
                    <Title mb={1}>
                        {val.description.substring(0, 80)} ...
                    </Title> :
                    val.post_type === 'article' ?
                        <Title mb={1}>
                            {val.description}
                        </Title> : null
            }
            {
                (val.description && val.description.length > 150) ?
                    <SubTitle>
                        {val.description.substring(0, 150)} ...
                    </SubTitle> :
                    <SubTitle>
                        {val.description}
                    </SubTitle>
            }
            {/* <SubTitle>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium enim nesciunt totam suscipit placeat rerum quaerat necessitatibus labore quis modi.</SubTitle> */}
            <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <FavoriteBorderOutlined sx={{ fontSize: '18px' }} /> */}
                    <Checkbox size='small' {...label} icon={<FavoriteBorderOutlined />} checkedIcon={<Favorite />} color='redish' />
                    <MiniTitle>1.2k likes</MiniTitle>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', cursor: 'pointer' }} onClick={handleExpandClick}>
                    <ChatOutlined sx={{ fontSize: '18px', color: '#627184' }} />
                    {/* <Checkbox size='small' {...label} icon={<ChatOutlined />} checkedIcon={<Chat />} color='primary'/> */}
                    <MiniTitle>4.7k Comments</MiniTitle>
                </Box>
                {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <AccessTime sx={{ fontSize: '18px' }} />
                    <MiniTitle>3 years ago</MiniTitle>
                </Box> */}
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                        display: 'flex',
                        alignItems: 'center',
                        // margin: '6px 10px 10px'
                        padding: '8px 0px',
                        borderTop: '1px solid #EAEAF2'
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleCommentSubmit}
                >
                    {/* <TextField
          size='small'
          fullWidth
          value={comment}
          id="basic_comment_textarea_id"
          placeholder='Comment something...'
          multiline
          maxRows={2}
          sx={{
            backgroundColor: (val.is_colored === 'yes' && val.role !== 'General') ? 'white' : '#f1f2f3',
          }}
          name="description"
          onChange={(e) => setComment(e.target.value)}
        /> */}
                    <Avatar variant='rounded' src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600' sx={{ width: 34, height: 34 }} />
                    <input
                        id="basic_comment_textarea_id"
                        value={comment}
                        placeholder='comment something'
                        style={{
                            backgroundColor: '#EAEAF2',
                            padding: '10px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            borderRadius: '8px',
                            margin: "0px 10px"
                        }}
                        name="description"
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Box sx={{ position: 'relative' }}>
                        <Button type='submit' size='small' variant="contained" sx={{ py: 1, textTransform: 'capitalize' }} disabled={(!token || createCommentStatus === 'loading') ? true : false} disableElevation>Send</Button>

                        {(createCommentStatus === 'loading') && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#44bd32',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}

export default RoomCard