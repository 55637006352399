import { Avatar, CardHeader, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import * as moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UnpinIcon from '@mui/icons-material/CancelOutlined';
import { red } from '@mui/material/colors';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Delete from '@mui/icons-material/DeleteOutlineOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { deletePost, handlePinPost, removePinPost, setOpenDeletePostDialog, updateShowHideStatus } from '../../store/postSlice'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import { entities } from "../HtmlEntity"
import { MoreHorizOutlined, PushPin, Visibility, VisibilityOff } from '@mui/icons-material';
import { decreaseTotalPinPostInGroupShortInfo, increaseTotalPinPostInGroupShortInfo, setOpenUaDialog } from '../../store/groupSlice';
import { SubTitle2, SubTitle3, Title2, Title4 } from '../../CustomComponents';

const CardTopHeader = ({ setisEditable, post_type, group_type, status, role, group_id, profile_pic, value, isPinned, setIsPinned, name, added_on, user_id, anchorEl, setAnchorEl, post_id,file_count }) => {
  const [report_to, set_report_to] = React.useState(null)
  const token = JSON.parse(localStorage.getItem("token"))
  const { uid = undefined } = JSON.parse(localStorage.getItem("user")) || {}
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const showUserProfile = (user_id) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    // navigate(`/profile/${user_id}/${group_id}`)
    navigate("/profile", {
      state: {
          group_id,
          user_id
      },
      // replace: true
  })
  }
  const handleMoreDetails = (event) => {
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    setAnchorEl(event.currentTarget)
  }
  const handleMenuItemClick = (e, category_type) => {
    // setOpenReportDialog(true)
    set_report_to(category_type)
    setAnchorEl(null);
    // handleReportDialogClose(category_type)
  }

  const deleteThePost = () => {
    setAnchorEl(null)
    if (value === 'saved') {
      return
    }
    dispatch(setOpenDeletePostDialog({status:true,post_id,post_type,value,file_count}))
    // if(file_count > 1 && post_type !== 'mediapoll' && value !== 'saved') {
    //   axios.delete(`${process.env.REACT_APP_API_URL}/api/post/deleteMultiFilePost/${post_id}`, { headers: { authorization: "Bearer " + token } })
    //   .then(response => {
    //     dispatch(deletePost({ feed_value: value, data: post_id }))
    //     dispatch(setSnackMessage(response.data.message))
    //     dispatch(setOpenSnack(true))
    //   }).catch(error => {
    //     dispatch(setSnackMessage("Sorry, something went wrong."))
    //     dispatch(setOpenSnack(true))
    //   })
    //   return
    // }
    // if (post_type !== 'mediapoll' && value !== 'saved') {
    //   axios.delete(`${process.env.REACT_APP_API_URL}/api/post/deletePost/${post_id}`, { headers: { authorization: "Bearer " + token } })
    //     .then(response => {
    //       dispatch(deletePost(post_id))
    //       dispatch(setSnackMessage(response.data.message))
    //       dispatch(setOpenSnack(true))
    //     }).catch(error => {
    //       dispatch(setSnackMessage("Sorry, something went wrong."))
    //       dispatch(setOpenSnack(true))
    //     })
    // }
    // if (post_type === 'mediapoll' && value !== 'saved') {
    //   axios.delete(`${process.env.REACT_APP_API_URL}/api/post/deleteMediaPollPost/${post_id}`, { headers: { authorization: "Bearer " + token } })
    //     .then(response => {
    //       dispatch(deletePost(post_id))
    //       dispatch(setSnackMessage(response.data.message))
    //       dispatch(setOpenSnack(true))
    //     }).catch(error => {
    //       dispatch(setSnackMessage("Sorry, something went wrong."))
    //       dispatch(setOpenSnack(true))
    //     })
    // }
  }

  const pinThePost = () => {
    setAnchorEl(null)
    setIsPinned("yes")
    axios.post(`${process.env.REACT_APP_API_URL}/api/post/add/pinPost`, { post_id, group_id }, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status !== 0) {
          setIsPinned(response.data.newPinPostId)
          dispatch(handlePinPost({ feed_value:value,value: response.data.newPinPostId, post_id }))
        }
        // dispatch(increaseTotalPinPostInGroupShortInfo())
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      }).catch(error => {
        dispatch(setSnackMessage("Sorry, something went wrong."))
        dispatch(setOpenSnack(true))
      })
  }

  const unpinThePost = () => {
    setAnchorEl(null)
    axios.delete(`${process.env.REACT_APP_API_URL}/api/post/delete/pinPost/${isPinned}/${group_id}`, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        if (response.data.status !== 0) {
          setIsPinned(null)
          if (location.pathname === "/pin-post-feed") {
            // remove the post from pinpostfeed
            dispatch(removePinPost({feed_value:value, data:post_id}))
          } else {
            dispatch(handlePinPost({ feed_value:value,value: null, post_id }))
          }
          // dispatch(decreaseTotalPinPostInGroupShortInfo())
        }
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      }).catch(error => {
        dispatch(setSnackMessage("Sorry, something went wrong."))
        dispatch(setOpenSnack(true))
      })
  }

  const handleEditPost = () => {
    setAnchorEl(null)
    setisEditable(true)
  }

  const handleHideOrShow = (post_status) => {
    setAnchorEl(null)
    if (!token) {
      dispatch(setOpenUaDialog(true))
      return
    }
    axios.put(`${process.env.REACT_APP_API_URL}/api/post/update/post_status`, {post_id,status:post_status}, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        // setPosts(posts.filter(el => el.xz12uied7 !== post_id))
        // dispatch(deletePost(post_id))
        if (response.data.status === 1) {
          dispatch(updateShowHideStatus({post_id, feed_value:value, value:post_status }))
        }
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      }).catch(error => {
        dispatch(setSnackMessage("Sorry, something went wrong."))
        dispatch(setOpenSnack(true))
      })
  }

  return (<>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >

      {/* <MenuItem onClick={() => {
        setAnchorEl(null)
        navigate(`/report-action/${uid}/${post_id}/${group_id}/${user_id}/1/1`)
      }}>
        <ListItemIcon>
          <Report fontSize="small" />
        </ListItemIcon>
        Report to Admin
      </MenuItem> */}
      {
        (user_id !== uid) &&
        <MenuItem onClick={() => {
          if (!token) {
            setAnchorEl(null)
            dispatch(setOpenUaDialog(true))
            return
          }
          setAnchorEl(null)
          navigate(`/report-action/${uid}/${post_id}/${group_id}/${user_id}/1/2`)
        }}>
          <ListItemIcon>
            <FlagIcon fontSize="small" />
          </ListItemIcon>
          {/* Report To ET Office */}
          Report
        </MenuItem>
      }
      {
        (user_id === uid && value !== 'saved') &&
        <MenuItem onClick={() => {
          setAnchorEl(null)
          deleteThePost(post_id)
        }}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          Delete Post
        </MenuItem>
      }
      {
        (user_id === uid && post_type !== 'groupProfile') &&
        <MenuItem onClick={handleEditPost}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Edit Post
        </MenuItem>
      }
      {
        (role !== 'General' && isPinned === null && token && value !== 'saved' && value !== 'myPost' && value !== 'member_post' && value !== 'my_member_post') &&
        <MenuItem onClick={pinThePost}>
          <ListItemIcon>
            <PushPin fontSize="small" />
          </ListItemIcon>
          Pin Post
        </MenuItem>
      }
      {
        (role !== 'General' && isPinned !== null && token && value !== 'myPost' && value !== 'saved' && value !== 'member_post' && value !== 'my_member_post') &&
        <MenuItem onClick={unpinThePost}>
          <ListItemIcon>
            <UnpinIcon fontSize="small" />
          </ListItemIcon>
          Unpin Post
        </MenuItem>
      }
      {
        (role !== 'General' && status === 'hide') &&
        <MenuItem onClick={() => handleHideOrShow('show')}>
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          Show To All
        </MenuItem>
      }
      {
        (role !== 'General' && status === 'show') &&
        <MenuItem onClick={() => handleHideOrShow('hide')}>
          <ListItemIcon>
            <VisibilityOff fontSize="small" />
          </ListItemIcon>
          Hide Post
        </MenuItem>
      }
    </Menu>
    {/* :reporter_id/:get_reported_id/:posted_group_id/:content_creator_id/:reported_for/:report_to */}
    <CardHeader
      avatar={
        <Avatar sx={{ bgcolor: red[500], width: '30px', height: '30px' }} variant='rounded' aria-label="recipe" src={profile_pic} onClick={() => showUserProfile(user_id)} />
      }
      action={
        <>
          {
            (isPinned !== null && value !== 'favorite_post'  && value !== 'saved' && token && value !== 'myPost' && value !== 'member_post' && value !== 'my_member_post') &&
            <IconButton aria-label="push-pin-icon" size='small'>
              <PushPin fontSize='small' />
            </IconButton>
          }
          {
            (value !== 'notification_post' && value !== 'favorite_post') &&
            <IconButton aria-label="settings" onClick={handleMoreDetails}>
              <MoreHorizOutlined />
            </IconButton>
          }
        </>
      }
      title={<Title2 component='span' onClick={() => showUserProfile(user_id)}>
        {name && name.replace(/&#?\w+;/g, match => entities[match])}
      </Title2>}
      subheader={
        <SubTitle2 color='disabled'>{moment(added_on).fromNow() + ' . ' + moment(added_on).format('DD-MMM-YY')}</SubTitle2>
      }
    />
  </>
  )
}

export default CardTopHeader