import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Collapse, Divider, IconButton, ListItemIcon, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import LikeOutline from '@mui/icons-material/FavoriteBorder';
import Like from '@mui/icons-material/Favorite';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import CancelShare from '@mui/icons-material/SyncDisabled';
import Share from '@mui/icons-material/Loop';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import React, { useRef } from 'react'
import CardTopHeader from './CardTopHeader';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import moment from 'moment/moment';
import Comment from './Comment';
import { entities } from "../HtmlEntity"
import { setOpenUaDialog } from '../../store/groupSlice';
import AllPostCardTopHeader from '../AllPost/AllPostCardTopHeader';
import { editPostDescription, increasePostCommentCount, setCurrentPostInfo, setFullPostDetails, setOpenBookmarkDialog, setOpenReactDialog, setOpenShareDialog, setPostToSave, setReactOnPost, setReactPostInfo, setRemovePostFromFolder, setRemovePostFromSavedFeed } from '../../store/postSlice';
import { AutoAwesomeMosaicOutlined, BookmarkAdd, BookmarkAddOutlined, BookmarkAddRounded, BookmarkAddedRounded, CameraOutdoor, CommentRounded, ContentCopy, Diversity1Outlined, Diversity2Outlined, Favorite, FavoriteBorderOutlined, FavoriteOutlined, FavoriteRounded, FlagOutlined, MoreHorizOutlined, Visibility } from '@mui/icons-material';
import { FilledButton, SubTitle1, SubTitle2, SubTitle3, Title2 } from '../../CustomComponents';
import SharePostMenu from '../Menu/SharePostMenu';
import ReactGA from 'react-ga4'
import theme from '../../Theme';
import LazyImage from '../Lazy/LazyImage';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ArticleCard = ({ val, value }) => {
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [isVisible, setIsVisible] = React.useState(false)
    const [description, setDescription] = React.useState()
    const [totalComment, setTotalComment] = React.useState(0)
    const [is_saved, set_is_saved] = React.useState(false)
    const [totalLiked, setTotalLiked] = React.useState(0)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const openOptionMenu = Boolean(anchorE2)
    const location = useLocation()
    const dispatch = useDispatch()
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user")) || {}
    const token = JSON.parse(localStorage.getItem('token'))
    const navigate = useNavigate()
    const ref = useRef(null)

    React.useEffect(() => {
        if (location.pathname.split('/')[1] === 'saved-post' || (val.is_saved && val.is_saved !== null)) {
            set_is_saved(true)
        } else {
            set_is_saved(false)
        }
    }, [val.is_saved])

    const toggleReadMore = () => setIsShowMore((show) => !show);

    const handleRemovePost = () => {
        set_is_saved(false)
    }

    // const callBackFunction = (entries) => {
    //     const [entry] = entries
    //     setIsVisible(entry.isIntersecting)
    // }

    let callBackFunction = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                //   console.log(entry)
                setIsVisible(true)
            }
        });
    };

    const options = {
        root: null,
        rootMargin: '-150px',
        threshold: 0.1
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(callBackFunction, options)
        if (ref?.current) {
            observer.observe(ref.current)
            if (isVisible) {
                if (val.file_type === "video") {
                    ref.current.play()
                }
            }
        }
        return () => {
            if (ref?.current) {
                if (val.file_type === "video") {
                    ref.current.pause()
                }
                observer.unobserve(ref.current)
            }
        }
    }, [ref, options])

    const handleVisitSharedPeopleProfile = (shared_by_user_id, shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // navigate(`/profile/${shared_by_user_id}/${shared_from_group_id}`)
        navigate("/profile", {
            state: {
                group_id: shared_from_group_id,
                user_id: shared_by_user_id
            },
            // replace: true
        })
    }
    const handleVisitSharedGroupProfile = (shared_from_group_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        navigate(`/group-profile/${shared_from_group_id}`)
    }

    const handleSharePost = (share_to) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        setAnchorE2(null)
        if (val.group_type === 'public' || val.type === 'friend' || val.type === 'follower') {
            if (share_to === 'group') {
                dispatch(setPostToSave({ feed_value: value, data: val.xz12uied7 }))
                dispatch(setOpenShareDialog(true))
            } else {

            }
        } else {
            dispatch(setSnackMessage(`You can't share... from a ${val.group_type} group.`))
            dispatch(setOpenSnack(true))
        }
    }

    const removeFromFolder = (now_post_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (value !== 'saved') {
            handleRemovePost()
        }
        if (value === 'saved') {
            dispatch(setRemovePostFromSavedFeed({ data: now_post_id }))
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/removed_saved_post/${now_post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    if (value !== 'saved') {
                        dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
                    }
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                } else {
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }

    const handleBookmarkPost = (event) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (location.pathname.split('/')[1] !== 'saved-post' && val.is_saved === null) {
            dispatch(setCurrentPostInfo({ post_item_id: val.xzu34ie0d98, feed_value: value }))
            dispatch(setOpenBookmarkDialog(true))
        } else {
            removeFromFolder(val.xzu34ie0d98)
        }
    }

    const showChannelProfile = (channel_id) => {
        navigate(`/channel-profile/${channel_id}`)
    }

    const handleReport = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        {/* :reporter_id/:get_reported_id/:posted_group_id/:content_creator_id/:reported_for/:report_to */}
        navigate(`/report-action/${user_id}/${val.xz12uied7}/${val.group_id}/${val.creator_id}/1/2`)
    }


    return (
        <>
            <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type={val.group_type} type={val.type} post_id={val.xz12uied7} value={value} group_id={val.group_id} creator_id={val.creator_id} group_name={val.group_name} />
            <Card elevation={0} sx={{ backgroundColor: val.is_colored === 'no' ? 'white' : val.role === 'Super Admin' ? '#d2f9de' : val.role === 'Admin' ? '#e6eeff' : 'white', width: '100%', marginBottom: 2, borderRadius: { xs: '0px', sm: '10px' }, border: '1px solid #E4E9F2' }}>
                {
                    (val.shared_by && val.shared_by_name !== val.name) &&
                    <>
                        {
                            (val.shared_from_group_id === 'null' || val.shared_from_group_id === null) ?
                                <SubTitle1 padding='10px' sx={{ backgroundColor: '#F7F9FC', color: theme.palette.dark.main }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, 0)}>{val.shared_by_name}</strong>
                                </SubTitle1> :
                                <SubTitle1 padding='10px' sx={{ backgroundColor: '#F7F9FC', color: theme.palette.dark.main }}>
                                    shared by — <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedPeopleProfile(val.shared_by, val.shared_from_group_id)}>{val.shared_by_name}</strong> from <strong sx={{ cursor: 'pointer' }} onClick={() => handleVisitSharedGroupProfile(val.shared_from_group_id)}>{val.shared_from_group_name}</strong> group
                                </SubTitle1>
                        }
                        <Divider />
                    </>
                }
                <CardHeader
                    avatar={
                        <Avatar sx={{ width: '30px', height: '30px' }} variant='rounded' aria-label="recipe" src={val.group_profile_pic} onClick={() => showChannelProfile(val.group_id)} ><CameraOutdoor fontSize='small' /></Avatar>
                    }
                    action={
                        <>
                            <Tooltip title="Report">
                                <IconButton aria-label="settings" onClick={handleReport}>
                                    <FlagOutlined fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            {/* <Title2 color='disabled.light'>report</Title2> */}
                        </>
                    }
                    title={<Title2 component='span' onClick={() => showChannelProfile(val.group_id)}>
                        {val.group_name && val.group_name.replace(/&#?\w+;/g, match => entities[match])}
                    </Title2>}
                    subheader={
                        <SubTitle2 color='disabled'>{moment(val.added_on).fromNow() + ' . ' + moment(val.added_on).format('DD-MMM-YY')}</SubTitle2>
                    }
                />
                {/* <CardContent sx={{ pt: 0, pb: 1 }}> */}
                <Box sx={{ fontSize: '16px', color: '#0984e3', marginBottom: '6px', cursor: 'pointer', marginLeft:'16px' }}>
                    {
                        val.tag?.split(",").map((tag, index) => {
                            return <span key={index} style={{ marginRight: '10px', fontSize: '14px' }} onClick={() => {
                                navigate({ pathname: '/search', search: createSearchParams({ searchedContent: tag, status: 'popular' }).toString() })
                            }}>#{tag}</span>
                        })
                    }
                </Box>
                {/* {(description && description.length > 120 && !isShowMore) ?
                        <>
                            <Title2 sx={{ whiteSpace: 'pre-line' }}>
                                {description.substring(0, 120)}...
                            </Title2>
                            <SubTitle1 component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</SubTitle1>
                        </>
                        :
                        <>
                            {
                                <Title2 sx={{ whiteSpace: 'pre-line' }}>
                                    {description}
                                </Title2>
                            }
                            {
                                description && description.length > 120 ?
                                    <SubTitle1 sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</SubTitle1> :
                                    null
                            }
                        </>
                    } */}
                {/* </CardContent> */}
                {/* {
                    val.file_count > 0 && (val.file_type === 'image' || val.file_type === 'gif') ?
                        <Box sx={{ px: 2, overflow: 'hidden', borderRadius: '20px' }}>
                            <LazyImage alt='No pic'
                                src={val.files}
                                onClick={fullImageHandler}
                                style={{ objectFit: 'cover', width: '100%', backgroundColor: '#2C3A47', minHeight: "200px", maxHeight: '90vh', minWidth: '100%' }} loading='lazy' />
                        </Box>
                        : val.file_count > 0 && val.file_type === 'video' ?
                            <Box sx={{ textAlign: 'center', width: '100%', px: 2 }}>
                                <video ref={ref} src={val.org_file} style={{ maxHeight: '500px', height: '100%', width: '100%' }} controls controlsList='nodownload' />
                            </Box>
                            : null
                } */}
                <Box sx={{ px: 2, overflow: 'hidden', borderRadius: '20px' }}>
                    <LazyImage alt='No pic'
                        // src="https://images.pexels.com/photos/2166711/pexels-photo-2166711.jpeg?auto=compress&cs=tinysrgb&w=600"
                        // src={val.file_type === 'image' ? val.files : val.org_file}
                        src={val.files}
                        onClick={() => {
                            navigate(`/read/post/${val.xz12uied7}`)
                        }}
                        style={{ objectFit: 'cover', width: '100%', backgroundColor: '#2C3A47', minHeight: "200px", maxHeight: '90vh', minWidth: '100%' }} loading='lazy' />
                </Box>
                <CardContent>
                    {(val.title && val.title.length > 120 && !isShowMore) ?
                        <>
                            <Title2 sx={{ whiteSpace: 'pre-line' }}>
                                {val.title.substring(0, 120)}...
                            </Title2>
                            <SubTitle1 component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</SubTitle1>
                        </>
                        :
                        <>
                            {
                                <Title2 sx={{ whiteSpace: 'pre-line' }}>
                                    {val.title}
                                </Title2>
                            }
                            {
                                val.title && val.title.length > 120 ?
                                    <SubTitle1 sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</SubTitle1> :
                                    null
                            }
                        </>
                    }
                </CardContent>
                <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px', gap: '8px' }}>
                            {/* <Checkbox
                                inputProps={{ 'aria-label': 'controlled' }}
                                icon={<FavoriteBorderOutlined fontSize='small' color='dark' />}
                            /> */}
                            <FavoriteBorderOutlined fontSize='small' color='dark' />
                            <SubTitle3 color='dark' sx={{ cursor: 'pointer' }}>
                                {val.total_react} Likes
                            </SubTitle3>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Visibility fontSize='small' color='dark' />
                            <SubTitle3 color='dark' sx={{ cursor: 'pointer' }}>
                                {val.total_view} views
                            </SubTitle3>
                        </Box>
                        <Tooltip title="Copy post link">
                            <IconButton aria-label="delete" size='small' onClick={() => {
                                // handleCopyLink(`https://www.emotiontown.com/read/post/${post.post_id}`)
                                ReactGA.event({
                                    category: 'copy',
                                    action: 'copied article post link',
                                    label: 'copied article post link'
                                })
                                navigator.clipboard.writeText(`https://www.emotiontown.com/read/post/${val.xz12uied7}`)
                                    .then(result => {
                                        dispatch(setSnackMessage("Link copied successfully."))
                                        dispatch(setOpenSnack(true))
                                    }).catch(error => {
                                    })
                            }}>
                                <ContentCopy fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                        <Checkbox
                            checked={is_saved || false}
                            onChange={handleBookmarkPost}
                            inputProps={{ 'aria-label': 'controlled' }}
                            icon={<BookmarkAddRounded fontSize='small' sx={{ color: theme.palette.light.dark }} />}
                            checkedIcon={<BookmarkAddedRounded color='dark' fontSize='small' />}
                        />
                    </Box>
                </CardActions>
            </Card>
        </>
    )
}

export default ArticleCard