import { Avatar, Box, CircularProgress, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, styled, Typography } from '@mui/material'
import React from 'react'
import Clap from '@mui/icons-material/SignLanguage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../status/Loading'
import NotFound from '../status/NotFound'
import WentWrong from '../status/WentWrong'
import moment from 'moment';

const EmojiContainer = styled(Box)(({ theme }) => ({
    width: '100px',
    padding: '5px',
    border: '1px solid gray',
    textAlign: 'center',
    display: 'inline-block'
}))

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const EmojiReactDetails = () => {
    const [expanded, setExpanded] = React.useState(false);
    const [reactCountStatus, setReactCountStatus] = React.useState('ok')
    const [reactCount, setReactCount] = React.useState({})
    const [reactDetails, setReactDetails] = React.useState([])
    const [callNo, setCallNo] = React.useState(1)
    const [hasMore,setHasMore] = React.useState(true)
    const token = JSON.parse(localStorage.getItem('token'))
    const navigate = useNavigate()
    const { post_id } = useParams()
    const emoji_item = [
        '',
        '😆',
        '😍',
        '😮',
        '😘',
        '😋',
        '😠',
        '😢',
    ];
    React.useEffect(() => {
        if (!reactCount.post_id) {
            setReactCountStatus('loading')
            axios.get(`${process.env.REACT_APP_API_URL}/api/post/react_count_details/${post_id}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.post_id) {
                        setReactCountStatus('ok')
                        setReactCount(response.data.data)
                    } else {
                        setReactCountStatus('not_found')
                    }
                }).catch(error => {
                    setReactCountStatus('error')
                })
        }
    }, [post_id])

    React.useEffect(() => {
        if (reactDetails.length <= 0 && expanded && reactCount.post_id === post_id) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/post/total_react_details/${post_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if (response.data.data.length >= 10) {
                        setCallNo(callNo + 1)
                        setReactDetails(response.data.data)
                    } else {
                        setReactDetails(response.data.data)
                        setHasMore(false)
                    }
                }).catch(error => {
                   
                })
        }
    }, [expanded])

    const fetchMoreData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/post/total_react_details/${post_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                if (response.data.data.length >= 10) {
                    setCallNo(callNo + 1)
                    setReactDetails(reactDetails.concat(response.data.data))
                } else {
                    setReactDetails(reactDetails.concat(response.data.data))
                    setHasMore(false)
                }
            }).catch(error => {
    
            })
    }


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Box sx={{ bgcolor: 'white', padding: '16px' }}>
            {
                reactCountStatus === 'loading' ? <Loading /> : reactCountStatus === 'not_found' ? <NotFound data={"No data found"} subData={"Sorry, we could not found any data to show.Please try again later."} /> : reactCountStatus === 'error' ? <WentWrong /> : reactCountStatus === 'ok' ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
                            <EmojiContainer>
                                {reactCount.first}
                                <Clap fontSize='inherit' sx={{ color: '#f0932b', }} />
                            </EmojiContainer>
                            <EmojiContainer>
                                {reactCount.second}
                                😆
                            </EmojiContainer>
                            <EmojiContainer>
                                {reactCount.third}
                                😍
                            </EmojiContainer>
                            <EmojiContainer>
                                {reactCount.fourth}
                                😮
                            </EmojiContainer>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 1 }}>
                            <EmojiContainer>
                                {reactCount.fifth}
                                😘
                            </EmojiContainer>
                            <EmojiContainer>
                                {reactCount.sixth}
                                😋
                            </EmojiContainer>
                            <EmojiContainer>
                                {reactCount.seventh}
                                😠
                            </EmojiContainer>
                            <EmojiContainer>
                                {reactCount.eighth}
                                😢
                            </EmojiContainer>
                        </Box>
                    </> : null
            }
            <Divider sx={{my:2}} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={handleExpandClick}>
                <Typography>React Details</Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {/* <Typography>{reactDetails[0].name}</Typography> */}
                <Box>
                    <List dense={true}>
                        <InfiniteScroll
                            dataLength={reactDetails.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            /></Box>}
                            endMessage={
                                reactDetails.length !== 0 ? <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p> : null
                            }
                        >
                            {/* <Typography>{reactDetails}</Typography> */}
                            {
                                reactDetails.map((val, index) => {
                                    // return <ListItem>
                                    //     <ListItemAvatar>
                                    //         <Avatar sx={{ width: 30, height: 30 }} aria-label={val.name} src={val.profile_pic} onClick={() => navigate(`/profile/${val.user_id}/0`)}/>
                                    //     </ListItemAvatar>
                                    //     <ListItemText
                                    //         primary={val.name}
                                    //         secondary={val.react !== '1' ? emoji_item[val.react - 1] : <Clap fontSize='inherit' sx={{ color: '#f0932b', }} />}
                                    //     />
                                    // </ListItem>
                                    return <Box sx={{display:'flex',alignItems:'start',marginBottom:'16px'}} key={val.reaction_id}>
                                        <Avatar sx={{ width: 30, height: 30 }} aria-label={val.name} src={val.profile_pic} onClick={() => navigate(`/profile/${val.user_id}/0`)}/>
                                        <Box sx={{ml:1,flexGrow:1}}>
                                            <Box sx={{display:'flex',justifyContent:'space-between',marginBottom:'4px'}}>
                                                <Typography variant='body2'>{val.name}</Typography>
                                                <Typography variant='caption' color='gray'>{moment(val.added_on).fromNow()}</Typography>
                                            </Box>
                                            <Typography>{val.react !== '1' ? emoji_item[val.react - 1] : <Clap fontSize='inherit' sx={{ color: '#f0932b', }} />}</Typography>
                                        </Box>
                                    </Box>
                                    // if (val.post_type === 'basic') {
                                    //   if (index === 3) {
                                    //     return <>
                                    //       <SuggestedGroup />
                                    //       <Basiccard key={index} val={val} value={value} />
                                    //     </>
                                    //   }
                                    //   return <Basiccard key={index} val={val} value={value} />
                                    // }
                                    // else if (val.post_type === 'compare') {
                                    //   if (index === 3) {
                                    //     return <>
                                    //       <SuggestedGroup />
                                    //       <CompareView key={index} val={val} value={value} />
                                    //     </>
                                    //   }
                                    //   return <CompareView key={index} val={val} value={value} />
                                    // }
                                    // else if (val.post_type === 'mediapoll') {
                                    //   if (index === 3) {
                                    //     return <>
                                    //       <SuggestedGroup />
                                    //       <PollView key={index} val={val} value={value} />
                                    //     </>
                                    //   }
                                    //   return <PollView key={index} val={val} value={value} />
                                    // }
                                    // else if (val.post_type === 'textpoll') {
                                    //   if (index === 3) {
                                    //     return <>
                                    //       <SuggestedGroup />
                                    //       <TextPoll key={index} val={val} value={value} />
                                    //     </>
                                    //   }
                                    //   return <TextPoll key={index} val={val} value={value} />
                                    // }
                                    // else {
                                    //   if (index === 3) {
                                    //     return <>
                                    //       <SuggestedGroup />
                                    //       <Ratedcard key={index} val={val} value={value} />
                                    //     </>
                                    //   }
                                    //   return <Ratedcard key={index} val={val} value={value} />
                                    // }
                                })
                            }
                        </InfiniteScroll>
                    </List>
                </Box>
            </Collapse>
            {/* <EmojiReactItem /> */}
        </Box>
    )
}

export default EmojiReactDetails