import { Avatar, Card, CardHeader, Divider, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { red } from '@mui/material/colors';
import BaRatedPost from './BaRatedPost';
import TextPollPost from './TextPollPost';
import ComparePost from './ComparePost';
import NoticePost from './NoticePost';
import LinkPost from './LinkPost';
import { useLocation, useNavigate } from 'react-router-dom';
import { entities } from '../../../HtmlEntity'
import MediaPollPost from './MediaPollPost';
import theme from '../../../../Theme';
import { Title2 } from '../../../../CustomComponents';
import ReactGA from 'react-ga4'

const CreatePost = ({ group_id, group_name,type,role }) => {
    const [selected, setSelected] = React.useState("love")
    const [isNoticeClicked, setIsNoticeClicked] = React.useState(false)
    const [anchorEl,setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const location = useLocation()
    const navigate = useNavigate()
    const { uid, name, profile_pic } = JSON.parse(localStorage.getItem("user"))

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      }, [])
    const handlePostType = (event) => {
        setSelected(event.target.value)
    }
    const handleNotice = () => {
        setIsNoticeClicked(true)
    }
    const goToProfile = () => {
        // navigate(`/profile/${uid}/${location.state.id}`)
        navigate("/profile", {
            state: {
                group_id: group_id,
                user_id: uid
            },
            // replace: true
        })
    }
    const handleClose = (value) => {
        setSelected(value)
        setAnchorEl(null)
    }
    return (
        <Card sx={{ marginBottom: 2, boxShadow: 'none' }}>
            <Title2 padding='10px' color='primary'>Post to {group_name}</Title2>
            <Divider/>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose('love')}>Love Post</MenuItem>
                <MenuItem onClick={() => handleClose('special')}>Special Post</MenuItem>
                <MenuItem onClick={() => handleClose('textpoll')}>Text Poll</MenuItem>
                <MenuItem onClick={() => handleClose('mediapoll')}>Media Poll</MenuItem>
            </Menu>
            <CardHeader
                avatar={
                    <Avatar variant='rounded' sx={{ width: 30, height: 30 }} aria-label="recipe" 
                    src={profile_pic}
                    // src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600'
                     onClick={goToProfile} />
                }
                // action={
                //     !isNoticeClicked ? <Button variant="text" onClick={handleNotice}>Create Notice</Button> : null
                // }
                title={
                    <>
                        {/* {`what's in your mind `} */}
                        <Typography sx={{ maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 600 }}>
                            {/* <span>share your emotion </span> */}
                            {name.replace(/&#?\w+;/g, match => entities[match])}
                        </Typography>
                    </>

                }
                // subheader={
                //     <>
                //         <span>create </span>
                //         <span style={{ color: theme.palette.primary.main,textTransform:'capitalize' }} onClick={(event) => setAnchorEl(event.currentTarget)}>{selected} Post</span>
                //     </>
                // }
            // subheader={
            //     !isNoticeClicked ? <select value={selected} style={{ padding: '4px' }} onChange={handlePostType}>
            //         <option value="love" style={{ padding: '4px' }}>Love Post</option>
            //         <option value="basic" style={{ padding: '4px' }}>Special Post</option>
            //         <option value="textpoll" style={{ padding: '4px' }}>Text Poll</option>
            //         <option value="mediapoll" style={{ padding: '4px' }}>Media Poll</option>
            //     </select> : null
            // }
            />
            {
                // isNoticeClicked ? <NoticePost group_id={group_id} setIsNoticeClicked={setIsNoticeClicked} /> : (selected === 'basic' || selected === 'rated' || selected === 'love') ? <BaRatedPost post_type={selected} /> : (selected === 'compare' || selected === 'mediapoll') ? <ComparePost post_type={selected} /> : selected === 'textpoll' ? <TextPollPost post_type={selected} /> : (selected === 'link') ? <LinkPost /> : null
                // isNoticeClicked ? <NoticePost group_id={group_id} setIsNoticeClicked={setIsNoticeClicked} /> : (selected === 'basic' || selected === 'love') ? <BaRatedPost post_type={selected} /> : selected === 'textpoll' ? <TextPollPost post_type={selected} /> : null
                isNoticeClicked ? <NoticePost group_id={group_id} setIsNoticeClicked={setIsNoticeClicked} /> : (selected === 'basic' || selected === 'love') ? <BaRatedPost post_type={selected} group_id={group_id} group_name={group_name} type={type} role={role} /> : (selected === 'compare' || selected === 'mediapoll') ? <MediaPollPost post_type={selected} group_id={group_id} /> : selected === 'textpoll' ? <TextPollPost post_type={selected} group_id={group_id} /> : null
            }
            {/* <BaRatedPost/> */}
            {/* <TextPollPost/> */}
            {/* <ComparePost /> */}
            {/* <CardContent sx={{ py: 0 }}>
                <textarea style={text_area_style} placeholder='Write something about you...' />
            </CardContent>
            <CardActions disableSpacing sx={{ justifyContent: 'space-between' }}>
                <Box>
                    <IconButton aria-label="add to favorites">
                        <Photo />
                    </IconButton>
                    <IconButton aria-label="share">
                        <Video />
                    </IconButton>
                    <IconButton aria-label="share">
                        <Gif />
                    </IconButton>
                </Box>
                <Box>
                    <CancelButton variant='outlined'>Cancel</CancelButton>
                    <PostButton>Post</PostButton>

                </Box>
            </CardActions> */}
        </Card>
    )
}

export default CreatePost