import { Avatar, Badge, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, Divider, TextField, Typography, styled } from '@mui/material'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment/moment';
import Comment from '../../Cards/Comment';
import { setOpenSnack, setSnackMessage } from '../../../store/authSlice';
import { entities } from '../../HtmlEntity';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const NotiMediaPoll = ({ val, setVal }) => {
    // const [val, setVal] = React.useState({})
    const [expanded, setExpanded] = React.useState(false);
    const [description, setDescription] = React.useState()
    const [comment, setComment] = React.useState("")
    const [newComment, setNewComment] = React.useState({})
    const [totalComment, setTotalComment] = React.useState(0)
    const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
    const [totalVoted, setTotalVoted] = React.useState(0)
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const socket = useOutletContext()
    // const [lineCount, setLineCount] = React.useState(0);
    const dispatch = useDispatch()
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem('token'))
    const ref = useRef(null)
    const navigate = useNavigate()
    React.useEffect(() => {
        if (val.xzu34ie0d98) {
            setTotalComment(val.total_comment)
            if (val.total_react !== 0) {
                setTotalVoted(val.total_react)
            }
            if (val.description && val.description.length > 0) {
                setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
            }
        }
    }, [val])

    // React.useEffect(() => {
    //     if (!val.xzu34ie0d98) {
    //         setLoading(true)
    //         axios.get(`${process.env.REACT_APP_API_URL}/api/post/get_post/${post_id}`, { headers: { authorization: "Bearer " + token } })
    //             .then(response => {
    //                 setLoading(false)
    //                 if (response.data.status === 1) {
    //                     setVal(response.data.data)
    //                 } else {
    //                     // show not found poll item
    //                 }
    //             }).catch(error => {
    //                 setLoading(false)
    //                 dispatch(setSnackMessage("Something went wrong. Try later."))
    //                 dispatch(setOpenSnack(true))
    //             })
    //     }
    // }, [])
    React.useEffect(() => {
        if (val && !val.options) {
            setLoading(true)
            axios.get(`${process.env.REACT_APP_API_URL}/api/post/get/poll_options/${val.xz12uied7}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    setLoading(false)
                    if (response.data.status === 1) {
                        setVal({ ...val, options: response.data.data })
                    } else {
                        // show not found poll item
                    }
                }).catch(error => {
                    setLoading(false)
                    dispatch(setSnackMessage("Something went wrong. Try later."))
                    dispatch(setOpenSnack(true))
                })
        }
    }, [val])

    const toggleReadMore = () => setIsShowMore((show) => !show);


    const handleCommentSubmit = (e) => {
        e.preventDefault()
        setCreateCommentStatus('loading')
        const data = { post_id: val.xz12uied7, group_id: val.group_id, comment, message: `comments on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`, user_to_notify: val.u_id }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                const new_comment = {
                    comment_id: response.data.comment_id,
                    comment,
                    added_on: moment().format(),
                    user_id,
                    profile_pic,
                    total_reply: 0,
                    total_reacted: 0,
                    is_reacted: null,
                    name
                }
                setNewComment(new_comment)
                setTotalComment(totalComment + 1)
                if (response.data.status === 1) {
                    if (val.creator_id !== user_id) {
                        socket?.emit('commentOnPost', { receiverid: val.u_id })
                    }
                }
                setCreateCommentStatus('ok')
            }).catch(error => {
                setCreateCommentStatus('error')
            })
        // dispatch(submitComment(data))
        setComment("")
    };

    return (
        // backgroundColor:val.role==="Super Admin" ? '#e7e6ff':val.role === 'Admin'?'#ebf7fa':'white'
        <>
            {
                (val && val.xzu34ie0d98) ?
                    <Card ref={ref} sx={{ width: '100%', borderRadius: '0px', marginBottom: 2, boxShadow: '0px 0px 25px -10px rgba(51,61,82,0.8)' }}>
                        <CardHeader
                            avatar={
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar src={val.profile_pic} />
                                    }
                                >
                                    <Avatar alt={val.group_name} src={val.group_profile_pic} />
                                </Badge>
                            }
                            title={`${val.user_name}`}
                            subheader={`posted to ${val.group_name} group`}
                        />
                        <CardContent sx={{ pt: 0, pb: 1 }}>
                            {(description && description.length > 250 && !isShowMore) ?
                                <>
                                    <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{whiteSpace:'pre-line'}}>
                                        {description.substring(0, 250)}...
                                    </Typography>
                                    <Typography component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</Typography>
                                </>
                                :
                                <>
                                    {
                                        <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{whiteSpace:'pre-line'}}>
                                            {description}
                                        </Typography>
                                    }
                                    {
                                        description && description.length > 250 ?
                                            <Typography sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</Typography> :
                                            null
                                    }
                                </>
                            }
                            {
                                (val.link) &&
                                <Box>
                                    {(val.link && val.link.length > 100) ?
                                        <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                            {val.link.substring(0, 100)} ...
                                        </a> :
                                        <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                            {val.link}
                                        </a>
                                    }
                                </Box>
                            }
                        </CardContent>
                        <Box sx={{ overflowX: 'scroll', display: 'flex' }}>
                            {
                                val.options && val.options.map((option, index) => {
                                    var percentage_value = Math.round((option.total_vote * 100) / totalVoted) || 0
                                    return <Box key={option.option_id} sx={{ padding: '2px', width: '300px', marginX: '10px', backgroundColor: 'white', paddingBottom: '10px', border: '1px solid #b2bec3' }}>
                                        <Box sx={{ width: '300px', height: '300px', backgroundColor: '#2d3436' }}>
                                            <img src={option.file} alt="no pic" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </Box>
                                        <Box sx={{ px: 1 }}>
                                            <Typography variant='subtitle2' marginBottom='5px'>{option.text_option !== 'null' ? option.text_option : ''}</Typography>
                                            <Box>
                                                <Typography sx={{ width: '25%', paddingLeft: '10px', color: 'gray' }}>{percentage_value}% vote </Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                })
                            }
                            {
                                (!val.options && loading === true) &&
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px', width: '100%' }}>
                                    <CircularProgress size={24} />
                                </Box>
                            }
                        </Box>
                        <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                                    <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                                        Total vote: {totalVoted}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                                    <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                                        Total comment: {totalComment}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ mt: 1 }} />
                        </CardActions>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { width: '100%' },
                                display: 'flex',
                                margin: '6px 10px 10px'
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleCommentSubmit}
                        >
                            <TextField
                                size='small'
                                fullWidth
                                value={comment}
                                id="basic_comment_textarea_id"
                                placeholder='Comment something...'
                                multiline
                                maxRows={2}
                                sx={{
                                    backgroundColor: '#f1f2f3',
                                }}
                                name="description"
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <Box sx={{ position: 'relative' }}>
                                {/* <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={loading ? true : (basicOrRatedPostData.file !== '' || basicOrRatedPostData.description !== '') ? false : true} disableElevation>Post</Button> */}
                                <Button type='submit' size='small' variant="contained" sx={{ ml: 1, py: 1, textTransform: 'capitalize' }} disabled={(createCommentStatus === 'loading') ? true : false} disableElevation>Send</Button>

                                {(createCommentStatus === 'loading') && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: '#44bd32',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent sx={{ padding: '0px 8px 24px' }}>
                                <Comment file_type={val.file_type} post_id={val.xz12uied7} role='General' is_colored='no' value='notification_post' newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} />
                            </CardContent>
                        </Collapse>
                    </Card> :
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px', width: '100%' }}>
                        <CircularProgress size={24} />
                    </Box>
            }
        </>
    )
}

export default NotiMediaPoll