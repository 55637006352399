import { Box, Checkbox, CircularProgress, Container, CssBaseline, FormControlLabel, IconButton, LinearProgress, TextField, Typography } from '@mui/material'
import React from 'react'
import { EmotInput, StyledInputLabel, FormFieldAlertBox, FilledButton, DisabledButton, OutlinedButton } from '../../CustomComponents'
import { ArrowBackIosNewOutlined, Block, InsertPhotoOutlined, OndemandVideoOutlined, SaveAsOutlined, CloseRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import theme from '../../Theme'

const UploadContent = () => {
  const [details, setDetails] = React.useState({ description: '', file_type: 'image' })
  const [files, setFiles] = React.useState([])
  const [error, setError] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [detailsUpdatingStatus, setDetailsUpdatingStatus] = React.useState(false)
  const [uploadPercentage, setUploadPercentage] = React.useState(0);
  const [timeNeeded, setTimeNeeded] = React.useState(0)
  const [speed, setSpeed] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  const convertToMinAndHour = (seconds) => {
    var toMin = Math.floor(seconds / 60)
    if (toMin > 60) {
      // convert to hour
      return `${Math.floor(toMin / 60) || 0} hour`
    } else if (toMin > 0 && toMin < 60) {
      // convert to minute
      return `${Math.floor(seconds / 60) || 0} min`
    } else {
      return `${seconds} sec`
    }
  }
  const convertToKBAndMB = (byte) => {
    var kbdata = Math.floor(byte / 1024)
    if (kbdata > 1024) {
      return `${Math.floor(kbdata / 1024) || 0} mb`
    } else if (byte < 1024) {
      return `${Math.floor(byte) || 0} b`
    } else {
      return `${kbdata} kb`
    }
  }

  const removeFile = (index_no) => {
    setFiles((items) => items.filter((item) => item.key !== index_no));
  }

  function getExtension(filename) {
    return filename.split('.').pop()
  }
  const handleFileSelect = (event, file_type) => {
    if (files.length > 0) {
      setFiles(files.splice(0, files.length))
    }
    setDetails({ ...details, file_type })
    const values = []
    for (let index = 0; index < event.target.files.length; index++) {
      var file_extension = getExtension(event.target.files[index].name)
      if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg' || file_extension.toLowerCase() === 'mp4' || file_extension.toLowerCase() === 'gif') {
        values.push({ file: event.target.files[index], status: 1, key: index })
      } else {
        values.push({ file: event.target.files[index], status: 0, key: index })
      }
    }
    setFiles(files.concat(values))
  }
  const handleInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setDetails(data => ({ ...data, [name]: value }))
    setError("")
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: {xs:'8px',sm:'16px'}
      }}
    >
      <CssBaseline />
      <Container maxWidth="sm">
        <Box component="form" noValidate
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            border: '1px solid #EAEAF2',
            padding: {xs:'8px',sm:'16px'}
          }}
        >
          <StyledInputLabel htmlFor="about-room-field-id">
            Write something about post
          </StyledInputLabel>
          <EmotInput
            required
            variant='standard'
            multiline
            rows={3}
            id="about-room-field-id"
            sx={{ mb: 1 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              (files.length > 0 && details.file_type !== "video") &&
              files.map((item, index) => {
                return <Box key={index} sx={{ position: 'relative', width: '60px', height: '60px', backgroundColor: 'orange', mr: 2, mt: 1 }}>
                  {
                    (item.status === 0) &&
                    <Box sx={{ width: '100%', height: '100%', backgroundColor: 'rgba(255,0,0,0.8)', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', top: 0, right: 0 }}>
                      <Block />
                    </Box>
                  }
                  <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={URL.createObjectURL(item.file)} alt="no pic" />
                  <IconButton color="primary" onClick={() => removeFile(item.key)} aria-label="upload picture" component="label" sx={{ backgroundColor: 'red', width: '18px', height: '18px', position: 'absolute', bottom: '2px', right: '-9px', color: 'white', '&:hover': { backgroundColor: 'red' } }}>
                    <CloseRounded />
                  </IconButton>
                </Box>
              })
            }
          </Box>
          {
            (files.length > 5) &&
            <Typography variant='caption' px={2}>upload maximum 5 files (jpg, png, jpeg)</Typography>
          }
          {
            (files.length > 0 && message) &&
            <Typography variant='caption' px={2} color='red'>{message}</Typography>
          }
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #EAEAF2', padding: '6px 0px' }}>
            <IconButton component='label' onChange={(event) => handleFileSelect(event, "image")} >
              <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
              <InsertPhotoOutlined color='dark' />
            </IconButton>
            <IconButton component='label' onChange={(event) => handleFileSelect(event, "video")} >
              <input hidden accept="video/mp4" multiple type="file" />
              <OndemandVideoOutlined color='dark' />
            </IconButton>
          </Box>
          {
            (uploadPercentage > 0) &&
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', marginRight: '4px' }}>
                  <LinearProgress variant="determinate" value={uploadPercentage} />
                </Box>
                <Box sx={{ minWidth: 35, textAlign: 'end' }}>
                  <Typography variant="body2" color="text.secondary">{`${Math.round(
                    uploadPercentage,
                  )}%`}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography fontSize='13px' color='grey' mx={1}>{convertToMinAndHour(timeNeeded)}</Typography>
                <Typography fontSize='13px' color='grey'>({convertToKBAndMB(speed)}/sec)</Typography>
              </Box>
            </>
          }
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
            <OutlinedButton startIcon={<ArrowBackIosNewOutlined />} onClick={() => navigate(-1)}>Back</OutlinedButton>
            <FilledButton type='submit' disabled={detailsUpdatingStatus ? true : false} component="label" startIcon={<SaveAsOutlined />}>
              Submit
            </FilledButton>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default UploadContent