import styled from '@emotion/styled'
import { Alert, AppBar, Autocomplete, Avatar, Box, Button, CircularProgress, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import theme from "../../../Theme"
import { ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReactGA from 'react-ga4'
import axios from 'axios';
import { Logo } from '../../../CustomComponents';
import logo from '../../../media/images/logo.png'
import { setOpenSnack, setSnackMessage } from '../../../store/authSlice';
import { useDispatch } from 'react-redux';

const SubmitButton = styled(Button)((theme) => ({
    fontWeight: '600',
    borderRadius: 0,
    backgroundColor: '#d1ddfa',
    color: '#4b7bec',
    padding: '12px 26px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#baccf8',
    }
}))
const BackButton = styled(Button)((theme) => ({
    fontWeight: '600',
    borderRadius: 0,
    backgroundColor: '#dfe6e9',
    color: '#2d3436',
    padding: '12px 26px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#b2bec3',
    }
}))
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Emotiontown.com'}
            {/* <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link> */}
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ContactUs = () => {
    const [data, setData] = React.useState({ name: '', email: '', subject: '', message: '' })
    const [isLoading, setIsLoading] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })
    }, [])

    React.useEffect(() => {
        setMessage("")
        return () => {
            setMessage("")
        }
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault()
        ReactGA.event({
            category: 'contact us',
            action: 'submit message',
        })
        setIsLoading(true)
        var name = data.name.trim()
        var email = data.email.trim()
        var subject = data.subject.trim()
        var message = data.message.trim()
        if (!name || !email || !subject || !message) {
            setIsLoading(false)
            setMessage("All fields required")
            setIsLoading(false)
            return
        } else {
            setIsLoading(false)
            setMessage("")
        }
        axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user/complain/contact_us`, data)
            .then(response => {
                setIsLoading(false)
                setData({ name: '', email: '', subject: '', message: '' })
                if (response.data.status === 'error' || response.data.status === 0) {
                    setMessage(response.data.message)
                }
                if (response.data.status === 1) {
                    navigate("/signin", { replace: true })
                    // navigate('/verification',{state:{mobile_no:mobile_no,mobile_prefix:mobile_prefix,dest:'new_user'},replace:true})
                    // setMessage(response.data.message)
                    dispatch(setSnackMessage(response.data.message))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                setIsLoading(false)
            })
    }
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ backgroundColor: 'white', height: `100vh`, position: 'fixed', width: '100%', zIndex: -1 }}></Box>
            <Grid item xs={6}>
                <Box sx={{ padding: '10px', position: 'sticky', top: 0, backgroundColor: 'white' }}>
                    <Logo
                        variant='h4'
                        noWrap
                        component="span"
                        sx={{ fontSize: '20px', fontWeight: 800, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', flexGrow: 1 }}>
                        EmotionTown
                    </Logo>
                </Box>
                <Container component='main'>
                    {/* <CssBaseline/> */}
                    <Box sx={{ marginTop: 1, py: 1, px: { xs: 2, md: 3 }, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>
                        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                            <img src={logo} alt="not found" style={{ width: '55px', marginbottom: '22px' }} />
                            <Typography fontWeight={600} color='primary' fontSize='19px'>
                                Welcome to Emotion Town
                            </Typography>
                            <Typography variant="caption" color='text.secondary'>
                                Feel free to contact with us
                            </Typography>
                        </Box>
                        {
                            (message && message !== null) &&
                            <Alert severity="error" sx={{ mt: 2, width: '96%' }}>{message}</Alert>
                        }
                        <Box component='form' autoComplete='off' onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete='false'
                                        fullWidth
                                        required
                                        id="name_input_id"
                                        label="Your name"
                                        type="text"
                                        name='name'
                                        value={data.name}
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="email_input_id"
                                        label="Your email"
                                        type="email"
                                        name='dob'
                                        value={data.email}
                                        onChange={(e) => setData({ ...data, email: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        autoComplete='off'
                                        type='text'
                                        id="subject-of-topic-input"
                                        label="Subject"
                                        value={data.subject}
                                        onChange={(e) => setData({ ...data, subject: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        autoComplete='off'
                                        type='text'
                                        multiline
                                        rows={3}
                                        id="message-input"
                                        label="Message"
                                        value={data.message}
                                        onChange={(e) => setData({ ...data, message: e.target.value })}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {/* <BackButton sx={{ marginRight: '20px' }} onClick={() => navigate(-1)}>Back</BackButton> */}
                                <Box sx={{ position: 'relative', backgroundColor: 'orange' }}>
                                    <SubmitButton type='submit' disabled={isLoading ? true : false}>Submit</SubmitButton>
                                    {isLoading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "#44bd32",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 1 }} />
                    <Box sx={{ padding: '10px', textAlign: 'center' }}>
                        {/* <Typography variant='caption' color='text.secondary' mr={1}>Disclaimer</Typography> */}
                        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
                        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
                        <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
                        <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
                    </Box>
                </Container>
            </Grid>
        </ThemeProvider>
    )
}

export default ContactUs