import * as React from 'react';
import { styled } from '@mui/material/styles';
import theme from '../../Theme'
import { CreatePostButton, FilledButton, Logo, Title } from '../../CustomComponents'
import CreateGroup from '@mui/icons-material/GroupAddRounded';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Button, Container, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, Chip, Backdrop, CircularProgress } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import Logout from '@mui/icons-material/Logout';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Add, AddHomeOutlined, AutoAwesomeMosaicOutlined, CameraOutdoor, CameraOutdoorOutlined, CollectionsBookmarkOutlined, CollectionsBookmarkRounded, Diversity1Outlined, EmojiPeople, EmojiPeopleOutlined, Engineering, FavoriteBorderRounded, FavoriteOutlined, FavoriteRounded, FeedbackOutlined, Forum, Groups, GroupsOutlined, GroupsRounded, HomeOutlined, HomeRounded, InsertInvitation, InsertInvitationOutlined, PersonAddAlt, PersonOutline } from '@mui/icons-material';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef } from 'react';
import { send } from '../../utils/Push.js'
import ReactGA from 'react-ga4'
import { resetPostSlice, setInvitationRequestInfo, setOpenNotifyDialog } from '../../store/postSlice';
import { emptyNotificationList, emptyTotalNotification, resetAuthSlice, setAccounts, setTotalNotification } from '../../store/authSlice';
import { removeGroupFromList, resetGroupSlice, setCurrentGroupInfoChangeRoleAndStatus, setOpenUaDialog, updateMemberRoleInGroup } from '../../store/groupSlice';
import logoIcon from '../../media/images/logo.png'
import { resetChannelSlice } from '../../store/channelSlice';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  // backgroundColor: '#EAEAF2',
  backgroundColor: theme.palette.light.light,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  '&:hover': {
  },
  // marginRight: theme.spacing(2),
  marginLeft: '28px',
  width: { sm: '250px', md: '300px' },
}));


const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundColor:'red'
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#2C3A47',
  // backgroundColor:'red',
  width: '100%',
  // color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  // color: rgba(152, 48, 255 ,0.8),
  color: theme.palette.getContrastText(deepOrange[500]),
  backgroundColor: 'rgba(252, 66, 123,0.8)',
  transition: 0.3,
  '&:hover': {
    backgroundColor: 'rgba(252, 66, 123,0.2)',
  },
}));

const drawerWidth = 300;

export default function PrimarySearchAppBar(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [prompt, setPrompt] = React.useState(null)
  const [postOptionsAnchor, setPostOptionsAnchor] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openList, setOpenList] = React.useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [allAccountsLoadingStatus, setAllAccountsLoadingStatus] = React.useState(false)
  const [value, setValue] = React.useState('')
  const total_notification = useSelector(state => state.auth.totalNotification)
  const my_notifications = useSelector(state => state.auth.notifications)
  // const allGroupPostListCallNo = useSelector(state => state.post.allGroupPostListCallNo)
  const { total_invitation, total_fav_request, total_group_request } = useSelector(state => state.post.invitationRequestInfo)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const open = Boolean(anchorEl);
  const openCreatePostMenu = Boolean(anchorE2)
  const openPostMenu = Boolean(postOptionsAnchor);
  const { uid = undefined, profile_pic, name = 'a' } = JSON.parse(localStorage.getItem("user")) || {}
  const token = JSON.parse(localStorage.getItem('token'))
  const accounts = JSON.parse(localStorage.getItem('accounts'))
  const searchRef = useRef(null)
  const location = useLocation()

  // added last ################################################
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false)

  React.useEffect(() => {
    // empty my post 
    // if (my_posts.length > 0) {
    //   if (location.pathname.split('/')[1] !== 'profile' && location.pathname.split('/')[1] !== 'group-profile' && location.pathname !== '/full-image' && location.pathname !== '/full-multi-image' && location.pathname !== '/shared-to-group' && location.pathname.split('/')[1] !== 'folders' && location.pathname.split('/')[1] !== 'report-action') {
    //     dispatch(emptyMyPostList())
    //   }
    // }
    // empty notifications
    if (my_notifications.length > 0) {
      if (location.pathname !== '/notifications' && location.pathname !== '/notifications_post' && location.pathname !== '/full-multi-image' && location.pathname !== '/full-image') {
        dispatch(emptyNotificationList())
      }
    }
    // empty save posts
    // if (save_posts.length > 0) {
    //   if (location.pathname.split('/')[1] !== 'saved-post' && location.pathname !== '/full-image' && location.pathname !== '/full-multi-image' && location.pathname !== '/shared-to-group' && location.pathname.split('/')[1] !== 'folders' && location.pathname.split('/')[1] !== 'report-action') {
    //     dispatch(emptySavePostList())
    //   }
    // }
    // empty favourite posts
    // if (favourite_posts.length > 0) {
    //   if (location.pathname !== '/favourite-post' && location.pathname !== '/full-image' && location.pathname !== '/full-multi-image' && location.pathname !== '/shared-to-group' && location.pathname.split('/')[1] !== 'folders' && location.pathname.split('/')[1] !== 'report-action') {
    //     dispatch(emptyFavouritePostList())
    //   }
    // }
  }, [location])

  React.useEffect(() => {
    props.socket?.on("getFavouriteNotification", (data) => {
      // setNotifications(data)
      dispatch(setTotalNotification(total_notification + 1))
    })
    props.socket?.on("removed-from-group", (data) => {
      // setNotifications(data)
      dispatch(setTotalNotification(total_notification + 1))
      dispatch(removeGroupFromList(data.group_id))
      dispatch(setCurrentGroupInfoChangeRoleAndStatus({ status: null, role: null }))
      dispatch(setOpenNotifyDialog({ status: true, message: `You are removed from ${data.group_name} group.` }))
      // remove from group list
      // show a dialog
    })
    props.socket?.on("replace_to_super_admin", (data) => {
      // setNotifications(data)
      dispatch(setTotalNotification(total_notification + 1))
      dispatch(setCurrentGroupInfoChangeRoleAndStatus({ status: 'active', role: 'Super Admin' }))
      dispatch(setOpenNotifyDialog({ status: true, message: `Now you are Super Admin of ${data.group_name} group.` }))
      // remove from group list
      // show a dialog
    })
    props.socket?.on("makes_admin", (data) => {
      // setNotifications(data)
      dispatch(setTotalNotification(total_notification + 1))
      dispatch(setCurrentGroupInfoChangeRoleAndStatus({ status: 'active', role: 'Admin' }))
      dispatch(setOpenNotifyDialog({ status: true, message: `Now you are an Admin of ${data.group_name} group.` }))
      // remove from group list
      // show a dialog
    })
    props.socket?.on("makes_general", (data) => {
      // setNotifications(data)
      dispatch(setTotalNotification(total_notification + 1))
      dispatch(setCurrentGroupInfoChangeRoleAndStatus({ status: 'active', role: 'General' }))
      dispatch(setOpenNotifyDialog({ status: true, message: `Now you are a General user of ${data.group_name} group.` }))
      // remove from group list
      // show a dialog
    })
    props.socket?.on("add_member", ({member_name, group_name}) => {
      // setNotifications(data)
      dispatch(setTotalNotification(total_notification + 1))
      send("Emotion Town", `${member_name} added you to ${group_name} group.`)
      // remove from group list
      // show a dialog
    })
    props.socket?.on("new-post", ({ group_name }) => {
      send("Emotion Town", `New post arrived in ${group_name} group`)
    })

    props.socket?.on("new-channel-post", ({ group_name }) => {
      send("Emotion Town", `New post arrived in ${group_name} channel`)
    })
  }, [props.socket])

  // React.useEffect(() => {
  //   const handler = (event) => {
  //     setPrompt(event)
  //   }

  //   window?.addEventListener('beforeinstallprompt', handler)

  //   return () => {
  //     window?.removeEventListener('beforeinstallprompt', handler)
  //   }
  // }, [])

  // React.useEffect(() => {
  //   console.log(`total notification`)
  //   axios.get(`${process.env.REACT_APP_API_URL}/api/users/total_notification`, { headers: { authorization: "Bearer " + token } })
  //     .then(response => {
  //       console.log(`new notification`)
  //       dispatch(setTotalNotification(response.data.data[0].total_notification))
  //     }).catch(error => {
  //       dispatch(emptyTotalNotification())
  //     })
  // }, [])
  // React.useEffect(() => {
  //   setTimeout(() => {      
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/users/inReqInfo`, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {
  //         dispatch(setInvitationRequestInfo(response.data.data))
  //       }).catch(error => {
  //         dispatch(emptyTotalNotification())
  //       })
  //   }, 30000);
  // }, [])
  // React.useEffect(() => {
  //   if (isSavedFolderOpen && folderList.length <= 0 && status !== 'empty') {
  //     setStatus('loading')
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/post/get_my_folders/${uid}`, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {
  //         if (response.data.data.length <= 0) {
  //           setStatus('empty')
  //         } else {
  //           dispatch(setFoldersList(response.data.data))
  //           setStatus('ok')
  //         }
  //       }).catch(error => {
  //         setStatus('error')
  //       })
  //   }
  // }, [isSavedFolderOpen])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleGoToNotificationPage = () => {
    dispatch(emptyTotalNotification())
    navigate("/notifications")
  }

  const handleOpenList = () => {
    setOpenList(!openList);
  };

  const createGrop = () => {
    navigate('/group-type')
    // navigate('/create-group')
  }
  const chatHistory = () => {
    navigate('/chat-history')
    // navigate('/create-group')
  }

  const goToSearch = () => {
    navigate({ pathname: '/search', search: createSearchParams({ searchedContent: searchValue.trim() }).toString() })
    setIsSearchButtonClicked(false)
    setSearchValue("")
  }

  // const handleAddToHomeScreenClick = () => {
  //   prompt.prompt()
  //   console.log(`add to home`)

  //   prompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //     } else {
  //     }
  //   })
  // }

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Logo
        variant='h4'
        noWrap
        //  component="span"
        sx={{ margin: '16px', fontSize: '20px', fontWeight: 600, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}
        onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate("/")
        }}
      >
        EmotionTown
      </Logo>
      <Divider />
      <List>
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate('/')
          setMobileOpen(!mobileOpen);
        }}>
          <ListItemIcon>
            <HomeOutlined color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Home"
          />
        </ListItemButton>
        {/* <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate('/group-type')
          setMobileOpen(!mobileOpen);
        }}>
          <ListItemIcon>
            <CreateGroup color='primary' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Create Group"
          />
        </ListItemButton> */}
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate("/favourite-list")
          setMobileOpen(!mobileOpen);

        }}>
          <ListItemIcon>
            <FavoriteOutlined color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Favourites"
          />
        </ListItemButton>
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          setMobileOpen(!mobileOpen);
          navigate("/all_folders")

        }}>
          <ListItemIcon>
            <CollectionsBookmarkOutlined color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Saved Post"
          />
        </ListItemButton>
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate('/invitations')
          setMobileOpen(!mobileOpen);
        }}>
          <ListItemIcon>
            <InsertInvitationOutlined color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Invitations"
          />
          {
            (total_invitation > 0) &&
            // <Avatar sx={{backgroundColor:'red',width:20,height:20}}>{total_invitation}</Avatar>
            <span style={{ display: 'inline-block', backgroundColor: 'red', color: 'white', borderRadius: '20px', fontSize: '11px', lineHeight: '18px', width: '18px', height: '18px', textAlign: 'center' }}>{total_invitation}</span>
          }
        </ListItemButton>
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate('/requests')
          setMobileOpen(!mobileOpen);
        }}>
          <ListItemIcon>
            <EmojiPeopleOutlined color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Requests"
          />
          {
            (total_fav_request > 0 || total_group_request > 0) &&
            <span style={{ display: 'inline-block', backgroundColor: 'red', color: 'white', borderRadius: '20px', fontSize: '11px', lineHeight: '18px', width: '18px', height: '18px', textAlign: 'center' }}>{total_fav_request + total_group_request}</span>
          }
        </ListItemButton>
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          setMobileOpen(!mobileOpen);
          navigate("/all_groups")

        }}>
          <ListItemIcon>
            <GroupsRounded color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Groups"
          />
        </ListItemButton>
        <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          setMobileOpen(!mobileOpen);
          navigate("/all_channels")
        }}>
          <ListItemIcon>
            <CameraOutdoorOutlined color='dark' fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primary="Channel"
          />
        </ListItemButton>
        {/* <Divider />
        <ListItemButton onClick={handleAddToHomeScreenClick}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "#5352ed", width: '30px', height: '30px' }} ><AddHomeOutlined fontSize='small' /></Avatar>
          </ListItemIcon>
          <ListItemText primary="Add to home screen" secondary="Create a shortcut" />
        </ListItemButton> */}
        {/* <ListItemButton onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate({ pathname: "/help-us", search: createSearchParams({ id: uid }).toString() })
          setMobileOpen(!mobileOpen);
        }}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "#2ecc71", width: '30px', height: '30px' }}>ET</Avatar>
          </ListItemIcon>
          <ListItemText primary="ET Office" secondary="Help us to improve our service" />
        </ListItemButton>
        <ListItemButton sx={{ backgroundColor: '#f1f2f6', borderRadius: '4px', margin: '0px 6px' }} onClick={() => {
          if (!token) {
            dispatch(setOpenUaDialog(true))
            return
          }
          navigate("/next_update")
        }}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "#cfccff", width: '30px', height: '30px' }}>
              <TipsAndUpdates color='primary' fontSize='inherit' />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary="Next update" secondary="upcoming features info" />
        </ListItemButton> */}
        {/* <ListItemButton component={Link} to="/privacy-policy">
          <ListItemIcon>
            <Policy color='primary' />
          </ListItemIcon>
          <ListItemText
            primary="Privacy Policy"
          />
        </ListItemButton>
        <ListItemButton component={Link} to="/terms-and-conditions">
          <ListItemIcon>
            <Feedback color='primary' />
          </ListItemIcon>
          <ListItemText
            primary="Terms & Conditions"
          />
        </ListItemButton> */}
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>Privacy Policy</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms-and-conditions')}>Terms & Condition</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/affiliate-disclosure')}>Affiliate Disclosure</Typography>
        <Typography variant='caption' color='text.secondary' mr={1} whiteSpace='nowrap' sx={{ cursor: 'pointer' }} onClick={() => navigate('/about_us')}>About Us</Typography>
        <Typography variant='caption' color='text.secondary' sx={{ cursor: 'pointer' }} onClick={() => navigate('/contact_us')}>Contact Us</Typography>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  // ###########################################################

  const openPostOptions = (event) => {
    setPostOptionsAnchor(event.currentTarget)
  }
  const closePostOptions = () => {
    setPostOptionsAnchor(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getMyAllAccounts()
  };
  const handleCreatePostButtonClicked = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const showMyProfile = (user_id) => {
    // dispatch(setUserProfileId(user_id))
    // navigate(`/profile/${user_id}/0`)
    navigate("/profile", {
      state: {
        group_id: 0,
        user_id
      },
      // replace: true
    })
  }

  const handleAddAccount = () => {
    ReactGA.event({
      category: 'add account',
      action: 'add account',
      label: 'add account'
    })
    navigate("/add-account", {
      state: {
        user_id: uid
      },
    })
  }

  const handleCloseCreatePostMenu = (item_no) => {
    setAnchorE2(null)
    if (item_no === 1) {
      navigate('/new_create_post')
    }
    else if (item_no === 2) {
      navigate("/ff_post", {
        state: {
          post_to: 'friend',
        },
      })
    }
    else {
      navigate("/ff_post", {
        state: {
          post_to: 'follower',
        },
      })
    }
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={handleGoToNotificationPage}
        >
          <Badge badgeContent={total_notification} color="error">
            <NotificationsIcon color='black' />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  // const getSearchedData = (event, searched_data) => {
  //   // if (event.key === 'Enter') {
  //   //   props.setSearchedContent(inputValue.trim())
  //   //   if (searched_data.trim().length < 1) {

  //   //   } else {
  //   //     navigate('/search')
  //   //   }
  //   // }
  //   if (searched_data.trim().length > 3) {
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/group/simple-search-group/${searched_data.trim()}`, { headers: { authorization: "Bearer " + token } })
  //       .then(response => {

  //         setSearchedData(response.data.data)
  //       }).catch(error => {
  //       })
  //   }
  // }
  const handleSearch = () => {
    // props.setSearchedContent(value.trim())
    // props.setSearchedContent(inputValue.trim())
    // navigate('/search') // is it necessary to delete previous stack
    navigate({ pathname: '/search', search: createSearchParams({ searchedContent: value.trim() }).toString() })
  }

  const handleLogout = () => {
    ReactGA.event({
      category: 'logout',
      action: 'logout',
      label: 'logout'
    })
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    dispatch(resetPostSlice())
    dispatch(resetGroupSlice())
    dispatch(resetAuthSlice())
    dispatch(resetChannelSlice())
    navigate("/signin")
  }
  const getMyAllAccounts = () => {
    if (!accounts) {
      setAllAccountsLoadingStatus(true)
      axios.get(`${process.env.REACT_APP_API_URL}/api/users/getMyAllAccounts`, { headers: { authorization: "Bearer " + token } })
        .then((res) => {
          if (res.data.status === 1) {
            // dispatch(setAccounts(res.data.data))
            localStorage.setItem('accounts', JSON.stringify(res.data.data))
          } else {
            dispatch(setAccounts([]))
          }
          setAllAccountsLoadingStatus(false)
        }).catch((error) => {
          setAllAccountsLoadingStatus(false)
        })
    }
  }
  const changeAccount = (account_user_id) => {
    if (account_user_id === uid) {
      showMyProfile(uid)
      return
    }
    ReactGA.event({
      category: 'change account',
      action: 'change account',
      label: 'change account'
    })
    setOpenBackdrop(true)
    dispatch(resetPostSlice())
    dispatch(resetGroupSlice())
    dispatch(resetAuthSlice())
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    props.socket?.emit('remove-user', uid)
    axios.post(`${process.env.REACT_APP_API_URL}/api/auth/getToken`, { user_id: account_user_id })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('token', JSON.stringify(res.data.token))
          localStorage.setItem('user', JSON.stringify(res.data.user))
          props.socket?.emit('newUser', res.data.user.uid)
          if (localStorage.getItem('token')) {
            navigate("/", { replace: true })
          }
          // localStorage.setItem('user', JSON.stringify(response.data.user.name.replace(/&#?\w+;/g, match => entities[match])))
          // navigate("/feed")
        }
        setOpenBackdrop(false)
        // navigate("/")
      }).catch((error) => {
        setOpenBackdrop(false)
      })
  }

  // this is only for valu not input value
  const checkSearchValue = (event) => {
    if (event.key === 'Enter') {

    }
    setValue(event.target.value)

  }

  // const handleClickOnSearchItem = (clickedGroupId, group_name) => {
  //   setInputValue(group_name)
  //   // searchRef.current.blur()
  //   setSearchedData([])
  //   navigate(`/group-profile/${clickedGroupId}`)
  // }

  return (
    // <ThemeProvider theme={theme}>
    <>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      // onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        // (location.pathname === '/search-content') &&
        (isSearchButtonClicked) &&
        <Box sx={{ display: { xs: 'block', md: 'none' }, flexGrow: 1, bgcolor: theme.palette.primary.main, position: 'fixed', width: '100%', zIndex: 5 }}>
          <AppBar position="sticky">
            <Toolbar>
              {/* <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
              > */}
              <IconButton sx={{ p: '10px', color: 'white' }} aria-label="menu" onClick={() => {
                setSearchValue("")
                setIsSearchButtonClicked(false)
              }}>
                <ArrowBackIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1, color: 'white' }}
                placeholder="Search groups or people"
                value={searchValue}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    goToSearch()
                  }
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                inputProps={{ 'aria-label': 'Search groups or people' }}
              />
              <IconButton type="button" sx={{ p: '10px', color: 'white' }} aria-label="search" onClick={goToSearch}>
                <SearchIcon />
              </IconButton>
              {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                  <DirectionsIcon />
                </IconButton> */}
              {/* </Paper> */}
            </Toolbar>
          </AppBar>
        </Box>
      }
      {
        // (location.pathname !== '/search-content') &&
        (!isSearchButtonClicked) &&
        // <Box sx={{ flexGrow: 1, backgroundColor: theme.palette.primary.main, position: 'fixed', width: '100%', zIndex: 5 }}>
        // <Box sx={{ flexGrow: 1, backgroundColor: 'white', position: 'fixed', width: '100%', zIndex: 5, borderBottom: (location.pathname === "/" || location.pathname === "/gm-vjx" || location.pathname === "/fav-post") ? '1px solid transparent' : '1px solid #E4E9E2' }}>
        <Box sx={{ flexGrow: 1, backgroundColor: 'white', position: 'fixed', width: '100%', zIndex: 5, borderBottom: '1px solid #E4E9E2' }}>
          <Container sx={{ p: { xs: 0 } }}>
            <AppBar position="sticky" sx={{ flexGrow: 1, boxShadow: 'none', backgroundColor: 'white' }}>
              <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <IconButton
                    // color="dark"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ display: { md: 'none' } }}
                  >
                    <MenuIcon sx={{ color: theme.palette.primary.dark }} fontSize='small' />
                  </IconButton>
                  <Box sx={{ display: 'flex', alignItems: "center" }}>
                    {/* <img src={logoIcon} style={{ width: '28px', height: '28px', marginRight: '10px',display: {xs:'none',md:'flex'} }} /> */}
                    {/* <Typography */}
                    <Logo
                      // variant={xs:'h5',md:'h4'}
                      variant='h4'
                      noWrap
                      component="span"
                      // color='black'
                      sx={{ fontSize: { xs: '17px', md: '20px' }, fontWeight: 600, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}
                      // sx={{ fontSize: { xs: '30px', sm: '40px' }, color:'black' }}
                      onClick={() => {
                        if (!token) {
                          dispatch(setOpenUaDialog(true))
                          return
                        }
                        navigate("/")
                      }}
                    >
                      EmotionTown
                    </Logo>
                    {/* <Box sx={{display:'flex',alignItems:'center',gap:1}}>
                    <img src={logoIcon} style={{objectFit:'contain',height:'36px',width:'36px'}}/>
                    <Typography fontWeight={600} color='primary' fontSize='18px'>EmotionTown</Typography>
                    </Box> */}
                    {/* </Typography> */}
                    <Search sx={{ display: { xs: 'none', sm: 'flex' } }}>
                      <TextField ref={searchRef} value={value}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch()
                          }
                        }}
                        onChange={(e) => {
                          setValue(e.target.value)
                        }}
                        variant='standard' sx={{ width: '100%', padding: '4px 8px', color: theme.palette.light.light }} placeholder='search ...' InputProps={{
                          type: 'search',
                          disableUnderline: true
                        }} />
                      <IconButton aria-label="search" disableRipple onClick={handleSearch} size='small'>
                        <SearchIcon sx={{ color: theme.palette.dark.dark, mx: 1 }} fontSize="small" />
                      </IconButton>
                    </Search>
                  </Box>
                </Box>
                <Box sx={{}} />
                {
                  token &&
                  <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
                    {/* <IconButton
                      aria-label="show 17 new notifications for md"
                      color="inherit"
                      onClick={handleGoToNotificationPage}
                    >
                    </IconButton> */}
                    <FilledButton variant='contained' startIcon={<Add />} onClick={handleCreatePostButtonClicked}>Create Post</FilledButton>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', fontSize: '12px', fontWeight: 600, mx: 1, color: theme.palette.dark.light, cursor: 'pointer' }} onClick={handleGoToNotificationPage}>
                      <Box sx={{ position: "relative", backgroundColor: theme.palette.light.light, padding: '6px', borderRadius: '6px' }}>
                        {/* <span style={{ display: 'inline-block', backgroundColor: 'red', height: "6px", width: "6px", borderRadius: '20px', position: "absolute", top: '5px', right: '5px' }}></span> */}
                        {/* <NotificationsIcon sx={{ marginRight: '3px', fontSize: "18px", color: theme.palette.dark.light }} /> */}
                        <Badge badgeContent={total_notification} color="error">
                          <NotificationsIcon sx={{ color: theme.palette.dark.light }} fontSize='small' />
                        </Badge>
                      </Box>
                      {/* <Box sx={{ position: "relative", backgroundColor: theme.palette.light.light, padding: '6px', borderRadius: '6px' }}>
                        <span style={{ display: 'inline-block', backgroundColor: 'red', height: "6px", width: "6px", borderRadius: '20px', position: "absolute", top: '5px', right: '5px' }}></span>
                        <Forum sx={{ marginRight: '3px', fontSize: "18px", color: theme.palette.dark.light }} />
                      </Box> */}
                      {/* 12 Notifications */}
                    </Box>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px', fontWeight: 600, mx: 1, color: theme.palette.dark.light }}>
                      <Forum color='black' sx={{ marginRight: '3px', fontSize: "18px" }} />
                      Messages
                    </Box> */}
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        {/* <Avatar sx={{ width: 32, height: 32 }} variant='rounded' src='https://images.pexels.com/photos/478544/pexels-photo-478544.jpeg?auto=compress&cs=tinysrgb&w=600'>{name.charAt(0).toUpperCase()}</Avatar> */}
                        <Avatar variant='rounded' sx={{ width: 30, height: 30 }} src={profile_pic}>{name.charAt(0).toUpperCase()}</Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      boxShadow: '1px 1px 7px #c0c0d8',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => changeAccount(uid)}>
                    <Avatar sx={{ width: 32, height: 32 }} src={profile_pic}>{name.charAt(0).toUpperCase()}</Avatar> {name}
                  </MenuItem>
                  {
                    accounts && accounts.map((account, index) => {
                      if (account.uid !== uid) {
                        return <MenuItem key={index} onClick={() => changeAccount(account.uid)}>
                          <Avatar sx={{ width: 30, height: 30 }} src={account.profile_pic}>{account.name.charAt(0).toUpperCase()}</Avatar> {account.name}
                        </MenuItem>
                      }
                    })
                  }
                  {
                    (!accounts || accounts.length < 3) &&
                    <MenuItem onClick={handleAddAccount} style={{ backgroundColor: '#ecf0f1' }}>
                      <ListItemIcon>
                        <PersonAddAlt fontSize="small" color='primary' />
                      </ListItemIcon>
                      Add Account
                    </MenuItem>
                  }
                  <Divider />
                  <MenuItem onClick={() => {
                    if (!token) {
                      dispatch(setOpenUaDialog(true))
                      return
                    }
                    navigate('/group-type')
                  }}>
                    <ListItemIcon>
                      <Groups fontSize="small" />
                    </ListItemIcon>
                    Create Group
                  </MenuItem>
                  <MenuItem onClick={() => {
                    if (!token) {
                      dispatch(setOpenUaDialog(true))
                      return
                    }
                    navigate("/create-room", {
                      state: {
                        status: 'create'
                      }
                    })
                  }}>
                    <ListItemIcon>
                      <CameraOutdoor fontSize="small" />
                    </ListItemIcon>
                    Create Channel
                  </MenuItem>
                  {/* <Divider/>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <FeedbackOutlined fontSize="small" />
                    </ListItemIcon>
                    Feedback
                  </MenuItem> */}
                  {/* <Divider/> */}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                <Menu
                  id="create_post_menu_id"
                  anchorEl={anchorE2}
                  open={openCreatePostMenu}
                  onClose={() => setAnchorE2(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => handleCloseCreatePostMenu(1)}>
                    <ListItemIcon>
                      <AutoAwesomeMosaicOutlined fontSize="small" />
                    </ListItemIcon>
                    Create Group Post
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseCreatePostMenu(2)}>
                    <ListItemIcon>
                      <Diversity1Outlined fontSize="small" />
                    </ListItemIcon>
                    Post To Friends
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseCreatePostMenu(3)}>
                    <ListItemIcon>
                      <Diversity1Outlined fontSize="small" />
                    </ListItemIcon>
                    Post To Follower
                  </MenuItem>
                </Menu>
                {
                  token &&
                  <Box sx={{ display: { xs: 'flex', sm: 'none' }, gap: 2, alignItems: 'center' }}>
                    {/* <IconButton
                      aria-label="show 17 new notifications"
                      color="inherit"
                      onClick={handleGoToNotificationPage}
                    > */}
                    <SearchIcon sx={{ color: theme.palette.dark.light }} onClick={() => {
                      setIsSearchButtonClicked(true)
                    }} />
                    <Badge badgeContent={total_notification} color="error">
                      <NotificationsIcon sx={{ color: theme.palette.dark.light }} onClick={handleGoToNotificationPage} />
                    </Badge>
                    {/* <Badge badgeContent={total_notification} color="error">
                      <Forum sx={{ color: theme.palette.dark.light }} onClick={handleGoToNotificationPage} />
                    </Badge> */}
                    {/* </IconButton> */}
                    <Tooltip title="Profile">
                      <Avatar variant='rounded' onClick={handleClick} sx={{ width: 26, height: 26 }} src={profile_pic}>{name.charAt(0).toUpperCase()}</Avatar>
                    </Tooltip>
                  </Box>
                }
                {
                  !token &&
                  // <Chip icon={<PersonOutline />} label="Sign In" color='inherit' onClick={() => navigate("/signin")} size='small'/>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      size="large"
                      aria-label="show more"
                      sx={{ display: { xs: 'flex', md: 'none' } }}
                      onClick={() => {
                        setIsSearchButtonClicked(true)
                      }}
                      color="inherit"
                    >
                      <SearchIcon />
                    </IconButton>
                    <Chip icon={<PersonOutline />} color='warning' label="Sign In" onClick={() => {
                      ReactGA.event({
                        category: 'sign in for unauthorized user',
                        action: 'going to sign in',
                        label: 'going to sign in from signin button in navbar'
                      })
                      navigate("/signin")
                    }} />
                  </Box>
                }
              </Toolbar>
            </AppBar>
            <Box component="nav">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
            {renderMobileMenu}
            {/* {renderMenu} */}
          </Container>
        </Box>
      }
    </>
    // </ThemeProvider>
  );
}

PrimarySearchAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


// REACT_APP_API_URL="http://api.emotiontown.com/"