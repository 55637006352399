import { Box, Typography } from '@mui/material'
import React from 'react'
import empty from '../../media/images/empty.png'
// import empty from '../../media/images/empty_box.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ColorButton, FilledButton } from '../../CustomComponents';
import { useNavigate } from 'react-router-dom';
import theme from '../../Theme';

const NotFound = ({data,subData}) => {
    const navigate = useNavigate()
    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
            <img src={empty} alt='no data found' style={{ width: '120px', height: '120px' }} />
            <Typography variant='h4' mt={1} component='span' color='primary' sx={{fontWeight:{xs:600,md:800}}} >{data}</Typography>
            <Typography variant='body2' component='p' mt={1} mb={2} color='gray' textAlign='center' sx={{xs:'90%',md:'70%'}}>{subData}</Typography>
            <FilledButton color='light' variant='contained' startIcon={<ArrowBackIcon />} onClick={() => navigate(-1,{replace:true})} sx={{color: theme.palette.dark.main}}>Back</FilledButton>
        </Box>
    )
}

export default NotFound