import React from 'react'
import { setOpenSnack } from '../store/authSlice'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Snackbar } from '@mui/material';

const SnackbarOption = () => {
    const openSnack = useSelector(state => state.auth.openSnack)
    const snackMessage = useSelector(state => state.auth.snackMessage)
    const dispatch = useDispatch()
    return (
        <Snackbar
            open={openSnack}
            autoHideDuration={3000}
            onClose={(event, reason) => {
                if (reason === 'clickaway') {
                    return;
                }
                dispatch(setOpenSnack(false));
            }}
            message={snackMessage}
            action={<IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }

                    dispatch(setOpenSnack(false));
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>}
        />
    )
}

export default SnackbarOption