import { AppBar, Box, Button, IconButton, Toolbar, Typography, Chip, Avatar } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import theme from '../Theme';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdsNavbar = () => {
const navigate = useNavigate()
const dispatch = useDispatch()
const {name,profile_pic} = JSON.parse(localStorage.getItem("user"))
  const handleLogout = () => {
    // dispatch(resetHomeSlice())
    // navigate("/signin")
    // localStorage.removeItem("token")
    // localStorage.removeItem("user")
  }
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main, position: 'fixed', width: '100%', top: '0px', left: '0px', zIndex: 5 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" fontWeight={600} component="div" sx={{ flexGrow: 1, color: theme.palette.light.light }}>
            EmotionTown ADS
          </Typography>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
          <Chip
          sx={{color:'white'}}
            avatar={<Avatar alt={name.split(" ")[0]} src={profile_pic} />}
            label={name.split(" ")[0]}
            variant="outlined"
          />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default AdsNavbar