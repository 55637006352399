import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControl, FormControlLabel, FormLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React from 'react'
import { EmotInput, StyledInputLabel, FormFieldAlertBox, FilledButton, DisabledButton, OutlinedButton, Title1, Title4 } from '../../CustomComponents'
import { ArrowBackIosNewOutlined, SaveAsOutlined } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import theme from '../../Theme'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice'
import axios from 'axios'
import { appendNewChannelIntList } from '../../store/channelSlice'
import ReactGA from 'react-ga4'

const topics = [
  'Art & Design',
  'Business',
  'Cooking',
  'Cultural Activity',
  'Dance',
  'Entertainment',
  'Fashion',
  'Festivals',
  'Film',
  'Fitness',
  'Game',
  'Investing',
  'Music',
  'Photography',
  'Reading',
  'Sports',
  'Technology',
  'Travel',
  'Writing'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, selectTheme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? selectTheme.typography.fontWeightRegular
        : selectTheme.typography.fontWeightMedium,
  };
}

const RoomCreate = () => {
  const [short_desc_count, set_short_desc_count] = React.useState(0)
  const [name_count, set_name_count] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [need, setNeed] = React.useState('')
  const [details, setDetails] = React.useState({
    name: '',
    short_description: '',
    description: '',
    language: 'bengali',
    topic: '',
    is_for_under_18: false
  })
  const [personName, setPersonName] = React.useState([]);
  const channels = useSelector(state => state.channel.channelList)
  const token = JSON.parse(localStorage.getItem('token'))
  const selectTheme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (details.name.trim().length <= 2) {
      setNeed('Channel name required')
      return
    }
    if (details.short_description.trim() === '') {
      setNeed('short description required')
      return
    }
    if (details.description.trim() === '') {
      setNeed('description required')
      return
    }
    setOpen(true)
    ReactGA.event({
      category: 'room create',
      action: 'create room',
      label: 'create room'
    })
    axios.post(`${process.env.REACT_APP_API_URL}/api/bi-xjV/create/create-room`, details, { headers: { authorization: "Bearer " + token } })
      .then(response => {
        setOpen(false)
        if (response.data.status === 1) {
          if (channels.length > 0) {
            dispatch(appendNewChannelIntList({ data: response.data.data }))
          }
          setDetails({
            name: '',
            short_description: '',
            description: '',
            language: 'bengali',
            topic: '',
            is_for_under_18: false
          })
          navigate(`/channel-profile/${response.data.data.channel_id}`, { replace: true })
          // navigate("/watch/room", {
          //   state: {
          //     id: response.data.via_aXcsdi
          //   },
          //   replace: true
          // })
        }
        dispatch(setSnackMessage(response.data.message))
        dispatch(setOpenSnack(true))
      })
      .catch(error => {
        setOpen(false)
        dispatch(setSnackMessage(`Sorry, something went wrong.`))
        dispatch(setOpenSnack(true))
      });
  }
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '8px'
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Container maxWidth="sm">
        <Title1 mb={2}>Create Channel</Title1>
        {
          need &&
          <Box sx={{ mb: 1, backgroundColor: 'wheat', borderRadius: '8px', padding: '16px' }}>
            {need}
          </Box>
        }
        <Box component="form"
          onSubmit={handleSubmit}
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            border: '1px solid #EAEAF2',
            padding: '16px'
          }}
        >
          <StyledInputLabel htmlFor="room-name-field-id">
            Your Room Name*
          </StyledInputLabel>
          <EmotInput
            required
            fullWidth
            id="room-name-field-id"
            value={details.name}
            onChange={(event) => {
              set_name_count(name_count + 1)
              if (name_count < 80) {
              setDetails({
                ...details, name: event.target.value
              })
            }
            }}
          />
          <Title4 textAlign='end' mb={2} mt={1} color='darkgray'>{name_count} / 80</Title4>
          <StyledInputLabel htmlFor="short-room-description-id">
            Short Descripiton*
          </StyledInputLabel>
          <EmotInput
            required
            id="short-room-description-id"
            value={details.short_description}
            onChange={(event) => {
              set_short_desc_count(short_desc_count + 1)
              if (short_desc_count < 150) {
              setDetails({
                ...details, short_description: event.target.value
              })
            }
            }}
          />
          <Title4 textAlign='end' mb={2} mt={1} color='darkgray'>{short_desc_count} / 150</Title4>
          <StyledInputLabel htmlFor="about-room-field-id">
            About Room*
          </StyledInputLabel>
          <EmotInput
            required
            variant='standard'
            multiline
            rows={3}
            id="about-room-field-id"
            sx={{ mb: 2 }}
            value={details.description}
            onChange={(event) => setDetails({ ...details, description: event.target.value })}
          />
          <FormControl sx={{ mb: 2 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">Language</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group-for-room-language"
              value={details.language}
              onChange={(e) => setDetails({ ...details, language: e.target.value })}
            >
              <FormControlLabel value="bengali" control={<Radio />} label="বাংলা" />
              <FormControlLabel value="hindi" control={<Radio />} label="हिंदी" />
              <FormControlLabel value="english" control={<Radio />} label="English" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ width: '100%', mb: 2 }}>
            <StyledInputLabel htmlFor="choose-room-topics-id">
              Choose Topics
            </StyledInputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="choose-topic-id"
              value={details.topic}
              onChange={(event) => setDetails({ ...details, topic: event.target.value })}
              input={<OutlinedInput label="choose-topic-id" />}
              MenuProps={MenuProps}
            >
              {topics.map((topic) => (
                <MenuItem
                  key={topic}
                  value={topic}
                  style={getStyles(topic, personName, selectTheme)}
                >
                  {topic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel control={<Checkbox checked={details.is_for_under_18 || false} onChange={event => setDetails({ ...details, is_for_under_18: event.target.checked })} />} label="is for under 18?" />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
            <Button variant='contained' color='light' sx={{ color: theme.palette.dark.light }} startIcon={<ArrowBackIosNewOutlined />} onClick={() => navigate(-1)}>Back</Button>
            <Button type='submit' variant='contained' disabled={open ? true : false} startIcon={<SaveAsOutlined />}>
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default RoomCreate