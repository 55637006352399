import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import wait from '../../media/images/wait.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ColorButton } from '../../CustomComponents';
import { useNavigate } from 'react-router-dom';

const RecommendedToUser = () => {
    const navigate = useNavigate()
    return (
        <Box sx={{ bgcolor: 'white', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 114px)' }}>
            <img src={wait} alt='Wait for next update' style={{ width: '150px', height: '150px', objectFit:'cover' }} />
            <Typography variant='h4' mt={2} component='span' color='primary' fontWeight='700' >Wait for next update</Typography>
            <Typography variant='body2' component='p' mt={1} mb={2} color='gray' >This is our upcoming feature. Within 7 days this feature will be opened. So, stay connected with us.</Typography>
            {/* <ColorButton startIcon={<ArrowBackIcon />} onClick={() => navigate(-1,{replace:true})}>Back</ColorButton> */}
            <Box sx={{display:'flex',justifyContent:'start',width:'100%'}}>
            <Button variant='contained' disableElevation sx={{textTransform:'capitalize'}} startIcon={<ArrowBackIcon />} onClick={() => navigate(-1,{replace:true})}>Back</Button>
            </Box>
        </Box>
    )
}

export default RecommendedToUser