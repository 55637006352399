import './App.css';
import Topprofile from './components/profile/Topprofile';
import { Navigate, Outlet, createBrowserRouter, RouterProvider, useNavigate, useLocation } from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import theme from './Theme'
import store from './store/store'

import Navbar from './components/Grid/Navbar'
import Rightbar from './components/Grid/Rightbar'
import CreateGroup from './components/Grid/Form/CreateGroup';
import GroupType from './components/Grid/Form/GroupType';
import SearchedContent from './components/Grid/SearchedContent';
import RegisterFourth from './components/Grid/Form/RegisterFourth';
import SignIn from './pages/auth/Signin';
import React, { Suspense, lazy, useState } from 'react';
import FullImage from './components/Grid/FullImage';
import Requests from './components/Grid/PrivateFac/Requests';
import Report from './components/Grid/PrivateFac/Report';
import EditProfile from './components/Edit/EditProfile';
import EditGroupProfile from './components/Edit/EditGroupProfile';
import CreateUserName from './components/Grid/CreateUserName';
import GroupList from './components/Grid/List/GroupList';
import SharedToGroup from './components/Grid/SharedToGroup';
import CreateAccountFirst from './components/Grid/Form/CreateAccountFirst';
import Verification from './components/Grid/Form/Verification';
import InviteToGroup from './components/Grid/InviteToGroup';
import InviteFromUserList from './components/Grid/InviteFromUserList';
import EmojiReactDetails from './components/Grid/EmojiReactDetails';
import Folders from './components/Grid/Folders';
import RateReactDetails from './components/Grid/RateReactDetails';
import ReportAction from './components/Grid/ReportAction';
import CreateGroupName from './components/Grid/CreateGroupName';
import HelpUs from './components/Grid/Helpus';
import Error from './components/status/Error';
import FullMultiImage from './components/Grid/FullMultiImage';
import ForgetPassword from './components/Grid/Form/ForgetPassword';
import ResetPassword from './components/Grid/Form/ResetPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition';
import { Avatar, Box, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import NewSidebar from './components/Grid/Sidebar';
import Invitations from './components/Invitations';
import First from './components/Cards/First';
import MyBottomNavigation from './components/MyBottomNavigation';
import SnackbarOption from './components/SnackbarOption';
import RecommendedToUser from './components/Grid/RecommendedToUser';
import Libraray from './components/Grid/Library';
import WentWrong from './components/status/WentWrong';
import NextUpdate from './components/NextUpdate';
import MainFullImage from './components/Grid/MainFullImage';
import LikedList from './components/Grid/List/LikedPeople';
import { io } from "socket.io-client";
import ReactGA from 'react-ga4'
// import NewCompare from './components/Cards/NewCompare';
import PostNotificationItem from './components/ListItem/PostNotificationItem';
import NotificationPost from './components/NotificationPost';
import Interests from './components/Grid/Form/Interests';
// import UAGroupProfile from './components/UnAuthorized/UAGroupProfile';
// import UATopprofile from './components/UnAuthorized/UATopprofile';
import GroupTopic from './components/Grid/Form/GroupTopic';
import Activities from './components/Grid/Activities';
import ContactUs from './components/Grid/Form/ContactUs';
import AboutUs from './components/Grid/AboutUs';
import RegisterSecond from './pages/auth/RegisterSecond';
import AvatarSignIn from './pages/auth/AvatarSignin';
import { AddBox, AutoAwesomeMosaic, AutoAwesomeMosaicOutlined, Delete, Diversity1, Diversity1Outlined, Diversity3, Diversity3Outlined, GridView, Groups2Outlined, HomeOutlined, ThumbUpAlt, ThumbUpAltOutlined, TravelExplore, TravelExploreOutlined, Whatshot } from '@mui/icons-material';
import NewCreatePost from './components/Grid/Form/Post/NewCreatePost';
import ViewPage from './pages/Room/ViewPage';
import RoomProfile from './components/MyRoom/RoomProfile';
import RecommendedFeed from './components/Feed/RecommendedFeed';
import EditRoom from './components/MyRoom/EditRoom';
import UploadContent from './components/MyRoom/UploadContent';
import ExploreFeed from './components/Feed/ExploreFeed';
// import BookmarkDialog from './components/Dialog/BookmarkDialog';
import RequestList from './components/Favourite/ToMeRequestList';
import CreateFriendAndFollowerPost from './components/CreatePost/CreateFriendAndFollowerPost';
import OnlineStatus from './OnlineStatus';
import FavRequests from './components/Favourite/FavRequests';
import axios from 'axios';
import { emptyTotalNotification, setTotalNotification } from './store/authSlice';
import { setInvitationRequestInfo } from './store/postSlice';
import IconSidebar from './components/Sidebar/IconSidebar';
import Conversation from './components/chat/Conversation';
import ChatPanel from './components/chat/ChatPanel';
import M_conversation from './components/chat/M_conversation';
import AffiliateDisclosure from './pages/AffiliateDisclosure';
import AddArticle from './components/MyRoom/AddArticle';
import RoomCreate from './components/MyRoom/RoomCreate';
import ReadPost from './components/MyRoom/ReadPost';
import ASidebar from './Admin/ASidebar';
import Home from './Admin/Home';
import Reports from './Admin/Reports';
import Contacts from './Admin/Contacts';
import Feedback from './Admin/Feedback';
import AdsNavbar from './ads/AdsNavbar';
import AdsHome from './ads/AdsHome';
import CreateAdsCard from './ads/CreateAdsCard';
// import AddMember from './components/AddMember';
// import ChannelProfile from './components/Room/ChannelProfile';
// import ChannelProfile from './components/channel/ChannelProfile';
// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// import InvitationRoundedIcon from '@mui/icons-material/EmailRounded';
// import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
// import RecommendRoundedIcon from '@mui/icons-material/RecommendRounded';
const NoGroupList = lazy(() => import('./components/Grid/List/NoGroupList'));
const AllGroups = lazy(() => import('./components/Grid/AllGroups'));
const AllChannels = lazy(() => import('./components/Grid/AllChannels'));
const AllFolders = lazy(() => import('./components/Grid/AllFolders'));
const PinFeed = lazy(() => import('./components/Feed/PinFeed'));
const Notifications = lazy(() => import('./components/Notifications'));
const GroupProfile = lazy(() => import('./components/Grid/GroupProfile'));
const ChannelProfile = lazy(() => import('./components/Room/ChannelProfile'));
const FavouriteList = lazy(() => import('./components/Grid/List/FavouriteList'));
const PinPostFeed = lazy(() => import('./components/Feed/PinPostFeed'));
const SavedPost = lazy(() => import('./components/Feed/SavedPost'));
const FavoriteFeed = lazy(() => import('./components/Feed/FavoriteFeed'));
const Userprofile = lazy(() => import('./components/profile/UserProfile'));
const Feed = lazy(() => import('./components/Feed/Feed'));
const GroupFeed = lazy(() => import('./components/Feed/GroupFeed'));
const MemberPostFeed = lazy(() => import('./components/Feed/MemberPostFeed'));
const BookmarkDialog = lazy(() => import('./components/Dialog/BookmarkDialog'))
const UASignInDialog = lazy(() => import('./components/UASignInDialog'))
const ShareDialog = lazy(() => import('./components/Dialog/ShareDialog'))
const WhoReactsDialog = lazy(() => import('./components/Dialog/WhoReactsDidalog'))
const ShareProfileDialog = lazy(() => import('./components/Dialog/ShareProfileDialog'))
const NotifyDialog = lazy(() => import('./components/Dialog/NotifyDialog'))
const DeletePostDialog = lazy(() => import('./components/Dialog/DeletePostDialog'))
const AddMember = lazy(() => import('./components/AddMember'))
// import UASignInDialog from './components/UASignInDialog';
// import ShareDialog from './components/Dialog/ShareDialog';
// import WhoReactsDialog from './components/Dialog/WhoReactsDidalog';
// import ShareProfileDialog from './components/Dialog/ShareProfileDialog';
// import NotifyDialog from './components/Dialog/NotifyDialog';
// import DeletePostDialog from './components/Dialog/DeletePostDialog';

const MEASURMENT_ID = "G-D7PF0K27RH"
ReactGA.initialize(MEASURMENT_ID)

const NewApp = () => {
  return (
    <Provider store={store}>
      <MyApp />
    </Provider>
  );
}


const MyApp = () => {

  const [socket, setSocket] = React.useState(null)
  const dispatch = useDispatch()
  const { uid = undefined } = JSON.parse(localStorage.getItem('user')) || {}
  const token = JSON.parse(localStorage.getItem('token'))

  React.useEffect(() => {
    setSocket(io(`https://chat.emotiontown.com/`));
    // setSocket(io(`http://localhost:3950/`));
  }, [])
  React.useEffect(() => {
    if (uid) {
      socket?.emit('newUser', uid)
    }
  }, [socket, uid])

  React.useEffect(() => {
    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/users/total_notification`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          dispatch(setTotalNotification(response.data.data[0].total_notification))
        }).catch(error => {
          // dispatch(emptyTotalNotification())
        })
    }
  }, [token, uid])

  React.useEffect(() => {
    if (token) {
      setTimeout(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/inReqInfo`, { headers: { authorization: "Bearer " + token } })
          .then(response => {
            dispatch(setInvitationRequestInfo(response.data.data))
          }).catch(error => {
            // dispatch(emptyTotalNotification())
          })
      }, 30000);
    }
  }, [token])

  const Layout = () => {
    // const [socket, setSocket] = useState(null)
    // const { uid = undefined } = JSON.parse(localStorage.getItem('user')) || {}
    // const token = JSON.parse(localStorage.getItem('token'))
    // React.useEffect(() => {
    //   // setSocket(io(`https://chat.emotiontown.com/`));
    //   setSocket(io(`http://localhost:3950/`));
    // }, [])
    // React.useEffect(() => {
    //   if (uid) {
    //     socket?.emit('newUser', uid)
    //   }
    // }, [socket, uid])

    return (
      <ThemeProvider theme={theme}>
        <div>
          <div>
            <Navbar socket={socket} />
            <Toolbar />
          </div>
          <div>
            <Grid container sx={{ backgroundColor: 'transparent' }}>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflow: 'scroll', borderRight: '1px solid #E4E9F2', backgroundColor: 'white' }}>
                <NewSidebar />
              </Grid>
              <Grid item xs={12} sm={7} md={6}>
                <Box sx={{
                  padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 80px' },
                }}>
                  <Outlet context={socket} />
                </Box>
              </Grid>
              <Grid item sm={5} md={3} sx={{ display: { xs: 'none', sm: 'block' }, padding: '0px', position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflowY: 'scroll' }}>
                <Rightbar />
              </Grid>
            </Grid>
          </div>
          <Suspense fallback={<div>Loading..</div>}><WhoReactsDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><NotifyDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><DeletePostDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareProfileDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}>
            <BookmarkDialog />
          </Suspense>
          <SnackbarOption />
          {
            token &&
            <MyBottomNavigation />
          }
          <Suspense fallback={<div>Loading..</div>}><UASignInDialog /></Suspense>
        </div>
      </ThemeProvider>
    )
  }
  const FillLayout = () => {
    // const [socket, setSocket] = useState(null)
    // const { uid = undefined } = JSON.parse(localStorage.getItem('user')) || {}
    // const token = JSON.parse(localStorage.getItem('token'))
    // React.useEffect(() => {
    //   // setSocket(io(`https://chat.emotiontown.com/`));
    //   setSocket(io(`http://localhost:3950/`));
    // }, [])
    // React.useEffect(() => {
    //   if (uid) {
    //     socket?.emit('newUser', uid)
    //   }
    // }, [socket, uid])

    return (
      <ThemeProvider theme={theme}>
        <div>
          <div>
            <Navbar socket={socket} />
            <Toolbar />
          </div>
          <div>
            <Grid container sx={{ backgroundColor: 'transparent' }}>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '64px', backgroundColor: "white", height: 'calc(100vh - 64px)', overflow: 'scroll', borderRight: '1px solid #E4E9F2' }}>
                <NewSidebar />
              </Grid>
              <Grid item xs={12} sm={7} md={6}>
                <Box sx={{
                  padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 20px' },
                }}>
                  <Outlet context={socket} />
                </Box>
              </Grid>
              <Grid item sm={5} md={3} sx={{ display: { xs: 'none', sm: 'block' }, padding: '0px', position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflowY: 'scroll' }}>
                <Rightbar />
              </Grid>
            </Grid>
          </div>
          <Suspense fallback={<div>Loading..</div>}><WhoReactsDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><NotifyDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><DeletePostDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareProfileDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}>
            <BookmarkDialog />
          </Suspense>
          <SnackbarOption />
          {
            token &&
            <MyBottomNavigation />
          }
          <Suspense fallback={<div>Loading..</div>}></Suspense><UASignInDialog />
        </div>
      </ThemeProvider>
    )
  }
  const FeedLayout = () => {
    // const [socket, setSocket] = useState(null)
    // const [isOnline, setIsOnline] = useState(navigator.onLine);
    // const { uid = undefined } = JSON.parse(localStorage.getItem('user')) || {}
    // const token = JSON.parse(localStorage.getItem('token'))
    const location = useLocation()
    const navigate = useNavigate()
    // React.useEffect(() => {
    //   // setSocket(io(`https://chat.emotiontown.com/`));
    //   setSocket(io(`http://localhost:3950/`));
    // }, [])
    // React.useEffect(() => {
    //   if (uid) {
    //     socket?.emit('newUser', uid)
    //   }
    // }, [socket, uid])

    // React.useEffect(() => {
    //   // Update network status
    //   const handleStatusChange = () => {
    //     setIsOnline(navigator.onLine);
    //   };

    //   // Listen to the online status
    //   window.addEventListener('online', handleStatusChange);

    //   // Listen to the offline status
    //   window.addEventListener('offline', handleStatusChange);

    //   // Specify how to clean up after this effect for performance improvment
    //   return () => {
    //     window.removeEventListener('online', handleStatusChange);
    //     window.removeEventListener('offline', handleStatusChange);
    //   };
    // }, [isOnline]);

    return (
      <ThemeProvider theme={theme}>
        <div>
          <div>
            <Navbar socket={socket} />
            <Toolbar />
          </div>
          {/* {isOnline ? (
        <h1 className='online'>You Are Online</h1>
      ) : (
        <h1 className='offline'>You Are Offline</h1>
      )} */}
          {/* <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 3, height: 'calc(100vh - 63px)',position:'sticky',top:'63px',overflow:'scroll',borderRight: '1px solid #E4E9E2',backgroundColor:'white' }}>
              <NewSidebar />
            </Box>
            <Box sx={{ flex: 6 }}>
              <Box sx={{
                padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 70px' },
              }}>
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, backgroundColor: 'white', position: 'sticky', top: { xs: '53px', sm: '63px' }, zIndex: 4 }}>
                  <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '13px', fontWeight: 600, borderBottom: (location.pathname === '/') ? '2px solid #10ac84' : '2px solid #EAEAF2', color: (location.pathname === '/') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/')}>
                    {
                      (location.pathname === "/") ?
                        <AutoAwesomeMosaic sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                        <AutoAwesomeMosaicOutlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                    }
                    Group Post
                  </Box>
                  <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '13px', fontWeight: 600, borderBottom: (location.pathname === '/gm-vjx') ? '2px solid #10ac84' : '2px solid #EAEAF2', color: (location.pathname === '/gm-vjx') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/gm-vjx')}>
                    {
                      (location.pathname === "/gm-vjx") ?
                        <Diversity3 sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/gm-vjx') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                        <Diversity3Outlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                    }
                    Member Post
                  </Box>
                  <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '13px', fontWeight: 600, borderBottom: (location.pathname === '/fav-post') ? '2px solid #10ac84' : '2px solid #EAEAF2', color: (location.pathname === '/fav-post') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/fav-post')}>
                    {
                      (location.pathname === "/fav-post") ?
                        <Diversity1 sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/fav-post') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                        <Diversity1Outlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                    }
                    Favorite Post
                  </Box>
                </Box>
                <Outlet context={socket} />
              </Box>
            </Box>
            <Box sx={{ flex: 3, height: 'calc(100vh - 63px)',position:'sticky',top:'63px',overflow:'scroll' }}>
              <Rightbar />
            </Box>
          </Box> */}
          <Grid container sx={{ backgroundColor: 'transparent' }}>
            <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflow: 'scroll', borderRight: '1px solid #E4E9F2', backgroundColor: 'white' }}>
              <NewSidebar />
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <Box sx={{
                padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 70px' },
              }}>
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, backgroundColor: 'white', position: 'sticky', top: { xs: '56px', sm: '63px' }, zIndex: 4 }}>
                  <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '13px', fontWeight: 600, borderBottom: (location.pathname === '/') ? '2px solid #10ac84' : '2px solid #EAEAF2', color: (location.pathname === '/') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/')}>
                    {
                      (location.pathname === "/") ?
                        <AutoAwesomeMosaic sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                        <AutoAwesomeMosaicOutlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                    }
                    Group Post
                  </Box>
                  <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '13px', fontWeight: 600, borderBottom: (location.pathname === '/gm-vjx') ? '2px solid #10ac84' : '2px solid #EAEAF2', color: (location.pathname === '/gm-vjx') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/gm-vjx')}>
                    {
                      (location.pathname === "/gm-vjx") ?
                        <Diversity3 sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/gm-vjx') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                        <Diversity3Outlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                    }
                    Friend Post
                  </Box>
                  <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '13px', fontWeight: 600, borderBottom: (location.pathname === '/fav-post') ? '2px solid #10ac84' : '2px solid #EAEAF2', color: (location.pathname === '/fav-post') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/fav-post')}>
                    {
                      (location.pathname === "/fav-post") ?
                        <Diversity1 sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/fav-post') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                        <Diversity1Outlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                    }
                    Following
                  </Box>
                  {/* <Box sx={{ cursor: 'pointer', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, fontSize: '14px', fontWeight: 600, borderBottom: (location.pathname === '/to0-recommended') ? '2px solid #6C63FF' : '2px solid #EAEAF2', color: (location.pathname === '/to0-recommended') ? theme.palette.primary.main : theme.palette.disabled.main }} onClick={() => navigate('/to0-recommended')}>
                      {
                        (location.pathname === "/to0-recommended") ?
                          <TravelExplore sx={{ fontSize: '18px', marginRight: '6px', color: (location.pathname === '/to0-recommended') ? theme.palette.primary.main : theme.palette.disabled.main }} /> :
                          <TravelExploreOutlined sx={{ fontSize: '18px', marginRight: '6px', color: theme.palette.disabled.main }} />
                      }
                      Explore
                    </Box> */}
                </Box>
                <Outlet context={socket} />
              </Box>
            </Grid>
            <Grid item sm={5} md={3} sx={{ display: { xs: 'none', sm: 'block' }, padding: '0px', position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflowY: 'scroll' }}>
              <Rightbar />
            </Grid>
          </Grid>
          <Suspense fallback={<div>Loading..</div>}><WhoReactsDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><NotifyDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><DeletePostDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareProfileDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}>
            <BookmarkDialog />
          </Suspense>
          <SnackbarOption />
          {/* <ChatBox/> */}
          {
            token &&
            <MyBottomNavigation />
          }
          <Suspense fallback={<div>Loading..</div>}></Suspense><UASignInDialog />
        </div>
      </ThemeProvider>
    )
  }
  const RoomLayout = () => {
    // const [socket, setSocket] = useState(null)
    // const { uid = undefined } = JSON.parse(localStorage.getItem('user')) || {}
    // const token = JSON.parse(localStorage.getItem('token'))
    const location = useLocation()
    const navigate = useNavigate()
    // React.useEffect(() => {
    //   // setSocket(io(`https://chat.emotiontown.com/`));
    //   setSocket(io(`http://localhost:3950/`));
    // }, [])
    // React.useEffect(() => {
    //   if (uid) {
    //     socket?.emit('newUser', uid)
    //   }
    // }, [socket, uid])

    return (
      <ThemeProvider theme={theme}>
        <div>
          <div>
            <Navbar socket={socket} />
            <Toolbar />
          </div>
          <div>
            <Grid container sx={{ backgroundColor: 'transparent' }}>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflow: 'scroll', borderRight: '1px solid #E4E9F2', backgroundColor: 'white' }}>
                <NewSidebar />
              </Grid>
              <Grid item xs={12} md={9}>
                <Box sx={{
                  padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 20px' }
                }}>
                  <Outlet context={socket} />
                </Box>
              </Grid>
              {/* <Grid item lg={4} sx={{ display: { xs: 'none', lg: 'block' }, padding: '0px', right: '0px', position: 'fixed', width: { lg: '41.65%' }, height: '100%' }}>
                <Rightbar />
              </Grid> */}
            </Grid>
          </div>
          <Suspense fallback={<div>Loading..</div>}><WhoReactsDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><NotifyDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><DeletePostDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareProfileDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}>
            <BookmarkDialog />
          </Suspense>
          <SnackbarOption />
          {
            token &&
            <MyBottomNavigation />
          }
          <Suspense fallback={<div>Loading..</div>}></Suspense><UASignInDialog />
        </div>
      </ThemeProvider>
    )
  }
  const AdminLayout = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
      <ThemeProvider theme={theme}>
        <div>
          <div>
            <Navbar socket={socket} />
            <Toolbar />
          </div>
          <div>
            <Grid container sx={{ backgroundColor: 'transparent' }}>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflow: 'scroll', borderRight: '1px solid #E4E9F2', backgroundColor: 'white' }}>
                <ASidebar />
              </Grid>
              <Grid item xs={12} md={9}>
                <Box sx={{
                  padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 20px' }
                }}>
                  <Outlet context={socket} />
                </Box>
              </Grid>
            </Grid>
          </div>
          <Suspense fallback={<div>Loading..</div>}><WhoReactsDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><NotifyDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><DeletePostDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareProfileDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}>
            <BookmarkDialog />
          </Suspense>
          <SnackbarOption />
          {
            token &&
            <MyBottomNavigation />
          }
          <Suspense fallback={<div>Loading..</div>}></Suspense><UASignInDialog />
        </div>
      </ThemeProvider>
    )
  }
  const AdsLayout = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
      <ThemeProvider theme={theme}>
        <div>
          <div>
            <AdsNavbar socket={socket} />
            <Toolbar />
          </div>
          <div>
            <Grid container sx={{ backgroundColor: 'transparent' }}>
              <Grid item xs={12}>
                <Box sx={{
                  padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 20px' }
                }}>
                  <Outlet context={socket} />
                </Box>
              </Grid>
            </Grid>
          </div>
          <Suspense fallback={<div>Loading..</div>}><WhoReactsDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><NotifyDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><DeletePostDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}><ShareProfileDialog /></Suspense>
          <Suspense fallback={<div>Loading..</div>}>
            <BookmarkDialog />
          </Suspense>
          <SnackbarOption />
          {
            token &&
            <MyBottomNavigation />
          }
          <Suspense fallback={<div>Loading..</div>}></Suspense><UASignInDialog />
        </div>
      </ThemeProvider>
    )
  }
  const ChatLayout = () => {
    return (
      <ThemeProvider theme={theme}>
        {/* <div>
            <Grid container sx={{ backgroundColor: 'transparent' }}>
              <Grid item md={1} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '0px', height: '100vh', overflow: 'scroll', borderRight: '1px solid #E4E9F2', backgroundColor:'white' }}>
                <IconSidebar/>
              </Grid>
              <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' }, position: 'sticky', top: '64px', height: 'calc(100vh - 64px)', overflow: 'scroll', borderRight: '1px solid #E4E9F2', backgroundColor:'white' }}>
                <Conversation />
              </Grid>
              <Grid item xs={12} md={7}>
                <Box sx={{
                  padding: { xs: '0px 0px 45px', sm: '0px 15px 45px', md: '0px 10px', lg: '0px 20px' }
                }}>
                  hello
                </Box>
              </Grid>
            </Grid>
          </div> */}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1, backgroundColor: 'white', borderRight: '1px solid #E4E9F2', position: 'sticky', height: '100vh', top: '0px', display: { xs: 'none', sm: 'block' } }}>
            <IconSidebar />
          </Box>
          <Box sx={{ flex: 4, backgroundColor: 'white', borderRight: '1px solid #E4E9F2', position: 'sticky', height: '100vh', top: '0px', display: { xs: 'none', md: 'block' } }}>
            <Conversation />
          </Box>
          <Box sx={{ flex: 11 }}>
            <Outlet context={socket} />
          </Box>
        </Box>
        <SnackbarOption />
        <Suspense fallback={<div>Loading..</div>}></Suspense><UASignInDialog />
      </ThemeProvider>
    )
  }

  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem("token")) {
      return <Navigate to="/signin" />
    }
    return children
  }

  const AuthRoute = ({ children }) => {
    if (localStorage.getItem("token")) {
      return <Navigate to="/" />
    }
    return children
  }
  //   const OnlineStatus = () => {
  //     const [isOnline,setOnline] = React.useState(false)
  //     React.useEffect(() => {
  //         function handleOnlineStatus() {
  //             setOnline(true)
  //         }
  //         function handleOfflineStatus() {
  //             setOnline(false)
  //         }
  //         window.addEventListener('online',handleOnlineStatus)
  //         window.addEventListener('offline',handleOfflineStatus)
  //         return () => {
  //             window.addEventListener('online',handleOnlineStatus)
  //             window.addEventListener('offline',handleOfflineStatus)
  //         }
  //     })
  //   return (
  //     <div>
  //         {(isOnline === true) ? 'online' : 'offline'}
  //     </div>
  //   )
  // }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <ProtectedRoute>
        <FeedLayout />
      </ProtectedRoute>,
      errorElement: <WentWrong />,
      children: [
        {
          path: "/",
          element: <Suspense fallback={<div>Loading..</div>}><Feed /></Suspense>,
          // element: <Suspense fallback={<div>Loading..</div>}><ExploreFeed /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/gm-vjx",
          element: <Suspense fallback={<div>Loading..</div>}><MemberPostFeed /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/to0-recommended",
          element: <ExploreFeed />,
          errorElement: <WentWrong />
        },
        {
          path: "/fav-post",
          element: <Suspense fallback={<div>Loading..</div>}><FavoriteFeed /></Suspense>,
          errorElement: <WentWrong />
        },
      ]
    },
    {
      path: '/',
      element: <ProtectedRoute>
        <RoomLayout />
      </ProtectedRoute>,
      errorElement: <WentWrong />,
      children: [
        {
          // path: "/profile/:user_id/:group_id",
          path: "/profile",
          element: <Suspense fallback={<div>Loading..</div>}><Userprofile /></Suspense>,
          errorElement: <WentWrong />
        },
        // {
        //   // path: "/profile/:user_id/:group_id",
        //   path: "/profile",
        //   element: <Topprofile />,
        //   errorElement: <WentWrong />
        // },
        // {
        //   path: "/watch/room",
        //   element: <RoomProfile />,
        //   errorElement: <WentWrong />
        // },
        // {
        //   path: "/read/post/:post_id",
        //   element: <ReadPost />,
        //   errorElement: <WentWrong />
        // },
        {
          path: "/create-room",
          // element: <CreateRoom />,
          element: <RoomCreate />,
          errorElement: <WentWrong />
        },
        {
          path: "/upload-content",
          element: <UploadContent />,
          errorElement: <WentWrong />
        }
      ]
    },
    {
      path: '/',
      element: <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>,
      errorElement: <WentWrong />,
      children: [
        {
          // path: "/profile/:user_id/:group_id",
          path: "/admin/home",
          element: <Home />,
          errorElement: <WentWrong />
        },
        {
          path: "/admin/reports",
          // element: <CreateRoom />,
          element: <Reports />,
          errorElement: <WentWrong />
        },
        {
          path: "/admin/contacts",
          element: <Contacts />,
          errorElement: <WentWrong />
        },
        {
          path: "/admin/feedback",
          element: <Feedback />,
          errorElement: <WentWrong />
        }
      ]
    },
    {
      path: '/',
      element: <ProtectedRoute>
        <AdsLayout />
      </ProtectedRoute>,
      errorElement: <WentWrong />,
      children: [
        {
          // path: "/profile/:user_id/:group_id",
          path: "/ads/home",
          element: <AdsHome />,
          errorElement: <WentWrong />
        },
        {
          path: "/ads/create",
          // element: <CreateRoom />,
          element: <CreateAdsCard />,
          errorElement: <WentWrong />
        }
      ]
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      errorElement: <WentWrong />,
      children: [
        {
          path: "/ff_post",
          element: <CreateFriendAndFollowerPost />,
          errorElement: <WentWrong />
        },
        {
          path: "/m_conversation",
          element: <M_conversation />,
          errorElement: <WentWrong />
        },
        // {
        //   path: "/chat",
        //   element: <ChatPanel />,
        //   errorElement: <WentWrong />
        // },
        {
          path: "/notifications",
          element: <Suspense fallback={<div>Loading..</div>}><Notifications /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/new_create_post",
          element: <NewCreatePost />,
          errorElement: <WentWrong />
        },
        {
          path: "/all_groups",
          element: <Suspense fallback={<div>Loading..</div>}><AllGroups /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/all_channels",
          element: <Suspense fallback={<div>Loading..</div>}><AllChannels /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/group_feed",
          element: <Suspense fallback={<div>Loading..</div>}><GroupFeed /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/t_pin-feed",
          element: <Suspense fallback={<div>Loading..</div>}><PinFeed /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/create-group",
          element: <CreateGroup />,
          errorElement: <WentWrong />
        },
        {
          path: "/group-type",
          element: <GroupType />,
          errorElement: <WentWrong />
        },
        {
          path: "/invitations",
          element: <Invitations />,
          errorElement: <WentWrong />
        },
        {
          path: "/requests",
          element: <FavRequests />,
          errorElement: <WentWrong />
        },
        {
          path: "/activities",
          element: <Activities />,
          errorElement: <WentWrong />
        },
        // {
        //   path: "/group-profile",
        //   element: <GroupProfile />,
        //   errorElement: <WentWrong/>
        // },
        // {
        //   path: "/uewa-profile",
        //   element: <UATopprofile />,
        //   errorElement: <WentWrong/>
        // },
        // {
        //   // path: "/profile/:user_id/:group_id",
        //   path: "/profile",
        //   element: <Topprofile />,
        //   errorElement: <WentWrong />
        // },
        {
          path: "/group-requests",
          element: <Requests />,
          errorElement: <WentWrong />
        },
        {
          path: "/recommended_to_user",
          element: <RecommendedToUser />,
          errorElement: <WentWrong />
        },
        {
          path: "/library",
          element: <Libraray />,
          errorElement: <WentWrong />
        },
        {
          path: "/report",
          element: <Report />,
          errorElement: <WentWrong />
        },
        // {
        //   path: "/feed",
        //   element: <Feed />,
        //   errorElement: <WentWrong/>
        // },
        {
          path: "/pin-post-feed",
          element: <Suspense fallback={<div>Loading..</div>}><PinPostFeed /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/edit-profile",
          element: <EditProfile />,
          errorElement: <WentWrong />
        },
        {
          path: "/edit-group-profile/:group_id",
          element: <EditGroupProfile />,
          errorElement: <WentWrong />
        },
        {
          path: "/create/user_name",
          element: <CreateUserName />,
          errorElement: <WentWrong />
        },
        {
          path: "/create/group_name",
          element: <CreateGroupName />,
          errorElement: <WentWrong />
        },
        {
          path: "/favourite-list",
          element: <Suspense fallback={<div>Loading..</div>}><FavouriteList /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/liked-list",
          element: <LikedList />,
          errorElement: <WentWrong />
        },
        {
          path: "/group-list",
          element: <GroupList />,
          errorElement: <WentWrong />
        },
        // {
        //   path: "/group-list",
        //   element: <GroupList />,
        //   errorElement: <WentWrong/>
        // },
        {
          path: "/help-us",
          element: <HelpUs />,
          errorElement: <WentWrong />
        },
        {
          path: "/no-groups-list",
          element: <Suspense fallback={<div>Loading..</div>}><NoGroupList /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/shared-to-group",
          element: <SharedToGroup />,
          errorElement: <WentWrong />
        },
        {
          path: "/invite-to-group",
          element: <InviteToGroup />,
          errorElement: <WentWrong />
        },
        {
          path: "/invite-from-user-list",
          element: <InviteFromUserList />,
          errorElement: <WentWrong />
        },
        {
          path: "/saved-post/:id/:sdkfh",
          element: <Suspense fallback={<div>Loading..</div>}><SavedPost /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "react-details/:post_id",
          element: <EmojiReactDetails />,
          errorElement: <WentWrong />
        },
        {
          path: "/folders/:post_id/:group_id/:value",
          element: <Folders />,
          errorElement: <WentWrong />
        },
        {
          path: "/all_folders",
          element: <Suspense fallback={<div>Loading..</div>}><AllFolders /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/rate-react-details/:post_id",
          element: <RateReactDetails />,
          errorElement: <WentWrong />
        },
        {
          path: "/next_update",
          element: <NextUpdate />,
          errorElement: <WentWrong />
        },
        {
          path: "/card",
          element: <First />,
          errorElement: <WentWrong />
        },
        {
          path: "/report-action/:reporter_id/:get_reported_id/:posted_group_id/:content_creator_id/:reported_for/:report_to",
          element: <ReportAction />,
          errorElement: <WentWrong />
        },
        {
          path: "/group_topic",
          element: <GroupTopic />,
          errorElement: <WentWrong />
        },
        {
          path: "/notifications_post",
          element: <NotificationPost />,
          errorElement: <WentWrong />
        },
        // {
        //   path: "/compare",
        //   element: <NewCompare />,
        //   errorElement: <WentWrong/>
        // },
        // {
        //   path: "/test",
        //   element: <Test />,
        //   errorElement: <WentWrong/>
        // },
        // {
        //   path: "/test",
        //   element: <PostNotificationItem />,
        //   errorElement: <WentWrong/>
        // },
      ]
    },
    {
      path: '/',
      element: <FillLayout />,
      errorElement: <WentWrong />,
      children: [
        {
          // path: "/group-profile",
          path: "/group-profile/:groupProfileId",
          element: <Suspense fallback={<div>Loading..</div>}><GroupProfile /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          // path: "/group-profile",
          path: "/add_member",
          element: <Suspense fallback={<div>Loading..</div>}><AddMember /></Suspense>,
          errorElement: <WentWrong />
        },
        {
          // path: "/group-profile",
          path: "/channel-profile/:groupProfileId",
          // element: <Suspense fallback={<div>Loading..</div>}> <ChannelProfile /> </Suspense>,
          element: <Suspense fallback={<div>Loading..</div>}> <RoomProfile /> </Suspense>,
          errorElement: <WentWrong />
        },
        {
          path: "/edit-room",
          element: <EditRoom />,
          errorElement: <WentWrong />
        },
        {
          // path: "/group-profile",
          path: "/add-article",
          // element: <Suspense fallback={<div>Loading..</div>}> <ChannelProfile /> </Suspense>,
          element: <AddArticle />,
          errorElement: <WentWrong />
        },
        {
          path: "/read/post/:post_id",
          element: <ReadPost />,
          errorElement: <WentWrong />
        },
        {
          path: "/search",
          element: <SearchedContent />,
          errorElement: <WentWrong />
        },
      ]
    },
    {
      path: '/',
      element: <ProtectedRoute><ChatLayout /></ProtectedRoute>,
      errorElement: <WentWrong />,
      children: [
        {
          // path: "/group-profile",
          path: "/chat-layout",
          element: <ChatPanel />,
          errorElement: <WentWrong />
        },
        {
          path: "/chat",
          element: <ChatPanel />,
          errorElement: <WentWrong />
        },

      ]
    },
    // {
    //   path: "/uewa-group-profile/:u_group_name",
    //   element: <UAGroupProfile />,
    //   errorElement: <WentWrong/>
    // },
    {
      path: '/signin',
      element: <AuthRoute><SignIn /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/signinA',
      element: <AuthRoute><AvatarSignIn /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/create-account-first',
      element: <AuthRoute><CreateAccountFirst /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/verification',
      element: <AuthRoute><Verification /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/register-second',
      element: <AuthRoute><RegisterSecond /></AuthRoute>,
      errorElement: <WentWrong />
    },
    // {
    //   path: '/register-second',
    //   element: <AuthRoute><RegisterSecond /></AuthRoute>,
    //   errorElement: <WentWrong/>
    // },
    {
      path: '/add-account',
      element: <ProtectedRoute><RegisterSecond /></ProtectedRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/interests',
      element: <AuthRoute><Interests /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/forget-password',
      element: <AuthRoute><ForgetPassword /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/reset-password',
      element: <AuthRoute><ResetPassword /></AuthRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/register-fourth',
      element: <AuthRoute><RegisterFourth /></AuthRoute>,
      errorElement: <WentWrong />
    },
    // {
    //   path: "/test",
    //   element: <Multi />,
    //   errorElement: <WentWrong/>
    // },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
      errorElement: <WentWrong />
    },
    {
      path: '/affiliate-disclosure',
      element: <AffiliateDisclosure />,
      errorElement: <WentWrong />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndCondition />,
      errorElement: <WentWrong />
    },
    {
      path: '/contact_us',
      element: <ContactUs />,
      errorElement: <WentWrong />
    },
    {
      path: '/about_us',
      element: <AboutUs />,
      errorElement: <WentWrong />
    },
    // {
    //   path: '/search-content',
    //   element: <Searching />
    // },
    {
      path: '/full-image',
      // element: <ProtectedRoute><FullImage /></ProtectedRoute>,
      element: <FullImage />,
      errorElement: <WentWrong />
    },
    {
      path: '/main-full-image',
      element: <ProtectedRoute><MainFullImage /></ProtectedRoute>,
      errorElement: <WentWrong />
    },
    {
      path: '/full-multi-image',
      element: <FullMultiImage />,
      errorElement: <WentWrong />
    },
    {
      path: '*',
      element: <Error />,
      errorElement: <WentWrong />
    }
  ])

  return (
    <div>
      <RouterProvider router={router} />
      {/* <Online><RouterProvider router={router} /></Online> */}
      {/* <Offline>
          <OnlineStatus/>
        </Offline> */}
    </div>
  )
}

export default NewApp
