import { Alert, Box, Button, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice'
import { setCurrentGroupInfoGroupName } from '../../store/groupSlice'
import {validUsername} from "../Regex"
import { Title2 } from '../../CustomComponents'

const CreateGroupName = ({id,name,group_name,type}) => {
    const [available, setAvailable] = React.useState(false)
    const [message,setMessage] = React.useState('')
    const [sorry, setSorry] = React.useState(false)
    const [data, setData] = React.useState("")
    // const [searchparams] = useSearchParams()
    const dispatch = useDispatch()
    const token = JSON.parse(localStorage.getItem("token"))
    const navigate = useNavigate()
    React.useEffect(() => {
        if (group_name !== null) {
            setData(group_name)
        }
    }, [id])
    // const {group_id} = useParams()
    const getGroupName = (event) => {
        setData(event.target.value.trim())
        if (event.target.value.trim().length > 2) {
            if(!validUsername.test(event.target.value)) {
                setAvailable(false)
                setSorry(true)
                setMessage("use only a-z,A-Z,0-9 or '_'.")
                return
            }else{
                setSorry(false)
            }
            axios.get(`${process.env.REACT_APP_API_URL}/api/group/group_name/${event.target.value.trim()}`, { headers: { authorization: "Bearer " + token } })
                .then(res => {
                    if (res.data.status === 0) {
                        setAvailable(false)
                        setSorry(true)
                        setMessage("Sorry,this name is already taken.")
                    } else {
                        setAvailable(true)
                        setSorry(false)
                    }
                }).catch(error => {

                })
        }else{
            setAvailable(false)
            setSorry(true)
            setMessage("Atleast 3 letters needed.")
        }
    }

    const saveGroupName = (e) => {
        e.preventDefault()
        if(!validUsername.test(data)) {
            setAvailable(false)
            setSorry(true)
            setMessage("use only a-z,A-Z,0-9 or '_'.")
            return
        }else{
            setSorry(false)
        }
        if(data.trim().length > 2) {
            axios.put(`${process.env.REACT_APP_API_URL}/api/group/group_name`, { group_name: data, id: id }, { headers: { authorization: "Bearer " + token } })
                .then(res => {
                    if (res.data.status === 1) {
                        dispatch(setCurrentGroupInfoGroupName(res.data.group_name))
                        // navigate(-1)
                    }
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                }).catch(error => {
                    dispatch(setSnackMessage("Failed to save. Try later."))
                    dispatch(setOpenSnack(true))
                })
        }else{
            setAvailable(false)
            setSorry(true)
            setMessage("Atleast 3 letters needed.")
        }
    }
    return (
        <Paper sx={{ p: 2 }}>
            {/* <Typography variant='h6' component='p'>Edit Or Create unique Group name</Typography> */}
            <Title2 >{(type==='group') ? 'Edit Or Create unique Group name' : 'Edit or create channel handle'}</Title2>
            <Typography variant='body2' mb={2}>For {name}</Typography>
            <Box
                component="form"
                // sx={{
                //     padding:'16px'
                // }}
                noValidate
                autoComplete="off"
                onSubmit={saveGroupName}
            >
                <TextField id="outlined-basic" value={data} label={(type === 'group') ? "Create Group name" : "Create channel handle"} variant="outlined" fullWidth sx={{ marginBottom: '16px' }} onChange={getGroupName} helperText="Atleast 3 letters needed" />
                <Typography variant='body2' mb={2}>This name will be unique and contain alpha numeric character. Ex. a-z,A-Z,0-9</Typography>
                {
                    available &&
                    <Alert severity="success" sx={{ mb: 1 }}>You may choose this name.</Alert>
                }{
                    sorry &&
                    <Alert severity="error" sx={{ mb: 1 }}>{message}</Alert>
                }
                <Button type='submit' variant='contained' disabled={available ? false : true}>Save</Button>
            </Box>
        </Paper>
    )
}

export default CreateGroupName