import styled from '@emotion/styled'
import { Avatar, Badge, Box, Button, CardActions, CardContent, CircularProgress, Divider, IconButton, LinearProgress, TextField, Tooltip, Typography } from '@mui/material'
import React, { useRef } from 'react'
import Photo from '@mui/icons-material/InsertPhotoOutlined';
import Video from '@mui/icons-material/VideocamOutlined';
import Gif from '@mui/icons-material/GifBoxOutlined';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelToken, isCancel } from 'axios';
import moment from 'moment/moment';
import { PostAdd, AddLink, Block, Yard, PlayCircleRounded } from '@mui/icons-material';
import { useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ReactGA from 'react-ga4'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import { appendNewPostIntList } from '../../store/postSlice';
import { EmotInput, FilledButton, SubTitle1, SubTitle2 } from '../../CustomComponents';
import theme from '../../Theme';

const SimpleFriendAndFollowPost = ({ post_type,  post_to }) => {
    const [basicOrRatedPostData, setBasicOrRatedPostData] = React.useState({ post_type, link: '', description: '', file_type: '' });
    const [files, setFiles] = React.useState([])
    const [error, setError] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [likeLink, setLikeLink] = React.useState(false)
    const [uploadPercentage, setUploadPercentage] = React.useState(0);
    const [timeNeeded, setTimeNeeded] = React.useState(0)
    const [speed, setSpeed] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const { name: group_name, type, role, is_colored } = useSelector(state => state.group.groupShortInfo)
    const token = JSON.parse(localStorage.getItem("token"))
    const socket = useOutletContext()
    // const [searchparams] = useSearchParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const controller = new AbortController()

    // React.useEffect(() => {
    //     socket?.on('new-post',(data) => {
    //         send("Emotiontown", `New post arrived in ${group_name} group.`)
    //     })
    // },[socket])
    const convertToMinAndHour = (seconds) => {
        var toMin = Math.floor(seconds / 60)
        if (toMin > 60) {
            // convert to hour
            return `${Math.floor(toMin / 60) || 0} hour`
        } else if (toMin > 0 && toMin < 60) {
            // convert to minute
            return `${Math.floor(seconds / 60) || 0} min`
        } else {
            return `${seconds} sec`
        }
    }
    const convertToKBAndMB = (byte) => {
        var kbdata = Math.floor(byte / 1000)
        if (kbdata > 1024) {
            return `${Math.floor(kbdata / 1000) || 0} mb`
        } else if (byte < 1000) {
            return `${Math.floor(byte) || 0} b`
        } else {
            return `${kbdata} kb`
        }
    }
    const handleMultiFileSubmit = (event) => {
        event.preventDefault()
        ReactGA.event({
            category: 'create post',
            action: 'create post',
            label: `${post_type} post creation`
        })
        setLoading(true)
        var total_allowed_pic = 0 // 1 2 3 4
        var formData = new FormData()
        formData.append("post_type", post_type);
        formData.append("description", basicOrRatedPostData.description);
        formData.append("link", basicOrRatedPostData.link);
        formData.append("status", 'show');
        formData.append("post_to", post_to);
        formData.append("file_type", 'image');
        for (let index = 0; index < files.length; index++) {
            if (files[index].status === 1 && total_allowed_pic <= 4) { // 0  1  2  3  4
                formData.append("post_file", files[index].file);
                total_allowed_pic++;
            }
        }
        formData.append("total_file", total_allowed_pic);
        if (total_allowed_pic === 0) {
            setMessage("Wrong file extension")
            setLoading(false)
            return
        } else {
            setMessage("")
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': "Bearer " + token
            },
            onUploadProgress: (data) => {
                console.log(Math.round(data.progress * 100))
                setUploadPercentage(Math.round(data.progress * 100));
                setTimeNeeded(Math.floor(data.estimated || 0))
                setSpeed(Math.round(data.rate))
            },
        }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/multi_file_post`, formData, config)
            .then(response => {
                setUploadPercentage(0);
                setTimeNeeded(0)
                event.target.reset()
                setLoading(false)
                if (response.data.status === 1) {
                    const current_post = {
                        "xz12uied7": response.data.post_id,
                        "xzu34ie0d98": response.data.xzu34ie0d98,
                        "group_name": group_name,
                        "creator_id": user_id,
                        "post_type": post_type,
                        "link": basicOrRatedPostData.link,
                        "total_react": 0,
                        "total_share": 0,
                        "total_comment": 0,
                        "file_type": basicOrRatedPostData.file_type,
                        "file_count": response.data.fileList.length,
                        "files": null,
                        "org_file": null,
                        "fileList": response.data.fileList,
                        "post_to": "all",
                        "description": basicOrRatedPostData.description,
                        "status": "active",
                        "added_on": moment().format(),
                        "name": name,
                        "profile_pic": profile_pic,
                        "react": null,
                        "is_saved": null,
                        "is_pinned": null,
                        "is_boosted": null,
                        "is_commented": null,
                        "is_colored": is_colored,
                        "post_to": post_to,
                        "role": role,
                    }
                    // dispatch(appendNewPostIntList(current_post))
                    if(post_to === 'friend') {
                        dispatch(appendNewPostIntList({feed_value:'member_post',data:current_post}))
                    }
                    if(post_to === 'follower') {
                        // dispatch(appendNewPostIntList({feed_value:'follower_post',data:current_post}))
                    }
                }
                if (response.data.status === 1) {
                    // socket?.emit('new-post', { roomId: group_id, group_name })
                }
                // setMediaPollOrComparePostData({ post_type, description: '', files: [] })
                setBasicOrRatedPostData({ post_type, link: '', description: '', file_type: '' })
                setFiles([])
                // dispatch(appendNewPostIntList(current_post))
                dispatch(setSnackMessage("Posted Successfully."))
                dispatch(setOpenSnack(true))
                navigate(-1)
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
                setLoading(false)
            })
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        if (files.length > 1) {
            handleMultiFileSubmit(event)
            return
        }
        ReactGA.event({
            category: 'create post',
            action: 'create post',
            label: `${post_type} post creation`
        })
        setLoading(true)

        if ((basicOrRatedPostData.description || basicOrRatedPostData.link) && (files.length === 0)) {
            // if (basicOrRatedPostData.file === undefined || basicOrRatedPostData.file === "") {
            // group_id, user_id, post_type, description, poll_options
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_simple_post`, { group_id: null, post_type: basicOrRatedPostData.post_type, link: basicOrRatedPostData.link, description: basicOrRatedPostData.description, "message": 'New Post Arrived',post_to, status:'show' }, {
                headers: { authorization: "Bearer " + token },
                onUploadProgress: (data) => {
                    console.log(Math.round(data.progress * 100))
                    setUploadPercentage(Math.round(data.progress * 100));
                    setTimeNeeded(Math.floor(data.estimated || 0))
                    setSpeed(Math.round(data.rate))
                },
            })
                .then(response => {
                    setLoading(false)
                    setUploadPercentage(0)
                    setTimeNeeded(0)
                    const post = {
                        "xz12uied7": response.data.post_id,
                        "xzu34ie0d98": response.data.xzu34ie0d98,
                        "group_name": group_name,
                        "creator_id": user_id,
                        "post_type": post_type,
                        "post_to":post_to,
                        "file_type": basicOrRatedPostData.file_type,
                        "link": basicOrRatedPostData.link,
                        "file_count": 0,
                        "total_react": 0,
                        "total_comment": 0,
                        "total_share": 0,
                        "files": '',
                        "org_file": null,
                        "post_to": "all",
                        // "options": "",
                        "description": basicOrRatedPostData.description,
                        "status": "active",
                        "added_on": moment().format(),
                        "name": name,
                        "profile_pic": profile_pic,
                        "react": null,
                        "is_saved": null,
                        "is_pinned": null,
                        "is_boosted": null,
                        "is_commented": null,
                        "is_colored": is_colored,
                        "post_to": post_to,
                        "role": role,
                    }
                    if (response.data.status !== 0) {
                        // dispatch(appendNewPostIntList(post))
                        if(post_to === 'friend') {
                            dispatch(appendNewPostIntList({feed_value:'member_post',data:post}))
                        }
                        if(post_to === 'follower') {
                            // dispatch(appendNewPostIntList({feed_value:'follower_post',data:post}))
                        }
                    }
                    if (response.data.status === 1) {
                        // socket?.emit('new-post', { roomId: group_id, group_name })
                    }
                    dispatch(setSnackMessage(response.data.message))
                    dispatch(setOpenSnack(true))
                    navigate(-1)
                }).catch(error => {
                    dispatch(setSnackMessage("Something went wrong.Try later."))
                    dispatch(setOpenSnack(true))
                })
            // simple post 
        } else {
            if (files[0].file.size > 60e6) {
                setLoading(false)
                dispatch(setSnackMessage("Please upload a file smaller than 60 mb."))
                dispatch(setOpenSnack(true))
                return false;
            }
            var formData = new FormData();
            // formData.append("user_id", user_id);
            formData.append("post_type", post_type);
            formData.append("description", basicOrRatedPostData.description);
            formData.append("file_type", basicOrRatedPostData.file_type);
            formData.append("post_file", files[0].file);
            formData.append("post_to", post_to);
            formData.append("status", 'show');
            formData.append("link", basicOrRatedPostData.link);
            const options = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'authorization': "Bearer " + token
                },
                onUploadProgress: (data) => {
                    // console.log(`rate: ${data.rate} progress: ${data.progress}`)
                    console.log(Math.round(data.progress * 100))
                    setUploadPercentage(Math.round(data.progress * 100));
                    setTimeNeeded(Math.floor(data.estimated || 0))
                    setSpeed(Math.round(data.rate))
                },
                // signal: controller.signal
            };
            if (basicOrRatedPostData.file_type === 'image') {
                axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_post`, formData, {
                    // signal:controller.signal,
                    headers: {
                        'content-type': 'multipart/form-data',
                        'authorization': "Bearer " + token
                    },
                    onUploadProgress: (data) => {
                        setUploadPercentage(Math.round(data.progress * 100));
                        setTimeNeeded(Math.floor(data.estimated || 0))
                        setSpeed(Math.round(data.rate))
                        // setUploadPercentage(Math.round(data.progress * 100));
                    },
                })
                    .then(response => {
                        // setUploadPercentage(100);

                        setUploadPercentage(0);
                        setTimeNeeded(0)


                        setLoading(false)
                        const post = {
                            "xz12uied7": response.data.post_id,
                            "xzu34ie0d98": response.data.xzu34ie0d98,
                            "group_name": group_name,
                            "creator_id": user_id,
                            "post_type": post_type,
                            "link": basicOrRatedPostData.link,
                            "total_react": 0,
                            "total_share": 0,
                            "total_comment": 0,
                            "file_type": basicOrRatedPostData.file_type,
                            "file_count": 1,
                            "files": response.data.post_file,
                            "org_file": response.data.org_post_file,
                            "post_to": "all",
                            "description": basicOrRatedPostData.description,
                            "status": "active",
                            "added_on": moment().format(),
                            "name": name,
                            "profile_pic": profile_pic,
                            "react": null,
                            "is_saved": null,
                            "is_pinned": null,
                            "is_boosted": null,
                            "is_commented": null,
                            "is_colored": is_colored,
                            "post_to": post_to,
                            "role": role,
                        }
                        if (response.data.status !== 0) {
                            // dispatch(appendNewPostIntList(post))
                            if(post_to === 'friend') {
                                dispatch(appendNewPostIntList({feed_value:'member_post',data:post}))
                            }
                            if(post_to === 'follower') {
                                // dispatch(appendNewPostIntList({feed_value:'follower_post',data:post}))
                            }
                        }
                        if (response.data.status === 1) {
                            // socket?.emit('new-post', { roomId: group_id, group_name })
                        }
                        dispatch(setSnackMessage(response.data.message))
                        dispatch(setOpenSnack(true))
                        // setUploaded(null)
                        navigate(-1)
                    }).catch(error => {
                        // if (isCancel(error)) {
                        //     alert(error.message);
                        // }
                        setUploadPercentage(0);
                        dispatch(setSnackMessage("Something went wrong.Try later."))
                        dispatch(setOpenSnack(true))
                        // setUploaded(null)
                        setLoading(false)
                    })
            } else if (basicOrRatedPostData.file_type === 'video' || basicOrRatedPostData.file_type === 'gif') {
                axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_post_vid_gif`, formData, options)
                    .then(response => {
                        // setUploadPercentage(100);

                        setUploadPercentage(0);
                        setTimeNeeded(0)


                        setLoading(false)
                        const post = {
                            "xz12uied7": response.data.post_id,
                            "xzu34ie0d98": response.data.xzu34ie0d98,
                            "group_name": group_name,
                            "creator_id": user_id,
                            "post_type": post_type,
                            "link": basicOrRatedPostData.link,
                            "total_react": 0,
                            "total_share": 0,
                            "total_comment": 0,
                            "file_type": basicOrRatedPostData.file_type,
                            "file_count": 1,
                            "files": response.data.post_file,
                            "org_file": response.data.org_post_file,
                            "post_to": "all",
                            "description": basicOrRatedPostData.description,
                            "status": "active",
                            "added_on": moment().format(),
                            "name": name,
                            "profile_pic": profile_pic,
                            "react": null,
                            "is_saved": null,
                            "is_pinned": null,
                            "is_boosted": null,
                            "is_commented": null,
                            "is_colored": is_colored,
                            "post_to": post_to,
                            "role": role,
                        }
                        if (response.data.status !== 0) {
                            if(post_to === 'friend') {
                                dispatch(appendNewPostIntList({feed_value:'member_post',data:post}))
                            }
                            if(post_to === 'follower') {
                                // dispatch(appendNewPostIntList({feed_value:'follower_post',data:post}))
                            }
                        }
                        if (response.data.status === 1) {
                            // socket?.emit('new-post', { roomId: group_id, group_name })
                        }
                        dispatch(setSnackMessage(response.data.message))
                        dispatch(setOpenSnack(true))
                        navigate(-1)
                        // setUploaded(null)
                    }).catch(error => {
                        // if (isCancel(error)) {
                        //     alert(error.message);
                        // }
                        setUploadPercentage(0);
                        dispatch(setSnackMessage("Something went wrong.Try later."))
                        dispatch(setOpenSnack(true))
                        // setUploaded(null)
                        setLoading(false)
                    })

            } else {

            }
        }
        setLikeLink(false)
        setBasicOrRatedPostData({ post_type, link: '', description: '', file_type: '', file: '' })
        setFiles([])
    }

    // const cancelUpload = () => {
    //     controller.abort()
    // };

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const handleFileSelect = (event, file_type) => {
        if (files.length > 0) {
            setFiles(files.splice(0, files.length))
        }
        setBasicOrRatedPostData({ ...basicOrRatedPostData, file_type })
        // return
        const values = []
        for (let index = 0; index < event.target.files.length; index++) {
            var file_extension = getExtension(event.target.files[index].name)
            // if (!event.target.files[index].name) {
            //     console.log(`return statement`)
            //     return false;
            // }
            if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg' || file_extension.toLowerCase() === 'mp4' || file_extension.toLowerCase() === 'gif') {
                // if(files.length > 0) {
                values.push({ file: event.target.files[index], status: 1, key: index })
                // }else{
                //     setFiles([{file:event.target.files[index],status:1}])
                // }
                // setError("")
            } else {
                // setFiles([...files,{file:event.target.files[index],status:0}])
                // if(files.length > 0) {
                values.push({ file: event.target.files[index], status: 0, key: index })
                // }else{
                //     setFiles([{file:event.target.files[index],status:0}])
                // }
                // setError("Sorry, this file format is not allowed.Use only png, jpg, jpeg, mp4, gif.")
            }
        }
        setFiles(files.concat(values))
    }
    // const handleFileSelect = (event, file_type) => {
    //     var file_extension = getExtension(event.target.files[0].name)
    //     if (!event.target.files[0].name) {
    //         return false;
    //     }
    //     if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg' || file_extension.toLowerCase() === 'mp4' || file_extension.toLowerCase() === 'gif') {
    //         setBasicOrRatedPostData({
    //             ...basicOrRatedPostData, "file_type": file_type, "file": event.target.files[0]
    //         })
    //         setError("")
    //     } else {
    //         setError("Sorry, this file format is not allowed.Use only png, jpg, jpeg, mp4, gif.")
    //     }
    // }
    const handleInputFieldChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setBasicOrRatedPostData(data => ({ ...data, [name]: value }))
        setError("")
    }
    const removeFile = (index_no) => {
        // setBasicOrRatedPostData({ ...basicOrRatedPostData, file: '', file_type: '' })
        setFiles((items) => items.filter((item) => item.key !== index_no));
        // remove file with index no
        // let file_value = files 
        // file_value.splice(index_no,1)
        // console.log(index_no)
        // setFiles(file_value)
    }
    const handleCancel = () => {
    }
    const addLink = () => {
        setLikeLink(true)
    }
    const cancelLink = () => {
        setLikeLink(false)
        setBasicOrRatedPostData({ ...basicOrRatedPostData, link: '' })
    }
    const text_area_style = {
        width: '100%',
        height: '80px',
        padding: '8px 12px',
        boxSizing: 'border - box',
        // border: '2px solid #ccc',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#f8f8f8',
        fontSize: '16px',
        resize: 'none',
        outline: 'none'
    }
    return (
        <Box component='form' onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
                {/* <TextField
                    fullWidth
                    value={basicOrRatedPostData.description}
                    id="basic_post_dialog_textfield_id"
                    label="what's in your mind ?"
                    multiline
                    rows={2}
                    name="description"
                    onChange={handleInputFieldChange}
                /> */}
                <EmotInput
                    fullWidth
                    value={basicOrRatedPostData.description}
                    id="basic_post_dialog_textfield_id"
                    label="what's in your mind ?"
                    multiline
                    rows={2}
                    name="description"
                    onChange={handleInputFieldChange}
                    placeholder="what's in your mind?"
                />
                {
                    (likeLink === true) &&
                    <TextField
                        fullWidth
                        value={basicOrRatedPostData.link}
                        id="link_textfield_id"
                        label="write link"
                        multiline
                        maxRows={4}
                        name="link"
                        onChange={handleInputFieldChange}
                        sx={{ mt: 1 }}
                    />
                }
                {
                    (likeLink === true) &&
                    <Typography variant='caption' color='error' sx={{ cursor: 'pointer', textAlign: 'end', display: 'block' }} onClick={cancelLink}>remove link</Typography>
                }
                <Typography variant='caption' color='red'>{error}</Typography>

                {
                    (uploadPercentage > 0) &&
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', marginRight: '4px' }}>
                                <LinearProgress variant="determinate" value={uploadPercentage} />
                            </Box>
                            <Box sx={{ minWidth: 35, textAlign: 'end' }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    uploadPercentage,
                                )}%`}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography fontSize='13px' color='grey' mx={1}>{convertToMinAndHour(timeNeeded)}</Typography>
                            <Typography fontSize='13px' color='grey'>({convertToKBAndMB(speed)}/sec)</Typography>
                        </Box>
                    </>
                }
            </CardContent>
            <Box sx={{ display: 'flex', px: 2, flexWrap: 'wrap' }}>
                {
                    (files.length > 0 && basicOrRatedPostData.file_type !== "video") &&
                    files.map((item, index) => {
                        return <Box key={index} sx={{ position: 'relative', width: '60px', height: '60px', backgroundColor: 'orange', mr: 2, mt: 1 }}>
                            {
                                (item.status === 0) &&
                                <Box sx={{ width: '100%', height: '100%', backgroundColor: 'rgba(255,0,0,0.8)', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', top: 0, right: 0 }}>
                                    <Block />
                                </Box>
                            }
                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={URL.createObjectURL(item.file)} alt="no pic" />
                            <IconButton color="primary" onClick={() => removeFile(item.key)} aria-label="upload picture" component="label" sx={{ backgroundColor: 'red', width: '18px', height: '18px', position: 'absolute', bottom: '2px', right: '-9px', color: 'white', '&:hover': { backgroundColor: 'red' } }}>
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                    })
                }
            </Box>
            {
                (files.length > 5) &&
                <Typography variant='caption' px={2}>upload maximum 5 files (jpg, png, jpeg)</Typography>
            }
            {
                (files.length > 0 && message) &&
                <Typography variant='caption' px={2} color='red'>{message}</Typography>
            }
            {/* <CardActions disableSpacing sx={{ justifyContent: 'space-between' }}> */}
                <Box sx={{ display: 'flex', alignItems: 'center',padding:'16px' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexGrow:1 }}>
                        <Box sx={{ display: 'flex', gap: 1 }} component='label' onChange={(event) => handleFileSelect(event, "image")}>
                            <input hidden accept="image/png,image/jpeg,image/jpg" multiple type="file" />
                            {
                                basicOrRatedPostData.file_type === "image" ?
                                    <Badge color="redish" variant="dot">
                                        <Yard sx={{ color: theme.palette.primary.main, fontSize: '18px' }} />
                                    </Badge>
                                    :
                                    <Yard sx={{ color: theme.palette.dark.light, fontSize: '18px' }} />
                            }
                            <SubTitle1>Images</SubTitle1>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }} component='label' onChange={(event) => handleFileSelect(event, "video")}>
                            <input hidden accept="video/mp4" type="file" />
                            {
                                basicOrRatedPostData.file_type === "video" ?
                                    <Badge color="redish" variant="dot">
                                        <PlayCircleRounded sx={{ color: theme.palette.primary.main, fontSize: '18px' }} />
                                    </Badge>
                                    :
                                    <PlayCircleRounded sx={{ color: theme.palette.dark.light, fontSize: '18px' }} />
                            }
                            <SubTitle1>Videos</SubTitle1>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }} component='label' onChange={(event) => handleFileSelect(event, "gif")}>
                            <input hidden accept=".gif" type="file" />
                            {
                                basicOrRatedPostData.file_type === "gif" ?
                                    <Badge color="redish" variant="dot">
                                        <Gif sx={{ color: theme.palette.primary.main, fontSize: '18px' }} />
                                    </Badge>
                                    :
                                    <Gif sx={{ color: theme.palette.dark.light, fontSize: '18px' }} />
                            }
                            <SubTitle1>GIF</SubTitle1>
                        </Box>
                    </Box>
                    {
                        (!likeLink) &&
                        <Tooltip title="Add link">
                            <Avatar sx={{ backgroundColor: '#cfccff', width: 30, height: 30 }} onClick={addLink}>
                                <AddLink color='primary' fontSize='inherit' />
                            </Avatar>
                        </Tooltip>
                    }
                </Box>
                <Divider/>
                <Box sx={{ mr: 1, position: 'relative',my:2,textAlign:'end' }}>
                    <FilledButton type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={loading ? true : (files.length !== 0 || basicOrRatedPostData.description !== '' || basicOrRatedPostData.link !== '') ? false : true} >Post</FilledButton>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#44bd32',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            {/* </CardActions > */}
        </Box >
    )
}

export default SimpleFriendAndFollowPost
