import { Avatar, Badge, Box, Button, CardActions, CardContent, Chip, CircularProgress, IconButton, LinearProgress, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import Photo from '@mui/icons-material/Landscape';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { appendNewPostIntList } from '../../../../store/postSlice';
import moment from 'moment/moment';
import ReactGA from 'react-ga4'
import { PostAdd, Add, Clear } from '@mui/icons-material';
import { setOpenSnack, setSnackMessage } from '../../../../store/authSlice';
import { useLocation, useOutletContext } from 'react-router-dom';

const PostButton = styled(Button)((theme) => ({
    backgroundColor: 'rgba(64,53,151,0.8)',
    borderRadius: '0px',
    border: '1px solid rgba(64,53,151,0.8)',
    color: 'white',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: 'rgba(64,53,151,0.9)',
    }
}))

const CancelButton = styled(Button)((theme) => ({
    border: '1px solid rgba(64,53,151,0.8)',
    // backgroundColor: 'transparent',
    borderRadius: '0px',
    color: 'rgba(64,53,151,0.8)',
    // display:'inline-block',
    padding: '4px 20px',
    marginLeft: '30px',
    '&:hover': {
        backgroundColor: '#f5f6fa',
    }
}))
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
const MediaPollPost = ({ post_type }) => {
    const [mediaPollOrComparePostData, setMediaPollOrComparePostData] = React.useState({ post_type, description: '', files: [] });
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [options, setOptions] = React.useState([]) // key , profile_pic, text ['hello','world','prabir']
    const [curFile, setCurFile] = React.useState('')
    const [uploadPercentage, setUploadPercentage] = React.useState(0);
    const [timeNeeded, setTimeNeeded] = React.useState(0)
    const [speed, setSpeed] = React.useState(0)
    // const user_id = useSelector((state) => state.auth.userInfo.id)
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const { name: group_name, type, role, is_colored } = useSelector(state => state.group.groupShortInfo)
    const token = JSON.parse(localStorage.getItem("token"))
    const socket = useOutletContext()
    const { id: group_id } = useSelector(state => state.group.groupShortInfo)
    // const group_id = useSelector((state) => state.group.groupProfileId)
    const dispatch = useDispatch()
    const location = useLocation()

    // React.useEffect(() => {
    //     if (mediaPollOrComparePostData.files.length === 1) {
    //         setError(`Multiple files neede for ${post_type}`)
    //         // return
    //     } else if (mediaPollOrComparePostData.files.length > 3) {
    //         setError(`Maximum 3 files can contain`)
    //         // return 
    //     } else {
    //         setError('')
    //     }
    // }, [mediaPollOrComparePostData])

    const convertToMinAndHour = (seconds) => {
        var toMin = Math.floor(seconds / 60)
        if (toMin > 60) {
            // convert to hour
            return `${Math.floor(toMin / 60) || 0} hour`
        } else if (toMin > 0 && toMin < 60) {
            // convert to minute
            return `${Math.floor(seconds / 60) || 0} min`
        } else {
            return `${seconds} sec`
        }
    }

    const convertToKBAndMB = (byte) => {
        var kbdata = Math.floor(byte / 1024)
        if (kbdata > 1024) {
            return `${Math.floor(kbdata / 1024) || 0} mb`
        } else if (byte < 1024) {
            return `${Math.floor(byte) || 0} b`
        } else {
            return `${kbdata} kb`
        }
    }

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        ReactGA.event({
            category: 'create post',
            action: 'create post',
            label: `${post_type} post creation`
        })
        setLoading(true)
        var formData = new FormData()
        formData.append("group_id", group_id);
        formData.append("post_type", post_type);
        formData.append("description", mediaPollOrComparePostData.description);
        formData.append("file_type", 'image');
        formData.append("total_file", mediaPollOrComparePostData.files.length);
        for (let index = 0; index < 3; index++) {
            // if (getExtension(mediaPollOrComparePostData.files[index].name).toLowerCase() !== ("png" || "jpg" || "jpeg" || "mp4" || "gif")) {
            //     dispatch(setSnackMessage(`[.${getExtension(mediaPollOrComparePostData.files[index].name)}] file not allowed. Use another file.`))
            //     dispatch(setOpenSnack(true))
            //     return false;
            // }
            // if (mediaPollOrComparePostData.files[index].file && !mediaPollOrComparePostData.files[index].name) {
            //     dispatch(setSnackMessage(`File not found.`))
            //     dispatch(setOpenSnack(true))
            //     return false;
            // }
            // if (mediaPollOrComparePostData.files[index].size > 70e6) {
            //     dispatch(setSnackMessage("Please upload a file smaller than 70 mb."))
            //     dispatch(setOpenSnack(true))
            //     return false;
            // }
            formData.append("post_file", mediaPollOrComparePostData.files[index]);
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': "Bearer " + token
            },
            onUploadProgress: (data) => {
                setUploadPercentage(Math.round((data.loaded / data.total) * 100));
                setTimeNeeded(Math.floor(data.estimated || 0))
                setSpeed(Math.round(data.bytes))
            },
        }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/media_poll`, formData, config)
            .then(response => {
                setUploadPercentage(0);
                setTimeNeeded(0)
                event.target.reset()
                setLoading(false)
                if (response.data.status === 1) {
                    const current_post = {
                        "xz12uied7": response.data.post_id,
                        "group_id": location.state.id,
                        "group_name":group_name,
                        "creator_id": user_id,
                        "post_type": post_type,
                        "total_react": 0,
                        "total_share": 0,
                        "total_comment": 0,
                        "post_to": "all",
                        "description": response.data.title,
                        "status": "active",
                        "added_on": moment().format(),
                        "name": name,
                        'options': response.data.options,
                        // 'files':response.data.file,
                        'is_voted': null,
                        "profile_pic": profile_pic,
                        "react": null,
                        "is_saved": null,
                        "is_pinned": null,
                        "is_boosted": null,
                        "is_commented": null,
                        "is_colored": is_colored,
                        "type": type,
                        "role": role,
                    }
                    dispatch(appendNewPostIntList(current_post))
                }
                if (response.data.status === 1) {
                    socket?.emit('new-post', { roomId: location.state.id, group_name })
                }
                setMediaPollOrComparePostData({ post_type, description: '', files: [] })
                // dispatch(appendNewPostIntList(current_post))
                dispatch(setSnackMessage("Posted Successfully."))
                dispatch(setOpenSnack(true))
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong.Try later."))
                dispatch(setOpenSnack(true))
                setLoading(false)
            })
    }

    const submitPostOption = (post_id) => {
        const options = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': "Bearer " + token
            },
            onUploadProgress: (data) => {
                // setUploadPercentage(Math.round((data.loaded / data.total) * 100));
            }
        };
        for (let index = 0; index < options.length; index++) {
            var formData = new FormData();
            formData.append("post_id", post_id);
            formData.append("option_text", options[index].text);
            formData.append("post_file", options[index].pic);
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_media_option`, formData, options)
                .then(response => {
                    if (response.data.status === 1) {
                        if (index === (options.length - 1)) {
                            setLoading(false)
                        }
                    }
                }).catch(error => {
                    setLoading(false)
                })
        }
    }

    // const handleSubmit = (event) => {
    //     setLoading(true)
    //     axios.post(`${process.env.REACT_APP_API_URL}/api/post/create_simple_post`, { group_id: location.state.id, post_type, link: '', description }, { headers: { authorization: "Bearer " + token } })
    //         .then(response => {
    //             if (response.data.status === 1) {
    //                 submitPostOption(response.data.post_id)
    //             }
    //         }).catch(error => {
    //             setLoading(false)
    //             dispatch(setSnackMessage("Something went wrong.Try later."))
    //             dispatch(setOpenSnack(true))
    //         })
    // }
    const handleFileSelect = (event) => {
        var file_extension = getExtension(event.target.files[0].name)
        if (!event.target.files[0].name) {
            return false;
        }
        if (file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' || file_extension.toLowerCase() === 'jpeg') {
            // setCurFile(event.target.files[0])
            setMediaPollOrComparePostData({ ...mediaPollOrComparePostData, files: [...event.target.files] })
            // let poll_options = []
            // for (let index = 0; index < event.target.files.length; index++) {
            //     poll_options.push({key:index,option:null})
            // }
            // setOptions([...poll_options])
            setError("")
        } else {
            setError("Sorry, this file format is not allowed.Use only png, jpg, jpeg, mp4, gif.")
        }
    }

    // const handleDelete = (chipToDelete) => () => {
    //     setOptions((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    // };
    return (
        <Box component='form' onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
                <TextField
                    fullWidth
                    id="basic_post_dialog_textfield_id"
                    label="Write description"
                    multiline
                    maxRows={2}
                    name={mediaPollOrComparePostData.description}
                    onChange={(event) => setMediaPollOrComparePostData({ ...mediaPollOrComparePostData, description: event.target.value })}
                />

                <Typography variant='caption' color='red'>{error}</Typography>
                {
                    (mediaPollOrComparePostData.files.length === 0) &&
                    <Typography variant='caption' color='grey'>* Take maximum 3 and minimum 2 image</Typography>
                }
                {/* <Typography variant='subtitle2' color='black' mt={1}>Add Options</Typography> */}
                {/* {
                    options.map((option) => {
                        return <Chip
                            key={option.key}
                            sx={{ marginTop: '4px' }}
                            avatar={<Avatar src={option.pic && URL.createObjectURL(option.pic)} />}
                            label={option.text}
                            onDelete={handleDelete(option)}
                        />
                    })
                } */}

                <Box sx={{ paddingTop: '6px' }}>
                    {/* {
                            mediaPollOrComparePostData.files.map((item, index) => {
                                return <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Avatar variant="rounded" src={item && URL.createObjectURL(item)} onClick={() => console.log(index)}>
                                        <IconButton aria-label="upload picture" component="label" sx={{ opacity: 8 }} onChange={(e) => handleFileSelect(e)}>
                                            <input hidden accept="image/*" type="file" />
                                            <Photo />
                                        </IconButton>
                                    </Avatar>
                                    <TextField
                                        label="wirte something"
                                        id="outlined-size-small"
                                        value={options[index]}
                                        size="small"
                                        sx={{ flexGrow: 1, marginX: '10px' }}
                                        onChange={(event) => setOptions([...options,])}
                                    />
                                    <Clear sx={{ color: 'red' }} onClick={() => {
                                        let key = Date.now()
                                        if (curFile !== '') {
                                            setOptions(option => {
                                                return [...option, { key: key, pic: curFile, text: optionText }]
                                            })
                                        }
                                        setOptionText('')
                                        setCurFile('')
                                    }} />
                                </Box>
                            })
                        } */}
                    {/* <Typography variant='body2' textAlign='right' marginRight='40px' marginTop='6px' fontSize='12px' flexGrow={1}>{optionText.length}/18</Typography> */}
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                            <img src={mediaPollOrComparePostData.files[0] && URL.createObjectURL(mediaPollOrComparePostData.files[0])} style={{ width: '40px', objectFit: 'cover', marginTop: '6px' }} />
                        </Box> */}
                    {
                        (uploadPercentage > 0) &&
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', marginRight: '4px' }}>
                                    <LinearProgress variant="determinate" value={uploadPercentage} />
                                </Box>
                                <Box sx={{ minWidth: 35, textAlign: 'end' }}>
                                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                                        uploadPercentage,
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Button onClick={cancelUpload} variant='contained' size='small'>cancel</Button> */}
                                <Typography fontSize='13px' color='grey' mx={1}>{convertToMinAndHour(timeNeeded)}</Typography>
                                <Typography fontSize='13px' color='grey'>({convertToKBAndMB(speed)}/sec)</Typography>
                            </Box>
                        </>
                    }
                    <Box>
                        {
                            mediaPollOrComparePostData.files.map((file, index) => {
                                if (index <= 2) {
                                    return <img key={index} src={mediaPollOrComparePostData.files[index] && URL.createObjectURL(mediaPollOrComparePostData.files[index])} style={{ width: '60px', height: '60px', marginRight: '6px', objectFit: 'cover', marginTop: '6px' }} />
                                }
                            })
                        }
                    </Box>
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'end' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Button onChange={(e) => handleFileSelect(e)} size='small' variant="contained" component="label" startIcon={<Photo />} sx={{ borderRadius: '20px', backgroundColor: '#cfccff', color: '#6C63FF', fontSize: '14px', fontWeight: 400 }}>
                        Take Multiple image
                        <input hidden accept='image/png,image/jpeg,image/jpg' multiple type="file" />
                    </Button>
                </Box>
                <Box sx={{ mr: 1, position: 'relative' }}>
                    <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={(loading || mediaPollOrComparePostData.files.length < 2 || mediaPollOrComparePostData.description.trim().length <= 0) ? true : false} disableElevation>Post</Button>

                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#44bd32',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
                {/* <Box>
                    <Button type='submit' variant="contained" startIcon={<PostAdd/>} disabled={(error || mediaPollOrComparePostData.files === '') ? true : false} disableElevation>Post</Button>
                </Box> */}
            </CardActions>
        </Box>
    )
}

export default MediaPollPost