import { Avatar, Badge, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, Divider, IconButton, TextField, Typography, styled } from '@mui/material'
import React, { useRef } from 'react'
import Clap from '@mui/icons-material/SignLanguage';
import LikeOutline from '@mui/icons-material/FavoriteBorder';
import Like from '@mui/icons-material/Favorite';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment/moment';
import { entities } from "../../HtmlEntity"
import { InsertCommentOutlined } from '@mui/icons-material';
import Comment from '../../Cards/Comment';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const NotiLovecard = ({ val }) => {
    const [expanded, setExpanded] = React.useState(false);
    const [description, setDescription] = React.useState()
    const [comment, setComment] = React.useState("")
    const [newComment, setNewComment] = React.useState({})
    const [totalComment, setTotalComment] = React.useState(0)
    const [createCommentStatus, setCreateCommentStatus] = React.useState('ok') // loading error
    const [isShowMore, setIsShowMore] = React.useState(false);
    const socket = useOutletContext()
    // const [lineCount, setLineCount] = React.useState(0);
    const { uid: user_id, name, profile_pic } = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem('token'))
    const ref = useRef(null)
    React.useEffect(() => {
        if (val.xzu34ie0d98) {
            setTotalComment(val.total_comment)
            if (val.description && val.description.length > 0) {
                setDescription(val.description.replace(/&#?\w+;/g, match => entities[match]))
            }
        }
    }, [val])

    // React.useEffect(() => {
    //         if (!val.xzu34ie0d98) {
    //             setLoading(false)
    //             axios.get(`${process.env.REACT_APP_API_URL}/api/post/get_post/${post_id}`, { headers: { authorization: "Bearer " + token } })
    //                 .then(response => {
    //                     setLoading(false)
    //                     if (response.data.status === 1) {
    //                         setVal(response.data.data)
    //                     } else {
    //                         // show not found poll item
    //                     }
    //                 }).catch(error => {
    //                     setLoading(false)
    //                     dispatch(setSnackMessage("Something went wrong. Try later."))
    //                     dispatch(setOpenSnack(true))
    //                 })
    //         }
    // },[])

    const toggleReadMore = () => setIsShowMore((show) => !show);


    const handleCommentSubmit = (e) => {
        e.preventDefault()
        setCreateCommentStatus('loading')
        const data = { post_id: val.xz12uied7, group_id: val.group_id, comment, message: `comments on your post in ${val.group_name.replace(/&#?\w+;/g, match => entities[match])} group`, user_to_notify: val.u_id }
        axios.post(`${process.env.REACT_APP_API_URL}/api/post/createComment`, data, { headers: { authorization: "Bearer " + token } })
            .then(response => {
                const new_comment = {
                    comment_id: response.data.comment_id,
                    comment,
                    added_on: moment().format(),
                    user_id,
                    profile_pic,
                    total_reply: 0,
                    total_reacted: 0,
                    is_reacted: null,
                    name
                }
                setNewComment(new_comment)
                setTotalComment(totalComment + 1)
                if (response.data.status === 1) {
                    if (val.creator_id !== user_id) {
                        socket?.emit('commentOnPost', { receiverid: val.u_id })
                    }
                }
                setCreateCommentStatus('ok')
            }).catch(error => {
                setCreateCommentStatus('error')
            })
        // dispatch(submitComment(data))
        setComment("")
    };


    return (
        // backgroundColor:val.role==="Super Admin" ? '#e7e6ff':val.role === 'Admin'?'#ebf7fa':'white'
        <Card ref={ref} sx={{ width: '100%', borderRadius: '0px', marginBottom: 2, boxShadow: '0px 0px 25px -10px rgba(51,61,82,0.8)' }}>
            <CardHeader
                avatar={
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <SmallAvatar src={val.profile_pic} />
                        }
                    >
                        <Avatar alt={val.group_name} src={val.group_profile_pic} />
                    </Badge>
                }
                title={`${val.user_name}`}
                subheader={`posted to ${val.group_name} group`}
            />
            <CardContent sx={{ pt: 0, pb: 1 }}>
                {(description && description.length > 250 && !isShowMore) ?
                    <>
                        <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                            {description.substring(0, 250)}...
                        </Typography>
                        <Typography component='span' sx={{ color: '#6C63FF', cursor: 'pointer' }} onClick={toggleReadMore}>read more</Typography>
                    </>
                    :
                    <>
                        {
                            <Typography variant="body2" component='span' lineHeight="18px" color="#353b48" sx={{ whiteSpace: 'pre-line' }}>
                                {description}
                            </Typography>
                        }
                        {
                            description && description.length > 250 ?
                                <Typography sx={{ color: '#6C63FF', cursor: 'pointer', my: 1 }} onClick={toggleReadMore}>show less</Typography> :
                                null
                        }
                    </>
                }
                {
                    (val.link) &&
                    <Box>
                        {(val.link && val.link.length > 100) ?
                            <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                {val.link.substring(0, 100)} ...
                            </a> :
                            <a href={val.link} target='_blank' color='primary' style={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                                {val.link}
                            </a>
                        }
                    </Box>
                }
            </CardContent>
            {
                val.file_count > 0 && (val.file_type == 'image' || val.file_type == 'gif') ?
                    <img alt='No pic'
                        // src="https://images.pexels.com/photos/2166711/pexels-photo-2166711.jpeg?auto=compress&cs=tinysrgb&w=600"
                        src={val.file_type === 'image' ? val.files : val.org_file}
                        style={{ objectFit: 'cover', width: '100%', backgroundColor: '#2C3A47', minHeight: "200px", minWidth: '100%' }} loading='lazy' />
                    : val.file_count > 0 && val.file_type == 'video' ?
                        <Box sx={{ textAlign: 'center', width: '100%' }}>
                            <video src={val.org_file} style={{ maxHeight: '500px', height: '100%', width: '100%' }} controls controlsList='nodownload' />
                        </Box>
                        : null
            }
            <CardActions disableSpacing sx={{ display: 'block', position: 'relative', boxSizing: 'border-box' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                        <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                            Total reacted: {totalLiked}
                        </Typography>
                    </Box> */}
                    {
                        (val.post_type === 'basic') ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 1) ? 'large' : 'small'} sx={{ padding: '0px', color: '#f0932b', marginRight: { xs: '9px', sm: '6px' } }} >
                                    <Clap fontSize='inherit' />
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 2) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😆
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 3) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😍
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 4) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😮
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 5) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😘
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 6) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😋
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 7) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😠
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart" size={(val.react !== null) && (parseInt(val.react) === 8) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} >
                                    😢
                                </IconButton>
                                <Typography pl={1} variant='body2' sx={{ cursor: 'pointer' }}>| {val.total_react}</Typography>
                            </Box> :
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                                {
                                    (val.react) ?
                                        <Like fontSize='small' sx={{ marginRight: '5px', color: "red" }} /> :
                                        <LikeOutline fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} />
                                }
                                <Typography sx={{ fontSize: '12px' }}>
                                    {val.total_react}
                                </Typography>
                            </Box>
                    }
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                        <InsertCommentOutlined sx={{fontSize:'18px'}}/>
                        <Typography sx={{ fontSize: '12px', color: 'grey', marginLeft:'5px' }}>
                            {totalComment}
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ mt: 1 }} />
            </CardActions>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { width: '100%' },
                    display: 'flex',
                    margin: '6px 10px 10px'
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleCommentSubmit}
            >
                {/* <input type='text' value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Comment something...' id="fname" name="fname" style={{
                    width: '100%',
                    padding: '12px 20px',
                    // margin: '8px 0px',
                    boxSizing: 'border-box',
                    border: 'none',
                    backgroundColor: '#f1f2f3',
                    color: 'white',
                    outlineColor: '#dcdde1',
                    color: '#525960'
                }} /> */}
                <TextField
                    size='small'
                    fullWidth
                    value={comment}
                    id="basic_comment_textarea_id"
                    placeholder='Comment something...'
                    multiline
                    maxRows={2}
                    sx={{
                        backgroundColor: '#f1f2f3',
                    }}
                    name="description"
                    onChange={(e) => setComment(e.target.value)}
                />
                <Box sx={{ position: 'relative' }}>
                    {/* <Button type='submit' size='small' variant="contained" startIcon={<PostAdd />} disabled={loading ? true : (basicOrRatedPostData.file !== '' || basicOrRatedPostData.description !== '') ? false : true} disableElevation>Post</Button> */}
                    <Button type='submit' size='small' variant="contained" sx={{ ml: 1, py: 1, textTransform: 'capitalize' }} disabled={(createCommentStatus === 'loading') ? true : false} disableElevation>Send</Button>

                    {(createCommentStatus === 'loading') && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#44bd32',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ padding: '0px 8px 24px' }}>
                    <Comment file_type={val.file_type} post_id={val.xz12uied7} role='General' is_colored='no' value='notification_post' newComment={newComment} setNewComment={setNewComment} total_comments={totalComment} set_total_comments={setTotalComment} />
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default NotiLovecard