import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { STATUS } from "./groupSlice";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        destination: {
            type: '',
            id: ''
        },
        accounts: {
            checked: false,
            all_accounts: []
        },


        // =================
        requestList: [],
        hasMoreRequest: true,
        requestListCallNo: 1,

        socket: {},
        // ok ========================================
        userProfileInfo: { name: "", about: '' },
        // ok ========================================
        peopleYouMayKnow: [],
        // ok ========================================
        openSnack: false,
        // ok ========================================
        hasInvitation: 0,

        // ok ========================================
        snackMessage: "",

        // ok ========================================
        accountInfo: {},
        totalNotification: 0,
        notifications: [],
        notificationsCallNo: 1, // ok ========================================
        hasMoreNotifications: true, // ok ========================================
        came_from: {
            from: '',
            id: ''
        },
    },
    reducers: {
        setDestination(state, action) {
            state.destination = action.payload
        },
        clearDestination(state, action) {
            state.destination = {
                type: '',
                id: ''
            }
        },
        setAccounts(state, action) {
            state.accounts = { checked: true, all_accounts: action.payload }
        },
        setPeopleYouMayKnow(state, action) {
            state.peopleYouMayKnow = action.payload.data
        },
        setStatusOfPeopleYouMayKnow(state, action) {
            state.peopleYouMayKnow = state.peopleYouMayKnow.map((people, index) => {
                if (people.user_id === action.payload.id) {
                    return { ...people, status: action.payload.status }
                } else {
                    return people
                }
            })
        },
        setTotalNotification(state, action) {
            state.totalNotification = (state.totalNotification + action.payload)
        },
        emptyTotalNotification(state, action) {
            state.totalNotification = 0
        },
        // ok ===========================
        setSocketInfo(state, action) {
            state.socket = action.payload
        },
        // ok ===========================
        removeSocketInfo(state, action) {
            state.socket = {}
        },
        // ok ========================================
        setNotifications(state, action) {
            state.notifications = action.payload
        },
        // ok ========================================
        appendNotifications(state, action) {
            state.notifications = state.notifications.concat(action.payload)
        },
        // ok ========================================
        appendNewNotificationInList(state, action) {
            state.notifications = [...action.payload, ...state.notifications]
        },
        // ok =========================================
        setNotificationsCallNo(state, action) {
            state.notificationsCallNo = (state.notificationsCallNo + 1)
        },
        // ok =========================================
        setHasMoreNotifications(state, action) {
            state.hasMoreNotifications = action.payload
        },
        //ok =========================================
        emptyNotificationList(state, action) {
            state.notifications = []
            state.hasMoreNotifications = false
            state.notificationsCallNo = 1
        },
        // ok ===========================
        setOpenSnack(state, action) {
            state.openSnack = action.payload
        },
        // ok ===========================
        setHasInvitation(state, action) {
            state.hasInvitation = action.payload
        },
        // ok ===========================
        setSnackMessage(state, action) {
            state.snackMessage = action.payload
        },
        setCameFrom(state, action) {
            state.came_from = action.payload
        },

        // ok ========================================
        setCreateAccountInfo(state, action) {
            Object.assign(state.accountInfo, action.payload)
        },

        // ok ========================================
        addAccountInfo(state, action) {
            state.accountInfo = { ...state.accountInfo, ...action.payload }
        },

        // ok ========================================  user profile info topprofile.jsx
        setuserProfileInfo(state, action) {
            state.userProfileInfo = action.payload
        },
        // ok ========================================  edit user profile info editprofile.jsx
        setuserProfileInfoAbout(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, about: action.payload.about }
        },
        // ok ========================================  edit user profile info editprofile.jsx
        setuserProfileInfoProfilePic(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, profile_pic: action.payload.profile_pic, org_profile_pic: action.payload.org_profile_pic }
        },
        // ok ========================================  edit user profile info editprofile.jsx
        setuserProfileInfoCoverPic(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, cover_pic: action.payload.cover_pic, org_cover_pic: action.payload.org_cover_pic }
        },

        // ok ========================================  edit user profile info editprofile.jsx
        setuserProfileInfoBasicInfo(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, name: action.payload.name, gender: action.payload.gender }
        },

        // ok ========================================  edit user profile info editprofile.jsx
        setuserProfileInfoUserName(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, user_name: action.payload }
        },
        // ok ========================================  edit user profile info topprofile.jsx
        setuserProfileInfoListing(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, is_listed: action.payload }
        },
        setuserProfileInfoFavouriteRelation(state, action) {
            state.userProfileInfo = { ...state.userProfileInfo, ...action.payload }
        },

        // set request list
        setRequestList(state, action) {
            // state.feedType = action.payload.feed_value
            state.requestList = action.payload.data
        },
        // append request list
        appendRequestList(state, action) {
            state.requestList = state.requestList.concat(action.payload.data)
        },
        // set has more
        setHasMoreRequest(state, action) {
            state.hasMoreRequest = action.payload.data
        },
        // set call no
        setRequestListCallNo(state, action) {
            state.requestListCallNo = state.requestListCallNo + 1
        },
        // remove from request list
        removeFromRequestList(state, action) {
            state.requestList = state.requestList.filter(request => request.user_id !== action.payload.user_id)
        },

        // update request list from pending <-> active
        updateRequestListStatus(state, action) {
            state.requestList = state.requestList.map((request, index) => {
                if (request.user_id === action.payload.user_id) {
                    return { ...request, status: action.payload.status }
                } else {
                    return request
                }
            })
        },

        // ok ========================================  SearchedPeopleItem.jsx
        // setUserProfileId(state, action) {
        //     state.userProfileId = action.payload
        // },
        // setStatus(state, action) {
        //     state.status = action.payload
        // },
        // setUserInfo(state, action) {
        //     state.userInfo = action.payload
        // },
        // setProfilePic(state, action) {
        //     // used at the time of update profile pic
        //     state.userInfo.profile_pic = action.payload
        // }
        resetAuthSlice(state, action) {
            state.destination= {
                type: '',
                id: ''
            }
            state.accounts = {
                checked: false,
                all_accounts: []
            }
            state.requestList = []
            state.hasMoreRequest = true
            state.requestListCallNo = 1
            state.socket = {}
            // ok ========================================
            state.userProfileInfo = { name: "", about: '' }
            // ok ========================================
            state.peopleYouMayKnow= []
            // ok ========================================
            state.openSnack = false
            // ok ========================================
            state.hasInvitation = 0

            // ok ========================================
            state.snackMessage = ""

            // ok ========================================
            state.accountInfo = {}
            state.totalNotification = 0
            state.notifications = []
            state.notificationsCallNo = 1 // ok ========================================
            state.hasMoreNotifications = true // ok ========================================
            state.came_from = {
                from: '',
                id: ''
            }
        }
    }

})


export const { setDestination, clearDestination, setAccounts, setPeopleYouMayKnow, setStatusOfPeopleYouMayKnow, resetAuthSlice, setSocketInfo, emptyTotalNotification, setTotalNotification, setNotificationsCallNo, setHasMoreNotifications, setNotifications, appendNotifications, appendNewNotificationInList, emptyNotificationList, removeSocketInfo, setAuth, setuserProfileInfo, setuserProfileInfoListing, setuserProfileInfoAbout, setuserProfileInfoBasicInfo, setuserProfileInfoCoverPic, setuserProfileInfoProfilePic, setuserProfileInfoUserName, setHasInvitation, setOpenSnack, setSnackMessage, setCreateAccountInfo, addAccountInfo, setCameFrom, setStatus, setProfilePic, setUserInfo, setUserProfileId, setuserProfileInfoFavouriteRelation, setRequestList, appendRequestList, setHasMoreRequest, setRequestListCallNo, removeFromRequestList, updateRequestListStatus } = authSlice.actions
export default authSlice.reducer