import { Alert, Box, CircularProgress, Divider, List, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";
import { MoreButton } from "../../CustomComponents";
import { setOpenSnack, setSnackMessage } from "../../store/authSlice";
// import { setGroupList, setNewGroupInList } from "../../store/groupSlice";
import RecommendedGroupListItem from "../Grid/Listitem/RecommendedGroupListItem";
// import {GroupAddIcon,GroupRemove,PlaylistAdd,PlaylistAddCheck} from '@mui/icons-material';
// import { MoreButton } from "../../CustomComponents";
// import Sidebarlistitem from './Listitem/Sidebarlistitem'
// import { setGroupList, setNewGroupInList } from "../../store/groupSlice";

const Recommended = ({ user_id }) => {
  const [status, setStatus] = React.useState('ok') // ok, error, loading, empty
  const [callNo, setCallNo] = React.useState(1)
  const [hasMore, setHasMore] = React.useState(true)
  const [groups, setGroups] = React.useState([])
  const { uid: my_id } = JSON.parse(localStorage.getItem("user")) || {}
  const token = JSON.parse(localStorage.getItem("token"))
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (groups.length <= 0) {
      setStatus('loading')
      axios.get(`${process.env.REACT_APP_API_URL}/api/group/recommended_groups_by_user/${user_id}/${callNo}`, { headers: { authorization: "Bearer " + token } })
        .then(response => {
          if (response.data.data.length === 0) {
            setStatus('empty')
          } else {
            setStatus('ok')
            setCallNo(callNo + 1)
            setGroups(response.data.data)
          }
          if (response.data.data.length < 8) {
            setHasMore(false)
          } else {
            setHasMore(true)
          }
        }).catch(error => {
          setStatus('error')
        })
    }
    // }
    // getGroupList()
  }, [])

  const handleRecommendedAction = (recommended_status, group_id) => {
    axios.put(`${process.env.REACT_APP_API_URL}/api/group/recommending_group`, { group_id, recommended_status }, { headers: { authorization: "Bearer " + token } })
      .then(res => {
        if(recommended_status === 'true'){
          dispatch(setSnackMessage("Done"))
          dispatch(setOpenSnack(true))
        }else{
          dispatch(setSnackMessage(`Now, no one can see this group from your profile.`))
          dispatch(setOpenSnack(true))
        }
        setGroups(groups.map(el => {
          if (el.id === group_id) {
            return { ...el, is_recommended: recommended_status }
          } else {
            return el
          }
        }))
      }).catch(error => {
        dispatch(setSnackMessage("Something went wrong."))
        dispatch(setOpenSnack(true))
      })
  }

  const handleLoadMoreGroups = async () => {
    // setStatus('loading')
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/group/recommended_groups_by_user/${user_id}/${callNo}`, { headers: { authorization: "Bearer " + token } });
      setGroups(groups.concat(res.data.data))
      if (res.data.data.length < 8) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
      // setStatus('ok')
      setCallNo(callNo + 1)
    } catch (error) {
      setStatus('error')
    }
  }

  return (
    <>
      {/* {
        (status === 'loading') &&
        <Box sx={{ textAlign: 'center', p: 1 }}>
          <CircularProgress
            size={24}
            sx={{
              color: '#44bd32',
            }}
          />
        </Box>
      } */}
      {
        (status === 'error') &&
        <Alert severity="error">Sorry, something went wrong</Alert>
      }
      {
        (status === 'empty') &&
        <Alert severity="info">No groups found.</Alert>
      }
      {
        <InfiniteScroll
          dataLength={groups.length}
          next={handleLoadMoreGroups}
          hasMore={hasMore}
          loader={<Box sx={{ height: '40px', position: 'relative' }}><CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          /></Box>}
        // endMessage={
        //   groups.length !== 0 ? <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p> : null
        // }
        >
          {/* <List dense={true} sx={{backgroundColor:'white',}}> */}
          {
            (status === 'ok' && groups.length !== 0) &&
            groups.map((group, index) => {
              // if (group.is_recommended === "true" && group.group_type !== 'private') {
              //   // setShowMessage(false)
              // } else {
              //   setShowMessage(true)
              // }

              if (user_id === my_id) {
                return <RecommendedGroupListItem handleRecommendedAction={handleRecommendedAction} user_id={user_id} is_recommended={group.is_recommended} key={index} description={group.description} pic={group.group_profile_pic} type={group.group_type} subtype={group.subtype} role={group.role} name={group.name} id={group.id} owner={true} />
              } else {
                if (group.is_recommended === "true" && group.group_type !== 'private') {
                  return <RecommendedGroupListItem handleRecommendedAction={handleRecommendedAction} user_id={user_id} is_recommended={group.is_recommended} key={index} description={group.description} pic={group.group_profile_pic} type={group.group_type} subtype={group.subtype} role={group.role} name={group.name} id={group.id} owner={false} />
                }
              }
            })
          }
          {/* </List> */}
        </InfiniteScroll>
      }
    </>
  )
}

export default Recommended