import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4'
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import theme from '../../Theme';

export default function BasicDetails({ setIsMobpass, handleDataSubmit, data, setData, message }) {
  // const [message, setMessage] = React.useState("")
  const [error, setError] = React.useState({ name: false, dob: false, gender: false })
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, [])
  // React.useEffect(() => {
  //   setMessage("")
  //   return () => {
  //     setMessage("")
  //   }
  // }, [])

  const handleBasicDetails = () => {
    var name = data.name.trim()
    var dob = data.dob.trim()
    var gender = data.gender.trim()
    if (!name) {
      setError({ ...error, name: true })
      return
    } else {
      setError({ ...error, name: false })
    }
    if (!dob) {
      setError({ ...error, dob: true })
      return
    } else {
      setError({ ...error, dob: false })
    }
    if (!gender) {
      setError({ ...error, gender: true })
      return
    } else {
      setError({ ...error, gender: false })
    }
    // setIsMobpass(true)
    handleDataSubmit()
  }

  return (
    <>
      <Box sx={{ padding: '10px' }} autoComplete='off'>
        {
          message &&
          <Box sx={{ padding: '20px', borderRadius: '8px', backgroundColor: 'wheat' }}>{message}</Box>
        }
        <TextField
          error={error.name}
          margin="normal"
          required
          fullWidth
          id="name_id"
          label="your name"
          name="name"
          type='text'
          color='primary'
          sx={{ borderColor: 'white', color: 'white', outlineColor: 'white' }}
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <TextField
          error={error.dob}
          fullWidth
          required
          id="dob_input_id"
          label="Your Date of birth"
          type="date"
          name='dob'
          value={data.dob}
          sx={{ my: 1 }}
          // defaultValue={data.dob}
          onChange={(e) => setData({ ...data, dob: e.target.value })}
        />
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={data.gender}
            onChange={(e) => setData({ ...data, gender: e.target.value })}
          >
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        <Box sx={{ position: 'relative', mt: 1, textAlign: 'end' }}>
          <Button
            // disabled={isLoading ? true : false}
            variant="contained"
            sx={{ mr: 1,color: theme.palette.dark.light }}
            disableElevation
            color='light'
            onClick={() => setIsMobpass(true)}
          >
            Back
          </Button>
          <Button
            onClick={handleBasicDetails}
            disabled={isLoading ? true : false}
            variant="contained"
            disableElevation
          >
            Submit
          </Button>
          {/* {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: "#44bd32",
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )} */}
        </Box>
      </Box>
    </>
  );
}

