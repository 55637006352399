import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Clap from '@mui/icons-material/SignLanguage';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import CancelShare from '@mui/icons-material/SyncDisabled';
import Share from '@mui/icons-material/Loop';
import ReactGA from 'react-ga4'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { setOpenSnack, setSnackMessage } from '../../store/authSlice';
import { setOpenUaDialog } from '../../store/groupSlice';
import { setCurrentPostInfo, setOpenBookmarkDialog, setOpenReactDialog, setPostToSave, setReactOnPost, setReactPostInfo, setRemovePostFromFolder } from '../../store/postSlice';
import SharePostMenu from '../Menu/SharePostMenu';
import { BookmarkAddOutlined } from '@mui/icons-material';


export default function Emojibottompost({ value, handleRemovePost, total_comment, total_react, setExpanded, is_saved, is_commented, expanded, react, val, post_id }) {
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [emojiNumber, setEmojiNumber] = React.useState(null);
    const [emojiTotalReact, setEmojiTotalReact] = React.useState(0)
    // const [isReactUpdating, setIsReactUpdating] = React.useState(false)
    const { uid = undefined } = JSON.parse(localStorage.getItem("user")) || {}
    const openOptionMenu = Boolean(anchorE2)
    const socket = useOutletContext()
    const token = JSON.parse(localStorage.getItem("token"))
    const dispatch = useDispatch();
    const navigate = useNavigate()
    React.useEffect(() => {
        if (react !== null) {
            setEmojiNumber(react)
        }
        if (total_react !== 0) {
            setEmojiTotalReact(total_react)
        }
    }, [])

    const handleSharePost = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (val.type === 'public') {
            // if(value === 'myPost') {
            //     dispatch(setMyPostToSave(post_id))
            // }else if(value === 'saved') {
            //     dispatch(setSavePostToSave(post_id))
            // }else if(value === 'pin') {
            //     dispatch(setPinPostToSave(post_id))
            // }else{
            // }
            dispatch(setPostToSave({ feed_value: value, data: post_id }))
            navigate("/shared-to-group", {
                state: {
                    value: value
                },
                // replace: true
            })
        } else {
            dispatch(setSnackMessage(`You can't share from a ${val.type} group.`))
            dispatch(setOpenSnack(true))
        }
    }
    const handleEmojiNumber = (emojiNo) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        // setIsReactUpdating(true)
        const val_copy = { ...val }
        if (!react) {
            // add reaction
            val_copy.react = emojiNo
            val_copy.total_react = (val_copy.total_react + 1)
            // create react     // user_id,post_id,react_value,group_id
            setEmojiNumber(emojiNo)
            setEmojiTotalReact(emojiTotalReact + 1)
            axios.post(`${process.env.REACT_APP_API_URL}/api/post/create/react_post`, { post_id, react_value: emojiNo, group_id: val.group_id, post_type: 'basic', message: `reacted on your post in ${val.group_name} group`, notified_user_id: val.u_id,from_where:val.type,topic:'postReact' }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    // if (value === 'pin') {
                    //     dispatch(setReactOnPinPost({ id: post_id, val_copy }))
                    // } else if (value === 'saved') {
                    //     dispatch(setReactOnSavePost({ id: post_id, val_copy }))
                    // } else if (value === 'myPost') {
                    //     dispatch(setReactOnMyPost({ id: post_id, val_copy }))
                    // } else {
                    // }
                    dispatch(setReactOnPost({ feed_value: value, id: post_id, val_copy }))
                    if (response.data.status === 1) {
                        if (val.creator_id !== uid) {
                            socket?.emit("reactOnPost", { receiverid: val.u_id })
                        }
                    }
                }).catch(error => {
                })
        } else if (react === emojiNo || emojiNumber === emojiNo) {
            // remove react    // react_id,post_id,react_value
            // const val_copy = {...val}
            val_copy.react = null
            val_copy.total_react = (val_copy.total_react - 1)
            // val_copy.my_reaction = 0

            setEmojiNumber(null)
            setEmojiTotalReact(emojiTotalReact - 1)
            axios.delete(`${process.env.REACT_APP_API_URL}/api/post/remove/react_post/${post_id}`, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    // if (value === 'pin') {
                    //     dispatch(setReactOnPinPost({ id: post_id, val_copy }))
                    // } else if (value === 'saved') {
                    //     dispatch(setReactOnSavePost({ id: post_id, val_copy }))
                    // } else if (value === 'myPost') {
                    //     dispatch(setReactOnMyPost({ id: post_id, val_copy }))
                    // } else {
                    // }
                    dispatch(setReactOnPost({ feed_value: value, id: post_id, val_copy }))
                }).catch(error => {
                })
        } else if (react !== emojiNo) {
            // update react     // post_id,before_react_value,react_value,react_id
            // const val_copy = {...val}
            val_copy.react = emojiNo

            setEmojiNumber(emojiNo)
            axios.put(`${process.env.REACT_APP_API_URL}/api/post/update/react_post`, { post_id, react_value: emojiNo }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    // if (value === 'pin') {
                    //     dispatch(setReactOnPinPost({ id: post_id, val_copy }))
                    // } else if (value === 'saved') {
                    //     dispatch(setReactOnSavePost({ id: post_id, val_copy }))
                    // } else if (value === 'myPost') {
                    //     dispatch(setReactOnMyPost({ id: post_id, val_copy }))
                    // } else {
                    // }
                    dispatch(setReactOnPost({ feed_value: value, id: post_id, val_copy }))
                }).catch(error => {
                })
        } else {

        }
        // dispatch()
        // setIsReactUpdating(false)
    }
    const removeFromFolder = (now_post_id) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (value !== 'saved') {
            handleRemovePost()
        }
        if (value === 'saved') {
            dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/api/post/removed_saved_post/${now_post_id}`, { headers: { authorization: "Bearer " + token } })
            .then(res => {
                if (res.data.status === 1) {
                    // if (value === 'pin') {
                    //     dispatch(setRemovePinPostFromFolder(now_post_id))
                    // } else if (value === 'saved') {

                    // } else if (value === 'myPost') {
                    //     dispatch(setRemoveMyPostFromFolder(now_post_id))
                    // } else {
                    // }
                    dispatch(setRemovePostFromFolder({ feed_value: value, data: now_post_id }))
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                } else {
                    dispatch(setSnackMessage(res.data.message))
                    dispatch(setOpenSnack(true))
                }
            }).catch(error => {
                dispatch(setSnackMessage("Something went wrong."))
                dispatch(setOpenSnack(true))
            })
    }
    const handleComment = () => {
        setExpanded(!expanded)
    }

    const goToReactDetails = () => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        if (emojiTotalReact === 1 && react !== null) {
            dispatch(setSnackMessage("Only you react on this post."))
            dispatch(setOpenSnack(true))
            return
        }
        if (emojiTotalReact > 0) {
            // navigate(`/react-details/${post_id}`)
            var post_group_id;
            if(val.group_id) {
                post_group_id = val.group_id
            }else{
                post_group_id = 0
            }
            dispatch(setReactPostInfo({total_react:emojiTotalReact,post_id,post_type:'basic',group_id: post_group_id}))
            dispatch(setOpenReactDialog(true))
        }
    }
    const handleBookmarkPost = (event) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }

        if(val.is_saved === null) {
            dispatch(setCurrentPostInfo({ post_id: val.xz12uied7, feed_value: value }))
            dispatch(setOpenBookmarkDialog(true))
        }else{
            removeFromFolder(val.xz12uied7)
        }

    }

    return (
        <>
            <SharePostMenu openOptionMenu={openOptionMenu} anchorE2={anchorE2} setAnchorE2={setAnchorE2} token={token} group_type={val.type} post_id={val.xz12uied7} value={value}  group_id={val.group_id} creator_id={val.creator_id} group_name={val.group_name}/>
            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 1) ? 'large' : 'small'} sx={{ padding: '0px', color: '#f0932b', marginRight: { xs: '9px', sm: '6px' } }} onClick={() => handleEmojiNumber(1)}>
                        <Clap fontSize='inherit' />
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 2) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(2)}>
                        😆
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 3) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(3)}>
                        😍
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 4) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(4)}>
                        😮
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 5) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(5)}>
                        😘
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 6) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(6)}>
                        😋
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 7) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(7)}>
                        😠
                    </IconButton>
                    <IconButton color="primary" aria-label="add to shopping cart" size={(emojiNumber !== null) && (emojiNumber == 8) ? 'large' : 'small'} sx={{ padding: '0px', marginRight: { xs: '3px', sm: '0px' } }} onClick={() => handleEmojiNumber(8)}>
                        😢
                    </IconButton>
                    <Typography pl={1} variant='body2' onClick={goToReactDetails} sx={{ cursor: 'pointer', display: { xs: 'block', sm: 'none' } }}>| {emojiTotalReact}</Typography>
                    <Typography pl={1} variant='body2' onClick={goToReactDetails} sx={{ cursor: 'pointer', display: { xs: 'none', sm: 'block' } }}>| {emojiTotalReact ? `${emojiTotalReact} reaction${(emojiTotalReact > 1) ? 's' : ''}` : `0 reaction`}</Typography>
                    {/* {
                        isReactUpdating &&
                        <CircularProgress
                            size={20}
                            sx={{
                                color: '#44bd32',
                                marginLeft: '4px'
                            }}
                        />
                    } */}
                    {/* <Typography pl={1} variant='caption' onClick={toggleDrawer("right", true)} sx={{ display: { xs: 'none', md: 'block' }, cursor: 'pointer' }}>| you,128 people reacted</Typography>
                    <Typography pl={1} variant='caption' onClick={toggleDrawer("bottom", true)} sx={{ display: { xs: 'block', md: 'none' }, cursor: 'pointer' }}>| you,128 people reacted</Typography> */}
                    {/* <Button onClick={toggleDrawer("bottom", true)}>Bottom</Button>
                    <Button onClick={toggleDrawer("right", true)}>Right</Button> */}
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    {/* <Button onClick={handleComment} disableElevation disableFocusRipple disableTouchRipple disableRipple variant="text" size='small'
                        startIcon={
                            (is_commented) ?
                                <CommentIcon fontSize='inherit' /> :
                                <CommentOutlinedIcon fontSize='inherit' />
                        }
                    >
                        {total_comment}
                    </Button> */}
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleComment}>
                        {
                            (is_commented) ?
                                <CommentIcon color='primary' sx={{ marginRight: '5px', fontSize: '18px' }} /> :
                                <CommentOutlinedIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} />
                        }
                        {/* <Comment fontSize='small' sx={{ mr: 1, color: "#2d3436" }} /> */}
                        <Typography color={is_commented ? 'primary' : 'inherit'} sx={{ fontSize: '12px' }}>
                            {total_comment}
                        </Typography>
                    </Box>
                    {/* <IconButton aria-label="comment" size='small' onClick={handleComment}>
                        {
                            (is_commented) ? <CommentIcon fontSize='inherit' color='primary' /> : <CommentOutlinedIcon fontSize='inherit' />
                        }
                    </IconButton> */}
                    {/* {
                        (val.type === "public") ?
                            <IconButton aria-label="share" size='small' onClick={handleSharePost}>
                                <SendOutlinedIcon fontSize='inherit' />
                            </IconButton>
                            :
                            <IconButton aria-label="share" size='small'>
                                <CancelShare fontSize='inherit' sx={{ color: '#d63031' }} />
                            </IconButton>

                    } */}
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mx: 2 }} onClick={(event) => setAnchorE2(event.currentTarget)}>
                        {
                            (val.type === "public") ?
                                <Share fontSize='small' sx={{ marginRight: '5px', color: "#2d3436" }} /> :
                                <CancelShare fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} />
                        }
                        {
                            // (val.type === 'public' || (val.shared_by && val.shared_by !== null)) &&
                            (val.type === 'public' || (!val.shared_by)) &&
                            <Typography sx={{ fontSize: '12px' }}>
                                {val.total_share}
                            </Typography>
                        }
                    </Box>
                    <Checkbox
                            checked={is_saved || false}
                            onChange={handleBookmarkPost}
                            inputProps={{ 'aria-label': 'controlled' }}
                            icon={<BookmarkAddOutlined fontSize='small' />}
                            checkedIcon={<BookmarkAddedIcon color='dark' fontSize='small' />}
                        />
                    {/* {
                        is_saved ?
                            <IconButton aria-label="save" size='small' onClick={() => removeFromFolder(post_id)}>
                                <BookmarkAddedIcon fontSize='inherit' sx={{ color: '#2d3436' }} />
                            </IconButton> :
                            <IconButton aria-label="save" size='small' onClick={() => {
                                if (!token) {
                                    dispatch(setOpenUaDialog(true))
                                    return
                                }
                                navigate(`/folders/${post_id}/${val.group_id}`)
                            }}>
                                <BookmarkAddOutlinedIcon fontSize='inherit' sx={{ color: '#2d3436' }} />
                            </IconButton>
                    } */}
                </Box>
                {/* <Folders /> */}
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, marginTop: '8px', justifyContent: 'space-between' }}>
                {/* <Button onClick={handleComment} disableElevation disableFocusRipple disableTouchRipple disableRipple variant="text" size='small'
                    startIcon={
                        (is_commented) ?
                            <CommentIcon fontSize='inherit' color='primary' /> :
                            <CommentOutlinedIcon fontSize='inherit' />
                    }
                >
                    {total_comment}
                </Button> */}
                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleComment}>
                    {
                        (is_commented) ?
                            <CommentIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} /> :
                            <CommentOutlinedIcon sx={{ marginRight: '5px', fontSize: '18px', color: '#2d3436' }} />
                    }
                    <Typography color={is_commented ? 'primary' : 'inherit'} sx={{ fontSize: '12px' }}>
                        {total_comment}
                    </Typography>
                </Box>
                {/* {
                    (val.type === "public") ?
                        <IconButton aria-label="share" size='small' onClick={handleSharePost}>
                            <SendOutlinedIcon fontSize='inherit' />
                        </IconButton>
                        :
                        <IconButton aria-label="share" size='small'>
                            <CancelShare fontSize='inherit' sx={{ color: '#d63031' }} />
                        </IconButton>

                } */}
                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(event) => setAnchorE2(event.currentTarget)}>
                    {
                        (val.type === "public") ?
                            <Share fontSize='small' sx={{ marginRight: '5px', color: "#2d3436" }} /> :
                            <CancelShare fontSize='small' sx={{ marginRight: '5px', color: '#2d3436' }} />
                    }
                    {
                        // (val.type === 'public' || (val.shared_by && val.shared_by !== null)) &&
                        (val.type === 'public' || (!val.shared_by)) &&
                        <Typography sx={{ fontSize: '12px' }}>
                            {val.total_share}
                        </Typography>
                    }
                </Box>
                <Checkbox
                            checked={is_saved || false}
                            onChange={handleBookmarkPost}
                            inputProps={{ 'aria-label': 'controlled' }}
                            icon={<BookmarkAddOutlined fontSize='small' />}
                            checkedIcon={<BookmarkAddedIcon color='dark' fontSize='small' />}
                        />
                {/* {
                    is_saved ?
                        <IconButton aria-label="save" size='small' onClick={() => removeFromFolder(post_id)}>
                            <BookmarkAddedIcon fontSize='inherit' sx={{ color: '#2d3436' }} />
                        </IconButton> :
                        <IconButton aria-label="save" size='small' onClick={() => {
                            if (!token) {
                                dispatch(setOpenUaDialog(true))
                                return
                            }
                            navigate(`/folders/${post_id}/${val.group_id}/${value}`)
                        }}>
                            <BookmarkAddOutlinedIcon fontSize='inherit' sx={{ color: '#2d3436' }} />
                        </IconButton>
                } */}
            </Box>
        </>
    );
}
