import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setOpenUaDialog } from '../../store/groupSlice'
import { setOpenShareDialog, setPostToSave } from '../../store/postSlice'
import { setOpenSnack, setSnackMessage } from '../../store/authSlice'
import { AutoAwesomeMosaicOutlined, Diversity1Outlined, Diversity2Outlined } from '@mui/icons-material'
import axios from 'axios'
import moment from 'moment'

const SharePostMenu = ({openOptionMenu,anchorE2,setAnchorE2,token,group_type,type,post_id,value,group_id,creator_id,group_name}) => {
    const dispatch = useDispatch()

    const handleSharePost = (share_to) => {
        if (!token) {
            dispatch(setOpenUaDialog(true))
            return
        }
        setAnchorE2(null)
        if (group_type === 'public' || group_type === 'channel' || type === 'friend' || type === 'follower') {
            if(share_to === 'group') {
                if(value !== 'channel_article') {
                    dispatch(setPostToSave({ feed_value: value, data: post_id }))
                }
                dispatch(setOpenShareDialog(true))
            }else{
                var posted_group_name = null 
                if(group_name !== undefined) {
                    posted_group_name = group_name
                }
                axios.post(`${process.env.REACT_APP_API_URL}/api/post/share_post_to_member_favorite`, { user_id: creator_id, original_post_id: post_id, shared_from_group_id: group_id, shared_from_group_name: posted_group_name,type:share_to }, { headers: { authorization: "Bearer " + token } })
                .then(response => {
                    if(response.data.status === 1) {
                        // if(creator_id !== null) {
                        //     dispatch(increasePostTotalShareCount({ feed_value: value, post_id, count: 1 }))
                        // }
                    }
                    setAnchorE2(null)
                    dispatch(setSnackMessage(response.data.message))
                    dispatch(setOpenSnack(true))
                }).catch(error => {
                    setAnchorE2(null)
                    dispatch(setSnackMessage(`Sorry,failed to share. Try later.`))
                    dispatch(setOpenSnack(true))
                })
            }
        }else {
            dispatch(setSnackMessage(`You can't share from a ${group_type} group.`))
            dispatch(setOpenSnack(true))
        }
    }

    return (
        <>
            <Menu
                id="share_menu_id"
                anchorEl={anchorE2}
                open={openOptionMenu}
                onClose={() => setAnchorE2(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        boxShadow: '1px 1px 7px #c0c0d8'
                    }
                }}
            >
                {
                    (type !== 'friend') &&
                    <MenuItem onClick={() => handleSharePost('friend')}>
                        <ListItemIcon>
                            <Diversity2Outlined fontSize="small" />
                        </ListItemIcon>
                        Share To Friends
                    </MenuItem>
                }
                {
                    (type !== 'follower') &&
                    <MenuItem onClick={() => handleSharePost('follower')}>
                        <ListItemIcon>
                            <Diversity1Outlined fontSize="small" />
                        </ListItemIcon>
                        Share To Followers
                    </MenuItem>
                }
                <MenuItem onClick={() => handleSharePost('group')}>
                    <ListItemIcon>
                        <AutoAwesomeMosaicOutlined fontSize="small" />
                    </ListItemIcon>
                    Share To Groups
                </MenuItem>
            </Menu>
        </>
    )
}

export default SharePostMenu